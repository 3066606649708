import React, {useState, useContext, useEffect} from 'react'
import Sidebar from '../AppBar/Appbar'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import LoadingStatus from '../../Helper/LoadingStatus'
import { getAllAnnouncements, AddAnnouncement, UpdateAnnouncement, DeleteAnnouncement, RestoreAnnouncement  } from  '../../../redux/actions/announcementActions/announcementAction'
import AnnouncementModal from './announcementModal'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../context/authProvider'
import Button from '@mui/material/Button'
import PaginationFeature from '../Utilities/paginationFeature'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {AnnouncementCategoryLabel, categoryArr, liveStatusArr} from './announcementFunctions'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import { toastTopRightError } from '../../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import './announcement.css'

export default function Announcements() {
    const loadingMsg = 'Fetching Announcements...';
    const [loadingStatus, setLoadingStatus] = useState({
        status: false,
        message: loadingMsg
    })
    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
        },
    }
    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.black,
          fontSize: '12px',
          whiteSpace: 'break-spaces',
        },
    }))
    const [authCreds] = useContext(AuthContext)
    const dispatch = useDispatch()
    const endpoint = authCreds.restEndpoint
    const q = new Date();
    const currentDate = new Date(q.getFullYear(),q.getMonth()+1,q.getDay());
    const getAnnouncementData = useSelector((state) => state.announcementReducer.getAllAnnouncements)
    const [announcementTitle, setAnnouncementTitle] = useState('');
    const [showDeletedAnnouncement, setShowDeletedAnnouncement] = useState(false)
    const [description, setDescription] = useState('')
    const [audience, setAudience] = useState('kholab');
    const [category, setCategory] = useState('informative');
    const [attachmentsPath, setAttachmentPath] = useState('');
    const [isActive, setIsActive] = useState('')
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0])
    const [expiryDate, setExpiryDate] = useState('')
    const [showModal, setshowModal] = useState(false)
    const [channel, setChannel] = useState(localStorage.getItem('channel'))
    const available_page_size = ['20','50', '100', '200', '500']
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(20)
    const [UID, setUID] = useState('')
    const [totalPages, setTotalPages] = useState(1)
    const [totalAnnouncements, setTotalAnnouncements] = useState(1)
    const [announcementData, setAnnouncementData] = useState([])
    const [categoryFilter, setCategoryFilter] = useState([])
    const [chapterFilter, setChapterFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState('All')
    const handleCreate = () => {
        setUID('');
        setAnnouncementTitle('')
        setDescription('')
        setAudience('kholab')
        setAttachmentPath('')
        setCategory('informative')
        setIsActive(true)
        setStartDate(new Date().toISOString().split('T')[0])
        setExpiryDate(null)
        setshowModal(true);
    }
    const handleExternalLink = (e, link) => {
        e.stopPropagation();
        window.open(link, '_blank')
    }
    const handleEdit = (editRows) => {
        if(editRows && editRows.id){
            setUID(editRows.id);
            setAnnouncementTitle(editRows.title)
            setDescription(editRows.description)
            setAudience(editRows.target_audience)
            setAttachmentPath(editRows.attachments_path)
            setCategory(editRows.category)
            setIsActive(editRows.is_active)
            setStartDate(editRows.start_date)
            setExpiryDate(editRows.expiry_date)
            setshowModal(true);
        }
    }
    const handleDeleteRestore = (id, title, type) => {
        let text = 'Delete'
        if(type=='restore') text = 'Restore'
        if (
            window.confirm(
              'Are you sure to '+text+' this Announcement? \n '+title
            )
          ) {
            setLoadingStatus({
              status: true,
              message: loadingMsg
            })

            let params = {
                id: id
            }
            if(type=='restore') {
                dispatch(RestoreAnnouncement(endpoint, params)).then(() => {
                    setLoadingStatus({ status: false, message: loadingMsg })
                    fetchData()
                })
            }else{
                dispatch(DeleteAnnouncement(endpoint, params)).then(() => {
                    setLoadingStatus({ status: false, message: loadingMsg })
                    fetchData()
                })
            }
        }
    }
    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected)
    }

    const fetchData = async () => {
        setLoadingStatus({ status: true, message: loadingMsg })
        const params = {
            per_page: pageSize,
            page: currentPage + 1, //handle 0 indexing
            show_deleted: showDeletedAnnouncement,
            show_inactive: false,
            include_inactive: true
        }
        const filtersArr = [];
        if(statusFilter && statusFilter =='live'){ // Active
            params.show_inactive = false;
            params.include_inactive = false;
        } else if(statusFilter && statusFilter =='archive'){ // Inactive
            params.show_inactive = true;
            params.include_inactive = false;
        } else if (showDeletedAnnouncement) {
            params.include_inactive = false;
        }
        if (categoryFilter && categoryFilter.length) {
            categoryFilter.forEach((category) => {
              filtersArr.push(`{"op":"eq","field":"category","value":"${category}"}`)
            })
        }
        if(chapterFilter && chapterFilter.length) {
            chapterFilter.forEach((chapter) => {
                filtersArr.push(`{"op":"eq","field":"target_audience","value":"${chapter}"}`)
            })
        }

        params.filters = '[' + filtersArr.join(',') + ']';
    
        dispatch(getAllAnnouncements(endpoint, params))
          .then(() => {
            setLoadingStatus({
              status: false,
              message: loadingMsg
            })
          })
          .catch(() => {
            setLoadingStatus({
              status: false,
              message: loadingMsg
            })
          })
    }
    const handleLiveArchive = (statusType, announcementId) => {
        setLoadingStatus({ status: true, message: loadingMsg})
        if(announcementId) {
            const activeText = (statusType == 'archive') ? 'Hide' : 'Visible';
            const activeDesc = (statusType == 'archive') ? 'It will update the status as Inactive.' :'It will update status as Active. Start Date as Today and Expiry Date as 30 days from Today.'
            
            if (window.confirm('Are you sure to '+activeText+' this Announcement? \n '+activeDesc)){
                const payload = {
                    id: announcementId,
                    send_email_notification: false
                }
                if(statusType == 'archive') {
                    payload.is_active = false;
                } else {
                    payload.is_active = true;
                    payload.start_date = new Date().toISOString().slice(0,10);
                    payload.expiry_date = new Date(Date.now() + ( 3600 * 1000 * 24*30)).toISOString().slice(0,10);
                }
                dispatch(UpdateAnnouncement(endpoint, payload))
                .then(() => {
                    setLoadingStatus({
                        status: false,
                        message: loadingMsg
                    })
                    fetchData()
                })
                .catch(() => {
                    setLoadingStatus({
                        status: false,
                        message: loadingMsg
                    })
                })
            }else {
                setLoadingStatus({ status: false, message: loadingMsg })
            }
        }else{
            setLoadingStatus({ status: false, message: loadingMsg })
            toast('Announcement Details Missing!', toastTopRightError)
        }
    }
    
    const handleSubmit = () => {
        setLoadingStatus({ status: true, message: loadingMsg})
        if(announcementTitle && description && category && audience){
            if(startDate) {
                const payload = {
                    is_active: isActive,
                    start_date: startDate ? startDate : null,
                    expiry_date: expiryDate ? expiryDate : null,
                    title: announcementTitle,
                    description: description,
                    category: category,
                    target_audience: audience.toLowerCase(),
                    attachments_path: attachmentsPath,
                    mentioned_emails: null,
                    send_email_notification: false
                }
                if(UID) {
                    payload.id = UID;
                    dispatch(UpdateAnnouncement(endpoint, payload))
                    .then(() => {
                        setLoadingStatus({
                            status: false,
                            message: loadingMsg
                        })
        
                    setshowModal(false)
                        fetchData()
                    })
                    .catch(() => {
                        setLoadingStatus({
                            status: false,
                            message: loadingMsg
                        })
                    })
                } else {
                    dispatch(AddAnnouncement(endpoint, payload))
                    .then(() => {
                        setLoadingStatus({
                            status: false,
                            message: loadingMsg
                        })
        
                        setshowModal(false)
                        fetchData()
                    })
                    .catch(() => {
                        setLoadingStatus({
                            status: false,
                            message: loadingMsg
                        })
                    })
                }
            }else{
                setLoadingStatus({ status: false, message: loadingMsg })
                toast('Please fill all the required fields', toastTopRightError)
            }

        }else{
            setLoadingStatus({ status: false, message: loadingMsg })
            toast('Please fill all the required fields', toastTopRightError)
        }
    }

    useEffect(() => {
        fetchData()
    }, [pageSize, currentPage, showDeletedAnnouncement, statusFilter, categoryFilter, chapterFilter])
    useEffect(()=>{
        let announcement_data = getAnnouncementData?.data
        if (announcement_data?.length > 0) {
            setAnnouncementData(announcement_data)
            setTotalPages(getAnnouncementData?.num_pages)
            setTotalAnnouncements(getAnnouncementData?.total_results)
        }else{
            setAnnouncementData([])
            setTotalPages(0)
            setTotalAnnouncements(0)
        }
    }, [getAnnouncementData])


    return (
        <Sidebar
            channel={channel}
            setChannel={setChannel}
            Page="Announcements"
            Index={
                <>
                
                <div style={{ minHeight: '100vh', backgroundColor: '#f5f7f8', display: 'flex', flexDirection: 'column', gap: '0.5rem', padding: '0.5rem'}}>
                    <div style={{display: 'flex', flexDirection: 'row', gap: '0.5em'}}>
                        <div style={{display: 'flex', alignItems: 'end'}}>
                            <BootstrapTooltip title="Click to Create a new Announcement" >
                                <Button size="medium" variant="outlined" onClick={() => handleCreate()} style={{textTransform: 'none', height: '3em'}}>
                                Submit an Announcement
                                </Button>
                            </BootstrapTooltip>
                        </div>
                        <div>
                            <Box sx={{ minWidth: 120, margin: '10px', marginBottom: 0 }}>
                                <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Status
                                </InputLabel>
                                <Select
                                    sx={{
                                    width: '100%',
                                    height: '37px',
                                    marginTop: '6px',
                                    }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select-standard"
                                    value={statusFilter}
                                    label="badge-name"
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                >
                                    <MenuItem value="All">
                                    <em>All</em>
                                    </MenuItem>
                                    {Object.entries(liveStatusArr).map(([key, val]) => {
                                    return (
                                        <MenuItem key={key} value={key}>
                                        {val}
                                        </MenuItem>
                                    )
                                    })}
                                </Select>
                                </FormControl>
                            </Box>
                        </div>
                        <div>
                            <Box sx={{ minWidth: 120, margin: '10px', maxWidth: 180, marginBottom: 0 }}>
                                <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                <Select
                                    multiple
                                    sx={{
                                    width: '100%',
                                    height: '37px',
                                    marginTop: '6px',
                                    
                                    }}
                                    style={{
                                        textTransform: 'capitalize'
                                    }}
                                    labelId="demo-simple-select-label categoryselectlabel"
                                    id="demo-simple-select-standard"
                                    value={categoryFilter}
                                    label="badge-name"
                                    onChange={(e) => setCategoryFilter(e.target.value)}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {Object.entries(categoryArr)?.map(([key,val]) => {
                                    return (
                                        <MenuItem key={key} value={key}>
                                        <Checkbox checked={categoryFilter.indexOf(key) > -1} />
                                        <ListItemText primary={val} />
                                        </MenuItem>
                                    )
                                    })}
                                </Select>
                                </FormControl>
                            </Box>
                        </div>
                        <div>
                            <Box sx={{ minWidth: 120, margin: '10px', maxWidth: 180, marginBottom: 0 }}>
                                <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Chapter</InputLabel>
                                <Select
                                    multiple
                                    sx={{
                                    width: '100%',
                                    height: '37px',
                                    marginTop: '6px',
                                    
                                    }}
                                    style={{
                                        textTransform: 'capitalize'
                                    }}
                                    labelId="demo-simple-select-label categoryselectlabel"
                                    id="demo-simple-select-standard"
                                    value={chapterFilter}
                                    label="badge-name"
                                    onChange={(e) => setChapterFilter(e.target.value)}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={'kholab'}>
                                        <Checkbox checked={chapterFilter.indexOf('kholab') > -1} />
                                        <ListItemText primary={'Kholab (General)'} />
                                    </MenuItem>
                            {JSON.parse(localStorage.getItem('badge_metadata'))?.channels?.map((chapter, index) => {
                                return (
                                    <MenuItem key={index} value={chapter.toLowerCase()}>
                                        <Checkbox checked={chapterFilter.indexOf(chapter.toLowerCase()) > -1} />
                                        <ListItemText primary={chapter} />
                                    </MenuItem>
                                )
                            })}
                                    {Object.entries(categoryArr)?.map(([key,val]) => {
                                    return (
                                        <MenuItem key={key} value={key}>
                                        <Checkbox checked={categoryFilter.indexOf(key) > -1} />
                                        <ListItemText primary={val} />
                                        </MenuItem>
                                    )
                                    })}
                                </Select>
                                </FormControl>
                            </Box>
                        </div>
                        <Box sx={{ minWidth: 120, display: 'flex', alignItems: 'end' }}>
                            <FormGroup>
                                <BootstrapTooltip title="Click to View Deleted Announcement">
                                <FormControlLabel
                                control={
                                    <Switch
                                    checked={showDeletedAnnouncement}
                                    onChange={() => setShowDeletedAnnouncement(!showDeletedAnnouncement)}
                                    sx={{
                                        paddingRight: '8px',
                                        letterSpacing: '1px',
                                    }}
                                    color="default"
                                    />
                                }
                                label="Deleted Announcement"
                                />
                                </BootstrapTooltip>
                            </FormGroup>
                        </Box>
                    </div>
                    {loadingStatus.status ? (
                    <LoadingStatus status_message={loadingStatus.message} />
                ) : (
                    <>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5em'}}>
                {(announcementData && announcementData.length > 0 )? 
                announcementData.map((item, index)=>{
                    const startDate = new Date(item.start_date);
                    const expiryDate = new Date(item.expiry_date);
                    
                    return (
                        <div key={index} className='announcement-card'>
                            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                <div className='announcement-header'>
                                    <span className='announcement-sender'>{item.created_by.split('@')[0]}</span>
                                    <span className='audience-announcement'>{item.target_audience}{item.target_audience == 'kholab' && ' (General)'}</span> 
                                    {AnnouncementCategoryLabel(item.category)}
                                    {item.is_active ? <span className='active_inactive_badge active_badge'><CheckCircleOutlineIcon /> Active</span> : <span className='active_inactive_badge inactive_badge'><RemoveCircleOutlineIcon /> Inactive</span> }

                                    {(!item.is_active || startDate > currentDate ||  (item.expiry_date && expiryDate < currentDate)) ? (
                                        <span style={{color:'#818182'}}>Hidden</span>
                                    ): (
                                        <span style={{color:'#155724'}}>Visible</span>
                                    )}
                                </div>
                                <div className="announcement-header">
                                    { (item.start_date || item.expiry_date) && (
                                        <span>{item.start_date ? (item.start_date).split("T")[0]  : ''} {item.expiry_date ? ' To '+(item.expiry_date).split("T")[0]  : ''}</span>
                                    )}
                                    
                                    <span style={{color: '#949494'}}>{item.view_count} Views</span>
                                </div>                                        
                            </div>
                            <div style={{fontWeight: '600'}}>{item.title}</div>
                            <p>{item.description}</p>
                            <div style={{display: 'flex', gap: '0.5rem'}}>
                            {(!item.is_active || startDate > currentDate ||  (item.expiry_date && expiryDate < currentDate)) ? (
                                <BootstrapTooltip title="Click to make it Visible in Homepage. It will update status as Active. Start Date as Today and Expiry Date as 30 days from today.">
                                    <Button size="medium" variant="outlined" onClick={() => handleLiveArchive('live', item.id)} style={{textTransform: 'none'}}>
                                        Make it Visible
                                    </Button>
                                </BootstrapTooltip>
                            ) :
                            (
                                <BootstrapTooltip title="Click to make it Hidden in Homepage. It will update the status as inactive.">
                                    <Button size="medium" variant="outlined" onClick={() => handleLiveArchive('archive', item.id)} style={{textTransform: 'none'}}>
                                        Make it Hidden
                                    </Button>
                                </BootstrapTooltip>
                            )
                        
                    }
                                {item.attachments_path && (
                                    <BootstrapTooltip title="Click to Open Extenal Link">
                                        <Button size="medium" variant="outlined" onClick={(e) => handleExternalLink(e, item.attachments_path)} style={{textTransform: 'none'}}>
                                            External Link
                                        </Button>
                                    </BootstrapTooltip>
                                )}
                                
                                <BootstrapTooltip title="Click to Edit this Announcement">
                                    <Button size="medium" variant="outlined" onClick={() => handleEdit(item)} style={{textTransform: 'none'}}>
                                        Edit
                                    </Button>
                                </BootstrapTooltip>
                            {item.is_deleted ? (
                                <BootstrapTooltip title="Click to Restore this Announcement">
                                    <Button size="medium" variant="outlined" onClick={() => handleDeleteRestore(item.id, item.title, 'restore')} style={{textTransform: 'none'}}>
                                        Restore
                                    </Button>
                                </BootstrapTooltip>
                            ): 
                            (
                                <BootstrapTooltip title="Click to Delete this Announcement">
                                    <Button size="medium" variant="outlined" onClick={() => handleDeleteRestore(item.id, item.title, 'delete')} style={{textTransform: 'none'}}>
                                        Delete
                                    </Button>
                                </BootstrapTooltip>
                            )}
                            </div>
                        </div>
                    )
                })
                : <span style={{fontWeight: 600}}>No Announcement Available</span>}
                    </div>
                    <PaginationFeature 
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        currentPage={currentPage}
                        totalResults={totalAnnouncements}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        availablePageSize={available_page_size}
                    />
                    </>
                )}
                </div>
                
                    <AnnouncementModal
                        showModal={showModal}
                        setshowModal={setshowModal}
                        // columnFilters={columnFilters}
                        handleSubmit={handleSubmit}
                        UID={UID}
                        announcementTitle={announcementTitle}
                        setAnnouncementTitle={setAnnouncementTitle}
                        description={description}
                        setDescription={setDescription}
                        audience={audience}
                        setAudience={setAudience}
                        category={category}
                        setCategory={setCategory}
                        attachmentsPath={attachmentsPath}
                        setAttachmentPath={setAttachmentPath}
                        isActive={isActive}
                        setIsActive={setIsActive}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        expiryDate={expiryDate}
                        setExpiryDate={setExpiryDate}
                    />
                </>
            }
        />
    )
}
