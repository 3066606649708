import React, { useEffect, useContext, useState } from 'react'
import LoadingStatus from '../../Helper/LoadingStatus'
import jwt_decode from 'jwt-decode'
import Sidebar from '../AppBar/Appbar'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import { useDispatch, useSelector } from 'react-redux'
import '../../../App.css'
import './idea.css'
import {
  getAllIdeas,
  createIdeasVote,
  createIdea,
  getAllFilters
} from '../../../redux/actions/ideasActions/ideasAction'
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction'
import { AuthContext } from '../../context/authProvider'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import CardsView from '../Utilities/cardView'
import { useNavigate } from 'react-router-dom'
import { toastTopRightError } from '../../Helper/ToastObjects'
import { toast } from 'react-toastify'
import { status_label, statusArr, sortByMapping, orderByMapping, categoryArr, complexityArr, getReviewCount, AddIdeasModel, IdeasPagination } from './IdeaFunction'

export default function IdeasDashboard() {
  let history = useNavigate()
  let tokenValue
  let tokenValues = localStorage.getItem('token')
  if (tokenValues) {
    tokenValue = jwt_decode(tokenValues)
  }
  const loadingMsg = 'Fetching Idea records...'
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: loadingMsg,
  })
  const handleCard = (id) => {
    history(`/ideas/detail?q=${id}`)
  }
  /* eslint-disable no-unused-vars */
  
  const [authCreds] = useContext(AuthContext)
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const filterValues = useSelector((state)=> state.ideasFilterReducer?.getIdeasFilter)
  
  const [ifMyIdeas, setIfMyIdeas] = useState(filterValues[0]?.filterIdeas?.myIdeas)
  const [ifMyLikedIdeas, setIfMyLikedIdeas] = useState(filterValues[0]?.filterIdeas?.myLikedIdeas)
  const [channel, setChannel] = useState(localStorage.getItem('channel'))
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(filterValues[0]?.filterIdeas?.currentPage)
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(filterValues[0]?.filterIdeas?.pageSize);
  const [searchValue, setSearchValue] = useState(filterValues[0]?.filterIdeas?.searchValue)
  const [sortBy, setSortBy] = useState(filterValues[0]?.filterIdeas?.sortBy)
  const [orderBy, setOrderBy] = useState(filterValues[0]?.filterIdeas?.orderBy)
  const [categoryFilter, setCategoryFilter] = useState(filterValues[0]?.filterIdeas?.category) // 'All'
  const [statusFilter, setStatusFilter] = useState(filterValues[0]?.filterIdeas?.status)
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }
  const [showAddIdeas, setShowAddIdeas] = useState(false)
  const [ideaTitle, setIdeaTitle] = useState('')
  const [description, setDescripiton] = useState('')
  const [category, setCategory] = useState('')
  const [chapter, setChapter] = useState('')
  const [complexity, setComplexity] = useState('')
  const handleCloseAddIdeas = () => {
    setShowAddIdeas(false)
  }

  const OpenAddIdeaModal = () => {
    setChapter(localStorage.getItem('channel'))
    setShowAddIdeas(true)
  }
  const handleCreateIdea = () => {
    setLoadingStatus({ status: true, message: loadingMsg })
    if (ideaTitle && description && category && chapter && complexity) {
      if (tokenValue && tokenValue.preferred_username) {
        const body = {
          title: ideaTitle,
          description: description,
          user_email: tokenValue.preferred_username.toLowerCase(),
          idea_category: category,
          chapter: chapter,
          complexity: complexity,
        }
        // create Idea
        dispatch(createIdea(endpoint, body)).then(async() => {
          setLoadingStatus({ status: false, message: loadingMsg })
          handleCloseAddIdeas()
          fetchData()
          const count = await getReviewCount(endpoint, channel);
          setTotalCount(count);
        })
      } else {
        setLoadingStatus({ status: false, message: loadingMsg })
        toast('Please login to continue.', toastTopRightError)
      }
    } else {
      setLoadingStatus({ status: false, message: loadingMsg })
      toast('Please fill all the required fields', toastTopRightError)
    }
  }
  useEffect(() => {
    async function forumMetadata() {
      setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=IDEAS`
      dispatch(getForumMetadata(url)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })

    if (forumMetadata?.loading === false) {
      setLoadingStatus({ status: false, message: 'Fetching data...' })

      localStorage.setItem(
        'channel_metadata',
        JSON.stringify(forumMetadata?.forumMetadata)
      )
    }
  }, [forumMetadata])
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected)
  }
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: '12px',
      whiteSpace: 'break-spaces',
    },
  }))
  const dispatch = useDispatch()
  const handleSearch= () => fetchData();
  const handleSearchEnter = (e) =>{
    if (e.key === 'Enter') {
      fetchData();
    }
  }
  
  const [searchFlag, setSearchFlag] = useState()

  const clearSearch = async () => {
    await setSearchValue('')
    setSearchFlag(true)
  }
  const handleOrderByChange = (val) => {
    setOrderBy(val)
  }
  const handleSortByChange = (val) => {
    setSortBy(val)
  }
  const handleVote = (ideaId, e) => {
    e.stopPropagation()
    if (tokenValue && tokenValue.preferred_username) {
      let payload = {
        id: ideaId,
        user_email: tokenValue.preferred_username.toLowerCase(),
        down_vote: false,
      }
      setLoadingStatus({ status: true, message: loadingMsg })
      dispatch(createIdeasVote(endpoint, payload))
        .then(() => {
          setLoadingStatus({ status: false, message: loadingMsg })
          fetchData()
        })
        .catch(() => {
          setLoadingStatus({ status: false, message: loadingMsg })
        })
    }
  }
  const getAllIdeasData = useSelector(
    (state) => state.getAllIdeasReducer.getAllIdeas
  )
  const [ideasData, setIdeasData] = useState([])
  const endpoint = authCreds.restEndpoint
  /* eslint-disable no-unused-vars */

  const fetchData = async () => {
    setLoadingStatus({ status: true, message: loadingMsg })
    const params = {
      page: currentPage+1,
      per_page: pageSize,
      sort_order: orderBy,
      sort_by_view: false,
      sort_by_vote: false,
    }
    if (sortBy && sortBy == 'Like') {
      params.sort_by_vote = true
    } else if (sortBy && sortBy == 'View') {
      params.sort_by_view = true
    }
    let filtersArr = []
    if (statusFilter && statusFilter != '') {
      statusFilter.forEach((statusItem) => {
        filtersArr.push(`{"op":"eq","field":"status","value":"${statusItem}"}`)
      })
    }else if(!ifMyIdeas){
      filtersArr.push(`{"op":"ne","field":"status","value":"New"}`)
    }
    if (categoryFilter && categoryFilter != 'All') {
      filtersArr.push(
        `{"op":"eq","field":"idea_category","value":"${categoryFilter}"}`
      )
    }
    if (channel && channel !== '') {
      filtersArr.push(`{"op":"eq","field":"chapter","value":"${channel}"}`)
    }
    params.filters = '[' + filtersArr.join(',') + ']'
     
    let url = `${endpoint}/api/ideas/get_all_ideas`
    if (searchValue && searchValue.length > 0) {
      params.query = searchValue
      url = `${endpoint}/api/ideas/search_idea`
    } else {
      params.query = ''
      url = `${endpoint}/api/ideas/get_all_ideas`
    }
    if(ifMyIdeas){
      url = `${endpoint}/api/ideas/my_ideas`
    } else if(ifMyLikedIdeas) {
      url = `${endpoint}/api/ideas/get_my_liked_ideas`
    }

    dispatch(getAllIdeas(url, params))
      .then(() => {
        setLoadingStatus({ status: false, message: loadingMsg })
      })
      .catch(() => {
        setLoadingStatus({ status: false, message: loadingMsg })
      })
  }
  useEffect(()=>{
    if(ifMyIdeas) {
      setIfMyLikedIdeas(false)
    }

    
  },[ifMyIdeas])
  useEffect(()=>{
      if(ifMyLikedIdeas) {
        setIfMyIdeas(false)
      }
      
    },[ifMyLikedIdeas])

  useEffect(() => {
    (async () => {
      const count = await getReviewCount(endpoint, channel);
      setTotalCount(count);
    })();
    fetchData();
  }, [
    sortBy,
    orderBy,
    categoryFilter,
    statusFilter,
    currentPage,
    searchFlag,
    channel,
    ifMyIdeas,
    ifMyLikedIdeas,
    pageSize
  ])
  useEffect(()=>{
    let filters = filterValues;
    if(filters[0]?.filterIdeas?.category){
      filters[0].filterIdeas.category = categoryFilter;
    }
    if(filters[0]?.filterIdeas?.orderBy){
      filters[0].filterIdeas.orderBy = orderBy;
    }
    if(filters[0]?.filterIdeas?.sortBy){
      filters[0].filterIdeas.sortBy = sortBy;
    }
    if(filters[0]?.filterIdeas?.status){
      filters[0].filterIdeas.status = statusFilter;
    }
    if(filters[0]?.filterIdeas?.searchValue || filters[0]?.filterIdeas?.searchValue === ''){
      filters[0].filterIdeas.searchValue = searchValue;
    }
    if(filters[0]?.filterIdeas?.pageSize){
      filters[0].filterIdeas.pageSize = pageSize;
    }
    if(filters[0]?.filterIdeas?.currentPage || filters[0]?.filterIdeas?.currentPage === 0){
      filters[0].filterIdeas.currentPage = currentPage;
    }
    if(filters[0]?.filterIdeas){
      filters[0].filterIdeas.myIdeas = ifMyIdeas;
      filters[0].filterIdeas.myLikedIdeas = ifMyLikedIdeas;
    }


    dispatch(getAllFilters(filters))

  },[categoryFilter, statusFilter, sortBy, orderBy, pageSize, currentPage, searchValue, ifMyIdeas, ifMyLikedIdeas])

  useEffect(() => {
    let ideasDataArr = getAllIdeasData?.data
    if (ideasDataArr?.length > 0) {
      setIdeasData(ideasDataArr)
    } else {
      setIdeasData([])
    }

    setTotalPages(Math.ceil(getAllIdeasData?.total_results / pageSize))
  }, [getAllIdeasData])
  
  return (
    <Sidebar
      channel={channel}
      setChannel={setChannel}
      ifMyIdeas={ifMyIdeas}
      setIfMyIdeas={setIfMyIdeas}
      ifMyLikedIdeas={ifMyLikedIdeas}
      setIfMyLikedIdeas={setIfMyLikedIdeas}
      Page="Ideas"
      totalCount={totalCount}
      Index={
        <div
          style={{
            backgroundColor: '#f5f7f8',
            padding: '15px',
            minHeight: '100vh',
          }}
        >
          
          <div className="nav-menu" style={{ display: 'flex' }}>
            <div>
              <Box sx={{ minWidth: 120, margin: '10px' }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    sx={{
                      width: '100%',
                      height: '37px',
                      marginTop: '6px',
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-standard"
                    value={categoryFilter}
                    label="badge-name"
                    onChange={(e) => setCategoryFilter(e.target.value)}
                  >
                    <MenuItem value="All">
                      <em>All</em>
                    </MenuItem>
                    {Object.entries(categoryArr).map(([key, val]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {val}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div>
              <Box sx={{ minWidth: 120, margin: '10px', maxWidth: 220 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    multiple
                    sx={{
                      width: '100%',
                      height: '37px',
                      marginTop: '6px',
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-standard"
                    value={statusFilter}
                    label="badge-name"
                    onChange={(e) => setStatusFilter(e.target.value)}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {ifMyIdeas && (<MenuItem key="New" value="New">
                      <Checkbox checked={statusFilter.indexOf('New') > -1} />
                      <ListItemText primary="New" />
                    </MenuItem>)}
                    {Object.entries(statusArr)?.map(([key, val]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          <Checkbox checked={statusFilter.indexOf(key) > -1} />
                          <ListItemText primary={key} />
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div>
              <DropdownButton
                id="dropdown-order-by"
                title={`Sort By: ${sortBy}`}
                className="glossary-sort-buttons idea-sort-buttons"
                onSelect={(eventKey) => handleSortByChange(eventKey)}
              >
                {sortByMapping.map((val) => (
                  <Dropdown.Item eventKey={val} key={val}>
                    {val}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div>
              <DropdownButton
                id="dropdown-order-by"
                title={orderBy}
                className="glossary-sort-buttons idea-sort-buttons"
                onSelect={(eventKey) => handleOrderByChange(eventKey)}
              >
                {orderByMapping.map((val) => (
                  <Dropdown.Item eventKey={val} key={val}>
                    {val}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div
              className="search-bar-collab"
              style={{ height: 'fit-content', margin: '13px', width: '30%' }}
            >
              <div
                className="collab-search-container"
                style={{ border: '1px solid #adb5bd', borderRadius: '0' }}
              >
                <input
                  placeholder="search..."
                  type="text"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value)
                  }}
                  onKeyDown={(e)=>handleSearchEnter(e)}
                  className="collab-search"
                />
                <>
                  {searchValue.length > 0 ? (
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip">Clear Search</Tooltip>}
                    >
                      <button onClick={clearSearch} className="clearbtn">
                        <ClearIcon />
                      </button>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </>
              </div>
              <button onClick={handleSearch} className="search-button">
                <SearchIcon />
              </button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BootstrapTooltip title="Click to Create idea">
                <Button
                  size="medium"
                  variant="outlined"
                  onClick={() => OpenAddIdeaModal()}
                >
                  <AddIcon /> Create
                </Button>
              </BootstrapTooltip>
            </div>
          </div>
          
          {loadingStatus.status ? (
            <LoadingStatus status_message={loadingStatus.message} />
          ) : (
            <>
              {ideasData && ideasData?.length == 0 ? (
                <div>More ideas to Come.</div>
              ) : (
                <>
                  <div className="cards">
                    {ideasData?.map((data) => {
                      let retArr = status_label(data.status)

                      return (
                        <CardsView
                          key={data.id}
                          handleClick={() => handleCard(data.id)}
                          title_content={
                            <>
                              {data.title && data.title.length > 35 ? (
                                <BootstrapTooltip title={data.title}>
                                  <span className="idea_title">
                                    {data.title}
                                  </span>
                                </BootstrapTooltip>
                              ) : (
                                <span className="idea_title">{data.title}</span>
                              )}
                              <span className="idea_label">Category</span>{' '}
                              <span className="idea_category_badge">
                                {categoryArr[data.idea_category]}
                              </span>
                              &nbsp;
                              <span className="idea_label">Status</span>{' '}
                              <span
                                className={`idea_category_badge ${retArr.badge_status_class}`}
                              >
                                {data.status}
                              </span>
                              <br />
                              <span className="idea_label">Requester</span>{' '}
                              <span
                                className={`idea_category_badge`}
                                style={{textTransform: 'capitalize'}}
                              >
                                
                                {data.created_by && data.assigned_to && data.created_by.split('@')[0].length > 9 ? (
                                  <span className='idea_user'><BootstrapTooltip title={data.created_by.split('@')[0]}>
                                    {data.created_by.split('@')[0]}
                                  </BootstrapTooltip></span>
                                ) 
                                : 
                                  data.created_by ? 
                                    data.created_by.split('@')[0] 
                                  : 
                                    'N/A'
                              } 
                              </span>
                              <span className="idea_label">Assignee</span>{' '}
                              <span
                                className={`idea_category_badge`}
                              >
                                {data.assigned_to && data.assigned_to.split('@')[0].length > 9 ? (
                                  <span className='idea_user'><BootstrapTooltip title={data.assigned_to.split('@')[0]}>
                                    {data.assigned_to.split('@')[0]}
                                  </BootstrapTooltip></span>
                                ) 
                                : 
                                  data.assigned_to ? 
                                    data.assigned_to.split('@')[0] 
                                  : 
                                    'N/A'
                              }                               
                              </span>
                            </>
                          }
                          border_status_class={retArr.border_status_class}
                          action_content={
                            <>
                              <BootstrapTooltip title="Click to Like idea">
                                <Button
                                  size="small"
                                  className="idea-button"
                                  onClick={(e) => handleVote(data.id, e)}
                                >
                                  <ThumbUpAltIcon /> Like
                                </Button>
                              </BootstrapTooltip>
                              <div
                                style={{
                                  display: 'flex',
                                  flexGrow: '1',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <span className="vote-label-count">
                                  {data.vote_count}
                                </span>
                                <span className="vote-label">Likes</span>
                                <span className="vote-label-count">
                                  {data.view_count}
                                </span>
                                <span className="vote-label">Views</span>
                              </div>
                            </>
                          }
                        />
                      )
                    })}
                  </div>
                  <IdeasPagination
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    totalResults={getAllIdeasData?.total_results}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                  />
                  
                  {/* ADD Ideas MODAL  */}
                </>
              )}
              <AddIdeasModel
                showAddIdeas={showAddIdeas}
                handleCloseAddIdeas={handleCloseAddIdeas}
                handleCreateIdea={handleCreateIdea}
                chapter={chapter}
                channel={channel}
                setIdeaTitle={setIdeaTitle}
                setDescripiton={setDescripiton}
                setCategory={setCategory}
                setChapter={setChapter}
                setComplexity={setComplexity}
                categoryArr={categoryArr}
                complexityArr={complexityArr}
              />
            </>
          )}
        </div>
      }
    />
  )
}
