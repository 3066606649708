import React from 'react'
import { MdHelpOutline } from 'react-icons/md'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Typography } from '@mui/material'
import { Button } from 'react-bootstrap'

export default function helpContent() {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ marginRight: '10px' }}>
      <Button
        aria-label="what is NPS Survey?"
        variant="outlined"
        label="fd"
        onClick={handleClickOpen}
      >
        <MdHelpOutline style={{ fontSize: '28px', color: '#F9D949' }} />
      </Button>

      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-describedby=""
      >
        <DialogTitle>
          Welcome to NPS survey, Your feedback shapes our organisation goals! It
          takes miniumun time to share your experience and help us to create
          efficient features for everyone. Thank you! <br />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography gutterBottom>
              <b>
                {' '}
                - Creation of dynamic survey forms from UI <br />
                - Track record of survey responses and completion percentage
                <br />
                - Helps in improving performance and organisation goals <br />
              </b>
            </Typography>
            <br />
            <Typography gutterBottom>
              <b>
                <u>Create Survey:</u>
              </b>
              <br />
              <br /> Create a new survey to gather feedback from your team.
              Define the survey name, questions, group and start/end date to
              publsh survey. You can also set the survey to private or public.{' '}
              <br />
              Public survey means anyone from the URL can submit their responses
              apart from group memebers.
              <br />
              Private survey means only group members can submit their
              responses. <br />
            </Typography>
            <br />
            <Typography gutterBottom>
              <b>
                <u> Create, View and Modify Groups:</u>
              </b>
              <br />
              <br /> Create a new group and add users to assign surveys while
              creating form. You can also view and modify existing groups. You can
              enable or disable user from the group by simply select the row.{' '}
              <br />
              Admin can view and update all the groups created by user. Group
              owner can view and edit their own groups only. <br />
            </Typography>
            <br />
            <Typography gutterBottom>
              <b>
                {' '}
                <u>Survey Admin:</u>
              </b>
              <br />
              <br /> Admin can view and update all the surveys created by user.
              Survey owner can view and edit their own surveys only. <br />
              In edit modal you can update the survey name, questions, group and
              start/end date. You can also set the survey to private or public.{' '}
              <br />
            </Typography>{' '}
            <br />
            <Typography gutterBottom>
              <b>
                {' '}
                <u>My Surveys:</u>
              </b>
              <br />
              <br />
              View all the surveys created by survey group owner and assigned to
              you. You can view the survey by clicking on the card. <br />
              Fill out all the Mandatory fields showing as required and submit
              your response. <br />
              Once submitted you can not modify or open the filled surveys.
            </Typography>{' '}
            <br />
            <Typography gutterBottom>
              <b>
                If you need any assistance regarding NPS surveys. Please email
                at{' '}
              </b>
              <a
                href="mailto:dl_khcdewebappdev@kraftheinz.com"
                className="email-link"
              >
                dl_khcdewebappdev@kraftheinz.com
              </a>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
