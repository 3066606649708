import Avatar from '@mui/material/Avatar'
import React, { useState, useContext, useEffect } from 'react'
import { stringAvatar } from '../../utils/Avatar'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import TooltipHover from '@mui/material/Tooltip'

import jwt_decode from 'jwt-decode'
import parse from 'html-react-parser'
import './index.css'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../../../../Helper/ToastObjects'
import Comment from './comment'
import { useSelector } from 'react-redux'
import 'quill-mention'
import Answer from './answer'
import LoadingStatus from '../../../../Helper/LoadingStatus'
import { AuthContext } from '../../../../context/authProvider'
import {
  createanswer,
  createcomment,
  createvote,
  getpostbyid,
  subscribePost,
} from '../../../../../redux/actions/collabAction'
import { ForumHandler } from '../../../../Utils'
import MentionHandler from '../../../Utilities/quillSearch'

function MainQuestion({ viewData, roles }) {
  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + '...' : str
  }
  let userFetchTimeOut

  //react quill source callback
  const quillSearch = (searchTerm, renderList, mentionChar) => {
    clearTimeout(userFetchTimeOut)
    if (searchTerm.length >= 3) {
      userFetchTimeOut = setTimeout(() => {
        ForumHandler.fetchUserData(
          endpoint,
          searchTerm,
          renderList,
          mentionChar
        )
      }, 1000)
    }
  }

  const module = MentionHandler.mentionconfig(quillSearch)

  const commentmodule = MentionHandler.mentionconfig(quillSearch)

  let search = window.location.search
  const params = new URLSearchParams(search)
  const id = params.get('q')
  const [authCreds] = useContext(AuthContext)

  const [questionData, setQuestionData] = useState(viewData)
  const [editorContent, setEditorContent] = useState('')
  const [commentEditorContent, setCommentEditorContent] = useState('')
  const [show, setShow] = useState(false)

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const dispatch = useDispatch()
  const getPostbyidcollabData = useSelector(
    (state) => state.getPostbyidcollabData
  )

  // const user = useSelector(selectUser)
  let tokenValue
  let tokenValues = localStorage.getItem('token')
  if (tokenValues) {
    tokenValue = jwt_decode(tokenValues)
  }
  const endpoint = authCreds.restEndpoint

  let admin = roles.includes('khdapi.Admin')
  let collabAdmin = roles.includes('khdapi.collabadmin')
  const handleQuill = (value) => {
    setEditorContent(value)
  }

  const handleCommentQuill = (value) => {
    setCommentEditorContent(value)
  }

  async function getUpdatedAnswer() {
    setLoadingStatus({ status: true, message: 'Fetching data...' })

    dispatch(getpostbyid(endpoint, id)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
    })
  }
  useEffect(() => {
    if (getPostbyidcollabData?.loading == false) {
      let quesData = getPostbyidcollabData?.getPostbyidcollabData?.data

      setQuestionData(quesData?.question)
    }
  }, [getPostbyidcollabData?.getPostbyidcollabData?.data])

  const handleSubcription = async () => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    const body = {
      user_email: tokenValue.preferred_username.toLowerCase(),
      post_id: id,
    }

    dispatch(subscribePost(endpoint, body, false)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      getUpdatedAnswer()
    })
  }
  const handleSubcriptionMulti = async (values) => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    const body = {
      user_email: values,
      post_id: id,
    }

    dispatch(subscribePost(endpoint, body, false)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      setEditorContent('')
      setCommentEditorContent('')
      getUpdatedAnswer()
    })
  }
  const handleUnSubcription = async () => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    const body = {
      user_email: tokenValue.preferred_username.toLowerCase(),
      post_id: id,
    }

    dispatch(subscribePost(endpoint, body, true)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      getUpdatedAnswer()
    })
  }
  const handleSubmit = async () => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    if (editorContent.replace(/<(.|\n)*?>/g, '').trim().length !== 0) {
      const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
      const matches = editorContent.match(regex)
      let mentionVal = matches ? matches.map((mention) => mention.slice(0)) : []
      const body = {
        parent_id: id,
        root_id: id,
        description: editorContent,
        channel: localStorage.getItem('channel'),
        user_email: tokenValue.preferred_username.toLowerCase(),
        mentioned_emails: mentionVal,
      }

      dispatch(createanswer(endpoint, body)).then(() => {
        if (mentionVal.length > 0) {
          dispatch(handleSubcriptionMulti(mentionVal))
        } else {
          setLoadingStatus({ status: false, message: 'Fetching data...' })
          setEditorContent('')
          setCommentEditorContent('')
          getUpdatedAnswer()
        }
      })
    } else {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      toast('Answer can not be empty', toastTopRightError)
    }
  }

  const handleComment = async () => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    if (commentEditorContent.replace(/<(.|\n)*?>/g, '').trim().length !== 0) {
      const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
      const matches = commentEditorContent.match(regex)
      let mentionVal = matches ? matches.map((mention) => mention.slice(0)) : []
      const body = {
        description: commentEditorContent,
        user_email: tokenValue?.preferred_username.toLowerCase(),
        parent_id: id,
        root_id: id,
        mentioned_emails: mentionVal,
      }
      dispatch(createcomment(endpoint, body)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })

        setShow(false)
        getUpdatedAnswer()
        if (mentionVal.length > 0) {
          dispatch(handleSubcriptionMulti(mentionVal))
        } else {
          setLoadingStatus({ status: false, message: 'Fetching data...' })
          setEditorContent('')
          setCommentEditorContent('')
          getUpdatedAnswer()
        }
      })
    } else {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      toast('Comment can not be empty', toastTopRightError)
    }
  }

  const handleUpvote = async () => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    const body = {
      id: id,
      user_email: tokenValue.preferred_username.toLowerCase(),
    }
    dispatch(createvote(endpoint, body)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })

      setShow(false)
      getUpdatedAnswer()
    })
  }
  const handleDownvote = async () => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })

    const body = {
      id: id,
      user_email: tokenValue.preferred_username.toLowerCase(),
      down_vote: true,
    }
    dispatch(createvote(endpoint, body)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })

      setShow(false)
      getUpdatedAnswer()
    })
  }
  const handleUpvoteAns = async (id) => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })

    const body = {
      id: id,
      user_email: tokenValue.preferred_username.toLowerCase(),
    }
    dispatch(createvote(endpoint, body)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })

      setShow(false)
      getUpdatedAnswer()
    })
  }
  const handleDownvoteAns = async (id) => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    const body = {
      id: id,
      user_email: tokenValue.preferred_username.toLowerCase(),
      down_vote: true,
    }
    dispatch(createvote(endpoint, body)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })

      setShow(false)
      getUpdatedAnswer()
    })
  }
  return (
    <>
      {loadingStatus.status && viewData != undefined ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="main">
          <div className="main-container">
            <div className="main-top">
              <h2 className="main-question">{questionData?.title} </h2>
              {/* <Link to="/add-question">
            <button>Ask Question</button>
          </Link> */}
              {/* <a href="/add-question">
            <button>Ask Question</button>
          </a> */}
            </div>
            <div className="main-desc">
              <div className="info-collab">
                <p>
                  Asked
                  <span>
                    {new Date(questionData?.created_on).toLocaleString()}
                  </span>
                </p>
                <p>
                  Active<span>today</span>
                </p>
                <p>
                  Viewed<span>{questionData?.view_count} times</span>
                </p>
                <p>
                  Asked By<span>{questionData?.user_full_name}</span>
                </p>
              </div>
              <div>
                {questionData?.is_subscribed_user ? (
                  <button
                    onClick={handleUnSubcription}
                    className="unsubscribe-btn"
                  >
                    Unsubscribe Post
                  </button>
                ) : (
                  <button onClick={handleSubcription} className="subscribe-btn">
                    Subscribe Post
                  </button>
                )}
              </div>
            </div>
            <div className="all-questions-post">
              <div className="all-questions-container">
                <div className="all-questions-left">
                  <div className="all-options">
                  <TooltipHover
                            arrow
                            placement="right"
                            title={
                              <h6>
                                Give upvote to the question if its useful
                              </h6>
                            }
                          >
                    <p className="arrow" onClick={handleUpvote}>
                      ▲
                    </p>
                    </TooltipHover>

                    <p className="arrow">{questionData?.vote_count}</p>
                    <TooltipHover
                            arrow
                            placement="right"
                            title={
                              <h6>
                                Give downvote to the question if its not useful
                              </h6>
                            }
                          >
                    <p className="arrow" onClick={handleDownvote}>
                      ▼
                    </p>
                            </TooltipHover>
                    {/* <BookmarkIcon />

                    <HistoryIcon /> */}
                  </div>
                </div>
                <div className="question-answer-view">
                  <p>
                    {questionData !== undefined
                      ? parse(questionData?.description)
                      : ''}
                  </p>

                  {/* <div className="author">
                    <small>
                      asked{' '}
                      {new Date(questionData?.created_on).toLocaleString()}
                    </small>
                    <div className="auth-details">
                      <Avatar {...stringAvatar('pankaj madaan')} />
                      <p>
                        {questionData?.user_email
                          ? questionData?.user_email
                          : 'user'}
                      </p>
                    </div>
                  </div> */}
                  <div className="comments">
                    <div
                      className="comment"
                      style={
                        questionData?.comments.length > 0
                          ? { borderTop: '1px solid #eee' }
                          : { borderTop: 'none' }
                      }
                    >
                      {questionData?.comments.map((comment) => {
                        return (
                          <>
                            <p key={comment?.id}>
                              <p style={{ display: 'inline-block' }}>
                                {parse(truncate(comment.description))}
                              </p>
                              <span>
                                -{' '}
                                {comment.user_full_name
                                  ? comment.user_full_name
                                  : 'user'}
                              </span>
                              <small>
                                {new Date(comment.created_on).toLocaleString()}
                              </small>
                              {comment?.is_owner || admin || collabAdmin ? (
                                <Comment
                                  comment={comment}
                                  questionData={questionData}
                                  key={comment.id}
                                  user_email={tokenValue.preferred_username}
                                  func={getUpdatedAnswer}
                                />
                              ) : (
                                ''
                              )}
                            </p>
                          </>
                        )
                      })}
                    </div>
                    <p onClick={() => setShow(!show)}>Add a comment</p>
                    {show && (
                      <div className="title">
                        <ReactQuill
                          style={{ background: '#fff' }}
                          theme="snow"
                          className="react-quill"
                          defaultValue={commentEditorContent}
                          onChange={handleCommentQuill}
                          modules={commentmodule}
                        />
                        <button
                          onClick={handleComment}
                          style={{
                            maxWidth: 'fit-content',
                          }}
                          className="comment-btn"
                        >
                          Add comment
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                flexDirection: 'column',
              }}
              className="all-questions-post"
            >
              <p
                style={{
                  marginBottom: '20px',
                  fontSize: '1.3rem',
                  fontWeight: '300',
                }}
              >
                {questionData && questionData?.answers.length} Answers
              </p>
              {questionData?.answers.map((answer) => (
                <>
                  <div
                    style={{
                      borderBottom: '1px solid #eee',
                    }}
                    key={answer.id}
                    className="all-questions-container"
                  >
                    <div className="all-questions-left">
                      <div className="all-options">
                        <p
                          className="arrow"
                          onClick={() => handleUpvoteAns(answer?.id)}
                        >
                          ▲
                        </p>
                        <p className="arrow">{answer?.vote_count}</p>
                        <p
                          className="arrow"
                          onClick={() => handleDownvoteAns(answer?.id)}
                        >
                          ▼
                        </p>

                        {/* <BookmarkIcon />

                        <HistoryIcon /> */}
                      </div>
                    </div>
                    <div className="question-answer-view">
                      {questionData !== undefined
                        ? parse(answer.description)
                        : ''}
                      {answer?.is_owner || admin || collabAdmin ? (
                        <Answer
                          answer={answer}
                          questionData={questionData}
                          key={answer.id}
                          user_email={tokenValue.preferred_username}
                          func={getUpdatedAnswer}
                        />
                      ) : (
                        ''
                      )}

                      <div className="author">
                        <small>
                          {new Date(answer.created_on).toLocaleString()}
                        </small>
                        <div className="auth-details">
                          <Avatar {...stringAvatar(answer?.user_full_name)} />
                          <p>
                            {answer?.user_full_name
                              ? answer?.user_full_name
                              : 'User'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="main-answer">
            <h3
              style={{
                fontSize: '22px',
                margin: '10px 0',
                fontWeight: '400',
              }}
            >
              Your Answer
            </h3>

            <ReactQuill
              style={{ background: '#fff' }}
              theme="snow"
              className="react-quill"
              defaultValue={editorContent}
              onChange={handleQuill}
              modules={module}
            />
          </div>
          <button
            onClick={handleSubmit}
            style={{
              maxWidth: 'fit-content',
            }}
            className="post-ans-btn"
          >
            Post your answer
          </button>
        </div>
      )}
    </>
  )
}

export default MainQuestion
