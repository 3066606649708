import React, { useState, useEffect, useContext, useRef } from 'react'
import '../indexChapter.css'
import './showUserFormChapter.css'
import parse from 'html-react-parser'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getSurvey,
  submitSurvey,
  getUserSurvey,
} from '../../../../redux/actions/npsActions/npsSurveyAction'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../../context/authProvider'
import LoadingStatus from '../../../Helper/LoadingStatus'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Rating from '@mui/material/Rating'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}))

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
}

function IconContainer(props) {
  const { value, ...other } = props
  return <span {...other}>{customIcons[value].icon}</span>
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
}
export default function ShowForm() {
  const [formValues, setFormValues] = useState([])
  const [checkVal, setcheckValue] = useState([])
  const [userData, setUserData] = useState([])
  const skippedSurveys = useRef([])
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [feedback, setFeedback] = useState(false)
  const [activeRating, setActiveRating] = useState(null);
  const [authCreds] = useContext(AuthContext)
  const endpoint = authCreds.restEndpoint
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const statusSort = 'Pending'
  const getSurveyData = useSelector((state) => state.getSurveyData)
  const getUserSurveyData = useSelector((state) => state.getUserSurveyData)
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: '',
  })
  useEffect(() => {
    if (getUserSurveyData?.loading === false) {
      const filteredSurveys = getUserSurveyData?.getUserSurveyData?.data.filter(
        (survey) => !skippedSurveys.current.includes(survey.id)
      )
      setUserData(filteredSurveys)
    }
  }, [getUserSurveyData, skippedSurveys.current, id])

  const fetchUserSurveyData = () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    let channel =   localStorage.getItem('channel') == null ? '' : localStorage.getItem('channel')
    const params = {
      filters: JSON.stringify([
        { op: 'ilike', field: 'status', value: `%${statusSort}%` },
        { op: 'eq', field: 'channel', value: `${channel}` },

      ]),
    }
    dispatch(getUserSurvey(endpoint, params)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    })
  }
  const loadNextSurvey = () => {
    const nextSurvey = userData.find((survey) => !skippedSurveys.current.includes(survey.id))
    if (nextSurvey) {
      toast.info(`Loading next survey: ${nextSurvey.form_name}`)
      navigate(`/nps-survey/${nextSurvey.id}`);
    } else {
      toast.error('No more surveys available.')
      navigate(`/nps-survey/view-surveys`)
    }
  }

  const handleSkip = () => {
    skippedSurveys.current = [...skippedSurveys.current, id];
    loadNextSurvey();
  }

  const handleDeclineSurvey = () => {
    setFeedback(true)
    setSubmitting(true)
  }

  const validateForm = (inputValues) => {
    let errors = {}
    errors = inputValues.map((obj) => {
      if (obj.required == true && obj.value == '' && feedback == false) {
        toast.error('Please Update Mandatory Fields')
        return (errors.error = 'This field is required')
      } else {
        return errors
      }
    })
    let newOption = errors.filter(function (element) {
      return element !== undefined
    })
    let newOption1 = [...new Set(newOption)]
    return newOption1
  }
  const handleChange = (e, index) => {
    const { value, checked, type } = e.target
    if (checked && type == 'checkbox') {
      setcheckValue([...checkVal, value])
      const values = [...formValues]
      values[index].checkboxValue = [...checkVal, value]
      setFormValues(values)
    }
    const values = [...formValues]
    values[index].value = e.target.value;
    setFormValues(values)
    if ( type === 'Nps') setActiveRating(Number(value));
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateForm(formValues))
    setSubmitting(true)
  }

  useEffect(() => {
    if (submitting == true) {
      if (feedback == true) {
        // Submit the form
        setLoadingStatus({ status: true, message: 'Fetching table records...' })

        const params = {
          survey_id: id,
          survey_response: [],
          decline_feedback: feedback,
        }
        dispatch(submitSurvey(endpoint, params)).then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
          setSubmitting(false)
          setFeedback(false)
          setActiveRating(null)
          skippedSurveys.current = [...skippedSurveys.current, id];
          loadNextSurvey();
        })
      } else if (Object.keys(errors).length === 0 || feedback == false) {
        // Submit the form
        setLoadingStatus({ status: true, message: 'Fetching table records...' })

        const params = {
          survey_id: id,
          survey_response: formValues,
          decline_feedback: feedback,
        }
        dispatch(submitSurvey(endpoint, params)).then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
          setSubmitting(false)
          setActiveRating(null)
          if (Object.values(errors[0]).length === 0) {
            skippedSurveys.current = [...skippedSurveys.current, id];
            loadNextSurvey();
          }
        })
      }
    }
  }, [errors, feedback])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    if (getSurveyData?.loading == false) {
      setFormValues(getSurveyData?.getSurveyData?.data?.survey_json)
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    }
  }, [getSurveyData])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    dispatch(getSurvey(endpoint, id, 'false')).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    })
    fetchUserSurveyData();
    setActiveRating(null)
  }, [id])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="nps detractors-0-6">
          <button onClick={() => navigate('/nps-survey/view-surveys')} class="btn-primary button-2">
            <b>Go back</b>
          </button>
          <div className="form">
            <div className="user-form-heading frame-parent">
              <div class="customer-experience-survey-glo-wrapper">
                <div class="customer-experience-survey-container">
                  <p class="customer-experience-survey">
                    <b>Customer Experience Survey</b>
                  </p>
                  <p class="global-business-service">{getSurveyData?.getSurveyData?.data?.form_name}</p>
                </div>
              </div>
              <div class="text-elementsmessaging-banner">
                <div class="thank-you-for">Thank you for Participating</div>
                <div class="were-looking-for-your-feedbac-wrapper">
                  <div class="customer-experience-survey-container">{getSurveyData?.getSurveyData?.data?.description}</div>
                </div>
              </div>
            </div>
            <div className='questions'>
              {formValues?.map((obj, index) => {
                return (
                  <div className="nps-parent" key={index}>
                    <div className='nps-box'>
                      <div class="h2-headline-parent">
                        <div className='h2-headline'>
                          {obj.type == 'text-read-only' ? (
                            <></>
                          ) : (
                            <label htmlFor={obj.label}>{obj.label}</label>
                          )}
                          {obj.required == true ? (
                            <label htmlFor={obj.label} style={{ color: 'red' }}>
                              *
                            </label>
                          ) : (
                            <></>
                          )}
                        </div>
                        {obj.type == 'radio' ? (
                          <div className="input-nps">
                            {obj.options?.map((option, i) => {
                              return (
                                <div key={i} className="radio-input-nps">
                                  <input
                                    type="radio"
                                    id={option}
                                    value={option}
                                    name="option"
                                    onChange={(e) => handleChange(e, index)}
                                    required={obj.required ? true : false}
                                  />
                                  <label
                                    className="radio-label-nps h2-headline"
                                    htmlFor={option}
                                  >
                                    {option}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        ) : obj.type == 'checkbox' ? (
                          <div className="input-nps">
                            {obj.options?.map((option, i) => {
                              return (
                                <div key={i} className="radio-input-nps">
                                  <input
                                    type="checkbox"
                                    id={option}
                                    value={option}
                                    required={obj.required ? true : false}
                                    onChange={(e) => handleChange(e, index)}
                                  />
                                  <label
                                    className="radio-label-nps"
                                    htmlFor={option}
                                  >
                                    {option}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        ) : obj.type == 'text-read-only' ? (
                          <div className="input-nps">
                            <p> {parse(obj.label)}</p>
                          </div>
                        ) : obj.type == 'textarea' ? (
                          <div className="input-nps">
                            <textarea
                              id={obj.label}
                              placeholder={obj.placeholder || ''}
                              name={obj.label}
                              onChange={(e) => handleChange(e, index)}
                              rows="4"
                              cols="70"
                            />
                          </div>
                        ) : obj.type == 'Nps' ? (
                          <div className="nps-form">
                            <div className="row-nps rating-buttons">
                              {[...Array(11).keys()].map((num) => (
                                <button
                                  key={num}
                                  className={`btn-input ${num === 0 ? 'btn-0' : num === 10 ? 'btn-10' : ''} ${activeRating === num ? 'active' : ''}`}
                                  onClick={() => handleChange({ target: { value: num, type: 'Nps' } }, index)}
                                >
                                  <b>{num}</b>
                                </button>
                              ))}
                            </div>
                            <div className="text-edit-me">
                              <div className="not-at-all">NOT AT ALL LIKELY</div>
                              <div className="not-at-all1">EXTREMELY LIKELY</div>
                            </div>
                          </div>
                        ) : obj.type == 'rating' ? (
                          <div>
                            {obj.ratingType == 'star' ? (
                              <>
                                <Rating
                                  name="half-rating"
                                  onChange={(e) => handleChange(e, index)}
                                  precision={1}
                                />
                              </>
                            ) : obj.ratingType == 'Heart' ? (
                              <>
                                <StyledRating
                                  name="customized-color"
                                  onChange={(e) => handleChange(e, index)}
                                  getLabelText={(value) =>
                                    `${value} Heart${value !== 1 ? 's' : ''}`
                                  }
                                  precision={1}
                                  style={{ color: 'red' }}
                                  icon={<FavoriteIcon fontSize="inherit" />}
                                  emptyIcon={
                                    <FavoriteBorderIcon fontSize="inherit" />
                                  }
                                />
                              </>
                            ) : obj.ratingType == 'faces' ? (
                              <>
                                <StyledRating
                                  name="highlight-selected-only"
                                  IconContainerComponent={IconContainer}
                                  getLabelText={(value) => customIcons[value].label}
                                  highlightSelectedOnly
                                  onChange={(e) => handleChange(e, index)}
                                  s
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <>
                            {' '}
                            <div className="input-nps">
                              <input
                                type={obj.type || 'text'}
                                id={obj.label}
                                value={obj.value || ''}
                                placeholder={obj.placeholder || ''}
                                required={obj.required ? true : false}
                                onChange={(e) => handleChange(e, index)}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div class="text-elementsmessaging-banner-parent">
              <div className="center-view-form-btn">
                <button className="Decline-btn" onClick={handleDeclineSurvey}>
                  Decline Survey
                </button>
                <button className="Skip-btn" onClick={handleSkip}>
                  I will fill it later
                </button>
                <button className="submit-btn" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
