import { React, useState, useContext, useEffect } from 'react'
import './indexChapter.css'
import Sidebar from '../AppBar/Appbar'
import NpsMenu from './chapterNpsMenu'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../context/authProvider'
import jwt_decode from 'jwt-decode'

import { useNavigate } from 'react-router-dom'
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction'
import CardsView from '../Utilities/cardView'
import {
  getSurveyTemplateList,
  deleteTemplate,
} from '../../../redux/actions/npsActions/npsChapterAction'
import LoadingStatus from '../../Helper/LoadingStatus'
import ReactPaginate from 'react-paginate'
import { Button, Modal } from 'react-bootstrap'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import styled from '@mui/material/styles/styled'
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    placement="bottom-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px',
    whiteSpace: 'break-spaces',
  },
}))
function CreateForm() {
  const [authCreds] = useContext(AuthContext)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [showBaseTemp, setShowBaseTemp] = useState(false)
  const [collabMetadata, setCollabMetadata] = useState('')
  const [deleteTemplateId, setDeleteTemplateId] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [channel, setChannel] = useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const getTemplateList = useSelector((state) => state.getTemplateList)
  const [loadingStatus, setLoadingStatus] = useState({
    status: true,
    message: 'Fetching table records...',
  })
  function truncate(str, n) {
    return str?.length > n ? str?.slice(0, n - 1) + '...' : str
  }
  let tokenValue = jwt_decode(localStorage.getItem('token'))

  useEffect(() => {
    if (getTemplateList?.loading == false) {
      setTotalPages(
        Math.ceil(getTemplateList?.getTemplateList?.total_results / 10)
      )
    }
  }, [])

  useEffect(() => {
    async function forumMetadata() {
      // setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=DEFAULT`
      dispatch(getForumMetadata(url)).then(() => {
        // setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])
  const handlePageChange = (number) => {
    setCurrentPage(number.selected)
  }
  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata)
    }
  }, [forumMetadata])
  // get endpoint from authCreds
  const endpoint = authCreds.restEndpoint

  // useDispatch
  const dispatch = useDispatch()

  let history = useNavigate()

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (showBaseTemp) {
      const params = {
        page_size: 10,
        page_number: currentPage + 1,
        filters: JSON.stringify([
          { op: 'eq', field: 'template_channel', value: `${channel}` },
          { op: 'eq', field: 'is_base_template', value: `${showBaseTemp}` },
        ]),
      }
      dispatch(getSurveyTemplateList(endpoint, params)).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    } else {
      const params = {
        page_size: 10,
        page_number: currentPage + 1,
        filters: JSON.stringify([
          { op: 'eq', field: 'template_channel', value: `${channel}` },
        ]),
      }
      dispatch(getSurveyTemplateList(endpoint, params)).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    }
  }, [currentPage, channel, showBaseTemp])

  const handleCardTemp = (base, external, internal, template, createdby) => {
    if (base == true && internal == true) {
      history(
        `/nps-survey/create-template?template=Internal {{CHAPTER}} Member NPS - {{YEAR}} {{QUARTER}}`
      )
    }
    if (base == true && external == true) {
      history(
        `/nps-survey/create-template?template={{CHAPTER}} Stakeholder NPS - {{YEAR}} {{QUARTER}}`
      )
    }
    if (
      base == true &&
      tokenValue.preferred_username.toLowerCase() == createdby &&
      internal == true
    ) {
      history(
        `/nps-survey/edit-template?template=Internal {{CHAPTER}} Member NPS - {{YEAR}} {{QUARTER}}`
      )
    }
    if (
      base == true &&
      tokenValue.preferred_username.toLowerCase() == createdby &&
      external == true
    ) {
      history(
        `/nps-survey/edit-template?template={{CHAPTER}} Stakeholder NPS - {{YEAR}} {{QUARTER}}`
      )
    }
    if (base == false) {
      history(`/nps-survey/edit-template?template=${template}`)
    }
  }

  const handleDeleteTemplate = (e, id) => {
    e.stopPropagation()
    setModalShow(true)
    setDeleteTemplateId(id)
  }
  const handleBaseCheck = () => {
    setShowBaseTemp(!showBaseTemp)
  }
  const handleCloseModal = () => {
    setModalShow(false)
  }
  const deleteTemplatefunc = () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    const params = {
      id: deleteTemplateId,
    }
    dispatch(deleteTemplate(endpoint, params)).then(() => {
      setModalShow(false)
      setDeleteTemplateId('')
      dispatch(
        getSurveyTemplateList(endpoint, {
          page_size: 10,
          page_number: currentPage + 1,
        })
      ).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    })
  }
  return (
    <>
      <div>
        <Sidebar
          Page="NPS"
          setChannel={setChannel}
          channel={channel}
          collabMetadata={collabMetadata}
          Index={
            <>
              {loadingStatus.status ? (
                <LoadingStatus status_message={loadingStatus.message} />
              ) : (
                <div className="nps">
                  {/* <Navbar title="NPS" HelpContent={HelpContent} /> */}

                  <NpsMenu />
                  <div
                    style={{
                      marginLeft: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={showBaseTemp}
                      onChange={handleBaseCheck}
                    />
                    <p style={{ marginLeft: '10px' }}>Show base template</p>
                  </div>
                  <div className="card-section-nps">
                    {getTemplateList?.getTemplateList?.data?.map((result) => {
                      return (
                        <CardsView
                          key="test"
                          title_content={
                            <>
                              {' '}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <span className="survey_title">
                                    {result?.template_name}
                                  </span>

                                  <span className="survey_description">
                                    {truncate(
                                      result?.template_description,
                                      100
                                    )}
                                  </span>
                                  {result?.is_base_template == true &&
                                  result?.is_internal ? (
                                    <span
                                      className="survey_description"
                                      style={{ color: 'blue' }}
                                    >
                                      (Base Template - Internal)
                                    </span>
                                  ) : result?.is_base_template == true &&
                                    result?.is_external ? (
                                    <span
                                      className="survey_description"
                                      style={{ color: 'blue' }}
                                    >
                                      (Base Template - External)
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    height: 'fit-content',
                                    zIndex: '99999',
                                  }}
                                  onClick={(e) =>
                                    handleDeleteTemplate(e, result?.id)
                                  }
                                >
                                  {(result?.is_base_template == true &&
                                    result?.is_external) ||
                                  (result?.is_base_template == true &&
                                    result?.is_internal) ? (
                                    <></>
                                  ) : (
                                    <DeleteIcon style={{ color: 'red' }} />
                                  )}
                                </div>
                              </div>
                              <div>
                                {result?.is_base_template == false &&
                                result?.is_internal == true ? (
                                  <>
                                    <BootstrapTooltip title="This is Internal template">
                                      {' '}
                                      <p className="temp-type">(Internal)</p>
                                    </BootstrapTooltip>
                                  </>
                                ) : result?.is_base_template == false &&
                                  result?.is_external == true ? (
                                  <>
                                    <BootstrapTooltip title="This is External template">
                                      {' '}
                                      <p className="temp-type">(External)</p>
                                    </BootstrapTooltip>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          }
                          border_status_class={
                            'border-primary card-height-chapter-nps'
                          }
                          action_content={<></>}
                          handleClick={() =>
                            handleCardTemp(
                              result?.is_base_template,
                              result?.is_external,
                              result?.is_internal,
                              result?.template_name,
                              result?.created_by
                            )
                          }
                        />
                      )
                    })}
                  </div>
                  <ReactPaginate
                    pageCount={totalPages}
                    onPageChange={handlePageChange}
                    forcePage={currentPage}
                    breakLabel={'...'}
                    previousLabel={'<<'}
                    nextLabel={'>>'}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                  />
                </div>
              )}
            </>
          }
        />
      </div>
      <Modal show={modalShow} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Template</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'hidden' }}>
          Are you sure to delete template ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteTemplatefunc}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default CreateForm
