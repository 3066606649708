import Avatar from '@mui/material/Avatar'
import { React, useContext } from 'react'
import './css/AllQuestions.css'
import axios from 'axios'
import { AuthContext } from '../../../../context/authProvider'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import { stringAvatar } from '../../utils/Avatar'
import EditIcon from '@mui/icons-material/Edit'
function AllQuestions({
  data,
  roles,
  setsearchCollabname,
  setQuestions,
  setTotalPages,
  setLoadingStatus,
  channel,
  setTotalQuestions,
}) {
  const [authCreds] = useContext(AuthContext)

  const endpoint = authCreds.restEndpoint

  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + '...' : str
  }

  function handleTagClick(e) {
    fetchPostsByTag(e.target.innerText)
  }

  const fetchPostsByTag = async (data) => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })

    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    await axios
      .get(
        `${endpoint}/api/collab/get_post_by_tag?tag_name=${data}&page=1&per_page=10&sort_order=desc&channel=${channel}`,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        setsearchCollabname(`[${data}]`)
        setLoadingStatus({ status: false, message: 'Fetching data...' })
        setQuestions(res?.data?.data)
        setTotalPages(Math.ceil(res?.data?.record_count / 10))
        setTotalQuestions(res?.data?.total_count)
      })
      .catch((err) => console.log(err))
  }

  let admin = roles.includes('khdapi.Admin')
  let collabAdmin = roles.includes('khdapi.collabadmin')

  let tags = data?.tags
  return (
    <div className="all-questions">
      <div className="all-questions-container">
        <div className="all-questions-left">
          <div className="all-options">
            <div className="all-option">
              <p>{data?.vote_count}</p>
              <span>votes</span>
            </div>
            <div className="all-option">
              <p>{data?.answer_count}</p>
              <span>answers</span>
            </div>
            <div className="all-option">
              <small>{data?.view_count} views</small>
            </div>
          </div>
        </div>
        <div className="question-answer">
          <Link to={`/forum/post?q=${data?.id}`}>{data.title}</Link>
          <div
            style={{
              lineHeight: '1.5',
              fontSize: '15px',
              overflowWrap: 'anywhere',
            }}
          >
            <p style={{ textAlign: 'justify', fontSize: '15px' }}>
              {parse(truncate(data?.description, 300))}
            </p>
          </div>
          <div className="main-page-tags">
            {tags.map((_tag) => (
              <button key={_tag} className="tagBtn" onClick={handleTagClick}>
                {_tag.tag_name}
              </button>
            ))}
            {data?.is_owner || admin || collabAdmin ? (
              <div className="edit">
                <Link to={`/forum/edit-post?q=${data?.id}`}>
                  {' '}
                  <EditIcon />{' '}
                </Link>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="author">
            <small>{new Date(data?.created_on).toLocaleString()}</small>
            <div className="auth-details">
              <Avatar {...stringAvatar(data?.user_full_name)} />
              <p>{data.user_full_name ? data.user_full_name : 'user'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllQuestions
