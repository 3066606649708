import React from 'react'
import { Table } from 'react-bootstrap'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { Button } from 'react-bootstrap'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import CloseIcon from '@mui/icons-material/Close'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px',
    whiteSpace: 'break-spaces',
  },
}))
export default function placeholderValues({ placeHolderValues }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <BootstrapTooltip title="Placeholder values can be used in templates and creation of surveys. You can define placeholders values in survey title, description and fields using {{ }} for example  {{CHAPTER}}">
        <Button
          className="m_r-5 m_t-10 nav-btn btn-sm badge-nav-btn"
          onClick={handleClickOpen}
          style={{ backgroundColor: '#ff9d0b', borderColor: '#ff9d0b' }}
        >
          {' '}
          Placeholder
        </Button>
      </BootstrapTooltip>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Placeholder values
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div style={{ marginBottom: '10px' }}>
            <p>
              {' '}
              You can use placeholders in survey title, survey description and
              survey question labels to get value populated automatically. The placeholder values will be replaced by their actual values.
            </p>
          </div>
          <div>
            <div>
              <Table bordered hover>
                <thead>
                  <tr
                    style={{
                      fontWeight: '500',
                      fontSize: '1rem',
                      lineHeight: '1.125rem',
                      verticalAlign: 'middle',
                      color: '#78829d',
                    }}
                  >
                    <th>Placeholder Label</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: '.875rem', lineHeight: '1.25rem' }}>
                  {placeHolderValues?.map((item) => {
                    return (
                      <tr key={item.name}>
                        <td>
                          <b>{item.name}</b>
                        </td>
                        <td>
                          {/* <BootstrapTooltip title={`Click to read`}>
                      <a
                        href="#"
                        onClick={(e) => ChapterHeadMail(e, item.user_email)}
                        style={{
                          textDecoration: 'underline',
                          color: 'cornflowerblue',
                        }}
                      > */}
                          {item.description}
                          {/* </a>
                    </BootstrapTooltip> */}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  )
}
