import React, { useContext, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReactQuill from 'react-quill'
import { ForumHandler } from '../../Utils'
import { AuthContext } from '../../context/authProvider'
import { toastTopRightError } from '../../Helper/ToastObjects'
import { toast } from 'react-toastify'
import jwt_decode from 'jwt-decode'
import { createPodEntry } from '../../../redux/actions/podsPageActions/podsAction'
import { useDispatch, useSelector } from 'react-redux'
import LoadingStatus from '../../Helper/LoadingStatus'
import { getPodFunctionalArea } from '../../../redux/actions/podsPageActions/podsAction'
import MentionHandler from '../Utilities/quillSearch'
import PodsHandler from '../Utilities/PodsUtils'

const AddPods = ({
  setShowAddPods,
  fetchData,
  pageSize,
  pageIndex,
  editorContentBuilder,
  setEditorContentBuilder,
  editorContentStakeholder,
  setEditorContentStakeholder,
  editorContentProductOwner,
  setEditorContentProductOwner,
  podName,
  setPodName,
  functionalArea,
  setFunctionalArea,
  description,
  setDescripiton,
  handleOpenRecoverPod,
  setPodRecoverID,
}) => {
  const [authCreds] = useContext(AuthContext)
  let tokenValue = jwt_decode(localStorage.getItem('token'))
  const dispatch = useDispatch()
  const podFunctionalAreas = useSelector((state) => state.podFunctionalAreas)

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  let userFetchTimeOut
  const endpoint = authCreds.restEndpoint

  const handleClose = () => setShowAddPods(false)

  const handleQuillStakeholder = (value) => {
    setEditorContentStakeholder(value)
  }
  const handleQuillProductOwner = (value) => {
    setEditorContentProductOwner(value)
  }
  const handleQuillBuilder = (value) => {
    setEditorContentBuilder(value)
  }

  const quillSearch = (searchTerm, renderList, mentionChar) => {
    clearTimeout(userFetchTimeOut)
    if (searchTerm.length >= 3) {
      userFetchTimeOut = setTimeout(() => {
        ForumHandler.fetchUserData(
          endpoint,
          searchTerm,
          renderList,
          mentionChar
        )
      }, 1000)
    }
  }

  const module = MentionHandler.mentionconfig(quillSearch)

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    dispatch(getPodFunctionalArea(endpoint))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }, [])

  const handleCreatePod = async () => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi

    let stakeholderValues = []
    let productOwnerValues = []
    let builderValues = []

    if (editorContentStakeholder) {
      const stakeholderMatches = editorContentStakeholder.match(regex)
      stakeholderValues = stakeholderMatches
        ? stakeholderMatches.map((mention) => mention.slice(0))
        : []
    }
    if (editorContentProductOwner) {
      const productOwnerMatches = editorContentProductOwner.match(regex)
      productOwnerValues = productOwnerMatches
        ? productOwnerMatches.map((mention) => mention.slice(0))
        : []
    }
    if (editorContentBuilder) {
      const builderMatches = editorContentBuilder.match(regex)
      builderValues = builderMatches
        ? builderMatches.map((mention) => mention.slice(0))
        : []
    }

    if (builderValues.length !== 0 && podName !== '' && description !== '') {
      const allMembers = [
        ...builderValues,
        ...stakeholderValues,
        ...productOwnerValues,
      ]

      if (PodsHandler.checkUniqueInArrays(allMembers)) {
        const body = {
          pod_name: podName,
          user_email: tokenValue?.preferred_username?.toLowerCase(),
          functional_area: functionalArea,
          description: description,
          builders_html: editorContentBuilder,
          stakeholders_html: editorContentStakeholder,
          product_owners_html: editorContentProductOwner,
          pod_members: {
            builder: builderValues,
            stakeholder: stakeholderValues,
            product_owner: productOwnerValues,
          },
          comments: '',
        }
        // create pod dispatch
        dispatch(createPodEntry(endpoint, body))
          .then(() => {
            setLoadingStatus({ status: false, message: 'Fetching data...' })
            handleClose()
            fetchData(
              { pageSize, pageIndex },
              JSON.stringify([{ op: 'eq', field: 'status', value: `Approved` }])
            )
          })
          .catch((error) => {
            if (error.response && error.response.status === 409) {
              setLoadingStatus({
                status: false,
                message: 'Failed to fetch data.',
              })

              handleClose()
              handleOpenRecoverPod()
              setPodRecoverID(error?.response?.data?.pod_data?.id)
            } else {
              console.error('Error fetching data:', error)
              setLoadingStatus({
                status: false,
                message: 'Failed to fetch data.',
              })
            }
          })
      } else {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
        toast(
          'A user can only be assigned as a Builder, Stakeholder, or Product Owner.',
          toastTopRightError
        )
      }
    } else {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      toast('Please fill all the required fields', toastTopRightError)
    }
  }

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Form>
            <Container className="m-0">
              <Row className="mb-3 mr-3 rowFlex">
                <Form.Group
                  className="flexItem col-4"
                  as={Col}
                  md="4"
                  controlId="validationFormikUsername"
                >
                  <Form.Label>
                    Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter pod name"
                    autoFocus
                    onChange={(e) => {
                      setPodName(e.target.value)
                    }}
                  />
                </Form.Group>
                <Form.Group
                  className="flexItem col-1"
                  as={Col}
                  md="4"
                  controlId="validationFormikUsername"
                >
                  <Form.Label>Functional area</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFunctionalArea(e.target.value)
                    }}
                  >
                    <option key="placeholder" value="">
                      Select functional area
                    </option>
                    {podFunctionalAreas?.podFunctionalAreas?.data?.functional_areas?.map(
                      (functionalArea) => (
                        <option key={Math.random()} value={functionalArea}>
                          {functionalArea}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mb-3 mr-3 rowFlex">
                <Form.Group
                  className="flexItem"
                  as={Col}
                  md="4"
                  controlId="validationFormikUsername"
                >
                  <Form.Label>
                    Description<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    onChange={(e) => {
                      setDescripiton(e.target.value)
                    }}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-5 mr-3 mt-2 rowFlex h-25">
                <Form.Group
                  className="flexItem col-4"
                  as={Col}
                  md="4"
                  controlId="validationFormikUsername"
                >
                  <Form.Label>
                    Builders<span className="text-danger">*</span>
                  </Form.Label>
                  <ReactQuill
                    style={{ background: '#fff' }}
                    theme="snow"
                    className="react-quill-badge"
                    // defaultValue={builderPlaceholder}
                    onChange={handleQuillBuilder}
                    modules={module}
                    placeholder="select members using @ operator"
                  />
                </Form.Group>
                <Form.Group
                  className="flexItem col-4"
                  as={Col}
                  md="4"
                  controlId="validationFormikUsername"
                >
                  <Form.Label>Product owners</Form.Label>
                  <ReactQuill
                    style={{ background: '#fff' }}
                    theme="snow"
                    className="react-quill-badge"
                    // defaultValue={builderPlaceholder}
                    onChange={handleQuillProductOwner}
                    modules={module}
                    placeholder="select members using @ operator"
                  />
                </Form.Group>
                <Form.Group
                  className="flexItem col-4"
                  as={Col}
                  md="4"
                  controlId="validationFormikUsername"
                >
                  <Form.Label>Stakeholders</Form.Label>
                  <ReactQuill
                    style={{ background: '#fff' }}
                    theme="snow"
                    className="react-quill-badge"
                    onChange={handleQuillStakeholder}
                    modules={module}
                    placeholder="select members using @ operator"
                  />
                </Form.Group>
              </Row>
            </Container>
          </Form>
          <Modal.Footer>
            <Button
              variant="primary"
              className="addModalBtn"
              type="submit"
              onClick={handleCreatePod}
            >
              Submit
            </Button>
            <Button
              variant="danger"
              type="submit"
              className="addModalBtn"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      )}
    </>
  )
}

export default AddPods
