class PodsHandler {
    static checkUniqueInArrays = (arrays) => {
      const allStrings = arrays.flat();
      const uniqueStrings = new Set(allStrings);
      
      // If the length of the Set is different from the length of the flat array, there are duplicates
      return allStrings.length === uniqueStrings.size;
    };
  }

export default PodsHandler
