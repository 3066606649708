import {
    GET_HOME_FILTERS_ERROR,
    GET_HOME_FILTERS,
    GET_HOME_FILTERS_LOAD,
  } from '../../constants/types'
  
  const initialState = {
    getHomePageFilter: {
        myPods: false
    },
    loading: false,
    error: null,
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_HOME_FILTERS_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
        }
      case GET_HOME_FILTERS:
        return {
          ...state,
          getHomePageFilter: action.payload,
          loading: false,
          error: null,
        }
      case GET_HOME_FILTERS_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        }
  
      default:
        return state
    }
  }
  
