import {
  GET_SNF_CLUSTER_DATA,
  LOAD_SNF_CLUSTER_DATA,
  ERROR_SNF_CLUSTER_DATA,
} from '../../constants/types'

const initialState = {
 snfClusterData:[],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_SNF_CLUSTER_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case ERROR_SNF_CLUSTER_DATA:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_SNF_CLUSTER_DATA: {
      return {
        ...state,
        snfClusterData: action.payload,
        loading: false,
        error: null,
      }
    }

    default:
      return state
  }
}
