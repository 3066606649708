import React, { useRef, useEffect, useContext } from 'react'
import { AuthContext } from '../../context/authProvider'
import { useDispatch, useSelector } from 'react-redux'
import { BiPencil } from 'react-icons/bi'
// profile photo
import GetUserProfilePhoto from '../Utilities/getUserProfilePhoto'
import LoadingStatus from '../../Helper/LoadingStatus'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import {
  Col,
  Row,
  Modal,
  Button,
  Form,
  InputGroup,
  Container,
} from 'react-bootstrap'
import jwt_decode from 'jwt-decode'
import {
  editUser,
  getBadgeusers,
  getUserKudos,
} from '../../../redux/actions/badgeAction'
import { Tabs, Tab } from 'react-bootstrap'
import { Card, CardContent, Typography } from '@mui/material'
import SendKudosForm from '../Kudos/sendKudos'
import StyledBadge from '../Badge/StyledBadge'

export default function userProfileModal({
  selectedUser,
  badgeData,
  setBadgeData,
  showModal,
  setShowModal,
  isLocalUser,
  history,
  pageSize,
  pageIndex,
  userKudos,
  defaultTabSelected = 1
}) {
  const fetchMessage = 'Fetching User Details...'
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: fetchMessage,
  })
  const [authCreds] = useContext(AuthContext)
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [isAboutMeEdited, setIsAboutMeEdited] = React.useState(false)
  const [userAboutMe, setUserAboutMe] = React.useState('')
  const [emailModal, setemailModal] = React.useState(false)
  const [kudosModal, setKudosModal] = React.useState(false)
  const [userProfileRenderKey, setUserProfileRenderKey] = React.useState(defaultTabSelected)
  const selected_user_email = selectedUser ? `${selectedUser.toLowerCase() + '@kraftheinz.com'}`: '';
  const filter = JSON.stringify([
    {
      op: 'eq',
      field: 'user_email',
      value: selected_user_email,
    },
  ])
  const userBadges = useSelector((state) => state.userBadges)
  //  const editUserData = useSelector((state) => state.editUserData)
  let tokenValue = jwt_decode(localStorage.getItem('token'))

  useEffect(() => {
    if (!badgeData) {
      // load badgeData
      setLoadingStatus({
        status: true,
        message: fetchMessage,
      })
      fetchUserData({ pageSize, pageIndex }, filter)
    }
  }, [])

  useEffect(() => {
    setLoadingStatus({
      status: true,
      message: 'Fetching table records...',
    })
    if (userBadges?.loading === false) {
      setLoadingStatus({
        status: false,
        message: fetchMessage,
      })
    }
    // only update badgeData if user has requested their own profile details
    if (
      userBadges?.userBadges?.data?.length == 1 &&
      userBadges?.userBadges?.data[0].user_name == selectedUser.toLowerCase()
    ) {
      setBadgeData(userBadges?.userBadges?.data[0])
    }
  }, [userBadges])

  useEffect(() => {
    setUserAboutMe(badgeData?.about_me)
  }, [badgeData])
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })

    if (!userKudos) {
      dispatch(getUserKudos(endpoint, selected_user_email)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }

    if (userKudos?.loading === false) {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
    }
  }, [userKudos])

  const getUserName = (email) => {
    if (!email) return ''
    return email.split('@')[0]
  }
  const navigateToAddQuestion = (userID, userName) => {
    history('/add-question', { state: { from: 'users', userID, userName } })
  }
  const turnOnEditMode = () => {
    setIsEditMode(true)
    inputRef.current.focus()
  }
  const inputRef = useRef(null)

  const openemailModal = () => {
    setemailModal(true)
  }
  const openKudosModal = () => {
    setKudosModal(true)
  }

  const handleCloseKudosModal = () => {
    setKudosModal(false)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    //    setImageUrl(null)
    setUserAboutMe('')
    setIsAboutMeEdited(false)
    setBadgeData(null)
    // if (isLocalUser && window.location.href.includes('/badge')) {
    //   const ps = 50
    //   fetchUserData({ ps, pageIndex })
    // }
  }
  const updateUserAboutMe = () => {
    if (isAboutMeEdited) {
      handleSubmit()
    }
    setIsAboutMeEdited(false)
  }
  const handleCloseEmailModal = () => {
    setemailModal(false)
  }

  function formatKudosDate(dateString) {
    const date = new Date(dateString)
    const options = { day: 'numeric', month: 'short', year: 'numeric' }
    return date.toLocaleDateString('en-GB', options)
  }

  function getAboutMeSection(about_me) {
    return !isLocalUser ? (
      <div className="details-inner">
        <p className="details-text">{about_me}</p>
      </div>
    ) : (
      <Form>
        <Container className="m-0">
          <Row className="mb-3 mr-3 rowFlex">
            <Form.Group
              className=""
              as={Col}
              md="12"
              controlId="validationFormikAboutMe"
            >
              <InputGroup>
                <Form.Control
                  ref={inputRef}
                  as="textarea"
                  rows={3}
                  aria-describedby="inputGroupPrepend"
                  name="AboutMe"
                  placeholder="My Intro..."
                  defaultValue={userAboutMe}
                  readOnly={!isEditMode}
                  onBlur={() => setIsEditMode(false)}
                  onChange={(e) => {
                    setUserAboutMe(e.target.value)
                    setIsAboutMeEdited(true)
                  }}
                />
                {!isEditMode && isLocalUser && (
                  <InputGroup.Text
                    className="edit-icon"
                    id="inputGroup-sizing-lg"
                    onClick={turnOnEditMode}
                  >
                    <BiPencil />
                  </InputGroup.Text>
                )}
              </InputGroup>
            </Form.Group>
          </Row>
        </Container>
      </Form>
    )
  }

  const renderKudosMessagesOnUserProfile = () => {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px' }}
      >
        <br />
        {userKudos?.userKudos?.map((kudos, index) => (
          <Card key={index} className="mb-3 kudos-card">
            <Typography
              variant="caption"
              style={{
                position: 'absolute',
                top: '3px',
                left: '10px',
                padding: '5px',
              }}
            >
              {formatKudosDate(kudos.submitted_on)}
            </Typography>
            <CardContent>
              <Typography variant="body1">{kudos.message}</Typography>
            </CardContent>
            <Typography
              variant="caption"
              style={{ position: 'absolute', bottom: '1px', right: '16px' }}
            >
              Appreciated by {kudos.sender_email}
            </Typography>
          </Card>
        ))}
      </div>
    )
  }

  const renderBadgesOnUserProfile = () => {
    return (
      <>
      <div className="user-profile-heading"><MilitaryTechIcon /> Chapter badges earned!</div>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >

          {badgeData?.badge_list
            ?.filter((obj) => obj.badge_level_name === 'Gold')
            .map((res) => {
              if (badgeData?.role_type === res.role_type) {
                return (
                  <StyledBadge key={res.id} badgeData={res} badgeType="Gold" /> 
                )
              }
              return null
            })}
          {/* </div> */}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {badgeData?.badge_list
            ?.filter((obj) => obj.badge_level_name === 'Silver')
            .map((res) => {
              if (badgeData?.role_type === res.role_type) {
                return (
                  <StyledBadge key={res.id} badgeData={res} badgeType="Silver"/> 
                )
              }
              return null
            })}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {badgeData?.badge_list
            ?.filter((obj) => obj.badge_level_name === 'Bronze')
            .map((res) => {
              if (badgeData?.role_type === res.role_type) {
                return (
                  <StyledBadge key={res.id} badgeData={res} badgeType="Bronze"/> 
                )
              }
              return null
            })}
        </div>
      </div>

      <div className="user-profile-heading"><MilitaryTechIcon /> Non-Chapter badges earned!</div>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >

          {badgeData?.badge_list
            ?.filter((obj) => obj.badge_level_name === 'Gold')
            .map((res) => {
              if (badgeData?.role_type !== res.role_type) {
                return (
                  <StyledBadge key={res.id} badgeData={res} badgeType="Gold"/> 
                )
              }
              return null
            })}
          {/* </div> */}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {badgeData?.badge_list
            ?.filter((obj) => obj.badge_level_name === 'Silver')
            .map((res) => {
              if (badgeData?.role_type !== res.role_type) {
                return (
                  <StyledBadge key={res.id} badgeData={res} badgeType="Silver"/> 
                )
              }
              return null
            })}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {badgeData?.badge_list
            ?.filter((obj) => obj.badge_level_name === 'Bronze')
            .map((res) => {
              if (badgeData?.role_type !== res.role_type) {
                return (
                  <StyledBadge key={res.id} badgeData={res} badgeType="Bronze"/> 
                )
              }
              return null
            })}
        </div>
      </div>
      </>
    )
  }

  const handleSubmit = async () => {
    setIsEditMode(false)
    setLoadingStatus({ status: true, message: fetchMessage })
    const payload = {
      user_email: badgeData?.user_email,
      first_name: badgeData?.first_name,
      last_name: badgeData?.last_name,
      user_name: badgeData?.user_name,
      title: badgeData?.title,
      role_type: badgeData?.role_type,
      company: badgeData?.company,
      khc_group: badgeData?.khc_group,
      city: badgeData?.city,
      country: badgeData?.country,
      badge_score: badgeData?.badge_score,
      about_me: userAboutMe,
      is_active: badgeData?.is_active,
      start_date: badgeData?.start_date,
      created_on: badgeData?.created_on,
      created_by: badgeData?.created_by,
    }
    dispatch(editUser(endpoint, payload))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: fetchMessage,
        })
        fetchUserData({ pageSize, pageIndex }, filter)
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: fetchMessage,
        })
      })
  }

  // Used to fetch new table data from server
  const fetchUserData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    show_deleted = false,
    sort_value = '[]'
  ) => {
    setLoadingStatus({ status: true, message: fetchMessage })
    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters,
      show_deleted: show_deleted,
      sort: sort_value,
      channel: null
    }
    dispatch(getBadgeusers(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: fetchMessage,
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: fetchMessage,
        })
      })
  }

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Modal size="xl" show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title> USER PROFILE</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody" style={{ textAlign: 'center' }}>
              <>
                <div className="containerBadge">
                  <div className="main-card">
                    <div className="cards">
                      <div className="card">
                        <div className="content">
                          <div className="img">
                            <GetUserProfilePhoto
                              selected_user_email={badgeData?.user_email}
                            />
                          </div>
                          <div className="details">
                            <div className="details-inner">
                              <b className="font-text"> Email:</b>{' '}
                              <p className="details-text">
                                {badgeData?.user_email}
                              </p>
                            </div>
                            <div className="details-inner">
                              <b className="font-text"> Company:</b>{' '}
                              <p className="details-text">
                                {badgeData?.company}
                              </p>
                            </div>
                            <div className="details-inner">
                              <b className="font-text"> Group:</b>{' '}
                              <p className="details-text">
                                {badgeData?.khc_group}
                              </p>
                            </div>
                            <div className="details-inner">
                              <b className="font-text">Title:</b>{' '}
                              <p className="details-text">{badgeData?.title}</p>
                            </div>
                            <div className="details-inner">
                              <b className="font-text">Country:</b>{' '}
                              <p className="details-text">
                                {badgeData?.country}
                              </p>
                            </div>
                            <div className="details-inner">
                              <b className="font-text">City:</b>{' '}
                              <p className="details-text">{badgeData?.city}</p>
                            </div>
                            <div className="details-inner">
                              <b className="font-text">Time in position:</b>{' '}
                              <p className="details-text">
                                {badgeData?.time_in_position}
                              </p>
                            </div>
                            <div className="details-inner">
                              <b className="font-text">About Me:</b>{' '}
                            </div>
                            {getAboutMeSection(badgeData?.about_me)}
                          </div>
                          {(isEditMode || isAboutMeEdited) && isLocalUser && (
                            <div className="media-icons">
                              <Button onClick={updateUserAboutMe}>
                                Update Bio
                              </Button>
                            </div>
                          )}
                          <div
                            className="media-icons"
                            style={{ display: 'flex', gap: '20px' }}
                          >
                            <Button
                              onClick={openemailModal}
                              style={{ padding: '5px', margin: 0 }}
                            >
                              Send message
                            </Button>
                            <Button
                              onClick={openKudosModal}
                              style={{ padding: '5px', margin: 0 }}
                              disabled={
                                selected_user_email.toLowerCase() ==
                                tokenValue.preferred_username.toLowerCase()
                              }
                            >
                              Send Kudos
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tabs-view">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Tabs
                        defaultActiveKey={defaultTabSelected ? defaultTabSelected : 1}
                        id="uncontrolled-tab-example"
                        className="user-profile-tabs"
                        style={{ border: 'none' }}
                        onSelect={(key) => setUserProfileRenderKey(key)}
                      >
                        <Tab
                          eventKey={1}
                          title={
                            'BADGES (' +
                            badgeData?.badge_list?.length +
                            ')'
                          }
                        ></Tab>
                        <Tab
                          eventKey={2}
                          title={
                            'VIEW KUDOS (' + userKudos?.userKudos?.length + ')'
                          }
                        ></Tab>
                      </Tabs>
                    </div>
                    {userProfileRenderKey == 1
                      ? renderBadgesOnUserProfile()
                      : renderKudosMessagesOnUserProfile()}
                  </div>
                </div>

                <Modal.Footer style={{ marginTop: '10px' }}>
                  <Button
                    variant="danger"
                    type="submit"
                    className="addModalBtn"
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </>
            </Modal.Body>
          </Modal>
          <Modal show={emailModal} onHide={handleCloseEmailModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                Do you want to send an Email or Post in Q&A Forum?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: 'hidden' }}>
              If you have a generic question that others can benefit from,
              please post it in the Q&A Forum forum, if not continue to send
              email.
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() =>
                  navigateToAddQuestion(
                    badgeData?.user_email,
                    badgeData?.user_email
                      ? getUserName(badgeData.user_email)
                      : ''
                  )
                }
                variant="primary"
              >
                Post in Q&A Forum
              </Button>
              <a href={'mailto:' + badgeData?.user_email}>
                <Button>Send Email</Button>
              </a>
              <Button variant="secondary" onClick={handleCloseEmailModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal size="xl" show={kudosModal} onHide={handleCloseKudosModal}>
            <Modal.Header closeButton>
              <Modal.Title>Send Kudos</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
              <SendKudosForm
                setKudoModalCollab={setKudosModal}
                receiverEmails={[selected_user_email]}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  )
}
