import React from 'react'
import { MdHelpOutline } from 'react-icons/md'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Typography } from '@mui/material'
import { Button } from 'react-bootstrap'

export default function IngestionHelpContent() {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ marginRight: '10px' }}>
      <Button
        aria-label="what is NPS Survey?"
        variant="outlined"
        label="fd"
        onClick={handleClickOpen}
      >
        <MdHelpOutline style={{ fontSize: '28px', color: '#F9D949' }} />
      </Button>

      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-describedby=""
      >
        <DialogTitle>
          Welcome to Ingestion Intake Form, Your feedback shapes our
          organisation goals! It takes minumun time to share your experience and
          help us to create efficient features for everyone. Thank you! <br />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography gutterBottom>
              <b>
                {' '}
                - <br />
                - <br />
                - <br />
              </b>
            </Typography>
            <br />
            <Typography gutterBottom>
              <b>
                <u>Create Ingestion Intake:</u>
              </b>
              <br />
              <br />
              <br />
              <br />
              <br />
            </Typography>
            <br />
            <Typography gutterBottom>
              <b>
                <u> Explanation of Ingestion Intake Form:</u>
              </b>
              <br />
              <br /> <br />
              <br />
            </Typography>
            <br />
            <Typography gutterBottom>
              <b>
                {' '}
                <u>Approval of Ingestion Intake Form</u>
              </b>
              <br />
              <br /> <br />
              <br />
            </Typography>{' '}
            <br />
            <Typography gutterBottom>
              <b>
                {' '}
                <u>Benfits of Ingestion Intake form:</u>
              </b>
              <br />
              <br />
              <br />
              <br />
            </Typography>{' '}
            <br />
            <Typography gutterBottom>
              <b>
                If you need any assistance regarding Ingestion Intake. Please
                email at{' '}
              </b>
              <a
                href="mailto:dl_khcdewebappdev@kraftheinz.com"
                className="email-link"
              >
                dl_khcdewebappdev@kraftheinz.com
              </a>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
