import React, { useContext, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getGlossaryTermByID } from '../../../../redux/actions/glossaryAction'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../../context/authProvider'
import {
  approveGlossaryTerm,
  rejectGlossaryTerm,
} from '../../../../redux/actions/glossaryAction'
import { getKholabChapterAdmin } from '../../../../redux/actions/kholabMetadataAction'
import jwt_decode from 'jwt-decode'
import LoadingStatus from '../../../Helper/LoadingStatus'
import parse from 'html-react-parser'
import EditTermModal from '../editTerm'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import 'react-quill/dist/quill.snow.css'
import KhcLogo from '../../../../media/kraftheinzlogo.png'

export default function ApproveTerm({ isAdmin, channel, userEmail }) {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const { id } = useParams()
  const [authCreds] = useContext(AuthContext)
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  let tokenValue = jwt_decode(localStorage.getItem('token'))
  const token = localStorage.getItem('token')
  const glossaryTermByID = useSelector(
    (state) => state.glossaryTermByID.term.data
  )
  const isChapterAdmin = useSelector(
    (state) => state.kholabChapterAdmin.chapterAdmin.is_admin
  )
  const [openModal, setOpenModal] = React.useState(false)
  const channels = useSelector(
    (state) => state.forumMetadata.forumMetadata.channels
  )

  function parseHtml() {
    const definition = glossaryTermByID?.definition
    if (!definition || definition.length === 0) {
      return ''
    }
    return parse(definition)
  }

  function TagsSection({ title, content }) {
    if (!content || content.length === 0) {
      return (
        <div>
          <strong>{title}:</strong>
        </div>
      )
    }
    return (
      <div>
        <strong>{title}:</strong>{' '}
        {content.map((item) => item.tag_name).join(', ')}
      </div>
    )
  }

  function DateMetadataSection({ title, by, on }) {
    if (!by || by.length === 0) {
      return (
        <div>
          <strong>{title}:</strong>
        </div>
      )
    }
    return (
      <div>
        <strong>{title}:</strong> {by ? `${by} - ` : ''}
        {on}
      </div>
    )
  }

  function MetadataSection({ title, content }) {
    if (!content) {
      return (
        <div>
          <strong>{title}</strong>
        </div>
      )
    } else {
      return (
        <div>
          <strong>{title}:</strong> {content}
        </div>
      )
    }
  }

  const handleApprove = () => {
    let payload = {
      approval_status: 'Approved',
      approved_by: tokenValue.preferred_username,
      modified_by: tokenValue.preferred_username,
      role_type: glossaryTermByID.role_type,
    }
    dispatch(approveGlossaryTerm(endpoint, payload, id))
  }

  const handleReject = () => {
    let payload = {
      approval_status: 'Rejected',
      modified_by: tokenValue.preferred_username,
      role_type: glossaryTermByID.role_type,
    }
    dispatch(rejectGlossaryTerm(endpoint, payload, id))
  }

  const fetchData = async () => {
    setLoadingStatus({ status: true, message: 'Fetching table record...' })
    dispatch(getGlossaryTermByID(endpoint, id))
    dispatch(getKholabChapterAdmin(endpoint, channel, userEmail))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table record...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table record...',
        })
      })
  }

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
    fetchData()
  }

  useEffect(() => {
    if (token != null) {
      fetchData()
    }
  }, [dispatch, id])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="glossary-approve-container">
          {isAdmin || isChapterAdmin ? (
            <div>
              <Link to="/glossary/pending">
                <Button
                  variant="success"
                  style={{ marginRight: '20px' }}
                  onClick={handleApprove}
                >
                  Approve
                </Button>
              </Link>
              <Link to="/glossary/pending">
                <Button variant="danger" onClick={handleReject}>
                  Reject
                </Button>
              </Link>
            </div>
          ) : null}
          {glossaryTermByID?.is_khc_term ? (
            <img
              src={KhcLogo}
              alt="Kraft Heinz Logo"
              className="glossary-term-khc-logo"
            />
          ) : null}
          <div className="glossary-term-header-container">
            <h1>{glossaryTermByID?.term}</h1>
            {glossaryTermByID?.is_owner || isAdmin || isChapterAdmin ? (
              <div>
                <IconButton onClick={() => handleModalOpen()}>
                  <EditIcon
                    className="glossary-term-edit-icon"
                    fontSize="large"
                  />
                </IconButton>
                <EditTermModal
                  onOpen={openModal}
                  onClose={handleModalClose}
                  channels={channels}
                  tokenValue={token}
                  existingTermData={glossaryTermByID}
                  endpoint={endpoint}
                />
              </div>
            ) : null}
          </div>
          <hr className="glossary-term-border-line" />
          {glossaryTermByID?.is_khc_term ? (
            <MetadataSection title="Kraft Heinz Related Term" />
          ) : null}
          <MetadataSection
            title="Channel"
            content={glossaryTermByID?.role_type}
          />
          <TagsSection title="Tags" content={glossaryTermByID?.tags} />
          <hr className="glossary-term-border-line" />
          <div className="glossary-term-definition-container">
            <div className="ql-editor">
              {parseHtml(glossaryTermByID?.definition)}
            </div>
          </div>
          <hr className="glossary-term-border-line" />
          <DateMetadataSection
            title="Created"
            by={glossaryTermByID?.created_by}
            on={glossaryTermByID?.created_on}
          />
          <DateMetadataSection
            title="Modified"
            by={glossaryTermByID?.modified_by}
            on={glossaryTermByID?.modified_on}
          />
          <DateMetadataSection
            title="Approved"
            by={glossaryTermByID?.approved_by}
            on={glossaryTermByID?.approved_on}
          />
          <strong>Approval Status:</strong> {glossaryTermByID?.approval_status}
        </div>
      )}
    </>
  )
}
