import React from 'react'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'

const HelperComponent = ({ openModel, closeModel, descriptionElementRef }) => {
  return (
    <Dialog
      open={openModel}
      onClose={closeModel}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" className="fw-bolder">
        About PODs
      </DialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Typography gutterBottom>
            This capability provides insights on{' '}
            <b>PODs (Product Oriented Delivery)</b> teams and their members.
          </Typography>
          <Typography gutterBottom>
            PODs provides insights into product teams and their members,
            including builders, stakeholders, and product owners. This tool
            enhances visibility across Kraft Heinz employees by offering a clear
            overview of team composition and roles.
          </Typography>
          <Typography>
            <Card className="mt-2 mb-2">
              <CardActionArea>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h7"
                    component="div"
                    className="fw-bold"
                  >
                    BUILDERS
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Chapter member (data engineer, data scientist, ml engineer,
                    bi engineer, cloud engineer, scrum master, data steward,
                    data architect, architect) involved in the design /
                    development / validation / monitoring of products
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Typography>
          <Typography gutterBottom>
            <Card className="mt-2 mb-2">
              <CardActionArea>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h7"
                    component="div"
                    className="fw-bold"
                  >
                    PRODUCT OWNERS
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Assigned product owner maximizing the value of a product. They are usually involved
                    with managing the back-log and participating in scrum ceremonies.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Typography>
          <Typography gutterBottom>
            <Card className="mt-2 mb-2">
              <CardActionArea>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h7"
                    component="div"
                    className="fw-bold"
                  >
                    STAKEHOLDERS
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Direct interest / user of the product (prioritized for NPS
                    feedback for Chapter NPS surveys). They are usually not part of daily scrum ceremonies.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Typography>

          <Typography gutterBottom>
            <Alert key="info" variant="info">
              For any query related to PODs Post it in Q&A Forum tool available
              in menu or
              <Alert.Link href="https://deweb.apps.kraftheinz.com/collab">
                <u> Click here </u>
              </Alert.Link>
            </Alert>
          </Typography>

          <Typography variant="h6" gutterBottom>
            <b>1. Create</b>
          </Typography>
          <Typography gutterBottom>
            Name, Description, Functional Area, and Builders are required
            fields, without these you will not be able to create a POD request.
          </Typography>
          <Typography gutterBottom>
            Inside Builders, Product owners, and Stakeholders input field select
            members using @ operator followed by KHC ID. Example: try entering
            @manvendrasingh
          </Typography>

          <Typography variant="h6" gutterBottom>
            <b> 2. Edit</b>
          </Typography>
          <Typography gutterBottom>
            Name, Description, Functional Area, and Builders are required
            fields.
          </Typography>
          <Typography gutterBottom>
            You can update any POD members by deleting and adding new values.
          </Typography>
          <Typography variant="h6" gutterBottom>
            <b> 3. My PODs</b>
          </Typography>
          <Typography gutterBottom>
            You can toggle My PODs button to filter PODs view where you are a
            member.
          </Typography>

          <Typography variant="h6" gutterBottom>
            <b> 4. Pending Requests</b>
          </Typography>
          <Typography gutterBottom>
            A view that shows all the POD requests. Request can be for a POD
            creation or modification.
          </Typography>
          <Typography gutterBottom>
            You can also filter Edit, Create, and Rejected POD requests by
            selecting the option from Request type dropdown on the top toolbar.
          </Typography>
          <Typography variant="h6" gutterBottom>
            <b>5. See POD details</b>
          </Typography>
          <Typography gutterBottom>
            <b>Clicking on a POD row</b> in home page will open up the{' '}
            <b>POD details view</b>. In this view you will be able to see all
            info related to that pod its name, functional area, description and
            member details.
          </Typography>
          <Typography gutterBottom>
            <Alert key="info" variant="info">
              <b>Note:</b> Only the admins and Chapter Heads can approve/reject
              a POD creation request. Change requests to existing PODs can be
              approved by the existing POD members. List of Chapter Heads:
              bryan.chavez@kraftheinz.com, jorge.balestra@kraftheinz.com,
              ashish.agrawal@kraftheinz.com, joseph.mcnamara@kraftheinz.com,
              joy.soda@kraftheinz.com, maria.vasu@kraftheinz.com,
              jaques.castello@kraftheinz.com, andrew.lindsay@kraftheinz.com,
              hema.yalamanchi@kraftheinz.com
            </Alert>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div style={{ flex: '0.95 0 0' }} />
        <Button onClick={closeModel}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default HelperComponent
