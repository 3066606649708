import {
    ADD_VALIDATE_USER_EMAILS_LOAD,
    ADD_VALIDATE_USER_EMAILS_ERROR,
    ADD_VALIDATE_USER_EMAILS_POST,
  } from '../../constants/types'
  
  const initialState = {
    getValidUserEmailData: [],
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case ADD_VALIDATE_USER_EMAILS_LOAD:
        return {
          ...state,
        }
      case ADD_VALIDATE_USER_EMAILS_POST:
        return {
          ...state,
          getValidUserEmailData: action.payload,
        }
      case ADD_VALIDATE_USER_EMAILS_ERROR:
        return {
          ...state,
        }
      default:
        return state
    }
  }
  
