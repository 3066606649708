import ReactPaginate from 'react-paginate'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

const PaginationFeature = ({
    totalPages,
    handlePageChange,
    currentPage,
    totalResults,
    pageSize,
    setPageSize,
    availablePageSize
}) => {
    return (
        <div style={{display:'flex', flexDirection:'row',border: '.7px solid #C2CCE1'}}>
        <ReactPaginate
          pageCount={totalPages}
          onPageChange={handlePageChange}
          forcePage={currentPage}
          breakLabel={'...'}
          previousLabel={'<<'}
          nextLabel={'>>'}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination idea-pagination"
          activeClassName="active"
          style={{marginBottom:0}}
        />
        <div style={{ display: 'flex', marginRight: '8px'}}>
          <DropdownButton
            id="dropdown-order-by"
            title={`Show: ${pageSize}`}
            className="glossary-sort-buttons idea-sort-buttons idea-sort-pagination-buttons"
            onSelect={(eventKey) => setPageSize(eventKey)}
          >
            {availablePageSize.map((pageSize) => (
              <Dropdown.Item eventKey={pageSize} key={pageSize}>
                {pageSize}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
        <div style={{display:'flex', alignItems:'center'}}><span style={{fontWeight: 600, fontSize: '14px', marginRight: '5px'}}>Total results:</span>{totalResults}</div>
      </div>
    )
}

export default PaginationFeature
