import React from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import Typography from '@mui/material/Typography';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'white',
    backgroundColor: "#243642",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const StyledBadge = ({ badgeData, badgeType }) => {
  const badgeStyles = {
    Gold: {
      background: 'badgeInner-gold',
      color: '#FFD700',
    },
    Silver: {
      background: 'badgeInner-silver',
      color: '#C0C0C0',
    },
    Bronze: {
      background: 'badgeInner-bronze',
      color: '#CD7F32',
    },
  }

  return (
    <HtmlTooltip
      title={
          <React.Fragment>
            <Typography color="inherit">{badgeType} badge for {badgeData?.badge_name}</Typography>
            <b><u>{'Requirements '}</u></b><b>:</b>{' '}{badgeData?.requirements}
          </React.Fragment>
      }
    >
      <div
        className={`${badgeStyles[badgeType]['background']} dashboard-flex dashboard_gap_0_5 recent-badge-container`}
      >
        <span>
          <MilitaryTechIcon
            className="icons"
            style={{
              background: badgeStyles[badgeType]['color'],
              fontSize: '27px',
            }}
          />
        </span>
        <div className="badge_container_desc">
          <span className="dashboard-transform-capitalize badge-name-tag">
            {badgeData.badge_name}
          </span>
          <span className="idea_category_badge badge-primary badge_container_desc_role_type badge-role-type-tag">
            {badgeData.role_type}
          </span>
        </div>
      </div>
    </HtmlTooltip>
  )
}

export default StyledBadge
