// import React from 'react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../context/authConfig'
// import Button from 'react-bootstrap/Button'

// function handleLogin(instance) {
//   instance.loginRedirect(loginRequest).catch((e) => {
//     console.error(e)
//   })
// }

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
  const { instance } = useMsal()
  return (
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e)
    })
  )
}
