import SearchIcon from '@mui/icons-material/Search'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Tooltip from 'react-bootstrap/Tooltip'
import ClearIcon from '@mui/icons-material/Clear'
import { useState } from 'react'

export default function Search({
  searchTerm,
  handleChange,
  handleSubmit,
  clearSearch,
  containerClass,
  formClass,
  clearButtonClass,
  searchButtonClass,
}) {
  const [isFocused, setIsFocused] = useState(false)
  const handleFocus = () => {
    setIsFocused(true)
  }
  // handles when user clicks off the search bar
  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <div className={containerClass}>
      <OverlayTrigger
        trigger="click"
        key="bottom"
        placement="bottom"
        overlay={
          <Popover id={`popover-positioned-bottom`}>
            <Popover.Body>
              To search with a tag type <strong>[tag]</strong>
            </Popover.Body>
          </Popover>
        }
      >
        <form onSubmit={handleSubmit} className={formClass}>
          <input
            className="form-control"
            type="text"
            placeholder="Search Terms..."
            defaultValue={searchTerm}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{width: '100%'}}
          />
          <>
            {searchTerm.length > 0 && !isFocused ? (
              <OverlayTrigger
                overlay={<Tooltip id="tooltip">Clear Search</Tooltip>}
              >
                <button onClick={clearSearch} className={clearButtonClass}>
                  <ClearIcon />
                </button>
              </OverlayTrigger>
            ) : (
              <></>
            )}
          </>
          <button
            type="submit"
            className={searchButtonClass}
            title="Search for terms"
          >
            <SearchIcon />
          </button>
        </form>
      </OverlayTrigger>
    </div>
  )
}
