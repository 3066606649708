import React, { useEffect, useContext, useState } from 'react'
import { loginRequest, protectedResources } from '../context/authConfig'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'

import { InteractionRequiredAuthError } from '@azure/msal-browser'
import * as authProvider from '../context/authProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../Helper/ToastObjects'
import LoadingStatus from '../Helper/LoadingStatus'
import InternetFrame from '../Helper/InternetFrame'

export default function Home() {
  const khde_url = 'https://khazeus2datastrgdev001.z20.web.core.windows.net/'
  const [authCreds, setAuthCreds] = useContext(authProvider.AuthContext)
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const endpoint = authCreds.restEndpoint
  const [tokenValue, settoken] = useState('')

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Checking user...',
  })
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Checking user...' })
    const request = {
      ...loginRequest,
      account: accounts[0],
    }
    setLoadingStatus({ status: true, message: 'Checking user...' })

    // Silently acquires an access token for AzureAD
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setLoadingStatus({ status: false, message: 'Checking user...' })

        localStorage.setItem('token', response.accessToken)
        settoken(response.accessToken)
        setAuthCreds((prev_state) => ({
          ...prev_state,
          accessToken: response.accessToken,
        }))
        setLoadingStatus({ status: false, message: 'Checking user...' })

      })
      .catch(() => {
        setLoadingStatus({ status: false, message: 'Checking user...' })
        instance.logoutRedirect().catch((e) => {
          console.error(e)
        })
      })

    // sets AuthCreds context with username on successful authentication.
    if (isAuthenticated) {
      let local_username = accounts[0].username.split('@')[0]
      localStorage.setItem('user', local_username)
      localStorage.setItem('channel', 'Data Engineering')
      setAuthCreds((prev_state) => ({
        ...prev_state,
        username: local_username,
      }))
      if (localStorage.getItem('is_login') == null && tokenValue != '') {
        createUser(local_username, accounts[0].username, tokenValue)
      }
    }
  }, [tokenValue])

  useEffect(() => {
    const graphRequest = {
      scopes: protectedResources.graphUser.scopes,
      account: accounts[0],
    }
    setLoadingStatus({ status: true, message: 'Checking user...' })
    // Silently acquires an access token for Microsoft Graph data
    instance
      .acquireTokenSilent(graphRequest)
      .then((res) => {
        localStorage.setItem('graphToken', res.accessToken)
        setAuthCreds((prev_state) => ({
          ...prev_state,
          graphToken: res.accessToken,
        }))
      })
      .catch((e) => {
        // Catch interaction_required errors and call interactive method to resolve
        if (e instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenRedirect(graphRequest)
            .then((res) => {
              localStorage.setItem('graphToken', res.accessToken)
              setAuthCreds((prev_state) => ({
                ...prev_state,
                graphToken: res.accessToken,
              }))
            })
            .catch((err) => {
              console.error(err)
            })
        }
        console.error(e)
      })
  }, [])

  async function createUser(username, email, tokenValue) {
    setLoadingStatus({ status: true, message: 'Checking user...' })

    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${tokenValue}`,
      khuser: `${username}`,
    }

    let body = {
      user_name: username,
      user_email: email,
    }
    await axios
      .post(`${endpoint}/api/user/create_user`, body, {
        headers: post_headers,
      })
      .then((d) => {
        if (d.code == 'ERR_NETWORK') {
          toast('Error: Network Error', toastTopRightError)
          setLoadingStatus({ status: false, message: 'user enable...' })
        } else {
          localStorage.setItem('is_login', true)
          setLoadingStatus({ status: false, message: 'user enable...' })
          window.location.href = localStorage.getItem('login_redirect_path') == null ? '/home' : localStorage.getItem('login_redirect_path')
        }
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          toast('Error: Network Error', toastTopRightError)
        } else {
          toast(err.response.data.message, toastTopRightError)
        }
      })
  }

  return (
    <>
      {loadingStatus.status == true ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div>
          {/* <tableau-viz id="tableauViz" src={tableau_url}></tableau-viz> */}
          {/* {history('/khde-docs')} */}
          <InternetFrame source_url={khde_url} />
        </div>
      )}
    </>
  )
}
