import React, {useState, useContext, useEffect} from "react";
import Sidebar from '../AppBar/Appbar'
import jwt_decode from 'jwt-decode'
import { AuthContext } from '../../context/authProvider'
import { getPodById, getPodDetails, getPodConsumedCredits, getPodAssets, createPodAsset, deletePodAsset } from '../../../redux/actions/podsPageActions/podsAction'
import {getDagsterDbtJobs} from './podFunctions'
import { ForumHandler } from '../../Utils'
import { getBadgeusers, getUserKudos } from '../../../redux/actions/badgeAction'
import MembersTable from './memberDetailsTable'
import { useDispatch, useSelector } from 'react-redux'
import LoadingStatus from '../../Helper/LoadingStatus'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Badge from 'react-bootstrap/Badge'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import UserProfile from '../Users/userProfileModal'
import AssessmentIcon from '@mui/icons-material/Assessment'
import PeopleIcon from '@mui/icons-material/People'
import DeleteIcon from '@mui/icons-material/Delete'
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import TabView from '../Utilities/tabView'
import StackedBarChart from '../Utilities/stackedBarChart'
import { RoleHandler } from '../Utilities/roleCheck'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { AsyncPaginate } from 'react-select-async-paginate'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }))
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }))
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }))

const PodDetailPage = () => {
    let history = useNavigate()
    let search = window.location.search
    let decodedToken
    let tokenValues = localStorage?.getItem('token')
    if (tokenValues) {
      decodedToken = jwt_decode(tokenValues)
    }
    const roleValue = RoleHandler.checkCollabAPIAdminRole(tokenValues)
    const params = new URLSearchParams(search)
    const id = params.get('q')
    const [channel, setChannel] = useState(localStorage.getItem('channel'))
    const [initLoad, setInitLoad] = useState(false)
    const [podInitLoad, setPodInitLoad] = useState(false);
    const [podDetail, setPodDetail] = useState({});
    const [badgeData, setbadgeData] = useState()
    const [showModal, setShowModal] = useState(false)
    const [isLocalUser, setIsLocalUser] = useState(false)
    const [builders, setBuilders] = useState([])
    const [stakeholders, setStakeholders] = useState([])
    const [productOwners, setProductOwners] = useState([])
    const [showAddAsset, setShowAddAsset] = useState(false);
    const [podAssets, setPodAssets] = useState([])
    const [membersEmail, setMembersEmail] = useState([])
    const [consumedCredits, setConsumedCredits] = useState([]);
    const [creditsAsset, setCreditAsset] = useState({})
    const [assetTypeList, setAssetTypeList] = useState([]);
    const [assetNameList, setAssetNameList] = useState([])
    const [assetJob, setAssetJob] = useState('')
    const [selectedAssetType, setSelectedAssetType] = useState('DAGSTER_DBT_JOB');
    const [selectedAssetName, setSelectedAssetName] = useState('');
    const handleCloseAddAsset = () => {
      setShowAddAsset(false);
      setAssetJob('');
    }
    const formatAssetName = (assetName) => {
      if(assetName && !assetName.toLowerCase().endsWith("_job")){
        assetName += '_job'
      }
      return assetName;
    }
    const handleCreateAsset = () => {
      let tempAsset = assetJob.dbt_jobs
      if(!tempAsset.toLowerCase().endsWith("_job")){
        tempAsset += '_job'
      }

      let createParams = {
        pod_name: podDetail.pod_name,
        platform: 'Data Engineering', 
        asset_type: 'DAGSTER_DBT_JOB',
        asset_name: tempAsset
      };
      setLoadingStatus({ status: true, message: 'Fetching table records...' })
      dispatch(createPodAsset(endpoint, createParams)).then(() => {
        
        fetchPodConsumedCredits()
        fetchPodAssets()
        setShowAddAsset(false);
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    const handleDeleteAsset = (assetId) => {
      setLoadingStatus({ status: true, message: 'Fetching table records...' })
      if (window.confirm('Are you sure to Delete this Asset ?')){
        dispatch(deletePodAsset(endpoint, assetId)).then(() => {
          fetchPodConsumedCredits()
          fetchPodAssets()
          setLoadingStatus({ status: false, message: 'Fetching table records...' })
          
        })
      } else {
        setLoadingStatus({ status: false, message: 'Fetching table records...' })
      }
    }
    const loadOptions = async (search, { page }) => {
      let response = await getDagsterDbtJobs(endpoint, search);
      return {
        options: response.data,
        // hasMore: response.length >= 1,
        additional: {
          page: search ? 2 : page + 1,
        },
      }
    }
    // Email pod btn states for getting pod details 
    const [encodedToEmails, setEncodedToEmails] = React.useState("")
    const [encodedCcEmails, setEncodedCcEmails] = React.useState("")
    const [encodedSubject, setEncodedSubject] = React.useState("")
    const [authCreds] = useContext(AuthContext)
    const dispatch = useDispatch()
    const endpoint = authCreds.restEndpoint
    const [loadingStatus, setLoadingStatus] = useState({
        status: false,
        message: 'Fetching table records...',
      })
    const podById = useSelector((state) => state.podById)
    const podDetails = useSelector((state) => state.podDetails)
    const userKudos = useSelector((state) => state.userKudos)
    const columns = [
        'EMAIL',
        'CHAPTER',
        'TITLE',
        'GOLD',
        'SILVER',
        'BRONZE',
        'KUDOS',
        'COMPANY',
        'GROUP',
        'COUNTRY',
        'CITY',
        'TIME IN POSITION',
    ]
    function openUserprofile(row) {
      setbadgeData(row)
      if (
        localStorage.getItem('user').toUpperCase() == row?.user_name.toUpperCase()
      ) {
        setIsLocalUser(true)
      } else {
        setIsLocalUser(false)
      }
      const badgeParams = {
        page_size: 1,
        page_number: 1,
        filters: JSON.stringify([{"op":"eq","field":"user_email","value":row?.user_email}]),
        channel: null
      }
      dispatch(getBadgeusers(endpoint, badgeParams)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
        setShowModal(true)
      })

      dispatch(getUserKudos(endpoint, row?.user_email)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
        setShowModal(true)
      })
    }
    const fetchData =  () => {
      setLoadingStatus({ status: true, message: 'Fetching table records...' })
      dispatch(getPodById(endpoint, id))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    }
    const fetchPodDetails = (podDetailParams) => {
      setLoadingStatus({ status: true, message: 'Fetching table records...' })
      dispatch(getPodDetails(endpoint, podDetailParams)).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    }
    const fetchPodConsumedCredits = () => {
      let filtersArr = [];
      const params = {pod_name:podDetail.pod_name}
      filtersArr.push(`{"op":"eq","field":"platform","value":"Data Engineering"}`)
      if(selectedAssetName) {
        filtersArr.push(`{"op":"eq","field":"asset_name","value":"${selectedAssetName}"}`)
      }
      if(selectedAssetType) {
        filtersArr.push(`{"op":"eq","field":"asset_type","value":"${selectedAssetType}"}`)
      }
      // 
      params.filters = '[' + filtersArr.join(',') + ']'
      dispatch(getPodConsumedCredits(endpoint, params)).then(() => {
        
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    }
    const fetchPodAssets = () => {
      const podData = {
        pod_name: podDetail.pod_name
      }

      dispatch(getPodAssets(endpoint, podData)).then(() => {
        
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    }
    useEffect(() => {
      if (podDetails?.podDetails?.data) {
        const toEmails = podDetails?.podDetails?.data?.members?.builders.map(builder => encodeURIComponent(builder?.user_email)).join(';');
        const ccEmails = podDetails?.podDetails?.data?.members?.product_owners.map(owner => encodeURIComponent(owner?.user_email)).join(';');
        const subject = encodeURIComponent(`Kholab: POD [${podDetail?.pod_name}] message`);
        setEncodedToEmails(toEmails)
        setEncodedCcEmails(ccEmails)
        setEncodedSubject(subject)

        const buildersEmail = podDetails?.podDetails?.data?.members?.builders.map(builder => builder?.user_email);
        const productOwnersEmail = podDetails?.podDetails?.data?.members?.product_owners.map(builder => builder?.user_email);
        const stakeholdersEmail = podDetails?.podDetails?.data?.members?.stakeholders.map(builder => builder?.user_email);
        const membersEmail = buildersEmail.concat(productOwnersEmail).concat(stakeholdersEmail);

        setMembersEmail(membersEmail);
      }
      if(podDetails?.podAssets?.pod_assets){
        setPodAssets(podDetails?.podAssets?.pod_assets)
      }
      if(podDetails?.podConsumedCredits?.pod_asset_credits){
        setConsumedCredits(podDetails?.podConsumedCredits?.pod_asset_credits)
      }
    }, [podDetails])
    useEffect(()=>{
        if (initLoad === true) {
            return
        }
        fetchData()
        setInitLoad(true)
    },[])

    useEffect(()=>{
      if(podDetail && podDetail.id){
        if (podInitLoad === true) {
          return
        }
        const podDetailParams = {
          pod_id: podDetail.id,
          builders: builders,
          stakeholders: stakeholders,
          product_owners: productOwners,
        }
        fetchPodDetails(podDetailParams);
        
        setLoadingStatus({ status: true, message: 'Fetching table records...' })
        fetchPodConsumedCredits()
        fetchPodAssets()
        setLoadingStatus({ status: false, message: 'Fetching table records...' })
        setPodInitLoad(true)
      }
    },[builders, stakeholders, productOwners])

    useEffect(()=>{
      if(selectedAssetName) {
        fetchPodConsumedCredits()
      }
    }, [ selectedAssetName])

    useEffect(()=>{
      const assetType = [...new Set(podAssets.map(item => item.asset_type))];
      const assetName = [...new Set(podAssets.map(item => item.asset_name))];
      setAssetTypeList(assetType)
      setAssetNameList(assetName);
    },[podAssets])

    useEffect(()=>{
      let combineCreditRes = consumedCredits?.reduce(((r, c) => Object.assign(r, c)), {});
      delete combineCreditRes.month_year;
      setCreditAsset(Object.keys(combineCreditRes))
    },[consumedCredits])
    
    useEffect(()=>{
      let podData = podById?.podById?.data?.pod_data;
      if (podData && podData.id) {
          setPodDetail(podData);
          const stakeholderMatches = podData?.stakeholders_html?.match(
              ForumHandler.userHTMLCharacters
          )
          const productOwnerMatches = podData?.product_owners_html?.match(
              ForumHandler.userHTMLCharacters
          )
          const builderMatches = podData?.builders_html?.match(
              ForumHandler.userHTMLCharacters
          )
          
          let stakeholderValues = stakeholderMatches
              ? stakeholderMatches.map((mention) => mention.slice(0))
              : []
          setStakeholders(stakeholderValues)
          
          let productOwnerValues = productOwnerMatches
              ? productOwnerMatches.map((mention) => mention.slice(0))
              : []
          setProductOwners(productOwnerValues)

          let builderValues = builderMatches
              ? builderMatches.map((mention) => mention.slice(0))
              : []
          setBuilders(builderValues)
          
      }
    }, [podById])

    const TeamTabContent = () => {
      return (
        <div>
          <Accordion defaultExpanded>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography>Builders</Typography>
                <Typography>
                    <Badge bg="secondary" className="pod-badge">
                    {podDetails?.podDetails?.data?.members?.builders?.length}
                    </Badge>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MembersTable
                    columns={columns}
                    members={podDetails?.podDetails?.data?.members?.builders}
                    openUserprofile={openUserprofile}
                />
              </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography>Product Owners </Typography>
              <Typography>
                  <Badge bg="secondary" className="pod-badge">
                  {
                      podDetails?.podDetails?.data?.members?.product_owners
                      ?.length
                  }
                  </Badge>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MembersTable
                  columns={columns}
                  members={
                  podDetails?.podDetails?.data?.members?.product_owners
                  }
                  openUserprofile={openUserprofile}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography>Stakeholders</Typography>
              <Typography>
                  <Badge bg="secondary" className="pod-badge">
                  {
                      podDetails?.podDetails?.data?.members?.stakeholders
                      ?.length
                  }
                  </Badge>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MembersTable
                  columns={columns}
                  members={
                  podDetails?.podDetails?.data?.members?.stakeholders
                  }
                  openUserprofile={openUserprofile}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )
    };

    const AssetTabContent = () => {
      
      const columns = (roleValue || (membersEmail.length > 0 && decodedToken && decodedToken.preferred_username && membersEmail.includes(decodedToken.preferred_username.toLowerCase()) ) ) ? ['Asset Type', 'Asset Name','Snowflake Credits', 'Action']: ['Asset Type', 'Asset Name','Snowflake Credits'];
      
      return (
        <>
          <div style={{display: 'flex', flexDirection: 'column' }}>
            <div style={{display: 'flex', gap: '1em'}}>
              <Form.Select
                onChange={(e) => {
                  setSelectedAssetType(e.target.value)
                }}
                value={selectedAssetType}
                style={{width: '15em'}}
              >
                <option key="placeholder" value="">
                  Select Asset Type
                </option>
              {assetTypeList?.map((item) =>{
                return (
                  <option value={item} key={item}>{item}</option> 
                 )
              })}
              </Form.Select>
              <Form.Select
                onChange={(e) => {
                  setSelectedAssetName(e.target.value)
                }}
                value={selectedAssetName}
                style={{width: '15em'}}
              >
                <option key="placeholder" value="">
                  Select Asset Name
                </option>
              {assetNameList?.map((item) =>{
                return (
                  <option value={item} key={item}>{item}</option> 
                 )
              })}
              </Form.Select>
            </div>
            {/* <div className="summary-tile-div">
              <div className="summary-tile-container"  style={{paddingLeft: 0}}>
                <div className="summary-tile">
                    <span className="summary-tile-count">
                      8  
                    </span>
                    <span className="summary-tile-desc">
                        <span className="summary-tile-link">Cost</span>
                    </span>
                    <span class="idea_category_badge badge-success badge_container_desc_role_type badge-role-type-tag"><ArrowUpwardIcon />2.1%</span>
                </div>
              </div>
              <div className="summary-tile-container"  >
                  <div className="summary-tile">
                      <span className="summary-tile-count">
                        8
                      </span>
                      <span className="summary-tile-desc">
                        <span className="summary-tile-link">Assets</span>
                      </span>
                  </div>
              </div>
              <div className="summary-tile-container" >
                <div className="summary-tile">
                    <span className="summary-tile-count">
                      8
                    </span>
                    <span className="summary-tile-desc">
                        <span className="summary-tile-link">Cost/Asset</span>
                    </span>
                </div>
              </div>                        
              <div className="summary-tile-container" style={{paddingRight: 0}}>
                <div className="summary-tile">
                  <span className="summary-tile-count">
                    8
                  </span>
                  <span className="summary-tile-desc">
                    <span className="summary-tile-link">Cost/DE</span>
                  </span>
                </div>
              </div>   
            </div> */}
            {consumedCredits && consumedCredits.length > 0 && creditsAsset && (<StackedBarChart xAxisKey="month_year" data={consumedCredits} barChartKey={creditsAsset} />)}
            
          </div>
          {(roleValue || (membersEmail.length > 0 && decodedToken && decodedToken.preferred_username && membersEmail.includes(decodedToken.preferred_username.toLowerCase()) ) ) && (
            <div style={{display: 'flex', flexDirection: 'row-reverse', marginBottom: '1em', marginTop: '1em'}}>
              <Tooltip title="Click to Add Asset. By Selecting Asset Name. It will automatically generate Credit.">
                <Button type="button"  onClick={()=>setShowAddAsset(true)} >
                  Add Asset
                </Button>
              </Tooltip>
            </div>
          )}
          
          <TableContainer component={Paper} style={{marginTop: '1em'}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={Math.random()} className="cell" align="center" style={{fontWeight: '600'}}>
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {podAssets.length > 0 ?  podAssets?.map((row) => (
                  <TableRow
                    key={row?.id}
                  >
                    <TableCell className="cell" align="center">
                      {row?.asset_type}
                    </TableCell>
                    <TableCell className="cell" align="center">
                      {row?.asset_name}
                    </TableCell>
                    <TableCell className="cell" align="center">
                      {row?.snowflake_credits}
                    </TableCell>
                    {(roleValue || (membersEmail.length > 0 && decodedToken && decodedToken.preferred_username && membersEmail.includes(decodedToken.preferred_username.toLowerCase()) ) ) && (
                    <TableCell className="cell" align="center">
                      <Tooltip title="Click to Delete this Asset.">
                        <Button type="button"  onClick={()=>handleDeleteAsset(row.id)} >
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                    )}
                    
                  </TableRow>
                )) :
                (<TableRow
                >
                  <TableCell className="cell" align="center" colSpan={(roleValue || (membersEmail.length > 0 && decodedToken && decodedToken.preferred_username && membersEmail.includes(decodedToken.preferred_username.toLowerCase()) ) ) ?  4 : 3}>
                    No Assets available.
                  </TableCell>
                </TableRow> 
                )
              
              }
              </TableBody>
            </Table>
          </TableContainer>
          
          
          <Modal
            size="md"
            show={showAddAsset}
            onHide={handleCloseAddAsset}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
              <Form>
                <Container className="m-0">
                  <Row className="mb-3 mr-3 rowFlex">
                    <Form.Group className="mt-2" as={Col} md="10" controlId="validationFormik01">
                      <Form.Label>
                        Asset Name
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <AsyncPaginate
                        value={assetJob}
                        loadOptions={loadOptions}
                        getOptionValue={(option) => formatAssetName(option.dbt_jobs)}
                        getOptionLabel={(option) => formatAssetName(option.dbt_jobs)}
                        onChange={setAssetJob}
                        additional={{
                          page: 0,
                        }}
                      />
                    </Form.Group>
                  </Row>
                </Container>
              </Form>
              <Modal.Footer style={{ gap: '10px' }}>
                <Button variant="primary" className="addModalBtn" type="button" onClick={handleCreateAsset}>
                  Submit
                </Button>
                <Button variant="danger" type="button" className="addModalBtn" onClick={handleCloseAddAsset}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
        </>
      )
    }

    const podDetailPageContent = {
      defaultTab: 'Team',
      dataSet: [
          {
              key: 'Team',
              title: <><PeopleIcon /> Team</>,
              content: <TeamTabContent />
          },
          {
            key: 'Asset',
            title: <><AssessmentIcon /> DE Asset</>,
            content: <AssetTabContent />
          }
      ]
    };

    
    return(
        <>
            {loadingStatus.status ? (
                <LoadingStatus status_message={loadingStatus.message} />
            ) : (
            <>
                <Sidebar
                    Page="pod_detail_page"
                    channel={channel}
                    setChannel={setChannel}
                    Index={
                        <>
                        <Container className="m-0" style={{ backgroundColor: '#fff', marginLeft: '1em !important', paddingTop: '1em'}}>
                          <div style={{display:'flex', height: '2em', marginBottom: '0.5em', gap: '0.5em'}}>
                            <Tooltip title="Name">
                              <span style={{color: '#071437', fontWeight: 500, fontSize: '1.25rem'}}>{podDetail?.pod_name}</span>
                            </Tooltip>
                            <Tooltip title="Funtional Area">
                              <Badge bg="primary" className="pod-badge" style={{width: 'fit-content', fontSize: '0.85rem', borderRadius: '99999px'}}>
                                  {podDetail?.functional_area}
                              </Badge>
                            </Tooltip>
                            <a
                              href={
                                podDetails?.podDetails?.data?.members
                                  ?.product_owners?.length
                                  ? `mailto:${encodedToEmails}?cc=${encodedCcEmails}&subject=${encodedSubject}`
                                  : `mailto:${encodedToEmails}?subject=${encodedSubject}`
                              }
                              className="btn email-pod-btn"
                              style={{ margin: '0', lineHeight: 1 }}
                            >  
                              <Tooltip title="Send email to POD members - builders and product owners">
                              Email POD
                              </Tooltip>
                            </a>
                            <Tooltip title="Click to go Back to PODs">
                              <Button type="button" onClick={()=>history('/pods')} style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#00000099', color: '#fff',borderColor: '#00000099', lineHeight: 1}}>
                                Go Back
                              </Button>
                            </Tooltip>
                          </div>
                          <Tooltip title="Description">
                            <Typography>{podDetail?.description}</Typography>
                          </Tooltip>
                          <TabView dataArr={podDetailPageContent} />
                        </Container>
                        {showModal ? (
                            <>
                            <UserProfile
                                selectedUser={badgeData?.user_name}
                                badgeData={badgeData}
                                setBadgeData={setbadgeData}
                                showModal={showModal}
                                setShowModal={setShowModal}
                                isLocalUser={isLocalUser}
                                history={history}
                                pageSize={1}
                                pageIndex={1}
                                userKudos={userKudos}
                            />
                            </>
                        ) : (
                            ''
                        )}
                        </>
                    }
                />
            </>
          ) }
        </>
        
    
    )
}

export default PodDetailPage;
