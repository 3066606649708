import ReactQuill from 'react-quill'
import { Form } from 'react-bootstrap'

const fieldTypeArr = {
  text: 'Text',
  textarea: 'Text Area',
  'text-read-only': 'Read Only Text',
  radio: 'Radio',
  checkbox: 'CheckBox',
  rating: 'Rating',
}

const EditFormChapterInput = ({
  editMode,
  fieldValue,
  inputRef,
  selectRefRating,
  handleDropdownRating,
  selectRef,
  handleDropdown,
  labelValue,
  placeholderRef,
  placeholderValue,
  handleEditSave,
  toggleSwitch,
  activeCheck,
  counter,
  setCounter,
  optionFunc,
  modules,
  formats,
}) => {
  return (
    <>
      <div className="dialog-box-nps">
        <select
          ref={selectRef}
          onChange={() => handleDropdown(selectRef)}
          defaultValue={fieldValue}
        >
          {Object.entries(fieldTypeArr)?.map(([key, val]) => {
            return <option key={key} value={key}>
              {val}
            </option>
          })}
          {/* <option value="text">Text</option>
                <option value="textarea">Text Area</option>
                <option value="text-read-only">
                Read Only Text
                </option>
                <option value="radio">Radio</option>
                <option value="checkbox">CheckBox</option>
                <option value="rating">Rating</option> */}

     
            <option value="Nps">Net Promoter Score</option>
          
        </select>
        {fieldValue !== 'text-read-only' ? (
          <input
            type="text"
            placeholder="Add Question"
            className="form-fields"
            ref={inputRef}
            defaultValue={labelValue}
          />
        ) : (
          <></>
        )}

        {fieldValue === 'text' || fieldValue == 'textarea' ? (
          <>
            <input
              type="text"
              placeholder="Add placeholder"
              ref={placeholderRef}
              className="form-fields"
              defaultValue={placeholderValue}
            />
            {editMode ? (
              <div className="save-btn-nps" onClick={handleEditSave}>
                Save
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div style={{ marginTop: '11px', marginBottom: '11px' }}>
        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          onChange={toggleSwitch}
          checked={activeCheck}
        />
        <label for="start" className="require">
          {' '}
          Required*
        </label>
      </div>
      {fieldValue == 'radio' ? (
        <>
          <div className="option-block">
            {optionFunc(counter)}
            <button
              type="button"
              onClick={() => setCounter(counter + 1)}
              className="option-btn"
            >
              +
            </button>
            {counter > 1 ? (
              <button
                type="button"
                onClick={() => setCounter(counter - 1)}
                className="option-btn"
              >
                -
              </button>
            ) : (
              <></>
            )}
            {editMode ? (
              <div className="save-btn-nps" onClick={handleEditSave}>
                Save
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      {fieldValue == 'checkbox' ? (
        <>
          <div className="option-block">
            {optionFunc(counter)}
            <button
              type="button"
              onClick={() => setCounter(counter + 1)}
              className="option-btn"
            >
              +
            </button>
            {counter > 1 ? (
              <button
                type="button"
                onClick={() => setCounter(counter - 1)}
                className="option-btn"
              >
                -
              </button>
            ) : (
              <></>
            )}
            {editMode ? (
              <div className="save-btn-nps" onClick={handleEditSave}>
                Save
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      {fieldValue == 'text-read-only' ? (
        <>
          <ReactQuill
            theme="snow"
            style={{ background: '#fff' }}
            modules={modules}
            formats={formats}
            ref={inputRef}
            className="rich-text-editor"
            required
          />
        </>
      ) : (
        <></>
      )}
      {fieldValue == 'rating' ? (
        <>
          <select
            ref={selectRefRating}
            onChange={() => handleDropdownRating(selectRefRating)}
            style={{
              width: '20%',
              border: '1px solid #9d9eff',
              padding: '5px',
              outline: 'none',
              margin: '6px',
            }}
          >
            <option value="star">Star</option>
            <option value="Heart">Heart</option>
            <option value="faces">Faces</option>
          </select>
          {editMode ? (
            <div className="save-btn-nps" onClick={handleEditSave}>
              Save
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {fieldValue == 'Nps' ? (
        editMode ? (
          <>
            <div className="save-btn-nps" onClick={handleEditSave}>
              Save
            </div>
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  )
}

export default EditFormChapterInput
