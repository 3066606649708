import { React, useState, useRef, useContext, useEffect } from 'react'
import Input from '../ChapterNPS/input'
import EditFormChapterInput from './editFormChapterInput.js'
import PreviewForm from './previewForm'
import './indexChapter.css'
import Sidebar from '../AppBar/Appbar'
import HelpIcon from '@mui/icons-material/Help'
import { toast } from 'react-toastify'
// import NpsMenu from './chapterNpsMenu'
import Placeholder from '../Utilities/placeholderValues'
import { Modal } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import LoadingStatus from '../../Helper/LoadingStatus'
import { AuthContext } from '../../context/authProvider'
import ReactQuill from 'react-quill'
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction'
import {
  createTemplate,
  getSurveyTemplate,
  previewSurveyData,
} from '../../../redux/actions/npsActions/npsChapterAction'
import { useNavigate } from 'react-router-dom'
import { getTemplatePlaceholder } from '../../../redux/actions/npsActions/npsChapterAction'

function CreateForm() {
  const [authCreds] = useContext(AuthContext)
  const [formValues, setFormValues] = useState([])
  const [toggle, setToggle] = useState(false)
  const [fieldValue, setFieldValue] = useState('text')
  const [fieldValueRating, setFieldValueRating] = useState('star')
  const [placeHolderValues, setPlaceHolderValues] = useState([])
  const [labelValue, setFormLabel] = useState('')
  // const [fieldType, setFieldType] = useState('')
  const [placeholderValue, setPlaceHolder] = useState('Enter your answer')
  const [editMode, setEditMode] = useState(false)
  const [counter, setCounter] = useState(1)
  const [indexValue, setIndex] = useState(0)
  const [preview, setPreview] = useState(false)
  const [description, setDescription] = useState('')
  // const [global, setGlobal] = useState(false)
  const [npsCounter, setNpsCounter] = useState(0)
  const [activeCheck, setActiveCheck] = useState(false)
  const [title, setTitle] = useState('')
  const [titlePreview, setTitlePreview] = useState('')
  const [descriptionPreview, setDescriptionPreview] = useState('')
  const [formValuesPreview, setFormValuesPreview] = useState('')
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching Metadata details...',
  })

  const [collabMetadata, setCollabMetadata] = useState('')
  const [channel, setChannel] = useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const getTemplate = useSelector((state) => state.getTemplate)
  const previewTemplate = useSelector((state) => state.previewTemplate)
  const getTemplatePlaceholderData = useSelector(
    (state) => state.getTemplatePlaceholderData
  )

  let history = useNavigate()

  useEffect(() => {
    async function forumMetadata() {
      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=DEFAULT`
      dispatch(getForumMetadata(url))
    }
    forumMetadata()
    dispatch(getTemplatePlaceholder(endpoint))
  }, [])

  useEffect(() => {
    if (getTemplatePlaceholderData?.loading === false) {
      setPlaceHolderValues(
        getTemplatePlaceholderData?.getTemplatePlaceholderData?.data
      )
    }
  }, [getTemplatePlaceholderData])

  useEffect(() => {
    if (getTemplate?.loading === false) {
      setFormValues(getTemplate?.getTemplate?.template_json)
      setFormValuesPreview(getTemplate?.getTemplate?.template_json)
      setTitle(getTemplate?.getTemplate?.template_name)
      setTitlePreview(getTemplate?.getTemplate?.template_name)
      setDescription(getTemplate?.getTemplate?.template_description)
      setDescriptionPreview(getTemplate?.getTemplate?.template_description)
    }
  }, [getTemplate])

  useEffect(() => {
    if (getTemplate?.loading === false) {
      const namesArray = getTemplate?.getTemplate?.template_json?.map(
        (obj) => obj.type
      )
      if (namesArray.includes('Nps')) {
        setNpsCounter(1)
      }
    }
  }, [getTemplate, npsCounter])
  useEffect(() => {
    if (formValues?.length > 0) {
      const namesArray = formValues?.map((obj) => obj.type)
      if (namesArray.includes('Nps')) {
        setNpsCounter(1)
      } else {
        setNpsCounter(0)
      }
    }
  }, [formValues, npsCounter])

  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata)
    }
  }, [forumMetadata])
  // get endpoint from authCreds
  const endpoint = authCreds.restEndpoint
  // useRef for input and placeholder
  const inputRef = useRef()
  const placeholderRef = useRef()
  const inputRefs = useRef([])
  const pushRef = (el) => {
    inputRefs.current.push(el)
  }
  const selectRef = useRef()
  const selectRefRating = useRef()

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
      ['code'],
    ],
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'code',
  ]
  // useDispatch
  const dispatch = useDispatch()
  // functions for handling form fields

  // const handleGlobal = (e) => {
  //   if (e.target.checked) {
  //     setGlobal(true)
  //   } else {
  //     setGlobal(false)
  //   }
  // }
  const handleChange = (e, index) => {
    const values = [...formValues]
    values[index].value = e.target.value
    setFormValues(values)
  }
  const handleCloseModal = () => {
    setPreview(false)
  }
  const handleDeleteField = (e, index) => {
    const values = [...formValues]
    values.splice(index, 1)
    setFormValues(values)
    setFormValuesPreview(values)
  }

  useEffect(() => {
    setFormValues(formValues)
  }, [labelValue, editMode, placeholderValue, indexValue, fieldValue])

  const handleEditField = (e, index) => {
    formValues.map((obj, i) => {
      if (i === index) {
        e.preventDefault()
        clearArray()
        setFieldValue(obj.type)
        setFieldValueRating(obj.ratingType)
        setFormLabel(obj.label)
        setPlaceHolder(obj.placeholder)
        setEditMode(!editMode)
        setActiveCheck(obj.required)
        setIndex(index)
      }
    })
  }
  const handleEditSave = (e) => {
    e.preventDefault()
    const values = [...formValues]

    let options = inputRefs?.current?.map((n) => {
      return n?.value
    })
    let newOption = options.filter(function (element) {
      return element !== undefined
    })
    let newOption1 = [...new Set(newOption)]
    if (newOption1[0] == '') {
      toast.error('Please enter options')
    } else {
      (values[indexValue].label = inputRef.current.value || 'label'),
        (values[indexValue].placeholder = placeholderRef?.current?.value || ''),
        (values[indexValue].type = selectRef.current.value || 'text'),
        (values[indexValue].value = ''),
        (values[indexValue].required = activeCheck),
        (values[indexValue].ratingType = fieldValueRating),
        (values[indexValue].options = newOption1 || [])

      setFormValues(values)
      setFormValuesPreview(values)
      // setToggle(false)
      setFieldValue(selectRef.current.value)
      setFieldValueRating('star')
      clearArray()
      setEditMode(false)
      // setToggle(false)
    }
  }
  const clearArray = () => {
    inputRefs.current.length = 0
  }
  const handleAddField = (e) => {
    if (
      inputRef.current.value === '' ||
      inputRef.current.value === null ||
      placeholderRef?.current?.value === '' ||
      placeholderRef?.current?.value === null
    ) {
      toast.error('Please enter values')
    } else {
      e.preventDefault()
      const values = [...formValues]

      let options = inputRefs?.current?.map((n) => {
        return n?.value
      })
      let newOption = options.filter(function (element) {
        return element !== undefined
      })
      let newOption1 = [...new Set(newOption)]
      if (newOption1[0] == '') {
        toast.error('Please enter options')
      } else {
        values.push({
          label: inputRef.current.value || 'label',
          placeholder: placeholderRef?.current?.value || '',
          type: selectRef.current.value || 'text',
          value: '',
          required: activeCheck,
          ratingType: fieldValueRating,
          options: newOption1 || [],
        })
        setFormValues(values)
        setFormValuesPreview(values)
        setToggle(false)
        setFieldValue(selectRef.current.value)
        setFieldValueRating('star')
        clearArray()
      }
    }
  }

  const addBtnClick = (e) => {
    e.preventDefault()
    setToggle(true)
    clearArray()
    setFieldValue('text')
    setFormLabel('')
    setPlaceHolder('Enter your answer')
    setCounter(1)
  }
  function optionFunc(c) {
    var MultiGold = []
    Array.from(Array(c), (e, i) => {
      MultiGold.push(
        <input
          className="options"
          type="text"
          placeholder="Add option"
          ref={pushRef}
          key={i}
          name={'option' + i}
        />
      )
    })
    return <div>{MultiGold}</div>
  }
  const handleDropdown = (selectRef) => {
    setFieldValue(selectRef.current.value)
  }
  const handleDropdownRating = (selectRefRating) => {
    setFieldValueRating(selectRefRating.current.value)
  }

  function toggleSwitch() {
    setActiveCheck(!activeCheck)
  }
  const handlePreview = () => {
    const payload = {
      template_json: formValuesPreview,
      template_name: titlePreview,
      template_description: descriptionPreview,
      template_channel: channel,
    }
    dispatch(previewSurveyData(endpoint, payload)).then(() => {
      setPreview(true)
    })
  }
  useEffect(() => {
    if (previewTemplate.loading == false) {
      setTitlePreview(previewTemplate?.previewTemplate?.template_name)
      setDescriptionPreview(
        previewTemplate?.previewTemplate?.template_description
      )
      setFormValuesPreview(previewTemplate?.previewTemplate?.template_json)
    }
  }, [previewTemplate])

  const handleSubmit = () => {
    setLoadingStatus({ status: true, message: 'Adding Details...' })

    const params = {
      template_json: formValues,
      template_name: title,
      template_description: description,
      template_channel: channel,
      template_tags: [
        {
          name: 'is_external',
          value:
            id === 'Internal {{CHAPTER}} Member NPS - {{YEAR}} {{QUARTER}}'
              ? false
              : true,
        },
        {
          name: 'is_internal',
          value:
            id === 'Internal {{CHAPTER}} Member NPS - {{YEAR}} {{QUARTER}}'
              ? true
              : false,
        },
      ],
    }
    dispatch(createTemplate(endpoint, params)).then(() => {
      setLoadingStatus({ status: false, message: 'Adding Details...' })
      setFormValues([])
      setNpsCounter(0)
      history('/nps-survey/template')
    })
  }
  let search = window.location.search
  const params = new URLSearchParams(search)
  const id = params.get('template')
  useEffect(() => {
    if (id !== null) {
      setLoadingStatus({
        status: true,
        message: 'Fetching Metadata details...',
      })
      dispatch(getSurveyTemplate(endpoint, id)).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching Metadata details...',
        })
      })
    }
  }, [])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <div>
            <Sidebar
              Page="NPS"
              setChannel={setChannel}
              channel={channel}
              collabMetadata={collabMetadata}
              Index={
                <>
                  <div className="nps">
                    {/* <NpsMenu placeHolderValues={placeHolderValues} /> */}

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <button
                        onClick={() => history('/nps-survey/template')}
                        class="button-2"
                      >
                        Go back
                      </button>
                      <div style={{ marginRight: '15%' }}>
                        {placeHolderValues.length > 0 ? (
                          <Placeholder placeHolderValues={placeHolderValues} />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <form className="nps-form-main">
                      <div style={{ display: 'flex', width: '100%' }}>
                        <input
                          type="text"
                          placeholder="Add Title"
                          onChange={(e) => setTitle(e.target.value)}
                          className="titleInput"
                          value={title}
                        />
                        <Tooltip
                          arrow
                          placement="top"
                          title="
                                Title values can include placeholders. For example, {{CHAPTER}} which means the name of the current selected chapter will be populated when you run survey.
                              "
                        >
                          <IconButton>
                            <HelpIcon />{' '}
                          </IconButton>
                        </Tooltip>
                      </div>
                      <textarea
                        id="w3review"
                        className="descInput"
                        name="w3review"
                        value={description}
                        rows="4"
                        cols="50"
                        placeholder="Add Description"
                        onChange={(e) => setDescription(e.target.value)}
                      />

                      {formValues?.map((obj, index) => (
                        <>
                          <Input
                            key={index}
                            objValue={obj}
                            onChange={handleChange}
                            index={index}
                            deleteField={handleDeleteField}
                            editField={handleEditField}
                            disabled={false}
                          />
                          {indexValue == index && editMode ? (
                            <>
                              <EditFormChapterInput
                                editMode={editMode}
                                fieldValue={fieldValue}
                                inputRef={inputRef}
                                selectRefRating={selectRefRating}
                                handleDropdownRating={handleDropdownRating}
                                selectRef={selectRef}
                                handleDropdown={handleDropdown}
                                labelValue={labelValue}
                                placeholderRef={placeholderRef}
                                placeholderValue={placeholderValue}
                                handleEditSave={handleEditSave}
                                toggleSwitch={toggleSwitch}
                                activeCheck={activeCheck}
                                counter={counter}
                                setCounter={setCounter}
                                optionFunc={optionFunc}
                                modules={modules}
                                formats={formats}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                      {!toggle ? (
                        <>
                          <div className="center-nps">
                            <button
                              className="add-btn-nps-1"
                              onClick={addBtnClick}
                            >
                              Add new
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="dialog-box-nps">
                            <select
                              ref={selectRef}
                              onChange={() => handleDropdown(selectRef)}
                              defaultValue={fieldValue}
                            >
                              <option value="text">Text</option>
                              <option value="textarea">Text Area</option>
                              <option value="text-read-only">
                                Read Only Text
                              </option>
                              <option value="radio">Radio</option>
                              <option value="checkbox">CheckBox</option>
                              <option value="rating">Rating</option>

                              {npsCounter > 0 ? (
                                <> </>
                              ) : (
                                <option value="Nps">Net Promoter Score</option>
                              )}
                            </select>
                            {fieldValue !== 'text-read-only' ? (
                              <input
                                type="text"
                                placeholder="Add Question"
                                className="form-fields"
                                ref={inputRef}
                                defaultValue={labelValue}
                              />
                            ) : (
                              <></>
                            )}

                            {fieldValue === 'text' ||
                            fieldValue == 'textarea' ? (
                              <>
                                <input
                                  type="text"
                                  placeholder="Add placeholder"
                                  ref={placeholderRef}
                                  className="form-fields"
                                  defaultValue={placeholderValue}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div
                            style={{ marginTop: '11px', marginBottom: '11px' }}
                          >
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="custom-switch"
                              onChange={toggleSwitch}
                              checked={activeCheck}
                            />
                            <label for="start" className="require">
                              {' '}
                              Required*
                            </label>
                          </div>
                          {fieldValue == 'radio' ? (
                            <>
                              <div className="option-block">
                                {optionFunc(counter)}
                                <button
                                  type="button"
                                  onClick={() => setCounter(counter + 1)}
                                  className="option-btn"
                                >
                                  +
                                </button>
                                {counter > 1 ? (
                                  <button
                                    type="button"
                                    onClick={() => setCounter(counter - 1)}
                                    className="option-btn"
                                  >
                                    -
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {fieldValue == 'checkbox' ? (
                            <>
                              <div className="option-block">
                                {optionFunc(counter)}
                                <button
                                  type="button"
                                  onClick={() => setCounter(counter + 1)}
                                  className="option-btn"
                                >
                                  +
                                </button>
                                {counter > 1 ? (
                                  <button
                                    type="button"
                                    onClick={() => setCounter(counter - 1)}
                                    className="option-btn"
                                  >
                                    -
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {fieldValue == 'text-read-only' ? (
                            <>
                              <ReactQuill
                                theme="snow"
                                style={{ background: '#fff' }}
                                // value={formData.definition}
                                // onChange={handleRichTextChange}
                                modules={modules}
                                formats={formats}
                                ref={inputRef}
                                className="rich-text-editor"
                                required
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          {fieldValue == 'rating' ? (
                            <>
                              <select
                                ref={selectRefRating}
                                onChange={() =>
                                  handleDropdownRating(selectRefRating)
                                }
                                style={{
                                  width: '20%',
                                  border: '1px solid #9d9eff',
                                  padding: '5px',
                                  outline: 'none',
                                  margin: '6px',
                                }}
                              >
                                <option value="star">Star</option>
                                <option value="Heart">Heart</option>
                                <option value="faces">Faces</option>
                              </select>
                              {/* {editMode ? (
                                <div
                                  className="save-btn-nps"
                                  onClick={handleEditSave}
                                >
                                  Save
                                </div>
                              ) : (
                                <></>
                              )} */}
                            </>
                          ) : (
                            <></>
                          )}
                          {fieldValue == 'Nps' ? <> </> : <></>}

                          <button
                            className="add-btn-nps"
                            type="button"
                            onClick={handleAddField}
                          >
                            Add
                          </button>
                        </>
                      )}
                    </form>

                    {formValues?.length > 0 ? (
                      <>
                        <div className="nps-form-main-btn">
                          <button
                            type="button"
                            className="submit-btn-nps-chapter"
                            onClick={handlePreview}
                          >
                            Preview
                          </button>
                          <button
                            type="button"
                            className="submit-btn-nps-chapter"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              }
            />
          </div>
          <Modal
            className="preview-nps-modal"
            show={preview}
            onHide={handleCloseModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Preview Survey</Modal.Title>
            </Modal.Header>
            <Modal.Body className="preview-nps-modal-body">
              <PreviewForm
                formValues={formValuesPreview}
                formTitle={titlePreview}
                formDesc={descriptionPreview}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  )
}
export default CreateForm
