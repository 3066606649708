import axios from 'axios'

export const getDagsterDbtJobs = async (endpoint, jobName = '') => {
    const params = { page_number: 1, page_size: 10 }
   
    let filtersArr = [];
    if (jobName && jobName !== '') {
        
        filtersArr.push(`{"op":"ilike","field":"JOB_NAME","value":"%${jobName}%"}`)
      }
      params.filters = '[' + filtersArr.join(',') + ']'
    const url = `${endpoint}/api/dbt_grading/get_dagster_dbt_jobs`
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    let jobsData = [];
    try {
    const response = await axios
      .get(url, {
        params: params,
        headers: post_headers,
      });
      jobsData = response.data;

      return jobsData;
    } catch (err) {
      // Error handling here
      return jobsData;
   }
  }
