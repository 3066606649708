import {
  PREVIEW_TEMPLATE_ERROR,
  PREVIEW_TEMPLATE_LOAD,
  PREVIEW_TEMPLATE_POST,
} from '../../../constants/types'

const initialState = {
  previewTemplate: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case PREVIEW_TEMPLATE_LOAD:
      return {
        ...state,
        loading: true,
      }
    case PREVIEW_TEMPLATE_POST:
      return {
        ...state,
        previewTemplate: action.payload,
        loading: false,
      }
    case PREVIEW_TEMPLATE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
