import React from 'react'
import Modal from 'react-bootstrap/Modal'
import LoadingStatus from '../../Helper/LoadingStatus'
import { Button } from 'react-bootstrap'
import {AnnouncementCategoryLabel } from '../Announcements/announcementFunctions'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

const AnnouncementItemModal = ({
    showAnnouncementItemModal,
    setShowAnnouncementItemModal,
    announcementItem,
    announcementTitle,
    BootstrapTooltip,
    handleExternalLink
}) => {
    const loadingMsg = 'Fetching Announcement Detail...'

    return (
        <Modal size="lg" show={showAnnouncementItemModal} onHide={() => setShowAnnouncementItemModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{announcementTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                {(announcementItem && announcementItem.id) ? (
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <div className='announcement-header'>
                            <span className='announcement-sender'>{announcementItem?.created_by.split('@')[0]}</span>
                            <span className='audience-announcement'>{announcementItem.target_audience}</span> 
                            {AnnouncementCategoryLabel(announcementItem.category)}
                            {announcementItem.is_active ? <span className='active_inactive_badge active_badge'><CheckCircleOutlineIcon /> Active</span> : <span className='active_inactive_badge inactive_badge'><RemoveCircleOutlineIcon /> Inactive</span> }
                            {announcementItem.attachments_path && (
                                <BootstrapTooltip title="Click to Open Extenal Link">
                                    <Button size="medium" variant="primary" onClick={(e) => handleExternalLink(e, announcementItem.attachments_path)} style={{textTransform: 'none'}}>
                                        External Link
                                    </Button>
                                </BootstrapTooltip>
                            )}
                        </div>
                        <div>
                            {announcementItem.is_active && (announcementItem.active_date || announcementItem.expiry_date) && (
                                <span>Active: {announcementItem.active_date ? 'From '+(announcementItem.active_date).split("T")[0]  : ''} {announcementItem.expiry_date ? ' To '+(announcementItem.expiry_date).split("T")[0]  : ''}</span>
                            )}
                            
                            <span style={{color: '#949494'}}>{announcementItem.view_count} Views</span>
                        </div>                                        
                    </div>
                ) : <div style={{minHeight:'10em'}}><LoadingStatus status_message={loadingMsg} /></div>}
                
                {(announcementItem && announcementItem.description) ? <p>{announcementItem.description}</p> : '' }
                <Modal.Footer>
                    <Button variant="danger" type="submit" className="addModalBtn"
                    onClick={()=>setShowAnnouncementItemModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
                    
            </Modal.Body>
        </Modal>
    )
}

export default AnnouncementItemModal;
