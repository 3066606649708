import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import './tabView.css'

export default function TabView({dataArr}) {
    return (
        <Tab.Container defaultActiveKey={dataArr.defaultTab}>
            <Row>
                <Col sm={12}>
                    <Nav variant="pills" className="flex-row tab_component" style={{    borderBottom: '1px solid #7E8299'}}>
                        {dataArr.dataSet.map(item=>{
                            return(
                                <Nav.Item  key={item.key}>
                                    <Nav.Link eventKey={item.key}>{item.title}</Nav.Link>
                                </Nav.Item>
                            )
                        })}
                    </Nav>
                </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Tab.Content>
                        {dataArr.dataSet.map(item=>{
                            return(
                            <Tab.Pane key={item.key} eventKey={item.key} >{item.content}</Tab.Pane>
                            )
                        })}
                        </Tab.Content>
                    </Col>
            </Row>
        </Tab.Container>
    )
}
