import React, { useState, useContext, useEffect } from 'react'
import Sidebar from '../AppBar/Appbar'
import LoadingStatus from '../../Helper/LoadingStatus'
import Table from 'react-bootstrap/Table'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../context/authProvider'
import ReactPaginate from 'react-paginate'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import '../../../App.css'
import { getJobPostings } from '../../../redux/actions/jobPostingActions/jobPostingAction'
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction'
import { styled } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SendIcon from '@mui/icons-material/Send'
import WorkIcon from '@mui/icons-material/Work'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import './index.css'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <Avatar
        sx={{ width: 25, height: 25, marginRight: 0, bgcolor: '#dadce0' }}
      >
        <ExpandMoreIcon className='expand-more-color' />
      </Avatar>
    }
    {...props}
  />
))(() => ({
  flexDirection: 'row-reverse',
  gap: '8px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {},
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export default function JobPostings() {
  const msg = 'Fetching Job Postings...'
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: msg,
  })
  const [expanded, setExpanded] = useState('')
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: '12px',
      whiteSpace: 'break-spaces',
    },
  }))
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  const applyJob = (e, applyLink) => {
    e.stopPropagation();
    window.open(applyLink, '_blank');
  }
  const sendJobEmail = (e, managerEmail, jobTitle) => {
    e.stopPropagation();
    window.location.href = `mailto:${managerEmail}?subject=Kholab: Job Posting [${jobTitle}] message`;
  }
  const [channel, setChannel] = useState(localStorage.getItem('channel'))
  const getJobPostingsData = useSelector(
    (state) => state.getJobPostingsReducer.getJobPostings
  )
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const [authCreds] = useContext(AuthContext)
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 20
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected)
  }
  const fetchData = () => {
    setLoadingStatus({ status: true, message: msg })

    const params = {
      page_size: pageSize,
      page_number: currentPage + 1,
    }
    if (channel && channel !== '') {
      let filtersArr = []
      filtersArr.push(`{"op":"eq","field":"ROLE_TYPE","value":"${channel}"}`)
      params.filters = '[' + filtersArr.join(',') + ']'
    }

    const url = `${endpoint}/api/collab/get_job_postings`
    dispatch(getJobPostings(url, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: msg,
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: msg,
        })
      })
  }

  const [initLoad, setInitLoad] = useState(false)
  useEffect(() => {
    if (initLoad === true) {
      return
    }
    setInitLoad(true)
    fetchData()
  }, [])
  useEffect(() => {
    fetchData()
  }, [currentPage, channel])
  useEffect(() => {
    setTotalPages(getJobPostingsData?.num_pages)
  }, [getJobPostingsData])
  useEffect(() => {
    async function forumMetadata() {
      setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=JOBPOSTINGS`
      dispatch(getForumMetadata(url)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })

    if (forumMetadata?.loading === false) {
      setLoadingStatus({ status: false, message: 'Fetching data...' })

      localStorage.setItem(
        'channel_metadata',
        JSON.stringify(forumMetadata?.forumMetadata)
      )
    }
  }, [forumMetadata])

  return (
    <Sidebar
      channel={channel}
      setChannel={setChannel}
      Page="Job Postings"
      Index={
        <div className='job-posting-container'>
          {loadingStatus.status ? (
            <LoadingStatus status_message={loadingStatus.message} />
          ) : (
            <>
              {getJobPostingsData &&
              getJobPostingsData.data &&
              getJobPostingsData?.data?.length == 0 ? (
                <div>More Jobs to Come.</div>
              ) : (
                <div>
                  {getJobPostingsData?.data?.map((data) => {
                    return (
                      <Accordion
                        expanded={expanded === data.ID}
                        key={data.ID}
                        onChange={handleChange(data.ID)}
                        className="job-posting-accordion"
                      >
                        <AccordionSummary>
                          <div className='job-posting-card'>
                            <div className='job-posting-card-title'>
                              {data.WD_OPEN_JOB_REQUISITION} #{data.ID}
                            </div>
                            <div className='flex-center'>
                              <div className='job-posting-card-detail'>
                                <div className='job-summary-section'>
                                  <span className="job-summary-label">Group</span>
                                  <span className="job-summary-text">{data.WD_REQ_JOB_FAMILY_GROUP} </span>
                                </div>
                                <div className='job-summary-section'>
                                  <span className="idea-summary-label">Location</span>
                                  <span className="job-summary-text">{data.WD_REQ_LOCATION}</span>
                                </div>
                                <div className='job-summary-section'>
                                  <span className="idea-summary-label">Hiring Manager</span>
                                  <span className="job-summary-text">{data.WD_HIRING_MANAGER.split('@')[0]}</span>
                                </div>
                                <div className='job-summary-section'>
                                  <span className="idea-summary-label">Job Posting Date</span>
                                  <span className="job-summary-text">{data.WD_EARLIEST_JOB_POSTING_START_DATE}</span>
                                </div>
                              </div>
                            </div>
                            <div className='job-posting-flex'>
                            {data.APPLY_LINK && (
                              <div>
                                <BootstrapTooltip title="Click to apply Job">
                                  <Button variant="contained" className="apply_job_button" endIcon={<WorkIcon />} onClick={(e)=>applyJob(e, data.APPLY_LINK)} size="small" >Apply</Button> 
                                </BootstrapTooltip>
                              </div>
                            )}
                              <div>
                                  <BootstrapTooltip title="Click to send email to Hiring Manager">
                                    <Button variant="contained" className="apply_job_button" onClick={(e) => sendJobEmail(e, data.WD_HIRING_MANAGER, data.WD_OPEN_JOB_REQUISITION)}  endIcon={<SendIcon />} size="small" >Send Email</Button>
                                  </BootstrapTooltip>
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table bordered>
                            <tbody>
                              <tr>
                                <td className='job-posting-w-10'>
                                  <span className="idea-summary-label">
                                    Worker Type
                                  </span>
                                </td>
                                <td className='job-posting-w-15'>
                                  <span className="table-data-col">
                                    {data.WD_WORKER_TYPE}
                                  </span>
                                </td>
                                <td className='job-posting-w-10'>
                                  <span className="idea-summary-label">
                                    Time Type
                                  </span>
                                </td>
                                <td className='job-posting-w-15'>
                                  <span className="table-data-col">
                                    {data.WD_TIME_TYPE}
                                  </span>
                                </td>
                                <td className='job-posting-w-10'>
                                  <span className="idea-summary-label">
                                    Req Job Family
                                  </span>
                                </td>
                                <td className='job-posting-w-15'>
                                  <span className="table-data-col">
                                    {data.WD_REQ_JOB_FAMILY}
                                  </span>
                                </td>
                                <td className='job-posting-w-10'>
                                  <span className="idea-summary-label">
                                    Job Location
                                  </span>
                                </td>
                                <td className='job-posting-w-15'>
                                  <span className="table-data-col">
                                    {data.WD_LOCATION},{' '}
                                  </span>
                                  <span className="table-data-col">
                                    {data.WD_LOCATION_COUNTRY}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="idea-summary-label">
                                    Job Posting Start Date
                                  </span>
                                </td>
                                <td>
                                  <span className="table-data-col">
                                    {data.WD_EARLIEST_JOB_POSTING_START_DATE}
                                  </span>
                                </td>
                                <td>
                                  <span className="idea-summary-label">
                                    Job Category
                                  </span>
                                </td>
                                <td>
                                  <span className="table-data-col">
                                    {data.WD_JOB_CATEGORY}
                                  </span>
                                </td>
                                <td>
                                  <span className="idea-summary-label">
                                    Staffing Status
                                  </span>
                                </td>
                                <td>
                                  <span className="table-data-col">
                                    {data.WD_STAFFING_STATUS}
                                  </span>
                                </td>
                                <td>
                                  <span className="idea-summary-label">
                                    Req Location
                                  </span>
                                </td>
                                <td>
                                  <span className="table-data-col">
                                    {data.WD_REQ_LOCATION}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="idea-summary-label">
                                    Req Job Family Group
                                  </span>
                                </td>
                                <td>
                                  <span className="table-data-col">
                                    {data.WD_REQ_JOB_FAMILY_GROUP}
                                  </span>
                                </td>
                                <td>
                                  <span className="idea-summary-label">
                                    Job Requisition Status Calc
                                  </span>
                                </td>
                                <td>
                                  <span className="table-data-col">
                                    {data.WD_JOB_REQUISITION_STATUS_CALC}
                                  </span>
                                </td>
                                <td>
                                  <span className="idea-summary-label">
                                    Role Type
                                  </span>
                                </td>
                                <td>
                                  <span className="table-data-col">
                                    {data.ROLE_TYPE ? data.ROLE_TYPE : 'N/A'}
                                  </span>
                                </td>
                                <td>
                                  <span className="idea-summary-label">
                                    Hiring Manager
                                  </span>
                                </td>
                                <td>
                                  <span className="table-data-col">
                                    {data.WD_HIRING_MANAGER}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="idea-summary-label">
                                    Created By
                                  </span>
                                </td>
                                <td colSpan="3">
                                  <span className="table-data-col">
                                    {data.CREATED_BY}
                                    <br />
                                    {data.CREATED_ON}
                                  </span>
                                </td>
                                <td>
                                  <span className="idea-summary-label">
                                    Modified By
                                  </span>
                                </td>
                                <td colSpan="3">
                                  <span className="table-data-col">
                                    {data.MODIFIED_BY
                                      ? data.MODIFIED_BY +
                                        '<br/>' +
                                        data.MODIFIED_ON
                                      : 'N/A'}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </Table>\
                          <div className='job-posting-detail'>
                            {data.WD_JOB_PROFILE && (
                              <div>
                                <span className="job-posting-label">
                                  Profile{' '}
                                </span>
                                <span className="job-posting-desc">
                                  {data.WD_JOB_PROFILE}
                                </span>
                              </div>
                            )}
                            {data.WD_JOB_DESC_SUMMARY && (
                              <div>
                                <span className="job-posting-label">
                                  Summary{' '}
                                </span>
                                <span className="job-posting-desc">
                                  {data.WD_JOB_DESC_SUMMARY}
                                </span>
                              </div>
                            )}
                            {data.WD_SUPERVISORY_ORGANIZATION && (
                              <div>
                                <span className="job-posting-label">
                                  Organization{' '}
                                </span>
                                <span className="job-posting-desc">
                                  {data.WD_SUPERVISORY_ORGANIZATION}
                                </span>
                              </div>
                            )}
                          </div>

                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.WD_JOB_DESCRIPTION,
                            }}
                          ></div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </div>
              )}
              <div className='job-posting-pagination-container'>
                <ReactPaginate
                  pageCount={totalPages}
                  onPageChange={handlePageChange}
                  forcePage={currentPage}
                  breakLabel={'...'}
                  previousLabel={'<<'}
                  nextLabel={'>>'}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination job-posting-pagination"
                  activeClassName="active"
                />
                <div className='job-posting-pagination-desc-container'><span className='job-posting-pagination-desc'>Total Jobs:</span>{getJobPostingsData?.total_results}</div>
              </div>
              
            </>
          )}
        </div>
      }
    />
  )
}
