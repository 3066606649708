import {
  LOAD_SNF_CLUSTER_DATA_BY_CLUSTER_ID,
  ERROR_SNF_CLUSTER_DATA_BY_CLUSTER_ID,
  GET_SNF_CLUSTER_DATA_BY_CLUSTER_ID,
} from '../../constants/types'

const initialState = {
  clusterData: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_SNF_CLUSTER_DATA_BY_CLUSTER_ID:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case ERROR_SNF_CLUSTER_DATA_BY_CLUSTER_ID:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_SNF_CLUSTER_DATA_BY_CLUSTER_ID: {
      return {
        ...state,
        clusterData: action.payload,
        loading: false,
        error: null,
      }
    }
    default:
      return state
  }
}
