import {
    GET_EMAIL_TEMPLATE_ERROR,
    GET_EMAIL_TEMPLATE_POST,
    GET_EMAIL_TEMPLATE_LOAD,
  } from '../../constants/types'
  
  const initialState = {
    getEmailTemplateData: [],
    loading: true,
    error: '',
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_EMAIL_TEMPLATE_LOAD:
        return {
          ...state,
          loading: true,
        }
      case GET_EMAIL_TEMPLATE_POST:
        return {
          ...state,
          loading: false,
          getEmailTemplateData: action.payload,
        }
      case GET_EMAIL_TEMPLATE_ERROR:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }
      default:
        return state
    }
  }
  
