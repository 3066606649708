import React from 'react'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from 'react-bootstrap'

const HelperComponent = ({ openModel, closeModel, transiton }) => {
  return (
    <Dialog
      maxWidth="md"
      open={openModel}
      TransitionComponent={transiton}
      keepMounted
      onClose={closeModel}
      aria-describedby=""
    >
     <DialogTitle>
          Welcome to Chapter NPS survey, Your feedback shapes our organisation goals! It
          takes miniumun time to share your experience and help us to create
          efficient features for everyone. Thank you! <br />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography gutterBottom>
              <b>
                {' '}
                - Creation of dynamic survey forms from UI <br />
                - Track record of survey responses and completion percentage
                <br />
                - Helps in improving performance and organisation goals <br />
              </b>
            </Typography>
            <br />
            <Typography gutterBottom>
              <b>
                <u>Create Template:</u>
              </b>
              <br />
              <br /> Create a new template based on base templates and start integrating directly in your survey. <br />
              Admin can view and update all the templates created by user. Template owner can view and edit their own templates only. <br />
            </Typography>
            <br />
            <Typography gutterBottom>
              <b>
                <u>Create Survey:</u>
              </b>
              <br />
              <br /> Create a new survey to gather feedback from your team.
              Define the survey name, questions, and start/end date to
              publsh survey. You can create survey by selecting any base template or your own template. <br />
              Base Template will be shown on the basis of your selection for internal or external survey. <br />

            </Typography>
            <br />
            <Typography gutterBottom>
              <b>
                <u> Survey Group Admin:</u>
              </b>
              <br />
              <br /> This operation is for admin only. You can also view and modify existing groups. You can
              enable or disable user from the group by simply select the row.{' '}
              <br />
             
            </Typography>
            <br />
            <Typography gutterBottom>
              <b>
                {' '}
                <u>Survey Admin:</u>
              </b>
              <br />
              <br /> Admin can view and update all the surveys created by user.
              Survey owner can view and edit their own surveys only. <br />
              In edit modal you can update the survey name, questions, group and
              start/end date. 
              <br />
            </Typography>{' '}
            <br />
            <Typography gutterBottom>
              <b>
                {' '}
                <u>My Surveys:</u>
              </b>
              <br />
              <br />
              View all the surveys created by survey group owner and assigned to
              you. You can view the survey by clicking on the card. <br />
              Fill out all the Mandatory fields showing as required and submit
              your response. <br />
              Once submitted you can not modify or open the filled surveys.
            </Typography>{' '}
            <br />
            <Typography gutterBottom>
              <b>
                If you need any assistance regarding Chapter NPS surveys. Please email
                at{' '}
              </b>
              <a
                href="mailto:dl_khcdewebappdev@kraftheinz.com"
                className="email-link"
              >
                dl_khcdewebappdev@kraftheinz.com
              </a>
            </Typography>
          </DialogContentText>
        </DialogContent>
      <DialogActions>
        <Button onClick={closeModel}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default HelperComponent
