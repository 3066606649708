import { useState, useRef, useEffect, useContext } from 'react'
import Input from '../input'
import '../indexChapter.css'
import { toast } from 'react-toastify'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ReactQuill from 'react-quill'
import { AuthContext } from '../../../context/authProvider'
import {
  editSurvey,
  getSurvey,
} from '../../../../redux/actions/npsActions/npsSurveyAction'
import LoadingStatus from '../../../Helper/LoadingStatus'
import { DateHandler } from '../../Utilities/dateutils'
import SelectAsyncPaginate from '../../Utilities/selectPaginate'
import EditFormChapterInput from '../editFormChapterInput'
import { format, subDays, isBefore, isAfter, parseISO, isEqual, differenceInDays } from 'date-fns'
import ConfigureEmailModal from '../../Utilities/ConfigureEmailModal'

function AdminViewForm({ surveyId }) {
  const [formValues, setFormValues] = useState([])
  const [toggle, setToggle] = useState(false)
  const [groupChoiceList, setGroupChoiceList] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [enableEdit, setEnableEdit] = useState(true)
  const [fieldValue, setFieldValue] = useState('text')
  const [counter, setCounter] = useState(1)
  const [indexValue, setIndex] = useState(0)
  const [title, setTitle] = useState('')
  const [placeholderValue, setPlaceHolder] = useState('Enter your answer')
  const [labelValue, setFormLabel] = useState('')
  const [npsCounter, setNpsCounter] = useState(0)
  const [initialCheckbox, setInitialCheckbox] = useState(true)
  const [reminderCheckbox, setReminderCheckbox] = useState(true)
  const [activeCheck, setActiveCheck] = useState(false)
  const [remCount, setRemCount] = useState(1)
  const [editMode, setEditMode] = useState(false)
  const [description, setDescription] = useState('')
  const [active, setActive] = useState(false)
  const [fieldValueRating, setFieldValueRating] = useState('star')
  const [channel, setChannel] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [emailSelect, setEmailSelect] = useState({
    initialEmail: {
      id: '',
      title: '',
    },
    reminderEmail: {
      id: '',
      title: '',
    },
  })
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [authCreds] = useContext(AuthContext)

  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const getSurveyData = useSelector((state) => state.getSurveyData)
  const inputRef = useRef()
  const placeholderRef = useRef()
  const inputRefs = useRef([])
  const pushRef = (el) => {
    inputRefs.current.push(el)
  }

  const selectRef = useRef()
  const selectRefRating = useRef()

  const handleDropdownRating = (selectRefRating) => {
    setFieldValueRating(selectRefRating.current.value)
  }

  useEffect(() => {
    if (getSurveyData?.loading === false) {
      const namesArray = getSurveyData?.getSurveyData?.data?.survey_json?.map(
        (obj) => obj.type
      )
      if (namesArray.includes('Nps')) {
        setNpsCounter(1)
      }
    }
  }, [getSurveyData, npsCounter])
  useEffect(() => {
    if (formValues?.length > 0) {
      const namesArray = formValues?.map((obj) => obj.type)
      if (namesArray.includes('Nps')) {
        setNpsCounter(1)
      } else {
        setNpsCounter(0)
      }
    }
  }, [formValues, npsCounter])
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    if (getSurveyData?.loading == false) {
      let groupNames =
        getSurveyData?.getSurveyData?.data?.active_survey_group_list?.map(
          (res) => {
            return { value: res?.label, label: res?.label, id: res?.id }
          }
        )
      setGroupChoiceList(groupNames)
      setFormValues(getSurveyData?.getSurveyData?.data?.survey_json)
      setTitle(getSurveyData?.getSurveyData?.data?.form_name)
      setDescription(getSurveyData?.getSurveyData?.data?.description)
      setActive(getSurveyData?.getSurveyData?.data?.is_active)
      setChannel(getSurveyData?.getSurveyData?.data?.channel)
      setInitialCheckbox(getSurveyData?.getSurveyData?.data?.is_initial_email_checkbox)
      setReminderCheckbox(getSurveyData?.getSurveyData?.data?.is_reminder_email_checkbox)
      setStartDate(
        DateHandler.dateTimetoDateConverter(
          getSurveyData?.getSurveyData?.data?.start_date
        )
      )
      setEndDate(
        DateHandler.dateTimetoDateConverter(
          getSurveyData?.getSurveyData?.data?.end_date
        )
      )
      setEmailSelect({
        initialEmail: {
          id: getSurveyData?.getSurveyData?.data?.initial_email_template_id,
          title: getSurveyData?.getSurveyData?.data?.initial_template_name,
        },
        reminderEmail: {
          id: getSurveyData?.getSurveyData?.data?.reminder_email_template_id,
          title: getSurveyData?.getSurveyData?.data?.reminder_template_name,
        },
      })
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    }
  }, [getSurveyData])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    dispatch(getSurvey(endpoint, surveyId, 'True')).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    })
  }, [])

  const handleChange = (e, index) => {
    const values = [...formValues]
    values[index].value = e.target.value
    setFormValues(values)
  }
  const handleDeleteField = (e, index) => {
    const values = [...formValues]
    values.splice(index, 1)
    setFormValues(values)
  }
  const handleInitialCheckbox = () => {
    setInitialCheckbox(!initialCheckbox)
  }
  const handleReminderCheckbox = () => {
    setReminderCheckbox(!reminderCheckbox)
  }
  const clearArray = () => {
    inputRefs.current.length = 0
  }
  const isDifferenceGreaterThanOne = (startDate, endDate) => {
    if (!startDate || !endDate) return false
    const start = parseISO(startDate)
    const end = parseISO(endDate)
    return differenceInDays(end, start) > 1
  }
  const isRemDateWithInRange = (startDate, endDate) => {
    if (isDifferenceGreaterThanOne(startDate, endDate) && reminderCheckbox) {
      const RemStartDate = format(subDays(parseISO(endDate), remCount), 'yyyy-MM-dd')
      return isAfter(parseISO(RemStartDate), parseISO(startDate)) && (isBefore(parseISO(RemStartDate), parseISO(endDate)) || isEqual(parseISO(RemStartDate), parseISO(endDate)))
    }
    return true
  }
  const handleAddField = (e) => {
    if (
      inputRef.current.value === '' ||
      inputRef.current.value === null ||
      placeholderRef?.current?.value === '' ||
      placeholderRef?.current?.value === null
    ) {
      toast.error('Please enter values')
    } else {
      e.preventDefault()
      const values = [...formValues]

      let options = inputRefs?.current?.map((n) => {
        return n?.value
      })
      let newOption = options.filter(function (element) {
        return element !== undefined
      })
      let newOption1 = [...new Set(newOption)]
      if (newOption1[0] == '') {
        toast.error('Please enter options')
      } else {
        values.push({
          label: inputRef.current.value || 'label',
          placeholder: placeholderRef?.current?.value || '',
          type: selectRef.current.value || 'text',
          value: '',
          ratingType: fieldValueRating,
          options: newOption1 || [],
        })
        setFormValues(values)
        setToggle(false)
        setFieldValue(selectRef.current.value)
        clearArray()
      }
    }
  }
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
      ['code'],
    ],
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'code',
  ]

  useEffect(() => {
    setFormValues(formValues)
  }, [labelValue, editMode, placeholderValue, indexValue, fieldValue])

  const handleEditField = (e, index) => {
    formValues.map((obj, i) => {
      if (i === index) {
        e.preventDefault()
        clearArray()
        setFieldValue(obj.type)
        setFieldValueRating(obj.ratingType)
        setFormLabel(obj.label)
        setPlaceHolder(obj.placeholder)
        setEditMode(!editMode)
        setActiveCheck(obj.required)
        setIndex(index)
      }
    })
  }
  const handleEditSave = (e) => {
    e.preventDefault()
    const values = [...formValues]

    let options = inputRefs?.current?.map((n) => {
      return n?.value
    })
    let newOption = options.filter(function (element) {
      return element !== undefined
    })
    let newOption1 = [...new Set(newOption)]
    if (newOption1[0] == '') {
      toast.error('Please enter options')
    } else {
      (values[indexValue].label = inputRef.current.value || 'label'),
        (values[indexValue].placeholder = placeholderRef?.current?.value || ''),
        (values[indexValue].type = selectRef.current.value || 'text'),
        (values[indexValue].value = ''),
        (values[indexValue].required = activeCheck),
        (values[indexValue].ratingType = fieldValueRating),
        (values[indexValue].options = newOption1 || [])

      setFormValues(values)
      setFieldValue(selectRef.current.value)
      setFieldValueRating('star')
      clearArray()
      setEditMode(false)
    }
  }

  const handleChangeCheck = () => {
    setActive(!active)
  }

  const addBtnClick = (e) => {
    e.preventDefault()
    setToggle(true)
    clearArray()
    setFieldValue('text')
    setCounter(1)
  }
  function optionFunc(c) {
    var MultiGold = []
    Array.from(Array(c), (e, i) => {
      MultiGold.push(
        <input
          className="options"
          type="text"
          placeholder="Add option"
          ref={pushRef}
          key={i}
        />
      )
    })
    return <div>{MultiGold}</div>
  }
  const handleDropdown = (selectRef) => {
    setFieldValue(selectRef.current.value)
  }
  function toggleSwitch() {
    setActiveCheck(!activeCheck)
  }
  const handleSubmit = () => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const dayDiff = (end.getTime() - start.getTime()) / (1000 * 3600 * 24)

    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if ((dayDiff == 0 || dayDiff == 1) && !emailSelect.initialEmail.id) {
      toast.error('Please select an initial emails.')
    } else if (dayDiff > 1 && (!emailSelect.initialEmail.id || !emailSelect.reminderEmail.id)) {
      toast.error('Please select both initial and reminder emails.')
    } else if (startDate == undefined || endDate == undefined) {
      toast.error('Please add start and end date')
    } else if (!isRemDateWithInRange(startDate, endDate)) {
      toast.error('Reminder days should be between start date and end date')
    } else {
      const groupID = groupChoiceList.map((object) => object.id)

      const params = {
        id: surveyId,
        form_name: title,
        description: description,
        created_on: getSurveyData?.getSurveyData?.data?.created_on,
        created_by: getSurveyData?.getSurveyData?.data?.created_by,
        is_active: active,
        is_private: true,
        nps_score_perc: getSurveyData?.getSurveyData?.data?.nps_score_perc,
        survey_comp_perc: getSurveyData?.getSurveyData?.data?.survey_comp_perc,
        survey_group_ids: groupID,
        start_date: startDate,
        end_date: endDate,
        form_json: formValues,
        channel: channel,
        initial_email_template_id: emailSelect.initialEmail.id,
        reminder_email_template_id: emailSelect.reminderEmail.id,
        scheduled_rem_days_count: isDifferenceGreaterThanOne(startDate, endDate) && reminderCheckbox ? remCount : -1,
        survey_tags: [
          {
            name: 'is_initial_email_checkbox',
            value: initialCheckbox,
          },
          {
            name: 'is_reminder_email_checkbox',
            value: isDifferenceGreaterThanOne(startDate, endDate) ? reminderCheckbox : false,
          },
        ],      }
      dispatch(editSurvey(endpoint, params)).then(() => {
        setEnableEdit(false)
        setNpsCounter(0)

        dispatch(getSurvey(endpoint, surveyId, 'True')).then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
      })
    }
  }
  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="nps">
          <form className="nps-form-main">
            <input
              type="text"
              placeholder="Add Title"
              onChange={(e) => setTitle(e.target.value)}
              className="titleInput"
              defaultValue={getSurveyData?.getSurveyData?.data?.form_name}
              // disabled={!enableEdit}
            />
            <textarea
              id="w3review"
              className="descInput"
              name="w3review"
              rows="4"
              cols="50"
              placeholder="Add Description"
              onChange={(e) => setDescription(e.target.value)}
              defaultValue={getSurveyData?.getSurveyData?.data?.description}
            />
            {formValues?.map((obj, index) => (
              <>
                <Input
                  key={index}
                  objValue={obj}
                  onChange={handleChange}
                  index={index}
                  deleteField={handleDeleteField}
                  editField={handleEditField}

                  // disabled={!enableEdit}
                />
                {indexValue == index && editMode ? (
                  <>
                    <EditFormChapterInput
                      editMode={editMode}
                      fieldValue={fieldValue}
                      inputRef={inputRef}
                      selectRefRating={selectRefRating}
                      handleDropdownRating={handleDropdownRating}
                      selectRef={selectRef}
                      handleDropdown={handleDropdown}
                      labelValue={labelValue}
                      placeholderRef={placeholderRef}
                      placeholderValue={placeholderValue}
                      handleEditSave={handleEditSave}
                      toggleSwitch={toggleSwitch}
                      activeCheck={activeCheck}
                      counter={counter}
                      setCounter={setCounter}
                      optionFunc={optionFunc}
                      modules={modules}
                      formats={formats}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            ))}
            {!toggle && enableEdit ? (
              <>
                <div className="center-nps">
                  <button className="add-btn-nps-1" onClick={addBtnClick}>
                    Add new
                  </button>
                </div>
              </>
            ) : enableEdit ? (
              <>
                <div className="dialog-box-nps">
                  <select
                    ref={selectRef}
                    onChange={() => handleDropdown(selectRef)}
                  >
                    <option value="text">Text</option>
                    <option value="textarea">Text Area</option>
                    <option value="text-read-only">Read Only Text</option>
                    <option value="radio">Radio</option>
                    <option value="checkbox">CheckBox</option>
                    <option value="rating">Rating</option>
                    <option value="Nps">Net Promoter Score</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Add Question"
                    className="form-fields"
                    ref={inputRef}
                  />
                  {fieldValue === 'text' || fieldValue == 'textarea' ? (
                    <input
                      type="text"
                      placeholder="Add placeholder"
                      ref={placeholderRef}
                      className="form-fields"
                      defaultValue="Enter Your Answer"
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div style={{ marginTop: '11px', marginBottom: '11px' }}>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                  <label for="start" className="require">
                    {' '}
                    Required*
                  </label>
                </div>
                {fieldValue == 'radio' ? (
                  <>
                    <div className="option-block">
                      {optionFunc(counter)}
                      <button
                        type="button"
                        onClick={() => setCounter(counter + 1)}
                        className="option-btn"
                      >
                        +
                      </button>
                      {counter > 1 ? (
                        <button
                          type="button"
                          onClick={() => setCounter(counter - 1)}
                          className="option-btn"
                        >
                          -
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {fieldValue == 'checkbox' ? (
                  <>
                    <div className="option-block">
                      {optionFunc(counter)}
                      <button
                        type="button"
                        onClick={() => setCounter(counter + 1)}
                        className="option-btn"
                      >
                        +
                      </button>
                      {counter > 1 ? (
                        <button
                          type="button"
                          onClick={() => setCounter(counter - 1)}
                          className="option-btn"
                        >
                          -
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {fieldValue == 'text-read-only' ? (
                  <>
                    <ReactQuill
                      theme="snow"
                      style={{ background: '#fff' }}
                      // value={formData.definition}
                      // onChange={handleRichTextChange}
                      modules={modules}
                      formats={formats}
                      ref={inputRef}
                      className="rich-text-editor"
                      required
                    />
                  </>
                ) : (
                  <></>
                )}
                {fieldValue == 'rating' ? (
                  <>
                    <select
                      ref={selectRefRating}
                      onChange={() => handleDropdownRating(selectRefRating)}
                      style={{
                        width: '20%',
                        border: '1px solid #9d9eff',
                        padding: '5px',
                        outline: 'none',
                      }}
                    >
                      <option value="star">Star</option>
                      <option value="Heart">Heart</option>
                      <option value="faces">Faces</option>
                    </select>
                  </>
                ) : (
                  <></>
                )}
                <button
                  className="add-btn-nps"
                  type="button"
                  onClick={handleAddField}
                >
                  Add
                </button>
              </>
            ) : (
              <></>
            )}
          </form>
          {formValues?.length > 0 ? (
            <div className="nps-form-main">
              <div>
                <p style={{ margin: '7px' }}>Survey Group </p>
              </div>

              <div style={{ width: '100%' }}>
                <SelectAsyncPaginate
                  onChange={(choice) => setGroupChoiceList(choice)}
                  endpointUrl="api/survey/get_survey_group_list"
                  defaultValue={groupChoiceList}
                  isDisabled={true}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          
        {formValues.length > 0 ? (
            <div className="nps-form-main">
              <h2 className="sub-head-nps">Survey Email Notification</h2>
              <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {emailSelect.initialEmail.title && (
                    <span>Initial Email: {emailSelect.initialEmail.title}</span>
                  )}
                  {emailSelect.reminderEmail.title && (
                    <span>
                      Reminder Email: {emailSelect.reminderEmail.title}
                    </span>
                  )}
                </div>
                <div className="center-nps">
                  <button className="add-btn-nps-1" onClick={handleShowModal}>
                    Configure Email
                  </button>
                </div>
              </>
              <>
                <ConfigureEmailModal
                  emailSelect={emailSelect}
                  setEmailSelect={setEmailSelect}
                  show={showModal}
                  handleClose={handleCloseModal}
                  isChapter={true}
                />
              </>
            </div>
          ) : (
            <></>
          )}
          {formValues?.length > 0 ? (
            <div className="nps-form-main">
              <h2 className="sub-head-nps">Scheduling</h2>
              <>
                <div style={{ marginTop: '20px' }}>
                  <div style={{ display: 'flex' }}>
                    <label for="start" className="date-label">
                      Start date:
                    </label>
                    <input
                      type="date"
                      className="date-field"
                      value={startDate}
                      min={new Date().toISOString().split('T')[0]}
                      // disabled={!enableEdit}
                      onChange={(event) => setStartDate(event.target.value)}
                    />{' '}
                    <label for="start" className="date-label">
                      End date:
                    </label>
                    <input
                      className="date-field"
                      type="date"
                      value={endDate}
                      min={new Date().toISOString().split('T')[0]}
                      onChange={(event) => setEndDate(event.target.value)}
                    />{' '}
                    <input
                      type="checkbox"
                      className="check-field"
                      onChange={handleChangeCheck}
                      checked={active}
                    />
                    <label for="start" className="date-label">
                      Is active
                    </label>
                  </div>
                </div>
                <br />
                <div style={{ marginTop: '20px' }}>
                <input
                  type="checkbox"
                  className="check-field"
                  checked={initialCheckbox}
                  onChange={handleInitialCheckbox}
                />
                <label for="start" className="date-label">
                  Send Initial Reminder
                </label>
                <input
                  type="checkbox"
                  className="check-field"
                  checked={reminderCheckbox}
                  onChange={handleReminderCheckbox}
                  disabled={!isDifferenceGreaterThanOne(startDate, endDate)}
                />
                <label for="start" className="date-label">
                  Automate Scheduled Reminder
                </label>
              </div>
              <br />
              { isDifferenceGreaterThanOne(startDate, endDate) && reminderCheckbox && <div>
                <label for="start" className="date-label">
                  Send daily reminder 
                </label>
                  <input
                      type="number"
                      value={remCount}
                      placeholder="enter a number"
                      style={{width: '120px' }}
                      onChange={(e) => setRemCount(e.target.value)}
                      defaultValue={getSurveyData?.getSurveyData?.data?.scheduled_rem_days_count}
                  />
                <label for="start" className="date-label">
                  days before end date
                </label>
              </div> }
              </>
            </div>
          ) : (
            <></>
          )}
          <button
            type="button"
            className="submit-btn-nps-edit"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      )}
    </>
  )
}
export default AdminViewForm
