import React from 'react'
import { useTable } from 'react-table'
import { Table } from 'react-bootstrap'
import { RefDataContext } from '../../context/refDataProvider'

const EditTable = ({
  table_data,
  setEditRows,
  table_cols,
  enabledColsMap,
  datatype,
  table_name,
  picklist_value,
}) => {
  // const [editData, setData] = React.useState(table_data)
  const [refData] = React.useContext(RefDataContext)
  //Adding picklist to each table's columns
  // if(table_data?.length > 0 ){
  //   table_data?.map((row) => {
  //     return row?.IS_ACTIVE == true ? setCheckBox(true) : setCheckBox(false)
  //   })
  // }
  let getSuggestions = (columnName) => {
    if (picklist_value && picklist_value?.length > 0) {
      let data = picklist_value.find((col) => col.name == columnName)
      if (data && data?.picklists) {
        return data.picklists.map((picklist, i) => (
          <option key={i} value={picklist.value}>
            {picklist.label}
          </option>
        ))
      }
      return null
    }
  }

  const handleApplyAll = (rows, cell) => {
    let value = cell.value;
    let columnId = cell.column.id; // Ensure you use cell.column.id, not cell.column.Header
  
    // Apply value to all rows
    rows.forEach((_, index) => {
      updateMyData(index, columnId, value);
    });
  };

  // given row, col and value. Sets the new data in the editData state and also sets data in parent prop
  const updateMyData = (rowIndex, columnId, value) => {
    setEditRows((old) =>
      old.map((row, index) =>
        index === rowIndex
          ? { ...row, [columnId]: value }
          : row
      )
    );
  };
  // custom component for editable cell
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
  }) => {
    const [value, setValue] = React.useState(initialValue)
    const onChange = (e) => {
      if (e.target.value == '') {
        return setValue(null)
      }
  
      let value = e.target.value
      if (picklist_value && picklist_value.length > 0) {
        let data = picklist_value.find((col) => col.name == id)
        if (
          (!isNaN(+value) && data.datatype === 'int') ||
          (!isNaN(+value) && data.datatype === 'float')
        ) {
          setValue(+e.target.value)
        } else if (data.datatype === 'bool') {
          setValue(e.target.value.toString())
        } else {
          setValue(e.target.value)
        }
      }
    }

    const onBlur = () => {
      updateMyData(index, id, value)
    }

    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    // disables the input element using enabledColsMap parent prop
    if (enabledColsMap[id] === true) {
      const treatString = ['str', 'enum']
      if (datatype[id] === 'date') {
        return (
          <>
            <td key={id}>
              <input
                type="date"
                list={id}
                data-key={id}
                style={{ width: '170px' }}
                data-row={index}
                data-column={id}
                value={value === null ? '' : value}
                onChange={onChange}
                onBlur={onBlur}
              />
              <datalist id={id}>{getSuggestions(id)}</datalist>
            </td>
          </>
        )
      } else if (datatype[id] === 'datetime') {
        return (
          <>
            <td key={id}>
              <input
                type="datetime-local"
                list={id}
                data-key={id}
                style={{ width: '170px' }}
                data-row={index}
                data-column={id}
                value={value === null ? '' : value}
                onChange={onChange}
                onBlur={onBlur}
              />
              <datalist id={id}>{getSuggestions(id)}</datalist>
            </td>
          </>
        )
      } else if (treatString.includes('str', 'enum')) {
        return (
          <>
            <td key={id}>
              <input
                type="text"
                list={id}
                data-key={id}
                data-row={index}
                data-column={id}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              <datalist id={id}>{getSuggestions(id)}</datalist>
            </td>
          </>
        )
      } else if (datatype[id] === 'bool') {
        return (
          <>
            <td key={id}>
              <input
                type="text"
                list={id}
                data-key={id}
                data-row={index}
                data-column={id}
                value={
                  value == null ? '' : value == false ? 'false' : 'true'
                }
                onChange={onChange}
                onBlur={onBlur}
              />
              <datalist id={id}>{getSuggestions(id)}</datalist>
            </td>
          </>
        )
      } else {
        return (
          <>
            <td key={id}>
              <input
                type="number"
                list={id}
                data-key={id}
                data-row={index}
                data-column={id}
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
              />
              <datalist id={id}>{getSuggestions(id)}</datalist>
            </td>
          </>
        )
      }
    } else {
      return (
        <input
          disabled
          data-row={index}
          data-column={id}
          value={value === null ? '' : value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )
    }
  }

  //  creates object for the custom component, which is then used in useTable hook
  const defaultColumn = {
    Cell: EditableCell,
    updateMyData,
  }

  // creates react-table
  const data = table_data
  const columns = table_cols
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
      defaultColumn,
      initialState: { hiddenColumns: refData.hiddenColumns[table_name] },
      updateMyData,
    })

  return (
    <>
      <Table responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th key={i} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return enabledColsMap[cell.column.id] === true ? (
                    <td {...cell.getCellProps()}>
                      <span className="applyover">
                        {cell.render('Cell')}
                        <abbr title="Drop-fill with cell value">
                          <button
                            className="applyall"
                            onClick={() => {
                              handleApplyAll(rows, cell)
                            }}
                          >
                            <strong>&#8595;</strong>
                          </button>
                        </abbr>
                      </span>
                    </td>
                  ) : (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default EditTable
