import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getGlossaryTerms,
  getPendingGlossaryTerms,
  getSearchGlossaryTerms,
  getLetterStatus,
} from '../../../redux/actions/glossaryAction'
import { AuthContext } from '../../context/authProvider'
import HeaderBar from './headerBar'
import Alphabet from './alphabet'
import CardList from '../Utilities/cardList'
import SubmitTerm from './submitTerm'
import './glossary.css'
import LoadingStatus from '../../Helper/LoadingStatus'
import Pagination from '../Utilities/pagination'

export default function Main({ channel, channels }) {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [authCreds] = useContext(AuthContext)
  const dispatch = useDispatch()
  const glossaryTerms = useSelector((state) => state.glossaryTerms)
  const glossaryLetterStatus = useSelector(
    (state) => state.glossaryLetterStatus
  )
  const [searchValue, setSearchValue] = useState('')
  const [searchedValue, setSearchedValue] = useState('')
  const [selectedLetter, setSelectedLetter] = useState('')
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [cardData, setCardData] = React.useState([])
  const endpoint = authCreds.restEndpoint
  const token = localStorage.getItem('token')
  const [currentPage, setCurrentPage] = useState(1)
  const pendingGlossaryTerms = useSelector(
    (state) => state.pendingGlossaryTerms
  )
  const [sortBy, setSortBy] = useState('approved_on')
  const [orderBy, setOrderBy] = useState('desc nulls last')
  const [khcTermsOnly, setKHCTermsOnly] = useState(null)

  const handleKHCTermsChange = (e) => {
    setKHCTermsOnly(e.target.checked)
  }

  const handleSearch = (event) => {
    setSearchValue(event.target.value)
  }

  const handleSortByChange = (event) => {
    setSortBy(event)
    // The default for Alphabetical sorting is Asc
    if (event === 'term') {
      setOrderBy('asc nulls last')
    } else {
      setOrderBy('desc nulls last')
    }
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    // If a search happens clear letter
    setSelectedLetter('')
    if (searchValue.trim().length >= 3) {
      if (
        searchValue[0] === '[' &&
        searchValue[searchValue.length - 1] === ']'
      ) {
        // reset pagination
        setCurrentPage(1)
        setSearchedValue(searchValue.slice(1, -1))
      } else {
        setCurrentPage(1)
        setSearchedValue(searchValue)
      }
    }
  }

  const clearSearch = (e) => {
    e.preventDefault()
    setSearchValue('')
    setSearchedValue('')
    fetchData()
    setCurrentPage(1)
  }

  const handlePageChange = (number) => {
    setCurrentPage(number)
  }

  const handleLetter = (letter) => {
    // Set search to null
    setSearchValue('')
    setSearchedValue('')
    if (selectedLetter === letter) {
      setSelectedLetter('')
    } else {
      setSelectedLetter(letter)
    }
    setCurrentPage(1)
  }

  const handleOpenForm = () => {
    setShowCreateForm(true)
  }

  const handleCloseForm = () => {
    setShowCreateForm(false)
    fetchData()
  }

  const fetchData = async ({
    page_size = 18,
    page_number = currentPage,
    sort = [{ field: sortBy, direction: orderBy }],
    filters = [{ field: 'approval_status', op: '==', value: 'Approved' }],
    is_active = true,
    user_query = searchedValue,
    is_tag = false,
  } = {}) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    // Filter to only KHC Terms
    if (khcTermsOnly) {
      filters = filters.concat({
        field: 'is_khc_term',
        op: '==',
        value: 'true',
      })
    }

    let params = {
      page_size: page_size,
      page_number: page_number,
      role_type: channel,
      sort: JSON.stringify(sort),
      filters: JSON.stringify(filters),
      is_active: is_active,
    }
    if (selectedLetter) {
      if (selectedLetter == '#') {
        // Only set starting type
        params['starting_type'] = ['number', 'special']
      } else {
        params['starting_term'] = selectedLetter
      }
    }

    // If it is a search
    if (user_query) {
      params['user_query'] = user_query
      params['is_tag'] = is_tag
      dispatch(getSearchGlossaryTerms(endpoint, params))
        .then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
        .catch(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
    } else {
      dispatch(getGlossaryTerms(endpoint, params))
        .then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
        .catch(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
    }
  }

  const fetchPendingCount = async () => {
    const pending_params = {
      page_size: 1,
      page_number: 1,
    }
    // Get number of pending terms
    dispatch(getPendingGlossaryTerms(endpoint, pending_params, channel))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  const fetchLetterStatus = async () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    // Get alphabet data
    const alphabet_params = {
      filters: JSON.stringify([
        { field: 'approval_status', op: '==', value: 'Approved' },
      ]),
      is_active: true,
      role_type: channel,
    }
    dispatch(getLetterStatus(endpoint, alphabet_params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  useEffect(() => {
    if (token != null) {
      fetchData()
    }
  }, [
    token,
    channel,
    selectedLetter,
    currentPage,
    searchedValue,
    sortBy,
    orderBy,
    khcTermsOnly,
  ])

  useEffect(() => {
    if (token != null) {
      fetchLetterStatus()
      fetchPendingCount()
      setSelectedLetter('') // Reset selected letter if user changes chapter
    }
  }, [token, channel])

  useEffect(() => {
    if (glossaryTerms?.loading === false) {
      setCardData(glossaryTerms?.glossaryTerms?.data)
    }
  }, [glossaryTerms, setCardData])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="glossary-app">
          <br />
          <HeaderBar
            searchTerm={searchValue}
            handleChange={handleSearch}
            handleSubmit={handleSearchSubmit}
            openSubmitForum={handleOpenForm}
            numActions={pendingGlossaryTerms?.pendingTerms?.total_results}
            clearSearch={clearSearch}
            handleSortByChange={handleSortByChange}
            handleOrderByChange={setOrderBy}
            sortBy={sortBy}
            orderBy={orderBy}
            khcTermsOnly={khcTermsOnly}
            handleKHCTermsChange={handleKHCTermsChange}
          />
          <SubmitTerm />
          {showCreateForm && (
            <SubmitTerm
              onOpen={showCreateForm}
              onClose={handleCloseForm}
              channels={channels}
              channel={channel}
            />
          )}
          <Alphabet
            clickedLetter={selectedLetter}
            filterByLetter={handleLetter}
            letterData={glossaryLetterStatus?.letterStatus?.data}
          />
          <CardList
            cardData={cardData}
            link={'term/'}
            containerClass="glossary-card-list"
            cardClass="glossary-card"
            logoClass="glossary-card-khc-logo"
            useLogo={true}
          />
          <Pagination
            pageSize={glossaryTerms.glossaryTerms.page_size}
            totalResults={glossaryTerms.glossaryTerms.total_results}
            numPages={glossaryTerms.glossaryTerms.num_pages}
            currentPage={currentPage}
            pageChange={handlePageChange}
            containerClass="glossary-pagination-container"
            buttonClass="glossary-pagination-button"
            buttonTextClass="glossary-pagination-text"
          ></Pagination>
        </div>
      )}
    </>
  )
}
