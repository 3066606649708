import React from 'react'
import { TagsInput } from 'react-tag-input-component'

const HandleTagsInput = ({
  value,
  style,
  onChange,
  name,
  placeHolder = 'Press enter or tab to add a new tag',
  seprators = ['Enter', 'Tab'],
}) => {
  return (
    <TagsInput
      value={value}
      style={style}
      onChange={onChange}
      name={name}
      placeHolder={placeHolder}
      seprators={seprators}
    />
  )
}

export default HandleTagsInput
