import React, { useEffect, useMemo, useContext } from 'react'
import '../RefData/RefTable.css'
import Nav from './badgeNavbar'
import LoadingStatus from '../../Helper/LoadingStatus'
import { MdOutlineDone, MdHighlightOff } from 'react-icons/md'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import { Table, Modal, Button, Col, Row } from 'react-bootstrap'
import { AuthContext } from '../../context/authProvider'
import { TableHandler } from '../../Utils'
// import LoadingStatus from '../../Helper/LoadingStatus'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { toastTopRightError } from '../../Helper/ToastObjects'
import { useDispatch, useSelector } from 'react-redux'
import '../../../App.css'
import './index.css'
import jwt_decode from 'jwt-decode'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Container from 'react-bootstrap/Container'
import { ForumHandler } from '../../Utils'
import { CheckLoginKholabChapterMember } from '../Utilities/KholabChapterDetails'
import '../../../App.css'
import {
  getBadgesForAdmin,
  createBadge,
  editBadge,
  exportbadgelist,
} from '../../../redux/actions/badgeAction'
import Sidebar from '../AppBar/Appbar'
import MentionHandler from '../Utilities/quillSearch'
function PendingRequest() {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  const [showModal, setShowModal] = React.useState(false)
  const [EditshowModal, setEditShowModal] = React.useState(false)
  const [authCreds] = useContext(AuthContext)
  const [editRows, setEditRows] = React.useState([])
  //   const [enabledColsMap, setEnabledColsMap] = React.useState({})
  const [tableData, setTableData] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(1)
  const [roleValue, setRolevalue] = React.useState(false)
  const [chapterRoleValue, setChapterRolevalue] = React.useState(false)

  const [editorContent, setEditorContent] = React.useState('')

  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = React.useState(0)
  const [tableColumns, setTableColumns] = React.useState([])
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnSort, setColumnSortValue] = React.useState([])
  const [isSort, setSort] = React.useState(false)
  const [badgeName, setBadgeName] = React.useState('')
  const [badgeLevel, setBadgeLevel] = React.useState('')
  const [badgeType, setBadgeType] = React.useState('')
  const [roleType, setRoleType] = React.useState('')
  const [badgeLevelName, setBadgeLevelName] = React.useState('')
  const [requirement, setRequirement] = React.useState('')
  const [editModeState, setEditModeState] = React.useState(false)
  const [rowIndex, setRowIndex] = React.useState(null)
  const [inlineValue, setInlineValue] = React.useState([])
  const [checkBox, setCheckBox] = React.useState(false)
  const [active, setActive] = React.useState(false)
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const token = localStorage.getItem('token')
  const adminBadges = useSelector((state) => state.adminBadges)
  const [channel, setChannel] = React.useState(localStorage.getItem('channel'))
  let tokenValue = jwt_decode(localStorage.getItem('token'))
  let userFetchTimeOut

  const handleQuill = (value) => {
    setEditorContent(value)
  }
  useEffect(() => {
    setChapterRolevalue(CheckLoginKholabChapterMember.CheckChapterMember(token, channel))
  }, [])
  
  //react quill source callback
  const quillSearch = (searchTerm, renderList, mentionChar) => {
    clearTimeout(userFetchTimeOut)
    if (searchTerm.length >= 3) {
      userFetchTimeOut = setTimeout(() => {
        ForumHandler.fetchUserData(
          endpoint,
          searchTerm,
          renderList,
          mentionChar
        )
      }, 1000)
    }
  }

  const module = MentionHandler.mentionconfig(quillSearch)
  const table_name = 'BADGE'

  const visibleBadgeCol = [
    'badge_name',
    'badge_level_name',
    'badge_type',
    'is_active_badge',
    'BadgeApproverCount',
    'requirements',
  ]
  const columnMetadata = {
    BADGE: {
      fields: [
        {
          name: 'badge_name',
          headerTitle: 'BADGE NAME',
          is_editable: true,
          is_filterable: true,
          size: 20,
        },
        {
          name: 'badge_level_name',
          headerTitle: 'BADGE LEVEL NAME',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'badge_type',
          headerTitle: 'BADGE TYPE',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'BadgeApproverCount',
          headerTitle: 'BADGE APPROVER COUNT',
          is_editable: true,
          is_filterable: true,
          size: 5,
        },
        {
          name: 'is_active_badge',
          headerTitle: 'ACTIVE BADGE',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'requirements',
          headerTitle: 'REQUIREMENT',
          is_editable: true,
          is_filterable: true,
          size: 30,
        },

        {
          name: 'KH_CREATED_BY',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_CREATED_ON',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_ETL_IS_DELETED',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_MODIFIED_BY',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_MODIFIED_ON',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_UID',
          is_editable: false,
          is_filterable: false,
        },
      ],
      tag: 'badge',
    },
  }
  const hiddenColumns = {
    BADGE: [
      'KH_UID',
      'KH_ETL_IS_DELETED',
      'KH_MODIFIED_ON',
      'KH_MODIFIED_BY',
      'KH_CREATED_ON',
      'KH_CREATED_BY',
    ],
  }

  useEffect(() => {
    localStorage.setItem('menu', 'admin-badge')
  }),
    []

  const handleClickCheckbox = (e) => {
    if (e.target.checked) {
      setCheckBox(true)
    } else {
      setCheckBox(false)
    }
  }
  const handleDeleteEditMode = () => {
    setEditModeState(false)
  }

  //Update using Inline Editing
  const handleUpdateEditMode = () => {
    editRows.push(inlineValue)

    // updateRecords_inline_editing()
    setEditModeState(false)
    setEditRows([])
  }

  const handleValueEditableMode = (idx) => (e) => {
    inlineValue[idx] = e.target.value
  }

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    setSort(true)

    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  // Deafult Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()
      setEditModeState(false)

      let timeoutId = setTimeout(() => {
        let filter_payload =
          checkBox === false
            ? [{ op: 'eq', field: 'role_type', value: `${channel}` }]
            : [
                {
                  op: 'eq',
                  field: 'is_active_badge',
                  value: checkBox.toString(),
                },
                { op: 'eq', field: 'role_type', value: `${channel}` },
              ]

        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        //disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }

  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(() => tableData, [hiddenColumns, tableData])
  const columns = useMemo(() => tableColumns, [hiddenColumns, tableColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { selectedRowIds, pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: hiddenColumns[table_name],
        pageSize: 50,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: <div></div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
            const currentState = row.getToggleRowSelectedProps()
            return (
              <IndeterminateCheckbox
                {...currentState}
                onClick={() => {
                  toggleAllRowsSelected(false)
                  toggleRowSelected(row.id, !currentState.checked)
                }}
              />
            )
          },
        },
        ...columns,
      ])
    }
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }

  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }

  // Used to fetch new table data from server
  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    show_deleted = false,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    setLocalPageIndex(pageIndex)

    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value,
    }
    dispatch(getBadgesForAdmin(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }
  useEffect(() => {
    if (adminBadges?.loading === false) {
      let table_data = adminBadges?.adminBadges?.data
      if (table_data?.length > 0) {
        setTableColumns(
          TableHandler.createColumnMappingforKholab(
            [
              'KH_UID',
              'KH_CREATED_ON',
              'KH_CREATED_BY',
              'KH_MODIFIED_ON',
              'KH_MODIFIED_BY',
              'KH_ETL_IS_DELETED',
              'badge_name',
              'badge_level_name',
              'badge_type',
              'BadgeApproverCount',
              'is_active_badge',
              'requirements',
            ],
            columnMetadata,
            table_name
          )
        )
        setTotalPages(adminBadges?.adminBadges?.num_pages)
        setTotalRows(adminBadges?.adminBadges?.total_results)
        setTableData(table_data)
      } else {
        setTotalPages(0)
        setTotalRows(0)
        setTableData([])
      }
    }
  }, [adminBadges, setTableData])

  // updates editRows state on select checkbox event
  useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])

  // fetches new table data on pageIndex, pageSize change
  useEffect(() => {
    //   setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (checkBox === false) {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([{ op: 'eq', field: 'role_type', value: `${channel}` }]),
        false,
        JSON.stringify(columnSort)
      )
    } else if (checkBox === true) {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([{ op: 'eq', field: 'is_active_badge', value: 'true' },{ op: 'eq', field: 'role_type', value: `${channel}` }])
      )
    } else {
      fetchData({ pageSize, pageIndex }, JSON.stringify(columnFilters))
    }
  }, [pageSize, pageIndex, columnSort, channel, checkBox])

  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem('hidden_columns', JSON.stringify(hiddenColumns))
  }, [hiddenColumns])

  const handleEditShowModal = () => {
    if (editRows.length > 0) {
      setEditShowModal(true)
      setBadgeName(editRows[0].badge_name)
      setBadgeLevelName(editRows[0].badge_level_name)
      setBadgeType(editRows[0].badge_type)
      setRoleType(editRows[0].role_type)
      setRequirement(editRows[0].requirements)
      setActive(editRows[0].is_active_badge)
    } else {
      toast('No records selected', toastTopRightError)
    }
  }

  function OpenAddModal() {
    setLoadingStatus({ status: false, message: 'Fetching table records...' })

    setShowModal(true)
  }
  useEffect(() => {
    if (
      tokenValue?.roles?.includes('khdapi.Admin') ||
      tokenValue?.roles?.includes('khdapi.collabadmin')
    ) {
      setRolevalue(true)
    } else {
      setRolevalue(false)
    }
  }, [])

  // const handleShowJira = () => setShowJira(true)
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleEditCloseModal = () => {
    setEditShowModal(false)
  }

  const handleSubmit = async () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    if (
      requirement == '' ||
      badgeName == '' ||
      badgeLevelName == '' ||
      badgeType == '' ||
      roleType == ''
    ) {
      toast('Please fill all fields', toastTopRightError)
      setLoadingStatus({ status: false, message: 'Adding Details...' })
    } else {
      const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
      const matches = editorContent.match(regex)
      let mentionVal = matches ? matches.map((mention) => mention.slice(0)) : []
      const payload = {
        requirements: requirement,
        badge_name: badgeName,
        badge_level_name: badgeLevelName,
        badge_type: badgeType,
        role_type: roleType,
        approvers: mentionVal,
        approvers_html: editorContent,
      }
      dispatch(createBadge(endpoint, payload)).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })

        handleCloseModal()
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify([
            { op: 'eq', field: 'role_type', value: `${channel}` },
          ])
        )
      })
    }
  }

  // state to export badge
  const exportBadgeData = () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    let visibleCols = visibleBadgeCol
    let columnRawFilters = columnFilters

    if (columnRawFilters === null || columnRawFilters.length === 0) {
      columnRawFilters = [{ op: 'eq', field: 'role_type', value: `${channel}` }]
    }

    const params = {
      filters: JSON.stringify(columnRawFilters),
      sort: JSON.stringify(columnSort),
    }
    dispatch(exportbadgelist(endpoint, visibleCols, params)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    })
  }

  const handleEditBadge = async () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (
      requirement == '' ||
      badgeName == '' ||
      badgeLevelName == '' ||
      badgeType == '' ||
      roleType == ''
    ) {
      toast('Please fill all fields', toastTopRightError)
      setLoadingStatus({ status: false, message: 'Adding Details...' })
    } else {
      const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
      const matches = editorContent.match(regex)
      let mentionVal = matches ? matches.map((mention) => mention.slice(0)) : []
      const payload = {
        id: editRows[0].id,
        badge_name: badgeName,
        badge_level_name: badgeLevelName,
        badge_type: badgeType,
        role_type: roleType,
        requirements: requirement,
        approvers: mentionVal,
        approvers_html: editorContent,
        is_active_badge: active == 'false' || active == false ? false : true,
        created_on: editRows[0].created_on,
        created_by: editRows[0].created_by,
      }
      dispatch(editBadge(endpoint, payload))
        .then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })

          handleEditCloseModal()

          fetchData(
            { pageSize, pageIndex },
            JSON.stringify([
              { op: 'eq', field: 'role_type', value: `${channel}` },
            ])
          )
        })
        .catch(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
    }
  }
  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Sidebar
            Page="Admin Badge"
            channel={channel}
            setChannel={setChannel}
            Index={
              <>
                <Nav
                  channel={channel}
                  setChannel={setChannel}
                  exportFunc={exportBadgeData}
                />

                <div
                  className="table-container"
                  style={{ textAlign: 'center' }}
                >
                  <Table {...getTableProps()}>
                    <thead className="sticky-table-header">
                      {headerGroups.map((headerGroup, i) => (
                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column, i) =>
                            column?.id === 'selection' ? (
                              <th key={column.id}>
                                <div
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {columnSort[0]?.field === column['Header'] ? (
                                    <span>
                                      {columnSort[0]?.direction === 'asc'
                                        ? ' 🔽'
                                        : '' ||
                                          columnSort[0]?.direction === 'desc'
                                        ? ' 🔼'
                                        : ''}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            ) : column.id === 'BadgeApproverCount' ? (
                              <th key={i}>
                                <div className="table-header-badge">
                                  {column.render('Header')}
                                </div>
                              </th>
                            ) : column.id == 'is_active_badge' ? (
                              <th key={i}>
                                <div className="table-header-badge">
                                  {column.render('Header')}
                                </div>
                                <div>
                                  <input
                                    type="checkbox"
                                    onClick={handleClickCheckbox}
                                    checked={checkBox}
                                  />
                                </div>
                              </th>
                            ) : (
                              <th key={i}>
                                <div
                                  onClick={() => handleSorting(column.id)}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {columnSort[0]?.field === column['id'] ? (
                                    <span>
                                      {columnSort[0]?.direction === 'asc'
                                        ? ' 🔽'
                                        : '' ||
                                          columnSort[0]?.direction === 'desc'
                                        ? ' 🔼'
                                        : ''}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            )
                          )}
                        </tr>
                      ))}
                    </thead>
                    <tbody className="tbody badgebody" {...getTableBodyProps()}>
                      {page.map((row, index) => {
                        prepareRow(row)
                        return (
                          <tr key={index}>
                            {row.cells.map((cell) => {
                              return cell.column.id === 'is_active_badge' ? (
                                cell.value == true ? (
                                  <td
                                    key={cell}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    <MdOutlineDone
                                      style={{
                                        fontSize: '25px',
                                        color: 'green',
                                      }}
                                    />
                                  </td>
                                ) : (
                                  <td
                                    key={cell}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    <MdHighlightOff
                                      style={{
                                        fontSize: '25px',
                                        color: 'red',
                                      }}
                                    />
                                  </td>
                                )
                              ) : (
                                <td
                                  key={cell}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                    },
                                  })}
                                >
                                  {cell.render('Cell')}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
                {/* Bottom ToolBar of table */}
                <Row>
                  <Col sm={6}>
                    <div className="page-control">
                      <div className="page-of">
                        Page{' '}
                        <em>
                          {pageIndex + 1} of {totalPages}
                        </em>
                      </div>
                      <div className="prev-next-btn">
                        <button
                          className="badge-btn"
                          onClick={() => onPrevious()}
                          disabled={!canPreviousPage}
                        >
                          {' '}
                          Prev{' '}
                        </button>
                        <button
                          onClick={() => onNext()}
                          className="badge-btn"
                          disabled={!canNextPage}
                        >
                          {' '}
                          Next{' '}
                        </button>
                      </div>
                      <div className="second-control">
                        <span>Go to page:</span>
                        <input
                          className="page-number-input"
                          type="number"
                          defaultValue={pageIndex || 1}
                          onBlur={(e) => {
                            const page = e.target.value
                              ? Number(e.target.value)
                              : 0
                            // handling zero indexing
                            gotoPage(page - 1)
                          }}
                        />
                        <select
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(Number(e.target.value))
                          }}
                        >
                          {available_page_size.map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div
                        style={{
                          display: 'inline-block',
                          marginLeft: '50px',
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        {' '}
                        Total results: {adminBadges?.adminBadges?.total_results}
                      </div>
                    </div>
                  </Col>
                  {/* Bottom-right Buttons */}
                  {roleValue || chapterRoleValue ? (
                    <Col sm={6}>
                      <Button
                        className="m_r-5 block-btn1 main-button btn-sm"
                        onClick={OpenAddModal}
                      >
                        Add
                      </Button>

                      <Button
                        className="m_r-5 m_t-10 block-btn1 main-button btn-sm"
                        onClick={handleEditShowModal}
                      >
                        Edit
                      </Button>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
                {loadingStatus.status == true ? (
                  <LoadingStatus status_message={loadingStatus.message} />
                ) : (
                  <Modal size="xl" show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modalBody">
                      <>
                        <Form>
                          <Container className="m-0">
                            <Row className="mb-3 mr-3 rowFlex">
                              <Form.Group
                                className="flexItem"
                                as={Col}
                                md="4"
                                controlId="validationFormikUsername"
                              >
                                <Form.Label>Badge name</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="text"
                                    placeholder="Badge Name"
                                    aria-describedby="inputGroupPrepend"
                                    name="mail"
                                    onChange={(e) =>
                                      setBadgeName(e.target.value)
                                    }
                                  />
                                </InputGroup>
                              </Form.Group>

                              <Form.Group
                                className=""
                                as={Col}
                                md="4"
                                controlId="validationFormik01"
                              >
                                <Form className="mt-2">
                                  <Form.Label> </Form.Label>
                                  <Form.Select
                                    onChange={(e) =>
                                      setBadgeLevelName(e.target.value)
                                    }
                                  >
                                    <option>Select Level Name</option>

                                    <option value="Gold">GOLD</option>
                                    <option value="Silver">SILVER</option>
                                    <option value="Bronze">BRONZE</option>
                                  </Form.Select>
                                </Form>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3 mr-3 rowFlex">
                              <Form.Group
                                className=""
                                as={Col}
                                md="4"
                                controlId="validationFormik01"
                              >
                                <Form className="mt-2">
                                  <Form.Label> </Form.Label>
                                  <Form.Select
                                    onChange={(e) => {
                                      setBadgeType(e.target.value)
                                    }}
                                  >
                                    <option>Badge Type</option>

                                    <option value="Technical">Technical</option>
                                    <option value="Non-Technical">
                                      Non-Technical
                                    </option>
                                    <option value="Functional">
                                      Functional
                                    </option>
                                  </Form.Select>
                                </Form>
                              </Form.Group>
                              <Form.Group
                                className=""
                                as={Col}
                                md="4"
                                controlId="validationFormik01"
                              >
                                <Form className="mt-2">
                                  <Form.Label> </Form.Label>
                                  <Form.Select
                                    onChange={(e) => {
                                      setRoleType(e.target.value)
                                    }}
                                  >
                                    <option>Role Type</option>
                                    {JSON.parse(
                                      localStorage.getItem('badge_metadata')
                                    )?.channels?.map((chapter, index) => {
                                      return (
                                        <option value={chapter} key={index}>
                                          {chapter}
                                        </option>
                                      )
                                    })}
                                  </Form.Select>
                                </Form>
                              </Form.Group>
                              <Form.Group
                                className=""
                                as={Col}
                                md="4"
                                controlId="validationFormik01"
                              >
                                <Form className="mt-2">
                                  <Form.Label> Add Approver</Form.Label>
                                  <ReactQuill
                                    style={{ background: '#fff' }}
                                    theme="snow"
                                    className="react-quill-badge"
                                    // defaultValue={editorContent}
                                    onChange={handleQuill}
                                    modules={module}
                                    placeholder="add users using @ operator"
                                    // formats={allowedFormats}
                                  />
                                </Form>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3 mr-3 rowFlex">
                              <Form.Group
                                className=""
                                as={Col}
                                // md="5"
                                controlId="validationFormikUsername"
                              >
                                <Form.Label>Requirements</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="text"
                                    placeholder="Requirements"
                                    aria-describedby="inputGroupPrepend"
                                    name="evidence"
                                    as="textarea"
                                    rows={4}
                                    onChange={(e) =>
                                      setRequirement(e.target.value)
                                    }
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Row>
                          </Container>
                        </Form>
                        <Modal.Footer>
                          <Button
                            variant="primary"
                            className="addModalBtn"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="danger"
                            type="submit"
                            className="addModalBtn"
                            onClick={handleCloseModal}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </>
                    </Modal.Body>
                  </Modal>
                )}

                {loadingStatus.status == true ? (
                  <LoadingStatus status_message={loadingStatus.message} />
                ) : (
                  <Modal
                    size="xl"
                    show={EditshowModal}
                    onHide={handleEditCloseModal}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Edit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modalBody">
                      <>
                        <Form>
                          <Container className="m-0">
                            <Row className="mb-3 mr-3 rowFlex">
                              <Form.Group
                                className=""
                                as={Col}
                                md="4"
                                controlId="validationFormik01"
                              >
                                <Form className="mt-2">
                                  <Form.Label>Role Type </Form.Label>
                                  <Form.Select
                                    onChange={(e) => {
                                      setRoleType(e.target.value)
                                    }}
                                    value={roleType}
                                  >
                                    {JSON.parse(
                                      localStorage.getItem('badge_metadata')
                                    )?.channels?.map((chapter, index) => {
                                      return (
                                        <option value={chapter} key={index}>
                                          {chapter}
                                        </option>
                                      )
                                    })}
                                  </Form.Select>
                                </Form>
                              </Form.Group>

                              <Form.Group
                                className="flexItem"
                                as={Col}
                                md="4"
                                controlId="validationFormikUsername"
                              >
                                <Form.Label>Badge name</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="text"
                                    placeholder="Badge Name"
                                    aria-describedby="inputGroupPrepend"
                                    name="mail"
                                    defaultValue={badgeName}
                                    onChange={(e) =>
                                      setBadgeName(e.target.value)
                                    }
                                  />
                                </InputGroup>
                              </Form.Group>

                              <Form.Group
                                className=""
                                as={Col}
                                md="4"
                                controlId="validationFormik01"
                              >
                                <Form className="mt-2">
                                  <Form.Label>Select Level Name </Form.Label>
                                  <Form.Select
                                    onChange={(e) =>
                                      setBadgeLevelName(e.target.value, e)
                                    }
                                    value={badgeLevelName}
                                  >
                                    <option value="Gold">GOLD</option>
                                    <option value="Silver">SILVER</option>
                                    <option value="Bronze">BRONZE</option>
                                  </Form.Select>
                                </Form>
                              </Form.Group>
                              <Form.Group
                                className=""
                                as={Col}
                                md="4"
                                controlId="validationFormik01"
                              >
                                <Form className="mt-2">
                                  <Form.Label>Badge Type </Form.Label>
                                  <Form.Select
                                    value={badgeType}
                                    onChange={(e) =>
                                      setBadgeType(e.target.value, e)
                                    }
                                  >
                                    <option value="Technical">Technical</option>
                                    <option value="Non-Technical">
                                      Non-Technical
                                    </option>
                                    <option value="Functional">
                                      Functional
                                    </option>
                                  </Form.Select>
                                </Form>
                              </Form.Group>
                              <Form.Group
                                className=""
                                as={Col}
                                md="4"
                                controlId="validationFormik01"
                              >
                                <Form className="mt-2">
                                  <Form.Label>Set Active </Form.Label>
                                  {/* <Form.Select
                              onChange={(e) => {
                                setActive(e.target.value)
                              }}
                              value={active}
                            >
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </Form.Select> */}
                                  <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch"
                                    //   value={isactive}
                                    checked={active}
                                    onChange={(e) =>
                                      setActive(e.target.checked)
                                    }
                                    // label="Check this switch"
                                  />
                                </Form>
                              </Form.Group>
                              <Form.Group
                                className=""
                                as={Col}
                                md="4"
                                controlId="validationFormik01"
                              >
                                <Form className="mt-2">
                                  <Form.Label>Edit Approver</Form.Label>
                                  <ReactQuill
                                    style={{ background: '#fff' }}
                                    theme="snow"
                                    className="react-quill-badge"
                                    defaultValue={editRows[0]?.approvers_html}
                                    onChange={handleQuill}
                                    modules={module}
                                    placeholder="add users using @ operator"
                                    // formats={allowedFormats}
                                  />{' '}
                                </Form>
                              </Form.Group>
                            </Row>

                            <Row className="mb-3 mr-3 rowFlex">
                              <Form.Group
                                className=""
                                as={Col}
                                // md="5"
                                controlId="validationFormikUsername"
                              >
                                <Form.Label>Requirements</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="text"
                                    placeholder="Requirements"
                                    aria-describedby="inputGroupPrepend"
                                    name="evidence"
                                    as="textarea"
                                    rows={4}
                                    defaultValue={requirement}
                                    onChange={(e) => {
                                      setRequirement(e.target.value)
                                    }}
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Row>
                          </Container>
                        </Form>
                        <Modal.Footer>
                          <Button
                            variant="primary"
                            className="addModalBtn"
                            type="submit"
                            onClick={handleEditBadge}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="danger"
                            type="submit"
                            className="addModalBtn"
                            onClick={handleEditCloseModal}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </>
                    </Modal.Body>
                  </Modal>
                )}
              </>
            }
          />
        </>
      )}
    </>
  )
}

export default PendingRequest
