import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import NpsMenu from './chapterNpsMenu'
import Sidebar from '../AppBar/Appbar'
import { useDispatch, useSelector } from 'react-redux'
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction'
import { AuthContext } from '../../context/authProvider'

export default function index() {
  const [authCreds] = useContext(AuthContext)

  const [collabMetadata, setCollabMetadata] = useState('')
  const [channel, setChannel] = React.useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const dispatch = useDispatch()
  let history = useNavigate()

  const endpoint = authCreds.restEndpoint

  useEffect(() => {
    async function forumMetadata() {
      // setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=DEFAULT`
      dispatch(getForumMetadata(url)).then(() => {
        // setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])

  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata)
    }
  }, [forumMetadata])
  const InternalSurvey = () => {
    localStorage.setItem('surveyType', 'internal')
    history('/nps-survey/create-chapter-survey')
  }
  const ExternalSurvey = () => {
    localStorage.setItem('surveyType', 'external')
    history('/nps-survey/create-chapter-survey')
  }
  return (
    <>
      <div>
        <Sidebar
          Page="NPS"
          setChannel={setChannel}
          channel={channel}
          collabMetadata={collabMetadata}
          Index={
            <>
              <NpsMenu />
              <div className="home-nps-chapter">
                <div>
                  <div class="instructions">
                    <ul>
                      <li>
                        Survey type: You need to choose a survey type below to
                        proceed. Internal NPS surveys are sent to
                        chapter members while External NPS surveys are sent to
                        Stakeholders/Product Owners (defined from PODs where
                        chapter members are a member of).
                      </li>
                      <li>
                        A default survey template will be selected based on the
                        survey type. The template can be expanded to add
                        additional questions (original base template questions
                        cannot be removed or altered - required to do consistent
                        analysis)
                        <ul>
                          <li>
                            The survey title/description/questions can leverage
                            supported Placeholders (values that are dynamically
                            populated). Supported placeholders can be viewed inside
                            the survey and email notification design views (Placeholder button).
                          </li>
                          <li>
                            NOTE: Design your questions to be clear, concise,
                            and easy to understand. Avoid leading questions
                            and bias.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Email templates are also available for the initial email
                        communication about the survey as well as the reminder
                        emails for non-responders. These can be customized as
                        necessary.
                      </li>
                      <li>
                        Scheduling: Select a date range where the survey will be
                        active. By default automated emails will be sent on the
                        start date and automated reminders (to non-responders)
                        will be sent based on number of days specified before
                        the end date (will be sent daily until the end date).
                      </li>
                    </ul>
                  </div>
                  <h2 className="sub-head-nps">Please choose survey type</h2>
                  {/* <Link to="/nps-survey/create-chapter-survey"> */}
                  <Button
                    variant="primary"
                    style={{ width: '300px', margin: '8px' }}
                    onClick={InternalSurvey}
                  >
                    Internal
                  </Button>{' '}
                  {/* </Link> */}
                  {/* <Link to="/nps-survey/create-chapter-survey"> */}
                  <Button
                    variant="primary"
                    style={{ width: '300px', margin: '8px' }}
                    onClick={ExternalSurvey}
                  >
                    External
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  )
}
