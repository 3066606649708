import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from 'react-bootstrap'

const HelperComponent = ({ openModel, closeModel, transiton }) => {
  return (
    <Dialog
      maxWidth="md"
      open={openModel}
      TransitionComponent={transiton}
      keepMounted
      onClose={closeModel}
      aria-describedby=""
    >
      <DialogTitle>{'Understanding KPIs'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography gutterBottom component="div">
            <Box fontWeight="700" display="inline">
              What are KPIs?
            </Box>{' '}
            {`
              Key Performance Indicators (KPIs) are measurable values that help organizations assess their success in achieving key business objectives.
            `}
            <br />
          </Typography>

          <Typography gutterBottom>
            <Box fontWeight="700" display="inline">
              NPS Score
            </Box>{' '}
            - One important KPI is the Net Promoter Score (NPS). It measures customer loyalty based on their likelihood to recommend a product or service.
          </Typography>

          <Typography gutterBottom>
            <Box fontWeight="700" display="inline">
              Understanding NPS
            </Box>{' '}
            - 
            <strong>Promoters (score 9-10):</strong> These are people who like the product or idea and are likely to recommend it to others.
            <br />
            <strong>Passives (score 7-8):</strong> These customers are satisfied but not enthusiastic.
            <br />
            <strong>Detractors (score 0-6):</strong> These are people who are unhappy with the product or idea.
            <br />
            The NPS is calculated by subtracting the percentage of Detractors from the percentage of Promoters, resulting in a score that can range from -100 to +100.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModel}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default HelperComponent
