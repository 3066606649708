import { faTruckMedical } from '@fortawesome/free-solid-svg-icons'
import {
  GET_NPS_METRICS,
  GET_NPS_METRICS_LOAD,
  GET_NPS_METRICS_ERROR,
} from '../../../constants/types'

const initialState = {
  npsMetrics: {},
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NPS_METRICS_LOAD:
      return {
        ...state,
        loading: faTruckMedical,
        npsMetrics: action.payload,
      }
    case GET_NPS_METRICS:
      return {
        ...state,
        loading: false,
        npsMetrics: action.payload,
      }
    case GET_NPS_METRICS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
