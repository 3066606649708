import {
    GET_FILTER_IDEAS_ERROR,
    GET_FILTER_IDEAS,
    GET_FILTER_IDEAS_LOAD,
  } from '../../constants/types'
  
  const initialState = {
    getIdeasFilter: [{
        filterIdeas: {category:'All', status: ['Unassigned', 'In Progress'], sortBy:'Latest',orderBy:'desc', searchValue:'', pageSize: 12,currentPage:0, myIdeas: false, myLikedIdeas: false},
        filterManageIdeas: {category:'All', sortBy:'Latest',orderBy:'desc',searchValue:'', pageSize: 12,currentPage:0}
    }],
    loading: false,
    error: null,
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_FILTER_IDEAS_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
        }
      case GET_FILTER_IDEAS:
        return {
          ...state,
          getIdeasFilter: action.payload,
          loading: false,
          error: null,
        }
      case GET_FILTER_IDEAS_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        }
  
      default:
        return state
    }
  }
  
