import {
    GET_ANNOUNCEMENT,
    GET_CHAPTER_ANNOUNCEMENT,
    GET_ANNOUNCEMENT_LOAD,
    GET_ALL_ANNOUNCEMENT_ERROR,
    GET_ALL_ANNOUNCEMENT,
    GET_ALL_ANNOUNCEMENT_LOAD
  } from '../../constants/types'
  
  const initialState = {
    getAllAnnouncements: [],
    getChapterAnnouncements: [],
    getAnnoncementByID: {},
    loading: false,
    error: null,
  }

  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_ALL_ANNOUNCEMENT_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
        }
      case GET_ANNOUNCEMENT_LOAD:
        return {
          ...state,
          getAnnoncementByID: {},
          loading: true,
          error: null,
        }
      case GET_ANNOUNCEMENT:
        return {
          ...state,
          getAnnoncementByID: action.payload,
          loading: true,
          error: null,
        }
      case GET_ALL_ANNOUNCEMENT:
        return {
          ...state,
          getAllAnnouncements: action.payload,
          loading: false,
          error: null,
        }
      case GET_CHAPTER_ANNOUNCEMENT:
        return {
          ...state,
          getChapterAnnouncements: action.payload,
          loading: false,
          error: null,
        }
      case GET_ALL_ANNOUNCEMENT_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        }
  
      default:
        return state
    }
  }
  
