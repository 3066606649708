import React, { useContext, useRef } from 'react'
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from 'react-table'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import InputGroup from 'react-bootstrap/InputGroup'
import { styled } from '@mui/material/styles'
import { Table, Button, Row, Col } from 'react-bootstrap'
import '../../../App.css'
import '../../../index.css'
import '../RefData/RefTable.css'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import LoadingStatus from '../../Helper/LoadingStatus'
import { toast } from 'react-toastify'
import { toastTopRightError } from '../../Helper/ToastObjects'
import { AuthContext } from '../../context/authProvider'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSnfClusterData,
  updateSnfClusterData,
  getMySnfClusterData,
  getSnfClusterDataByClusterId,
} from '../../../redux/actions/snfCluster/snfClusterData'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import SnowflakeClusterData from './SnowflakeClusterData'

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px',
    whiteSpace: 'break-spaces',
  },
}))

function SnfTable({ columns }) {
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [totalPages, setTotalPages] = React.useState()
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnSort, setColumnSortValue] = React.useState([])
  const [editRows, setEditRows] = React.useState([])
  const [isMyData, setIsMyData] = React.useState(true)
  const isMyDataRef = useRef(isMyData)
  const token = localStorage.getItem('token')
  const rowIndex = null
  const dispatch = useDispatch()
  const isMergeableOptions = ['True', 'False']
  const editStatusOptions = ['Pending', 'Approved', 'Rejected', 'In Progress']
  const statusOptions = [
    'Approved',
    'Pending',
    'Rejected',
    'In Progress',
    'Completed',
  ]
  const removeFilterColumns = [
    'SOURCE_DETAIL',
    'REQUESTED_BY',
    'DATA_CLASSIFICATION',
    'COMMENTS',
    'REQUEST_DATE',
    'REVIEW_COMMENTS',
  ]
  const available_page_size = ['5', '10', '20', '50', '100']
  const [isMergeable, setIsMergeable] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [showModal, setshowModal] = React.useState(false)
  const [reviewComments, setReviewComments] = React.useState([])
  const [updateRecord, setUpdateRecord] = React.useState([])
  const [editModeState, setEditModeState] = React.useState(false)
  const [statusSelection, setStatusSelection] = React.useState('')
  const inlineValue = []
  const [remarks, setRemarks] = React.useState('')
  const [authCreds] = useContext(AuthContext)
  const endpoint = authCreds.restEndpoint
  const statusSelectionRef = useRef(statusSelection)
  const [selectedRows, setSelectedRows] = React.useState([])

  React.useEffect(() => {
    isMyDataRef.current = isMyData
  }, [isMyData])

  React.useEffect(() => {
    statusSelectionRef.current = statusSelection
  }, [statusSelection])
  const snfClusteringData = useSelector((state) => state.getSnfClusterData)
  const [data, setData] = React.useState(
    snfClusteringData?.snfClusterData?.data || []
  )

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records....',
  })
  let timeoutIds = []
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  const handleDeleteEditMode = () => {
    setEditModeState(false)
  }
  const handleUpdateEditMode = () => {
    editRows.push(inlineValue)

    // updateRecords_inline_editing()
    setEditModeState(false)
    setEditRows([])
  }
  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    React.useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()
      setEditModeState(false)

      let timeoutId = setTimeout(() => {
        let filter_payload =
          statusSelectionRef.current !== '' &&
          statusSelectionRef.current !== 'Select Status'
            ? [
                {
                  op: 'eq',
                  field: 'STATUS',
                  value: `${statusSelectionRef.current}`,
                },
              ]
            : []
        headers.forEach((header_obj, index) => {
          if (
            header_obj.filterValue !== undefined &&
            index >= 1 &&
            (header_obj.id === 'CLUSTER' ||
              header_obj.id === 'RECORD_COUNT' ||
              header_obj.id === 'COLUMN_COUNT')
          ) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = value
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = value
                filter_payload.push(local_obj)
              }
            })
          } else if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }
  React.useEffect(() => {
    if (statusSelection.length > 0 && statusSelection !== 'Select Status') {
      let filter = columnFilters
      let filter_payload = [
        { op: 'eq', field: 'STATUS', value: `${statusSelection}` },
      ]
      let isPresent = true
      const filters = filter.map((item) => {
        if (item.field === 'STATUS') {
          isPresent = false
          return filter_payload[0]
        } else {
          return item
        }
      })
      if (isPresent) {
        filters.push(filter_payload[0])
      }
      setColumnFilters([...filters])
      fetchData({ pageSize, pageIndex }, JSON.stringify([...filters]))
    } else {
      let filter = columnFilters
      let filters = filter.map((item) => {
        if (item?.field !== 'STATUS') {
          return item
        }
      })
      filters = filters.filter((item) => item != null)
      fetchData({ pageSize, pageIndex }, JSON?.stringify([...filters]))
      setColumnFilters(filters)
    }
  }, [statusSelection])
  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }
  function truncate(str, n) {
    return str?.length > n ? str?.slice(0, n - 1) + '...' : str
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: ['id'],
        pageSize: 10,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: <div></div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
            const currentState = row.getToggleRowSelectedProps()
            return (
              <IndeterminateCheckbox
                {...currentState}
                onClick={() => {
                  toggleAllRowsSelected(false)
                  toggleRowSelected(row.id, !currentState.checked)
                }}
              />
            )
          },
        },
        ...columns,
      ])
    }
  )

  React.useEffect(() => {
    if (snfClusteringData?.loading === false) {
      let table_data = snfClusteringData?.snfClusterData?.data
      setTotalPages(snfClusteringData?.snfClusterData?.num_pages)
      setData(table_data)
    } else {
      setTotalPages(0)
      setData([])
    }
  }, [snfClusteringData])
  React.useEffect(() => {
    if (snfClusteringData?.snfClusterData?.data) {
      setData(snfClusteringData?.snfClusterData?.data)
    }
  }, [data])
  React.useEffect(() => {
    const fetchAndSetData = async () => {
      if (token != null) {
        const filters = JSON.stringify(columnFilters) || []
        await fetchData(
          { pageSize, pageIndex },
          filters,
          JSON.stringify(columnSort)
        )
      }
    }
    fetchAndSetData()
  }, [pageSize, pageIndex, columnSort, token, isMyData])
  React.useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])
  const handleCloseModal = () => {
    setshowModal(false)
  }

  const handleUpdateIsMergeable = () => {
    const editedRow = updateRecord
    let clusterKeys = selectedRows.filter((item) => item)
    clusterKeys.push(editedRow.ID)

    const body = {
      IS_MERGEABLE: isMergeable,
      REMARKS: remarks,
      STATUS: status,
      keys: clusterKeys,
    }

    if (
      (clusterKeys.length > 1 && isMergeable === true) ||
      isMergeable === false
    ) {
      dispatch(updateSnfClusterData(endpoint, {}, body))
    } else {
      toast('Please select atleast 1 record to merge', toastTopRightError)
    }
  }
  const handleEdit = () => {
    if (editRows.length === 0) {
      toast(
        'No records selected, Please select the  record',
        toastTopRightError
      )
    } else {
      if (editRows.length > 0) {
        const editRecord = editRows[0]
        fetchDataByClusterId(editRecord.CLUSTER, editRecord['ID'])
        setUpdateRecord(editRecord)
        setshowModal(true)
        setRemarks(editRecord?.REMARKS)
        setStatus(editRecord?.STATUS)
        setIsMergeable(editRows[0]?.IS_MERGEABLE)
        //   setComments(editRows[0]?.EDIT_COMMENT)
        setReviewComments(editRows[0]?.REVIEW_COMMENTS)
      } else {
        toast('No records selected', toastTopRightError)
      }
    }
  }
  const handleSorting = (header) => {
    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  const fetchData = async (
    { pageSize = 10, pageIndex = 1 },
    filters = null,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    setLocalPageIndex(pageIndex)
    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value || JSON.stringify(columnSort),
    }
    const isData = isMyDataRef.current
    if (isData) {
      dispatch(getMySnfClusterData(endpoint, params))
        .then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
        .catch(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
    } else {
      dispatch(getSnfClusterData(endpoint, params))
        .then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
        .catch(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
    }
  }
  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }

  const fetchDataByClusterId = (clusterId, id) => {
    let filter_payload = [
      { op: 'eq', field: 'CLUSTER', value: `${clusterId}` },
      { op: 'ne', field: 'ID', value: `${id}` },
    ]
    const params = {
      filters: JSON.stringify(filter_payload),
    }
    dispatch(getSnfClusterDataByClusterId(endpoint, params))
  }

  const onNext = () => {
    gotoPage(pageIndex + 1)
  }
  return (
    <>
      <div>
        {loadingStatus.status ? (
          <LoadingStatus status_message={loadingStatus.message} />
        ) : (
          <div>
            <div className="table-container" style={{ textAlign: 'center' }}>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isMyData}
                      onChange={(e) => {
                        setIsMyData(e.currentTarget.checked)
                      }}
                      sx={{
                        paddingRight: '8px',
                        letterSpacing: '1px',
                        float: 'right',
                      }}
                      // className='ideas_switch'
                    />
                  }
                  style={{
                    display: 'flex',
                    float: 'right',
                  }}
                  label="My Data"
                />
              </div>
              <Table {...getTableProps()}>
                <thead className="sticky-table-header">
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, i) =>
                        column?.id === 'selection' ? (
                          <th key={column.id}>
                            <div
                              {...column.getHeaderProps(
                                column.getSortByToggleProps(),
                                {
                                  style: { width: column.size },
                                }
                              )}
                              className="table-header-badge"
                            >
                              {column.render('Header')}
                              {columnSort[0]?.field === column['Header'] && (
                                <span>
                                  {columnSort[0]?.direction === 'asc'
                                    ? ' 🔽'
                                    : ''}
                                  {columnSort[0]?.direction === 'desc'
                                    ? ' 🔼'
                                    : ''}
                                </span>
                              )}
                            </div>
                            <div>
                              {column.canFilter
                                ? column.render('Filter')
                                : null}
                            </div>
                          </th>
                        ) : column.id === 'STATUS' ? (
                          <th key={i}>
                            <div
                              onClick={() => handleSorting(column.id)}
                              className="table-header-badge"
                            >
                              {column.render('Header')}
                              {columnSort[0]?.field === column['id'] ? (
                                <span>
                                  {columnSort[0]?.direction === 'asc'
                                    ? ' 🔽'
                                    : '' || columnSort[0]?.direction === 'desc'
                                    ? ' 🔼'
                                    : ''}
                                </span>
                              ) : null}
                            </div>
                            <div>
                              <select
                                className="form-control"
                                defaultValue={statusSelection}
                                onChange={(e) =>
                                  setStatusSelection(e.target.value)
                                }
                              >
                                <option>Select Status</option>
                                {statusOptions?.map((pod) => {
                                  return (
                                    <option key={pod} value={pod}>
                                      {pod}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </th>
                        ) : removeFilterColumns.includes(column.id) ? (
                          <th key={i}>
                            <div
                              onClick={() => handleSorting(column.id)}
                              className="table-header-badge"
                            >
                              {column.render('Header')}
                              {columnSort[0]?.field === column['id'] ? (
                                <span>
                                  {columnSort[0]?.direction === 'asc'
                                    ? ' 🔽'
                                    : '' || columnSort[0]?.direction === 'desc'
                                    ? ' 🔼'
                                    : ''}
                                </span>
                              ) : null}
                            </div>
                          </th>
                        ) : (
                          <th key={i}>
                            <div
                              onClick={() => handleSorting(column.id)}
                              className="table-header-badge"
                            >
                              {column.render('Header')}
                              {columnSort[0]?.field === column['id'] ? (
                                <span>
                                  {columnSort[0]?.direction === 'asc'
                                    ? ' 🔽'
                                    : '' || columnSort[0]?.direction === 'desc'
                                    ? ' 🔼'
                                    : ''}
                                </span>
                              ) : null}
                            </div>
                            <div>
                              {column.canFilter
                                ? column.render('Filter')
                                : null}
                            </div>
                          </th>
                        )
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody className="tbody mybadgesBody" {...getTableBodyProps()}>
                  {page.map((row, index) => {
                    prepareRow(row)
                    return (
                      <tr key={index}>
                        {row.cells.map((cell) => {
                          return cell.column.id === 'review_comments' ? (
                            <BootstrapTooltip title={cell.render('Cell')}>
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.size + '%',
                                  },
                                })}
                              >
                                {truncate(cell.value, 100)}
                              </td>
                            </BootstrapTooltip>
                          ) : cell.column.id === 'evidence' ? (
                            <BootstrapTooltip title={cell.render('Cell')}>
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.size + '%',
                                  },
                                })}
                              >
                                {truncate(cell.value, 100)}
                              </td>
                            </BootstrapTooltip>
                          ) : cell.column.id === 'chat' ? (
                            <BootstrapTooltip title={cell.render('Cell')}>
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.size + '%',
                                  },
                                })}
                              >
                                {truncate(cell.value, 100)}
                              </td>
                            </BootstrapTooltip>
                          ) : (
                            <>
                              {' '}
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.size + '%',
                                  },
                                })}
                              >
                                {cell.render('Cell')}
                              </td>
                            </>
                          )
                        })}
                        {editModeState && index === rowIndex ? (
                          <>
                            <div className="inlineedit">
                              <button
                                className="inlineinternal"
                                onClick={handleUpdateEditMode}
                              >
                                &#10004;
                              </button>
                              <button
                                className="inlineinternal"
                                onClick={handleDeleteEditMode}
                              >
                                &#10060;
                              </button>
                            </div>
                          </>
                        ) : null}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
            <Row>
              <Col sm={6}>
                <div className="page-control">
                  <div className="page-of">
                    Page{' '}
                    <em>
                      {pageIndex + 1} of {totalPages}
                    </em>
                  </div>
                  <div className="prev-next-btn">
                    <button
                      className="badge-btn"
                      onClick={() => onPrevious()}
                      disabled={!canPreviousPage}
                    >
                      {' '}
                      Prev{' '}
                    </button>
                    <button
                      onClick={() => onNext()}
                      className="badge-btn"
                      disabled={!canNextPage}
                    >
                      {' '}
                      Next{' '}
                    </button>
                  </div>
                  <div className="second-control">
                    <span>Go to page:</span>
                    <input
                      className="page-number-input"
                      type="number"
                      defaultValue={pageIndex || 1}
                      onBlur={(e) => {
                        const page = e.target.value ? Number(e.target.value) : 0
                        // handling zero indexing
                        gotoPage(page - 1)
                      }}
                    />
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value))
                      }}
                    >
                      {available_page_size.map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{
                      display: 'inline-block',
                      marginLeft: '50px',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    Total results:
                    {snfClusteringData?.snfClusterData?.total_results}
                  </div>
                </div>
              </Col>
              {/* Bottom-right Buttons */}
              <Col sm={6}>
                <Button
                  className="m_r-5 block-btn1 main-button btn-sm"
                  onClick={handleEdit}
                >
                  EDIT
                </Button>
              </Col>
            </Row>
            <Modal size="xl" show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Request</Modal.Title>
              </Modal.Header>
              <Modal.Body className="modalBody">
                <>
                  {/* <Form> */}

                  <Form.Group className="" as={Row} md="2">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Table Catalog</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Table Catalog"
                          aria-describedby="inputGroupPrepend"
                          name="mail"
                          defaultValue={updateRecord?.TABLE_CATALOG}
                          disabled
                          // isInvalid={!!errors.de_mail}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Table Schema</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Table Schema"
                          aria-describedby="inputGroupPrepend"
                          name="mail"
                          defaultValue={updateRecord?.TABLE_SCHEMA}
                          disabled
                          // isInvalid={!!errors.de_mail}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Table Name</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Table Name"
                          aria-describedby="inputGroupPrepend"
                          name="mail"
                          defaultValue={updateRecord?.TABLE_NAME}
                          disabled
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      className=""
                      as={Row}
                      md="2"
                      controlId="validationFormikUsername"
                    >
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Column Count</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Table Catalog"
                            aria-describedby="inputGroupPrepend"
                            name="mail"
                            defaultValue={updateRecord?.COLUMN_COUNT}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        md="2"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Record Count</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Record Count"
                            aria-describedby="inputGroupPrepend"
                            name="mail"
                            defaultValue={updateRecord?.RECORD_COUNT}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                    </Form.Group>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Developer Emails</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Developer Emails"
                        aria-describedby="inputGroupPrepend"
                        name="mail"
                        defaultValue={updateRecord?.DEVELOPER_EMAILS}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Reason For Similarity</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder=">Reason For Similarity"
                        aria-describedby="inputGroupPrepend"
                        name="mail"
                        defaultValue={updateRecord?.REASON_FOR_SIMILARITY}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="" as={Row} md="2">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Is Mergeable</Form.Label>
                      <Form.Select
                        defaultValue={isMergeable}
                        onChange={(e) => {
                          const isMerge =
                            e.target.value.toLowerCase() === 'true'
                              ? true
                              : e.target.value.toLowerCase() === 'false'
                              ? false
                              : ''
                          setIsMergeable(isMerge)
                        }}
                      >
                        <option>--select--</option>
                        {isMergeableOptions?.map((pod) => {
                          return (
                            <option key={pod} value={pod}>
                              {pod}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        defaultValue={status}
                        onChange={(e) => {
                          setStatus(e.target.value)
                        }}
                      >
                        <option>--select--</option>
                        {editStatusOptions?.map((pod) => {
                          return (
                            <option key={pod} value={pod}>
                              {pod}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Other tables with in the cluster</Form.Label>
                    <SnowflakeClusterData
                      setSelectedRows={setSelectedRows}
                      selectedRows={selectedRows}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Remarks</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Remarks"
                        aria-describedby="inputGroupPrepend"
                        name="mail"
                        defaultValue={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  {false && (
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Review Comments</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Review Comments"
                        value={reviewComments}
                        // onChange={(e) => handleEditChange(e, 'review_comments')}
                      />
                    </Form.Group>
                  )}
                  {/* </Form> */}
                  <Modal.Footer>
                    {editRows[0]?.status == 'Rejected' ? (
                      <Button
                        variant="primary"
                        className="addModalBtn"
                        type="submit"
                        // onClick={handleEditBadge}
                      >
                        Re-Submit
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        className="addModalBtn"
                        type="submit"
                        onClick={() => handleUpdateIsMergeable()}
                      >
                        Submit
                      </Button>
                    )}

                    <Button
                      variant="danger"
                      type="submit"
                      className="addModalBtn"
                      onClick={handleCloseModal}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </div>
    </>
  )
}
export default SnfTable

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)
