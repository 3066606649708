import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import './cardView.css'

export default function CardsView({
  title_content,
  border_status_class,
  action_content,
  handleClick,
}) {
  return (
    <div className="card-col">
      <Card
        className={`idea_card ${border_status_class}`}
        onClick={handleClick}
      >
        <CardHeader
          style={{padding:"2px"}}
          title={title_content}
        ></CardHeader>
        <CardActions style={{ paddingTop: '0', paddingBottom: '0' }}>
          {action_content}
        </CardActions>
      </Card>
    </div>
  )
}
