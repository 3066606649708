import React, { useEffect, useState, useContext } from 'react'
import Sidebar from '../../../AppBar/Appbar'
import './css/index.css'
import Main from './Main'
import { AuthContext } from '../../../../context/authProvider'
import Rightbar from './Rightbar'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import { useDispatch, useSelector } from 'react-redux'
import {
  getCollabEntries,
  getMostUpvotedPosts,
  addDataReset,
  searchInCollab,
} from '../../../../../redux/actions/collabAction'
import { getForumMetadata } from '../../../../../redux/actions/forumMetadataAction'
import LoadingStatus from '../../../../Helper/LoadingStatus'
import { getpopulartags } from '../../../../../redux/actions/collabAction'
import ReactPaginate from 'react-paginate'
import Filter from './filter'
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  color: theme.palette.text.secondary,
  height: '100%',
}))

export function Index() {
  const [authCreds] = useContext(AuthContext)

  const [roles, setRoles] = useState([])
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [newactive, setnewactive] = useState(true)
  const [oldactive, setoldctive] = useState(false)
  const [mostviewactive, setmostviewactive] = useState(false)
  const [unansweredactive, setunansweredactive] = useState(false)
  const endpoint = authCreds.restEndpoint
  const collabData = useSelector((state) => state.collabData)
  const tagData = useSelector((state) => state.tagData)
  const mostUpvotedPosts = useSelector((state) => state.mostUpvotedPosts)
  const searchData = useSelector((state) => state.searchData)
  // const [userData, setUserData] = useState()
  const [questions, setQuestions] = useState([])
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [tags, setTags] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [searchcollabName, setsearchCollabname] = useState('')
  const [collabMetadata, setCollabMetadata] = useState('')
  const [isTag, setIsTag] = useState(false)

  const dispatch = useDispatch()
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const [channel, setChannel] = React.useState(localStorage.getItem('channel'))
  const [upvotedPosts, setUpvotedPosts] = useState()

  //  let de_name = tokenValue.name
  // let de_mail = tokenValue.preferred_username
  let top = 10
  let skip = currentPage * top

  useEffect(() => {
    dispatch(addDataReset())
    async function getQuestion() {
      let url =
        endpoint +
        `/api/collab/get_all_posts?page=${
          currentPage + 1
        }&per_page=10&channel=${channel}`

      if (unansweredactive) {
        if (searchcollabName.length > 0) {
          getSearchResults(['created_on desc'], top, skip, unansweredactive)
        } else {
          url = url + `&answer_count=0`
          getAllPosts(url)
        }
      }
      if (
        newactive === false &&
        oldactive === false &&
        mostviewactive === false &&
        unansweredactive === false
      ) {
        setLoadingStatus({ status: true, message: 'Fetching data...' })

        if (searchcollabName.length > 0) {
          getSearchResults(['created_on desc'], top, skip, unansweredactive)
        } else {
          url = url + `&sort_order=desc`
          getAllPosts(url)
        }
      }
      if (newactive) {
        if (searchcollabName.length > 0) {
          getSearchResults(['created_on desc'], top, skip, unansweredactive)
        } else {
          url = url + `&sort_order=desc`
          getAllPosts(url)
        }
      }
      if (oldactive) {
        setLoadingStatus({ status: true, message: 'Fetching data...' })

        if (searchcollabName.length > 0) {
          getSearchResults(['created_on asc'], top, skip, unansweredactive)
        } else {
          url = url + `&sort_order=asc`
          getAllPosts(url)
        }
      }
      if (mostviewactive) {
        setLoadingStatus({ status: true, message: 'Fetching data...' })

        if (searchcollabName.length > 0) {
          getSearchResults(['view_count desc'], top, skip, unansweredactive)
        } else {
          url = url + `&sort_by_view=true`
          getAllPosts(url)
        }
      }
    }
    getQuestion()
  }, [
    currentPage,
    unansweredactive,
    newactive,
    oldactive,
    mostviewactive,
    endpoint,
    channel,
  ])

  function getAllPosts(url) {
    dispatch(getCollabEntries(url)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
    })
  }

  function getSearchResults(sort_order, top, skip, unansweredactive) {
    let searchText
    if (
      searchcollabName[0] === '[' &&
      searchcollabName[searchcollabName.length - 1] === ']'
    ) {
      searchText = searchcollabName.slice(1, -1)
    } else {
      searchText = searchcollabName
    }

    dispatch(
      searchInCollab(
        endpoint,
        searchText,
        channel,
        isTag,
        sort_order,
        top,
        skip,
        unansweredactive
      )
    ).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
    })
  }

  useEffect(() => {
    if (searchData?.searchData?.data) {
      setQuestions(searchData?.searchData?.data)
      setTotalPages(Math.ceil(searchData?.searchData?.record_count / 10))
      setTotalQuestions(searchData?.searchData?.record_count)
    }
  }, [searchData?.searchData?.data])

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected)
  }

  useEffect(() => {
    async function forumMetadata() {
      setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=Q_AND_A_FORUM`
      dispatch(getForumMetadata(url)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])

  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata)
    }
  }, [forumMetadata])

  useEffect(() => {
    if (collabData?.loading == false) {
      setRoles(collabData?.collabData?.user_roles)
      setQuestions(collabData?.collabData?.data)
      setTotalPages(Math.ceil(collabData?.collabData?.total_count / 10))
      setTotalQuestions(collabData?.collabData?.total_count)
    }
  }, [collabData?.collabData?.data])

  useEffect(() => {
    if (mostUpvotedPosts?.loading == false) {
      setUpvotedPosts(mostUpvotedPosts?.mostUpvotedPosts?.data)
    }
  }, [mostUpvotedPosts?.mostUpvotedPosts?.data])

  useEffect(() => {
    if (tagData?.loading == false) {
      setTags(tagData?.tagData?.data)
    }
  }, [tagData?.tagData?.data])

  useEffect(() => {
    async function handleMostUpvoted() {
      setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url =
        endpoint +
        `/api/collab/get_all_posts?page=${
          currentPage + 1
        }&sort_by_vote=true&per_page=10&channel=${channel}`
      dispatch(getMostUpvotedPosts(url)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    handleMostUpvoted()
  }, [channel])

  useEffect(() => {
    async function handleTags() {
      setLoadingStatus({ status: true, message: 'Fetching data...' })

      dispatch(getpopulartags(endpoint, channel)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    handleTags()
  }, [channel])

  async function getUnansweredQuestion() {
    setLoadingStatus({ status: true, message: 'Fetching data...' })

    setunansweredactive(!unansweredactive)
    setnewactive(false)
    setoldctive(false)
    setmostviewactive(false)
  }
  async function getNewestQuestion() {
    if (!newactive) {
      setLoadingStatus({ status: true, message: 'Fetching data...' })
      setunansweredactive(false)
      setnewactive(true)
      setoldctive(false)
      setmostviewactive(false)
    }
  }
  async function getOldestQuestion() {
    if (!oldactive) {
      setLoadingStatus({ status: true, message: 'Fetching data...' })
      setunansweredactive(false)
      setnewactive(false)
      setoldctive(true)
      setmostviewactive(false)
    }
  }
  async function getViewedQuestion() {
    if (!mostviewactive) {
      setLoadingStatus({ status: true, message: 'Fetching data...' })
      setunansweredactive(false)
      setnewactive(false)
      setoldctive(false)
      setmostviewactive(true)
    }
  }

  return (
    <>
      {collabData?.loading ||
      tagData?.loading ||
      mostUpvotedPosts.loading ||
      forumMetadata.loading ||
      loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Sidebar
            Page="Q&A Forum"
            channel={channel}
            setChannel={setChannel}
            setCurrentPage={setCurrentPage}
            collabMetadata={collabMetadata}
            Index={
              <>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container>
                    <Grid item xs={9}>
                      <Item>
                        {' '}
                        <Main
                          questions={questions}
                          searchcollabName={searchcollabName}
                          setsearchCollabname={setsearchCollabname}
                          setQuestions={setQuestions}
                          setTotalPages={setTotalPages}
                          filters={
                            <Filter
                              uq={getUnansweredQuestion}
                              nq={getNewestQuestion}
                              oq={getOldestQuestion}
                              mq={getViewedQuestion}
                              unansweredactive={unansweredactive}
                              newactive={newactive}
                              oldactive={oldactive}
                              mostviewactive={mostviewactive}
                            />
                          }
                          content={
                            <ReactPaginate
                              pageCount={totalPages}
                              onPageChange={handlePageChange}
                              forcePage={currentPage}
                              breakLabel={'...'}
                              previousLabel={'<<'}
                              nextLabel={'>>'}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                            />
                          }
                          roles={roles}
                          totalQuestions={totalQuestions}
                          setTotalQuestions={setTotalQuestions}
                          channel={channel}
                          currentPage={currentPage}
                          setIsTag={setIsTag}
                          isTag={isTag}
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={3}>
                      <Item>
                        {' '}
                        <Rightbar
                          tags={tags}
                          upvotedPosts={upvotedPosts}
                          setsearchCollabname={setsearchCollabname}
                          setQuestions={setQuestions}
                          setTotalPages={setTotalPages}
                          setLoadingStatus={setLoadingStatus}
                          channel={channel}
                          setTotalQuestions={setTotalQuestions}
                        />
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </>
            }
          />
        </>
      )}
    </>
  )
}

export default Index
