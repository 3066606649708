import React, { useEffect, useMemo, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../../context/authProvider'
import LoadingStatus from '../../../Helper/LoadingStatus'
import Pagination from '../../Utilities/pagination'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import { TableHandler } from '../../../Utils'
import { Table } from 'react-bootstrap'
import { GlossaryHomeButton } from '../GlossaryUtils/glossaryHomeButton'
import { getPendingGlossaryTerms } from '../../../../redux/actions/glossaryAction'

export default function PendingTerms({ channel }) {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const pendingGlossaryTerms = useSelector(
    (state) => state.pendingGlossaryTerms
  )
  const [authCreds] = useContext(AuthContext)
  const [tableData, setTableData] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [tableColumns, setTableColumns] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [columnFilters, setColumnFilters] = useState([])
  const [columnSort, setColumnSortValue] = useState([
    { field: `created_on`, direction: 'desc' },
  ])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const endpoint = authCreds.restEndpoint
  const token = localStorage.getItem('token')
  const table_name = 'TERMS'
  const formatDate = (dateStr) => {
    if (dateStr === null) {
      return null
    }
    return dateStr.split('T')[0]
  }

  const columnMetadata = {
    TERMS: {
      fields: [
        {
          name: 'id',
          headerTitle: 'ID',
          is_filterable: true,
          size: 5,
        },
        {
          name: 'term',
          headerTitle: 'Term',
          is_filterable: true,
          size: 20,
        },
        {
          name: 'starting_term',
          headerTitle: 'Starting Term',
          is_filterable: true,
          size: 10,
        },
        {
          name: 'definition',
          headerTitle: 'Definition',
          is_filterable: true,
          size: 10,
        },
        {
          name: 'role_type',
          headerTitle: 'Role Type',
          is_filterable: false,
          size: 10,
        },
        {
          name: 'view_count',
          headerTitle: 'View Count',
          is_filterable: false,
          size: 5,
        },
        {
          name: 'is_active',
          headerTitle: 'Is Active',
          is_filterable: false,
          size: 5,
        },
        {
          name: 'approval_status',
          headerTitle: 'Approval Status',
          is_filterable: false,
          size: 10,
        },
        {
          name: 'approved_on',
          headerTitle: 'Approved On',
          is_filterable: false,
          size: 5,
        },
        {
          name: 'approved_by',
          headerTitle: 'Approved By',
          is_filterable: true,
          size: 5,
        },
        {
          name: 'created_on',
          headerTitle: 'Created On',
          is_filterable: true,
          size: 8,
        },
        {
          name: 'created_by',
          headerTitle: 'Created By',
          is_filterable: true,
          size: 10,
        },
        {
          name: 'modified_on',
          headerTitle: 'Modified On',
          is_filterable: true,
          size: 5,
        },
        {
          name: 'modified_by',
          headerTitle: 'Modified By',
          is_filterable: true,
          size: 5,
        },
        {
          name: 'tags',
          headerTitle: 'Tags',
          is_filterable: false,
          size: 15,
        },
      ],
      tag: 'TERMS',
    },
  }
  const hiddenColumns = {
    TERMS: [
      'id',
      'definition',
      'starting_term',
      'view_count',
      'is_active',
      'approved_on',
      'approved_by',
      'modified_on',
      'modified_by',
    ],
  }
  const handleRowClick = (cc) => {
    navigate(`/glossary/approve-term/${cc.original.id}`)
  }
  const fetchData = async (
    { pageSize = 20, pageIndex = currentPage },
    filters = null,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    const params = {
      page_size: pageSize,
      page_number: pageIndex,
      filters: filters,
      sort: sort_value,
    }
    dispatch(getPendingGlossaryTerms(endpoint, params, channel))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  useEffect(() => {
    if (pendingGlossaryTerms?.loading === false) {
      let table_data = pendingGlossaryTerms?.pendingTerms?.data
      setTableColumns(
        TableHandler.createColumnMappingforKholab(
          [
            'id',
            'term',
            'starting_term',
            'definition',
            'role_type',
            'view_count',
            'is_active',
            'approval_status',
            'approved_on',
            'approved_by',
            'created_on',
            'created_by',
            'modified_on',
            'modified_by',
            'tags',
          ],
          columnMetadata,
          table_name
        )
      )
      setTotalPages(pendingGlossaryTerms?.pendingTerms?.num_pages)
      setTableData(table_data)
    } else {
      setTotalPages(0)
      setTableData([])
    }
  }, [pendingGlossaryTerms, setTableData])

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimeout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }

  // Default Column
  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()

      let timeoutId = setTimeout(() => {
        let filter_payload = []
        headers?.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter?.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, currentPage }, filter_payload, columnSort)
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }

  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }
  const data = useMemo(() => tableData || [], [tableData])
  const columns = useMemo(() => tableColumns || [], [tableColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: currentPage,
        hiddenColumns: hiddenColumns[table_name],
        pageSize: 20,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  )

  const handlePageChange = (number) => {
    setCurrentPage(number)
  }

  // fetches new table data on pageIndex, pageSize change
  useEffect(() => {
    if (token != null) {
      fetchData({ pageSize, currentPage }, columnFilters, columnSort)
    }
  }, [pageSize, columnSort, token, channel, currentPage])

  useEffect(() => {
    // If user changes the channel reset the index
    setCurrentPage(1)
  }, [channel])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <div className="glossary-term-view-container">
            <GlossaryHomeButton />
            <div className="glossary-title-container">
              <h3>Pending Terms - Requiring Admin Action</h3>
              <h6>Approve or Reject Terms</h6>
              <h6>Click on a row for more details</h6>
            </div>
            <hr />
          </div>
          <div className="table-container" style={{ textAlign: 'center' }}>
            <Table {...getTableProps()}>
              <thead className="sticky-table-header">
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) =>
                      column?.id === 'selection' ? (
                        <th key={column.id} style={{ width: '10px' }}>
                          <div
                            {...column.getHeaderProps(
                              column.getSortByToggleProps(),
                              { style: { width: '10px' } }
                            )}
                            className="table-header-badge"
                          >
                            {column.render('Header')}

                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : column.id == 'is_active' ? (
                        <th key={i} style={{ width: '50px' }}>
                          <div className="table-header-badge">
                            {column.render('Header')}
                          </div>
                        </th>
                      ) : (
                        <th key={i}>
                          <div
                            onClick={() => handleSorting(column.id)}
                            className="table-header-badge"
                          >
                            {column.render('Header')}

                            {columnSort[0]?.field === column['id'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      )
                    )}
                  </tr>
                ))}
              </thead>
              <tbody className="tbody mybadgesBody" {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row)
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        handleRowClick(row)
                      }}
                    >
                      {row.cells.map((cell) => {
                        // Format individual columns
                        return cell.column.id === 'created_on' ? (
                          <td
                            key={cell}
                            {...cell.getCellProps({
                              style: {
                                width: cell.column.size + '%',
                                textAlign: 'center',
                              },
                            })}
                          >
                            {formatDate(cell.value)}
                          </td>
                        ) : cell.column.id === 'tags' ? (
                          <td
                            key={cell}
                            {...cell.getCellProps({
                              style: {
                                width: cell.column.size + '%',
                                textAlign: 'center',
                              },
                            })}
                          >
                            {cell.value.map((item) => item.tag_name).join(', ')}
                          </td>
                        ) : (
                          <td
                            key={cell}
                            {...cell.getCellProps({
                              style: {
                                width: cell.column.size + '%',
                                textAlign: 'center',
                              },
                            })}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          <Pagination
            pageSize={pendingGlossaryTerms.pendingTerms.page_size}
            totalResults={pendingGlossaryTerms.pendingTerms.total_results}
            numPages={pendingGlossaryTerms.pendingTerms.num_pages}
            currentPage={currentPage}
            pageChange={handlePageChange}
            containerClass="glossary-pagination-container"
            buttonClass="glossary-pagination-button"
            buttonTextClass="glossary-pagination-text"
          ></Pagination>
        </>
      )}
    </>
  )
}
