import { styled} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import { Box } from '@mui/material'
const CustomTooltipContainer = styled('div')({
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    padding: '10px',
    fontFamily: "'Helvetica Neue', Arial, sans-serif",
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.5',
    textAlign: 'left',
  })
  
  const useStyles = makeStyles({
    boxContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    nps: {
      width: 10,
      height: 10,
      backgroundColor: 'blue',
      marginRight: 8,
    },
    promoters: {
      width: 10,
      height: 10,
      backgroundColor: '#70B77E',
      marginRight: 8,
      marginLeft: 8,
    },
    passives: {
      width: 10,
      height: 10,
      backgroundColor: '#FFDD57',
      marginRight: 8,
      marginLeft: 8,
    },
    detractors: {
      width: 10,
      height: 10,
      backgroundColor: '#E57373',
      marginRight: 8,
      marginLeft: 8,
    },
    annotation:{
        fontFamily: "'Helvetica Neue', Arial, sans-serif",

    }
  })
  
  
  const MatrixBox = styled(Box)({
    textAlign: 'center',
    width: '100%',
    padding: '20px',
    fontFamily: "'Helvetica Neue', Arial, sans-serif",
    background: '#ffff',
    height: '100dvh',
  })
  
export {MatrixBox,useStyles,CustomTooltipContainer}
