import {
  GET_KHOLAB_CHAPTER_ADMIN,
  GET_KHOLAB_CHAPTER_ADMIN_LOADING,
  GET_KHOLAB_CHAPTER_ADMIN_ERROR,
} from '../constants/types'
import axios from 'axios'
import { toastTopRightError } from '../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const getKholabChapterAdmin =
  (endpoint, chapter, user_email) => async (dispatch) => {
    try {
      dispatch({
        type: GET_KHOLAB_CHAPTER_ADMIN_LOADING,
      })
      let get_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      const params = {
        chapter: chapter,
        user_email: user_email,
      }

      await axios
        .get(`${endpoint}/api/kholab/check_chapter_admin`, {
          headers: get_headers,
          params: params,
        })
        .then((res) => {
          dispatch({
            type: GET_KHOLAB_CHAPTER_ADMIN,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_KHOLAB_CHAPTER_ADMIN_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }
