import {
  GET_MY_BADGES,
  GET_MY_BADGES_LOAD,
  GET_MY_BADGES_ERROR,
  EDIT_MY_BADGE_ERROR,
  EDIT_MY_BADGE_LOAD,
  EDIT_MY_BADGE_POST,
  GET_RECENT_BADGES,
  GET_RECENT_BADGES_LOAD,
  GET_RECENT_BADGES_ERROR
} from '../constants/types'

const initialState = {
  myBadges: [],
  recentBadges: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MY_BADGES_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_MY_BADGES:
      return {
        ...state,
        myBadges: action.payload,
        loading: false,
        error: null,
      }
    case GET_MY_BADGES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_RECENT_BADGES_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_RECENT_BADGES:
      return {
        ...state,
        recentBadges: action.payload,
        loading: false,
        error: null,
      }
    case GET_RECENT_BADGES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case EDIT_MY_BADGE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case EDIT_MY_BADGE_POST:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case EDIT_MY_BADGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
