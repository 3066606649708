import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import CardsView from '../Utilities/cardView'
import TabView from '../Utilities/tabView'
import {chapterContent} from './kholabData';
import './dashboard.css'

export default function KholabChapterModel({showChapterOverView, handleCloseKholabModal}) {
    return (
        <Modal size="xl" show={showChapterOverView} className="kholab-dashboard-modal" onHide={handleCloseKholabModal}>
            <Modal.Header closeButton>
                <Modal.Title>Chapters</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <CardsView
                    title_content={
                        <>
                            <span className="panel-heading">Vision</span>
                            <span className="panel-content">To help teams scale-up efficiently and responsibly.</span>
                        </>
                    }
                    border_status_class="border-success"
                />
                <CardsView
                    title_content={
                        <>
                            <span className="panel-heading">Mission</span>
                            <span className="panel-content">To enable peak employee performance by ensuring employees with similar job function are using the same frameworks / tools /platforms and have the similar opportunities for professional development, & guidance.</span>

                        </>
                    }
                    border_status_class="border-info"
                />
                <CardsView
                    title_content={
                        <>
                            <span className="panel-heading">Values</span>
                            <span className="panel-content">Below are our Values</span>
                            <ul className="panel-content">
                                <li>All chapters will have standard structure and modus of operation.</li>
                                <li>We will foster collaboration by breaking silos.</li>
                            </ul>
                        </>
                    }
                    border_status_class="border-warning"
                />
                <TabView dataArr={chapterContent} />
                <Modal.Footer style={{ gap: '10px' }}>
                    <Button variant="danger" type="submit" className="addModalBtn" onClick={handleCloseKholabModal} style={{ color: '#dc3545', border: '1px solid #dc3545' }} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    )
}
