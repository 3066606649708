import {
  LOAD_SNF_CLUSTER_DATA,
  GET_SNF_CLUSTER_DATA,
  ERROR_SNF_CLUSTER_DATA,
  LOAD_SNF_CLUSTER_DATA_BY_CLUSTER_ID,
  GET_SNF_CLUSTER_DATA_BY_CLUSTER_ID,
  ERROR_SNF_CLUSTER_DATA_BY_CLUSTER_ID,
} from '../../constants/types'
import axios from 'axios'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const getSnfClusterData = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SNF_CLUSTER_DATA,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    await axios
      .get(`${endpoint}/api/snf_cluster/get_all_data`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_SNF_CLUSTER_DATA,
          payload: res.data,
        })
      })
  } catch (e) {
    toast(e.response.data.message, toastTopRightError)
    dispatch({
      type: ERROR_SNF_CLUSTER_DATA,
      payload: [],
    })
  }
}

export const getMySnfClusterData = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_SNF_CLUSTER_DATA,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    await axios
      .get(`${endpoint}/api/snf_cluster/get_my_table`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_SNF_CLUSTER_DATA,
          payload: res.data,
        })
      })
  } catch (e) {
    toast(e.response.data.message, toastTopRightError)
    dispatch({
      type: ERROR_SNF_CLUSTER_DATA,
      payload: [],
    })
  }
}

export const updateSnfClusterData =
  (endpoint, params, body) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_SNF_CLUSTER_DATA,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
      await axios
        .put(`${endpoint}/api/snf_cluster/update_snf_cluster`, body, {
          headers: post_headers,
          params: params,
        })
        .then((res) => {
          toast(res.data.message, toastTopRightSuccess)
          setTimeout(() => window.location.reload(), 5000)
        })
    } catch (e) {
      toast(e.response.data.message, toastTopRightError)
      dispatch({
        type: ERROR_SNF_CLUSTER_DATA,
        payload: [],
      })
    }
  }

export const getSnfClusterDataByClusterId =
  (endpoint, params) => async (dispatch) => {
    try {
      dispatch({
        type: LOAD_SNF_CLUSTER_DATA_BY_CLUSTER_ID,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      await axios
        .get(`${endpoint}/api/snf_cluster/get_all_data`, {
          params: params,
          headers: post_headers,
        })
        .then((res) => {
          dispatch({
            type: GET_SNF_CLUSTER_DATA_BY_CLUSTER_ID,
            payload: res.data,
          })
        })
    } catch (e) {
      toast(e.response.data.message, toastTopRightError)
      dispatch({
        type: ERROR_SNF_CLUSTER_DATA_BY_CLUSTER_ID,
        payload: [],
      })
    }
  }
