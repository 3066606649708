import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from 'recharts';
import './stackedBarChart.css'

export default function StackedBarChart({
    xAxisKey,
    data,
    barChartKey
}) {
    const getColor = (key) =>{
        let color = '#e1e5ec';
        const colorArr = ['#3598dc', '#578ebe', '#2C3E50', '#22313F', '#67809F','#4B77BE', '#4c87b9', '#5e738b','#5C9BD1','#94A0B2', '#32c5d2','#1BBC9B', '#1BA39C', '#36D7B7', '#44b6ae', '#26C281', '#3faba4', '#4DB3A2', '#2ab4c0', '#29b4b6', '#E5E5E5', '#e9edef', '#fafafa','#555555', '#95A5A6', '#BFBFBF', '#ACB5C3', '#bfcad1', '#525e64', '#e7505a', '#E08283','#E26A6A', '#e35b5a', '#D91E18', '#EF4836', '#d05454', '#f36a5a', '#e43a45', '#c49f47','#E87E04', '#f2784b', '#f3c200', '#F7CA18', '#F4D03F', '#c8d046', '#c5bf66', '#c5b96b','#8E44AD', '#8775a7', '#BF55EC', '#8E44AD', '#9B59B6', '#9A12B3', '#8775a7', '#796799', '#8877a9', '#2f353b'];
        if(colorArr[key]){
            color = colorArr[key]
        }
        return color
    }
    return (
    <div className="stackedBarChart" >
        <BarChart width={500} height={300} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisKey} />
            <YAxis />
            <Tooltip />
            <Legend />
            {
                Object.entries(barChartKey).map(([key, val]) => <Bar dataKey={val} stackId="a" fill={getColor(key)} key={key} />)
            
            }
        </BarChart>
        <div style={{display: 'flex', justifyContent: 'center'}}>Snowflake Credits</div>  
    </div>
    )
}
