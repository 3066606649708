import React from 'react'
import { Link } from 'react-router-dom'

export const GlossaryHomeButton = () => {
  return (
    <div>
      <Link to="/glossary">
        <button className="glossary-back-button">
          <span className="glossary-back-button-icon">◀︎</span>
          <span>Home</span>
        </button>
      </Link>
    </div>
  )
}
