import React from 'react'
import { MdHelpOutline } from 'react-icons/md'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Typography } from '@mui/material'
import { Button } from 'react-bootstrap'

export default function SnfClusterHelp() {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ marginRight: '10px' }}>
      <Button
        aria-label="what is NPS Survey?"
        variant="outlined"
        label="fd"
        onClick={handleClickOpen}
      >
        <MdHelpOutline style={{ fontSize: '28px', color: '#F9D949' }} />
      </Button>

      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-describedby=""
      >
        <DialogTitle>
          Welcome to Snowflake Clustering, Your feedback shapes our organisation
          goals! It takes minumun time to share your experience and help us to
          create efficient features for everyone. Thank you! <br />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <br />
            <Typography gutterBottom>
              <b>
                <u>Snowflake Clustering:</u>
              </b>
              <br />
              Snowflake clustering groups tables that are similar based on
              column name, row count, and column count. The aim of this is to
              identify duplicated tables in the database and merge/drop them,
              all of which are valid cases. It consists of a cluster column with
              an integer value, which will be the same for similar tables. The
              other existing columns include the database name, schema, table
              name, is_mergeable, status, and developer email. Developers can
              edit the is_mergeable, remarks, and status columns. The
              is_mergeable column determines whether the developer can merge the
              tables following their analysis. The developers can update
              comments, if any, in the remarks column.
              <br />
            </Typography>
            <Typography gutterBottom>
              <b>
                If you need any assistance regarding Snowflake Clustering.
                Please email at{' '}
              </b>
              <a
                href="mailto:dl_khcdewebappdev@kraftheinz.com"
                className="email-link"
              >
                dl_khcdewebappdev@kraftheinz.com
              </a>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
