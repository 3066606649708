import {
  GET_KHOLAB_CHAPTER_ADMIN,
  GET_KHOLAB_CHAPTER_ADMIN_LOADING,
  GET_KHOLAB_CHAPTER_ADMIN_ERROR,
} from '../constants/types'

const initialState = {
  chapterAdmin: [],
  error: null,
  loading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_KHOLAB_CHAPTER_ADMIN_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_KHOLAB_CHAPTER_ADMIN:
      return {
        ...state,
        loading: false,
        chapterAdmin: action.payload,
        error: null,
      }
    case GET_KHOLAB_CHAPTER_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
