import * as React from 'react'
import { useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

export default function SnowflakeClusterData({
  selectedRows,
  setSelectedRows,
}) {
  const snfClusteringData = useSelector((state) => state.getClusterData)
  const [data, setData] = React.useState(
    snfClusteringData?.clusterData?.data || []
  )
  React.useEffect(() => {
    const dataLength = snfClusteringData?.clusterData?.data?.length ?? 0
    setSelectedRows(Array(dataLength).fill(''))
  }, [data])

  React.useEffect(() => {
    setData(snfClusteringData?.clusterData?.data)
  }, [snfClusteringData])
  const handleCheck = (event, row, rowIndex) => {
    if (event.target.checked === true) {
      const newList = [...selectedRows]
      newList[rowIndex] = row.ID
      setSelectedRows(newList)
    } else if (event.target.checked === false) {
      const newListRemove = [...selectedRows]
      newListRemove[rowIndex] = ''
      setSelectedRows(newListRemove)
    }
  }
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      >
        <Table sx={{ minWidth: 430 }} aria-label="Grade Table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className="grade-table-header"
              ></TableCell>
              <TableCell
                className="grade-table-header"
                style={{ padding: '5px' }}
              >
                Cluster
              </TableCell>
              <TableCell
                align="left"
                className="grade-table-header"
                style={{ padding: '5px' }}
              >
                Table Catalog
              </TableCell>
              <TableCell
                align="left"
                className="grade-table-header"
                style={{ padding: '5px' }}
              >
                Table Schema
              </TableCell>
              <TableCell
                align="left"
                className="grade-table-header"
                style={{ padding: '5px' }}
              >
                Table Name
              </TableCell>
              <TableCell
                align="left"
                className="grade-table-header"
                style={{ padding: '5px' }}
              >
                Is Mergeable
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="grade-table-body idea-table-body">
            {data?.length > 0 && (
              <>
                {' '}
                {data?.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex} // Fallback to rowIndex if row?.name is not unique or undefined
                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            onClick={(e) => handleCheck(e, row, rowIndex)}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell align="left">{row?.CLUSTER}</TableCell>
                    <TableCell align="left">{row?.TABLE_CATALOG}</TableCell>
                    <TableCell align="left">{row?.TABLE_SCHEMA}</TableCell>
                    <TableCell align="left">{row?.TABLE_NAME}</TableCell>
                    <TableCell align="left">
                      {row?.IS_MERGEABLE === true
                        ? 'True'
                        : row?.IS_MERGEABLE === false
                        ? 'False'
                        : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
