import React, {useState, useContext, useEffect} from "react"
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Sidebar from '../AppBar/Appbar'
import Button from '@mui/material/Button'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import CardsView from '../Utilities/cardView'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import { getAllAnnouncements, getHomePageFilters, getAnnouncement } from  '../../../redux/actions/announcementActions/announcementAction'
import {chapterHeads, kholabSections} from './kholabData'
import { getRecentQuestions } from '../../../redux/actions/collabAction'
import { getBadgeusers, getRecentBadges, getRecentKudos, getUserKudos } from '../../../redux/actions/badgeAction'
import { getPods } from '../../../redux/actions/podsPageActions/podsAction'
import { getAllIdeas, getAllFilters } from '../../../redux/actions/ideasActions/ideasAction'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../context/authProvider'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import CelebrationIcon from '@mui/icons-material/Celebration'
import KholabChapterModel from './kholabChapterModel'
import AnnouncementPopupModal from './announcementPopupModel'
import AnnouncementItemModal from './announcementItemModel'
import UserProfile from '../Users/userProfileModal'
import ReportIcon from '@mui/icons-material/Report'
import StarsIcon from '@mui/icons-material/Stars'
import DangerousIcon from '@mui/icons-material/Dangerous'
import Carousel from 'react-bootstrap/Carousel';
import '../../../App.css'
import './dashboard.css'
import StyledBadge from "../Badge/StyledBadge"

export default function KholabDashboard(){
    const history = useNavigate()
    let decodedToken
    let tokenValues = localStorage?.getItem('token')
    if (tokenValues) {
        decodedToken = jwt_decode(tokenValues)
    }
    const handleClick = (path) => {
        history(path)
    }
    const [showChapterOverView, setShowChapterOverView] = useState(false);
    const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
    const [showAnnouncementItemModal, setShowAnnouncementItemModal] = useState(false);
    const [selectedAnnouncementTab, setSelectedAnnouncementTab] = useState('');
    const [kholabPage, setKholabPage] = useState(1);
    const [kholabLimit, setKholabLimit] = useState(10);
    const [totalKholabPages, setTotalKholabPages] = useState(0);
    const [totalKholabAnnouncements, setTotalKholabAnnouncements] = useState(0);
    const [channelPage, setChannelPage] = useState(1);
    const [channelLimit, setChannelLimit] = useState(10);
    const [totalChannelPages, setTotalChannelPages] = useState(0);
    const [totalChannelAnnouncements, setTotalChannelAnnouncements] = useState(0);
    const channel = localStorage.getItem('channel');
    const [announcementData, setAnnouncementData] = useState([]);
    const [announcementItem, setAnnouncementItem] = useState({})
    const [announcementTitle, setAnnouncementTitle] = useState('')
    const [chapterAnnouncementData, setChapterAnnouncementData] = useState([]);
    const [recentBadges, setRecentBadges] = useState([]);
    const [recentKudos, setRecentKudos] = useState([]);
    const [recentPosts, setRecentPosts] = useState([]);
    const [kudosCount, setKudosCount] = useState(0);
    const [podCount, setPodCount] = useState(0);
    const [badgeCount, setBadgeCount] = useState(0);
    const [ideaCount, setIdeaCount] = useState(0);
    const [viewMyIdeas, setViewMyIdeas] = useState(false);
    const [viewMyKudos, setViewMyKudos] = useState(false);
    const [badgeData, setbadgeData] = useState()
    const [showModal, setShowModal] = useState(false)
    const [selectTab, setSelectTab] = useState(2)
    const [kudoModal, setkudoModal] = useState(false)
    const handleOpenKudoModal = (flag)=>{
        fetchRecentKudos();
        setkudoModal(flag);
    }
    const handleOpenUserProfileModal = (pageType) => {
        if(userBadges && userBadges.userBadges && userBadges.userBadges.data && userBadges.userBadges.data.length > 0) {setbadgeData(userBadges.userBadges.data[0])}
        setShowModal(true);
        setSelectTab(pageType)
    }
    const handleViewMore = (e) =>{
        e.stopPropagation();
        setShowChapterOverView(true);
    }
    const handleCloseKholabModal = ()=>{
        setShowChapterOverView(false);
    }
    const numDifferentiation = (val) => {
        if(val >= 10000000) val = (val/10000000).toFixed(2) + 'C';
        else if(val >= 100000) val = (val/100000).toFixed(2) + 'L';
        else if(val >= 1000) val = (val/1000).toFixed(2) + 'K';
        return val;
    }
    const sendChapterMail = (e, distributionMail) => {
        e.stopPropagation();
        window.location.href = `mailto:${distributionMail}`;
    }
    const viewKnowledgeRepository = (e, knowledgeLink) => {
        e.stopPropagation();
        window.open(knowledgeLink, '_blank');

    }
    const ChapterHeadMail = (e, chapterHeadMail) => {
        e.stopPropagation();
        window.location.href = `mailto:${chapterHeadMail}?subject=KHOLAB: Head of Chapter Email`;
    }
    const [authCreds] = useContext(AuthContext)
    const dispatch = useDispatch()
    const [channelTabIndex, setChannelTabIndex] = useState(0);

    const handleSelectChannel = (selectedIndex) => {
        setChannelTabIndex(selectedIndex);
    };
    const userBadges = useSelector((state) => state.userBadges)
    const myBadges = useSelector((state) => state.myBadges)
    const podsData = useSelector((state) => state.podsData?.podsMenuData)
    const myKudos = useSelector((state) =>  state.userKudos)
    const getAnnouncementData = useSelector((state)=> state.announcementReducer.getAllAnnouncements)
    const getChapterAnnouncementData = useSelector((state)=> state.announcementReducer.getChapterAnnouncements);
    const getAnnouncementItem = useSelector((state)=>state.announcementReducer.getAnnoncementByID)
    const filterValues = useSelector((state)=> state.ideasFilterReducer?.getIdeasFilter)
    const homePageFilterVal = useSelector((state) => state.homeFilterReducer?.getHomePageFilter)
    const getAllRecentPosts = useSelector((state) => state.recentPosts?.recentPosts)
    const getAllIdeasData = useSelector( (state) => state.getAllIdeasReducer.getAllIdeas)
    const getRecentKudosData = useSelector( (state)=>  state.userKudos?.recentKudos)
    const endpoint = authCreds.restEndpoint
    const fetchAnnouncement = async(announcementId) => {
        const params = {announcement_id: announcementId}
        dispatch(getAnnouncement(endpoint, params))
    }
    const fetchAnnouncements = (channel, page_num = 1, page_limit = 3) => {
        const params = {
            per_page: page_limit,
            page: page_num,
            sort_order: 'desc',
            show_deleted: false,
            include_inactive: false,
            show_inactive: false,
            filters: JSON.stringify([{"op":"eq","field":"target_audience","value":channel.toLowerCase()}])
        }
        dispatch(getAllAnnouncements(endpoint, params, channel));        
    }
    const fetchBadgeCount = () => {
        const badgeParams = {
            page_size: 1,
            page_number: 1,
            filters: JSON.stringify([{"op":"eq","field":"user_email","value":decodedToken.preferred_username.toLowerCase()}]),
            channel: channel
        }
        dispatch(getBadgeusers(endpoint, badgeParams))
    }
    const fetchRecentBadges = () => {
        const badgeParams = {
            page_number: 1,
            badge_limit: 6
        }
        dispatch(getRecentBadges(endpoint, badgeParams))
    }
    const fetchRecentKudos = () => {
        const kudosParams = {
            page_number: 1,
            kudos_limit: 5
        }
        dispatch(getRecentKudos(endpoint, kudosParams))

    }
    const handleExternalLink = (e, link) => {
        e.stopPropagation();
        window.open(link, '_blank')
    }
    const handleViewAnnounementDetail = (itemId, title) => {
        fetchAnnouncement(itemId);
        setAnnouncementTitle(title);
        setShowAnnouncementItemModal(true);
    }
    const AnnouncementLine = (item) => {
        let infoClass = "status-category-critical-icon"
        let infoIcon = <DangerousIcon  />
        if(item.category && item.category == 'informative') {
            infoClass = "status-category-info-icon"
            infoIcon = <ReportIcon />
        } else if (item.category =='important') {
            infoClass = "status-category-imp-icon"
            infoIcon = <StarsIcon  />
        }
        return(
            <BootstrapTooltip onClick={()=>handleViewAnnounementDetail(item.id, item.title)} title={'Click to View Announcement Detail'} >
                <span className={`status-category-icon ${infoClass}`}>
                    {infoIcon} {item.title}
                </span>
            </BootstrapTooltip>
        )
        
    }
    
    const fetchPodCount = () => {
        dispatch(
            getPods(endpoint, decodedToken.preferred_username.toLowerCase())
          )
    }
    const fetchKudosCount = () => {
        dispatch(getUserKudos(endpoint, decodedToken.preferred_username.toLowerCase()))
    }
    const fetchRecentQuestions = () => {
        let url = endpoint+`/api/collab/get_all_posts?page=1&sort_order=desc&per_page=7`
        dispatch(getRecentQuestions(url))
    }
    const fetchIdeasCount = () => {
        const url =`${endpoint}/api/ideas/my_ideas`
        const ideaParams = {page_size: 1, page_number: 1};
        dispatch(getAllIdeas(url, ideaParams));
    }
    const [initLoad, setInitLoad] = useState(false)
    useEffect(()=>{
        fetchRecentKudos();
    }, [kudoModal])
    useEffect(() => {
      if (initLoad === true) {
        return
      }
      setInitLoad(true)
      fetchAnnouncements('kholab');
      fetchAnnouncements(channel);
      fetchBadgeCount();
      fetchPodCount();
      fetchKudosCount();
      fetchIdeasCount();
      fetchRecentBadges();
      fetchRecentKudos();
      fetchRecentQuestions();
    }, [])
    useEffect(()=>{
        setAnnouncementData(getAnnouncementData?.data);
        setChapterAnnouncementData(getChapterAnnouncementData?.data)
        setAnnouncementItem(getAnnouncementItem?.data)
        setTotalKholabPages(getAnnouncementData?.num_pages)
        setTotalKholabAnnouncements(getAnnouncementData?.total_results)
        setTotalChannelPages(getChapterAnnouncementData?.num_pages)
        setTotalChannelAnnouncements(getChapterAnnouncementData?.total_results)


        if(podsData && podsData.data && podsData.data.my_pods) {
            setPodCount(podsData.data.my_pods.length)
        }
        if(userBadges && userBadges.userBadges && userBadges.userBadges.data && userBadges.userBadges.data.length > 0) {setbadgeData(userBadges.userBadges.data[0]); setBadgeCount(userBadges.userBadges.data[0].badge_list.length)}
        if(myKudos && myKudos.userKudos) { setKudosCount(myKudos?.userKudos?.length);  }
        if(getAllIdeasData && getAllIdeasData.total_results) setIdeaCount(getAllIdeasData?.total_results)
        if(myBadges && myBadges.recentBadges) setRecentBadges(myBadges.recentBadges.data)

        if(getRecentKudosData &&  getRecentKudosData.data) setRecentKudos(getRecentKudosData.data)

        if(getAllRecentPosts &&  getAllRecentPosts.data) setRecentPosts(getAllRecentPosts.data)
        
    }, [getAnnouncementData, getChapterAnnouncementData, podsData, userBadges, myBadges, myKudos, getAllIdeasData, getRecentKudosData , getAllRecentPosts, getAnnouncementItem])
   
    const handleOpenAnnouncementModel = (type) => {
        setShowAnnouncementModal(true);
        setSelectedAnnouncementTab(type)
        setKholabPage(1)
        setKholabLimit(10)
        setChannelPage(1)
        setChannelLimit(10)
        fetchAnnouncements('kholab',1, 10);
        fetchAnnouncements(channel, 1, 10);
    }
    const handleCloseAnnouncementModel = () => {
        setShowAnnouncementModal(false)
        fetchAnnouncements('kholab');
        fetchAnnouncements(channel);
    }
    useEffect(()=>{
        if(viewMyIdeas) {
            let filters = filterValues;
            if(filters[0]?.filterIdeas){
                filters[0].filterIdeas.myIdeas = true;
            }
            dispatch(getAllFilters(filters))
            handleClick('/ideas')
            
        }

    },[viewMyIdeas])
    useEffect(()=>{
        if(viewMyKudos) {
            let homePagefilters = homePageFilterVal;
            if(homePagefilters){
                homePagefilters.myPods = true;
                dispatch(getHomePageFilters(homePagefilters))
                handleClick('/pods')
            }
        }
    }, [viewMyKudos])
    
    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.black,
          fontSize: '12px',
          whiteSpace: 'break-spaces',
        },
      }))

    return (
        <Sidebar
            Page="dashboard"
            kudoModal={kudoModal}
            setkudoModal={setkudoModal}
            Index={
                <div>
                    {((announcementData &&  announcementData.length > 0) || (chapterAnnouncementData &&  chapterAnnouncementData.length > 0)) &&
                        (
                        <>
                            <Row className="dashboard-section-type-1" style={{marginTop: '0.5em'}}>
                                <Col xs={12} md={12} className="announcements">                            
                                    <Carousel  variant="dark" controls={false} activeIndex={channelTabIndex} onSelect={handleSelectChannel}>
                                        <Carousel.Item>
                                            <div className="kholab-dashboard-heading"><NotificationsActiveIcon /> 
                                                Announcements!
                                                <span className="idea_category_badge badge-primary badge_container_desc_role_type badge-role-type-tag">Kholab (General)</span>
                                                <BootstrapTooltip title="View All Announcement" onClick={()=>handleOpenAnnouncementModel('kholab')} >
                                                    <Button size="medium" variant="outlined"  style={{textTransform: 'none', height:'2em'}}>
                                                    View All
                                                    </Button>
                                                </BootstrapTooltip>
                                            </div>
                                            
                                            <div>
                                                
                                            {(announcementData &&  announcementData.length > 0) ?(
                                                <ul style={{listStyleType: 'none'}}>
                                            {announcementData.map(item=> 
                                                    <li className="menu-item" key={item.id}>
                                                        {AnnouncementLine(item)}
                                                    </li>
                                            ) }
                                                </ul>
                                            ) : (<div>No General Announcement Available</div>)}
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className="kholab-dashboard-heading"><NotificationsActiveIcon /> Announcements! <span className="idea_category_badge badge-primary badge_container_desc_role_type badge-role-type-tag">{channel}</span>
                                            <BootstrapTooltip title="View All Announcement" onClick={()=>handleOpenAnnouncementModel('channel')} >
                                                <Button size="medium" variant="outlined"  style={{textTransform: 'none', height:'2em'}}>
                                                View All
                                                </Button>
                                            </BootstrapTooltip>
                                            </div>
                                            <div>                                        
                                            {(chapterAnnouncementData &&  chapterAnnouncementData.length > 0) ? (
                                                <ul style={{listStyleType: 'none'}}>
                                                    {chapterAnnouncementData.map(item=> 
                                                        <li className="menu-item" key={item.id}>
                                                            {AnnouncementLine(item)}
                                                        </li>
                                                    ) }
                                                </ul>
                                            ) : (<div>No Chapter related Announcement available.</div>
                                            )}
                                            </div>
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                                
                            </Row>
                            <AnnouncementPopupModal showAnnouncementModal={showAnnouncementModal} selectedAnnouncementTab={selectedAnnouncementTab} handleCloseAnnouncementModel={handleCloseAnnouncementModel} channel={channel} announcementData={announcementData} chapterAnnouncementData={chapterAnnouncementData} kholabPage={kholabPage} setKholabPage={setKholabPage} kholabLimit={kholabLimit} setKholabLimit={setKholabLimit} channelPage={channelPage} setChannelPage={setChannelPage} channelLimit={channelLimit} setChannelLimit={setChannelLimit} totalKholabPages={totalKholabPages} totalKholabAnnouncements={totalKholabAnnouncements} totalChannelPages={totalChannelPages} totalChannelAnnouncements={totalChannelAnnouncements} handleExternalLink={handleExternalLink} BootstrapTooltip={BootstrapTooltip} />
                            <AnnouncementItemModal showAnnouncementItemModal={showAnnouncementItemModal} setShowAnnouncementItemModal={setShowAnnouncementItemModal} announcementItem={announcementItem} announcementTitle={announcementTitle} BootstrapTooltip={BootstrapTooltip} handleExternalLink={handleExternalLink} />
                        </>
                    )
                    }
                    <div className="summary-tile-div">
                        <BootstrapTooltip title="Click to View My PODs" onClick={() => setViewMyKudos(true)}>
                            <div className="summary-tile-container"  style={{paddingLeft: 0}}>
                                <div className="summary-tile">
                                    <span className="summary-tile-count">
                                        {numDifferentiation(podCount)}
                                    </span>
                                    <span className="summary-tile-desc">
                                        <SupervisedUserCircleIcon className="dashboard-metrics-icon" />
                                        <span className="summary-tile-link">My PODs</span>
                                    </span>
                                </div>
                            </div>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Click to View My Ideas">
                            <div className="summary-tile-container" onClick={() => setViewMyIdeas(true)} >
                                <div className="summary-tile">
                                    <span className="summary-tile-count">
                                        {numDifferentiation(ideaCount)}
                                    </span>
                                    <span className="summary-tile-desc">
                                        <LightbulbIcon className="dashboard-metrics-icon"/> <span className="summary-tile-link">My Ideas</span>
                                    </span>
                                </div>
                            </div>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Click to View My Badges">
                            <div className="summary-tile-container" onClick={() => handleOpenUserProfileModal(1)}>
                                <div className="summary-tile">
                                    <span className="summary-tile-count">
                                        {numDifferentiation(badgeCount)}
                                    </span>
                                    <span className="summary-tile-desc">
                                        <MilitaryTechIcon className="dashboard-metrics-icon" />
                                        <span className="summary-tile-link">My Badges</span>
                                    </span>
                                </div>
                            </div>
                        </BootstrapTooltip>
                        {showModal ? (
                            <>
                                <UserProfile
                                selectedUser={badgeData?.user_name}
                                badgeData={badgeData}
                                setBadgeData={setbadgeData}
                                showModal={showModal}
                                setShowModal={setShowModal}
                                isLocalUser={false}
                                history={history}
                                pageSize={1}
                                pageIndex={1}
                                userKudos={myKudos}
                                defaultTabSelected={selectTab}
                                />
                            </>
                            ) : (
                            ''
                            )
                        }
                        <BootstrapTooltip title="Click to View My PODs">
                            <div className="summary-tile-container" style={{paddingRight: 0}} onClick={() => handleOpenUserProfileModal(2)}>
                                <div className="summary-tile">
                                    <span className="summary-tile-count">
                                        {numDifferentiation(kudosCount)}
                                    </span>
                                    <span className="summary-tile-desc">
                                        <CelebrationIcon className="dashboard-metrics-icon" /> <span className="summary-tile-link">My Kudos</span>
                                    </span>
                                </div>
                            </div>
                        </BootstrapTooltip>
                    </div>                
                    <Row className="dashboard-section-type-2">
                        <Col xs={12} md={8} style={{paddingLeft: 0}}>
                            <CardsView
                                title_content={
                                    <span className="kholab-dashboard-heading">
                                        What is KHOLAB?
                                    </span>
                                }
                                border_status_class='border-dashboard kholab-dashboard'
                                action_content={
                                    <div className="dashboard-flex dashboard-flex-col">
                                        <p className="chapter-summary" style={{minHeight: 'initial'}}>
                                            Kraft Heinz Chapter Collaboration Tool - a comprehensive platform designed to enhance transparency, communication, and coordination among all Chapters within the organization. This tool enables members to collaborate, share knowledge, and acknowledge each other while also progressing Chapter maturity and Chapter member growth. For chapter leadership, it provides a centralized view for managing PODs, monitoring growth and performance, and facilitating strategy and decision-making around the Chapter structure and initiatives.
                                        </p>
                                        <span className="kholab-dashboard-heading" style={{borderBottom: "1px solid #e9ecef"}}>
                                            Sections
                                        </span>
                                        <div className="dashboard-flex dashboard-flex-col dashboard_gap_0_5">
                                            {kholabSections.map((item, index) => {
                                                return  (
                                            <div className="dashboard-flex sections-card" key={index}>
                                                <span className="dashboard_font_weight_600" style={{flexBasis: '15%'}}>{item.title}</span>
                                                <span style={{flexBasis: '85%'}}>{item.content}</span>
                                            </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            />
                            <CardsView
                                title_content={
                                    <span className="kholab-dashboard-heading">
                                        What is a Chapter?
                                    </span>
                                }
                                action_content={
                                    <div className="dashboard-flex dashboard-flex-col">
                                        <p className="chapter-summary">
                                        A Chapter is a cross-cutting global network of specialist employees, sharing a common job function. Chapters ensure that all members have access to frameworks / tools / platforms, professional development, & guidance on how to perform at their peak. Chapters enable the high-quality deliverables and outcomes of Chapter members. Membership of Chapters is mandatory where that specialism is the core of an employee&rsquo;s role.
                                        </p>
                                        <div className="kholab-dashboard-actions">
                                            <BootstrapTooltip title="Click to View More about Technical Chapter">
                                                <Button size="small" className="idea-button" onClick={(e) => handleViewMore(e)}>
                                                View More
                                                </Button>
                                            </BootstrapTooltip>
                                        </div>
                                    </div>
                                }
                            />
                            <KholabChapterModel showChapterOverView={showChapterOverView} handleCloseKholabModal={handleCloseKholabModal} channel={channel} />
                            <CardsView 
                                title_content={
                                    <div className="dashboard-chapter-head-heading dashboard-flex dashboard_gap_0_5">
                                        <PeopleAltIcon /> Chapters at KHC
                                    </div>
                                }
                                border_status_class='border-dashboard'
                                action_content={
                                    <div>
                                        <Table bordered hover>
                                            <thead>
                                                <tr style={{ fontWeight: '500', fontSize: '1rem', lineHeight: '1.125rem', verticalAlign: 'middle'}}>
                                                    <th>Chapter</th>
                                                    <th>Head of Chapter</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{fontSize: '.875rem', lineHeight: '1.25rem'}}>
                                        {chapterHeads.map((item)=>{
                                            return(
                                                <tr key={item.user_email}>
                                                    <td>
                                                        {item.description ? (<BootstrapTooltip title={item.description} style={{textDecoration: 'underline', color: 'cornflowerblue'}}>
                                                            {item.role_type ? item.role_type: 'N/A'}
                                                        </BootstrapTooltip>): (item.role_type ? item.role_type: 'N/A')}
                                                        
                                                    </td>
                                                    <td>
                                                        <BootstrapTooltip title={`Click to mail Chapter Head (${item.user_email})`}>
                                                            <a href="#" onClick={(e)=>ChapterHeadMail(e, item.user_email)} style={{textDecoration: 'underline', color: 'cornflowerblue', textTransform: 'capitalize'}}>
                                                                {item.user_email.split('@')[0]}
                                                                </a>
                                                        </BootstrapTooltip>
                                                                
                                                    </td>
                                                    <td className="dashboard-flex dashboard_gap_0_5">
                                                        {item.distribution_list ? (
                                                            <BootstrapTooltip title={`Click to Email Chapter (${item.distribution_list})`}>
                                                            <Button variant="contained" className="apply_job_button" onClick={(e) => sendChapterMail(e,item.distribution_list)} size="small" style={{width: 'fit-content', fontSize: '0.6rem', lineHeight: '1'}}>EMAIL CHAPTER</Button>
                                                            </BootstrapTooltip>
                                                        ):''}
                                                        {item.link ? (
                                                            <BootstrapTooltip title={`Click to View Knowledge Repository ${item.link_desc ? '('+item.link_desc+')' : ''}`}>
                                                            <Button variant="contained" className="apply_job_button" onClick={(e) => viewKnowledgeRepository(e,item.link)}   size="medium" style={{width: 'fit-content', fontSize: '0.6rem', lineHeight: '1'}} >VIEW CHAPTER DOCS</Button>
                                                            </BootstrapTooltip>
                                                        ):''}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            />
                        </Col>
                        <Col xs={12} md={4} className="dashboard-flex dashboard-flex-col" style={{paddingRight: 0}}>
                            <div className="badges-container">
                                <div className="kholab-dashboard-heading"><MilitaryTechIcon /> New badges earned!</div>
                                <div className="dashboard-flex dashboard-flex-col dashboard-badge-content">
                                    {(recentBadges && recentBadges.length > 0)  ? (recentBadges.map(item=>{
                                        const badgeType = item.badge_level_name
                                    return (
                                        <div key={item.id}>
                                            <StyledBadge badgeData={item} badgeType={badgeType} />
                                            <span className="dashboard_badge_user">
                                                - {item.user_email ? item.user_email.split('@')[0] : 'N/A'} {item?.reviewed_on && (<span className="badge_timestamp"> ({ new Date(item?.reviewed_on).toISOString().split('T')[0]})</span>
                                                )}
                                            </span>
                                        </div>
                                    )
                                    })) : ''}
                                </div>
                                <div className="kholab-dashboard-actions">
                                    <BootstrapTooltip title="Click to View Badges">
                                        <Button size="small" className="idea-button" onClick={() => handleClick('/badge')}>
                                            View More
                                        </Button>
                                    </BootstrapTooltip>
                                </div>
                            </div>
                            <div className="badges-container recent-kudos">
                                <div className="kholab-dashboard-heading dashboard-flex dashboard_gap_0_5"><CelebrationIcon /> Recent Kudos!</div>
                                <div className="dashboard-flex dashboard-flex-col dashboard-kudos-content">
                                    {recentKudos.map((item) => {
                                        return (
                                            <div className="dashboard-flex dashboard-flex-col" key={item.id}>
                                                <span className="dashboard-transform-capitalize dashboard_font_weight_600">{item.receiver_email ? item.receiver_email.split('@')[0] : 'N/A'}</span>
                                                <span>
                                                {item.message && item.message.length > 120 ? (
                                                    <BootstrapTooltip title={item.message}>
                                                        &ldquo;`{item.message.substring(0, 120)}...&rdquo;
                                                    </BootstrapTooltip>
                                                ):
                                                (<>&ldquo;{item.message}&rdquo;</>)
                                                }
                                                </span>
                                                <span className="dashboard-flex dashboard-transform-capitalize dashboard-justify-end">- {item.sender_email ? item.sender_email.split('@')[0] : 'N/A'}{item?.submitted_on && (<span className="badge_timestamp"> ({ item?.submitted_on})</span>)}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="kholab-dashboard-actions">
                                    <BootstrapTooltip title="Click to Send Kudos">
                                        <Button size="small" className="idea-button" onClick={()=>handleOpenKudoModal(true)}>
                                            Send Kudos
                                        </Button>
                                    </BootstrapTooltip>
                                </div>
                            </div>
                            <div className="badges-container recent-questions">
                                <div className="kholab-dashboard-heading"><QuestionAnswerIcon /> Recently Asked Questions</div>
                                <div className="dashboard-flex dashboard-flex-col dashboard-badge-content">
                            {recentPosts.map((item, index)=>{
                                return(
                                    <BootstrapTooltip key={item.id} title={`Click to View. ${item.title} Question Detail`}>
                                        <div className="recent-questions-container" onClick={()=>handleClick(`/forum/post?q=${item.id}`)}>
                                            <span className={ index%2 ===0 ? "bg-primary recent-questions-div": "bg-success recent-questions-div" }></span>
                                            <div className="recent-questions-content">
                                                <span className="recent-questions-title">
                                                    {item.title && item.title.length > 60 ? (
                                                        <>{item.title.substring(0, 60)}...</>
                                                    ):
                                                    (<>{item.title}</>)
                                                    }
                                                </span>
                                                <span className="idea_category_badge badge-primary badge_container_desc_role_type">{item.channel}</span>
                                                <span className="recent-questions-desc">{item.user_full_name}({item.created_on ? new Date(item?.created_on).toISOString().split('T')[0] : '' })</span>
                                            </div>
                                        </div>
                                    </BootstrapTooltip>
                                )
                            })}
                                </div>
                                <div className="kholab-dashboard-actions">
                                    <BootstrapTooltip title="Click to View Q & A Forum">
                                        <Button size="small" className="idea-button" onClick={() => handleClick('/forum')}>
                                            View More
                                        </Button>
                                    </BootstrapTooltip>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        />
    )

}
