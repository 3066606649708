import React from 'react'
import HomeIcon from '@mui/icons-material/Home'
import TagIcon from '@mui/icons-material/Tag'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import './css/Sidebar.css'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

// import { MdHelpOutline } from 'react-icons/md'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Button } from 'react-bootstrap'
import HelpIcon from '@mui/icons-material/Help'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
function Sidebar({
  channel,
  setChannel,
  ifChannel,
  setCurrentPage,
  setIfChannelChange,
  collabMetadata,
}) {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleChange = (event) => {
    localStorage.setItem('channel', event.target.value)
    setChannel(event.target.value)
    setCurrentPage(0)
    setIfChannelChange(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div className="sidebar">
      <div className="sidebar-container">
        <div className="sidebar-options">
          <div className="sidebar-option">
            <div className="link">
              <div
                className="link-tag"
                style={{ borderBottom: '1px solid #ddd' }}
              >
                <h4 className="collab-head">Q&A Forum</h4>
              </div>
              <>
                {ifChannel ? (
                  <>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 140, maxWidth: 140 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Channel
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={channel}
                        onChange={handleChange}
                        label="channel"
                      >
                        {collabMetadata?.channels?.map((chapter, index) => {
                          return (
                            <MenuItem value={chapter} key={index}>
                              {chapter}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </>
                ) : null}
              </>
              <div className="link-tag">
                <HomeIcon />
                <Link to="/forum">Home</Link>
              </div>
              <div className="link-tag">
                <TagIcon />
                <Link to="/coming-soon">Tags</Link>
              </div>
              <div className="link-tag">
                <MilitaryTechIcon />
                <Link to="/badge">Badge</Link>
              </div>
              <div className="link-tag">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip">What is Q&A Forum ?</Tooltip>}
                >
                  <>
                    <HelpIcon />
                    <p
                      onClick={handleClickOpen}
                      style={{
                        color: 'rgba(0, 0, 0, 0.534)',
                        cursor: 'pointer',
                      }}
                    >
                      Help
                    </p>

                    {/* <Button
                      aria-label="how to use capacity tracker ?"
                      variant="outlined"
                      label="fd"
                      onClick={handleClickOpen}
                    >
                      <MdHelpOutline
                        style={{ fontSize: '28px', color: '#F9D949' }}
                      />
                        <Link to="/coming-soon">Tags</Link>

                    </Button> */}
                  </>
                </OverlayTrigger>

                <Dialog
                  maxWidth="md"
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-describedby="How to Use Capacity Tracker ?"
                >
                  <DialogTitle>{'How to use Collab ?'}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Typography gutterBottom component="div">
                        <Box fontWeight="700" display="inline">
                          Ask Question
                        </Box>{' '}
                        - Q&A Forum tool allows you to add questions with the
                        options of title, body and tags.
                        <br />
                        <ul>
                          <ol>
                            title- Add the relevant title for the post.
                            <br />
                            body- You can add description of your post and
                            elabrote your query in detail. you can use
                            code-blocks, links, headings and other specify tools
                            in text area.
                            <br />
                            <b>Note:</b> You can use @ to mention someone in
                            your post.
                            <br />
                            tags- You can add tags relevant with the post you
                            are adding. Click on enter after writing tag in
                            input field.
                          </ol>
                        </ul>
                      </Typography>

                      <Typography gutterBottom>
                        <Box fontWeight="700" display="inline">
                          Answer/Comment
                        </Box>{' '}
                        - You are allowed to contribute in the platform by
                        answering to the queries and give them detailed answer.
                        Also you can commnet on the post if you want to convey
                        something to the user who posted the question. user will
                        be notified via email who posted the questions.
                        <br />
                        <b>Note:</b> You can use @ to mention someone in your
                        answer.
                      </Typography>

                      <Typography gutterBottom>
                        <Box fontWeight="700" display="inline">
                          Upvote/Downvote
                        </Box>{' '}
                        - If you think any post you saw is relevant and helps
                        you as well you can give upvote by clicking on up arrow
                        showing along with question/answer. similarly if
                        something is not useful and wrong post is posted you can
                        downvote it.
                      </Typography>
                      <Typography gutterBottom>
                        <Box fontWeight="700" display="inline">
                          Search
                        </Box>{' '}
                        - You can search any post by entering the value in the
                        search field. Minimum 3 characters are allowed to
                        perform auto search.
                      </Typography>
                      <Typography gutterBottom>
                        <Box fontWeight="700" display="inline">
                          Filters
                        </Box>{' '}
                        - You can sort the questions with the given options of
                        newest/oldest and mostly viewed by click on filters
                        showing in homepage.
                      </Typography>
                      <Typography gutterBottom>
                        <Box fontWeight="700" display="inline">
                          Help
                        </Box>{' '}
                        - If you need any help you can reach out to us
                        <br />{' '}
                        <a
                          href="mailto:pankaj.maddan@kraftheinz.com"
                          className="email-link"
                        >
                          pankaj.maddan@kraftheinz.com
                        </a>
                        ,
                        <a
                          href="mailto:manvendrasingh.bais@kraftheinz.com "
                          className="email-link"
                        >
                          manvendrasingh.bais@kraftheinz.com{' '}
                        </a>
                        ,
                        <a
                          href="mailto:rohit.tiwari@kraftheinz.com"
                          className="email-link"
                        >
                          rohit.tiwari@kraftheinz.com
                        </a>
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
