import React, { useEffect, useMemo, useContext } from 'react'
import '../../RefData/RefTable.css'
import NavbarRow from '../../../Navbar'
import HelpContent from '../helpContent'
import '../index.css'
import NpsMenu from '../npsMenu'
import AdminViewForm from './adminViewForm'
import LoadingStatus from '../../../Helper/LoadingStatus'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import { Table, Button, Col, Row } from 'react-bootstrap'
import { DateHandler } from '../../Utilities/dateutils'
import { AuthContext } from '../../../context/authProvider'
import { TableHandler } from '../../../Utils'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import ShowResponse from './showSurveyResponse'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { MdHighlightOff, MdOutlineDone } from 'react-icons/md'
import { BiExport } from 'react-icons/bi'
import './surveyadmin.css'
import {
  getAdminSurvey,
  sentSurveyReminder,
  exportSurveyResponses,
} from '../../../../redux/actions/npsActions/npsSurveyAction'
import { RoleHandler } from '../../Utilities/roleCheck'
export default function SurveyAdmin() {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [editRows, setEditRows] = React.useState([])
  const [tableData, setTableData] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = React.useState(0)
  const [tableColumns, setTableColumns] = React.useState([])
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [columnFilters, setColumnFilters] = React.useState([])
  // const [surveyViewId, setSurveyViewId] = React.useState('')
  const [surveyId, setSurveyId] = React.useState('')
  const [columnSort, setColumnSortValue] = React.useState([])
  const [showModal, setshowModal] = React.useState(false)
  const [checkBox, setCheckBox] = React.useState(false)
  const [showAlertModal, setAlertModal] = React.useState(false)
  const [showResponseModal, setResponseModal] = React.useState(false)
  const [groupChoiceList, setGroupChoiceList] = React.useState([])
  const [isInitial, setIsInitial] = React.useState(false)
  const dispatch = useDispatch()
  const [authCreds] = useContext(AuthContext)

  const endpoint = authCreds.restEndpoint

  const getAdminSurveyData = useSelector((state) => state.getAdminSurveyData)

  let tokenValue = localStorage?.getItem('token')
  //checking admin role
  const isAdmin = RoleHandler.checkAdminRole(tokenValue)

  const table_name = 'USER'
  const columnMetadata = {
    USER: {
      fields: [
        {
          name: 'form_name',
          headerTitle: 'SURVEY NAME',
          is_editable: true,
          is_filterable: true,
          size: 20,
        },
        {
          name: 'start_date',
          headerTitle: 'START DATE',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'end_date',
          headerTitle: 'END DATE',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'nps_score_perc',
          headerTitle: 'NPS SCORE',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'survey_comp_perc',
          headerTitle: 'COMPLETION RATE',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'is_active',
          headerTitle: 'IS ACTIVE',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
      ],
      tag: 'user',
    },
  }
  const hiddenColumns = {
    USER: [
      'KH_UID',
      'KH_ETL_IS_DELETED',
      'KH_MODIFIED_ON',
      'KH_MODIFIED_BY',
      'KH_CREATED_ON',
      'KH_CREATED_BY',
    ],
  }

  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    setLocalPageIndex(pageIndex)

    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value,
    }
    dispatch(getAdminSurvey(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  useEffect(() => {
    let table_data = getAdminSurveyData?.getAdminSurveyData?.data
    if (table_data?.length > 0) {
      setTableColumns(
        TableHandler.createColumnMappingforKholab(
          [
            'form_name',
            'start_date',
            'end_date',
            'survey_comp_perc',
            'is_active',
          ],
          columnMetadata,
          table_name
        )
      )
      setTotalPages()
      setTotalRows()
      setTableData(table_data)
    } else {
      setTotalPages(0)
      setTotalRows(0)
      setTableData([])
    }
  }, [setTableData, getAdminSurveyData])

  const handleEdit = () => {
    if (editRows.length > 0) {
      setshowModal(true)
      // setSurveyViewId(editRows[0].survey_version_id)
      setSurveyId(editRows[0].id)
    }
  }

  const handleCloseModal = () => {
    setshowModal(false)
    fetchData({ pageSize, pageIndex }, JSON.stringify(columnFilters))
  }
  const handleAlertCloseModal = () => {
    setAlertModal(false)
  }
  const handleSendAlertInitial = () => {
    setIsInitial(true)
    setAlertModal(true)
    let groupNames = editRows[0]?.active_survey_group_list?.map((res) => {
      return res.label
    })
    setGroupChoiceList(groupNames)
  }
  const handleSendAlertReminder = () => {
    setIsInitial(false)
    setAlertModal(true)
    let groupNames = editRows[0]?.active_survey_group_list?.map((res) => {
      return res.label
    })
    setGroupChoiceList(groupNames)
  }
  const SendReminder = () => {
    setLoadingStatus({ status: true, message: 'Sending Survey Reminder...' })
    const params = {
      survey_id: editRows[0]?.id,
      is_initial: isInitial,
      is_reminder: !isInitial,
    }
    dispatch(sentSurveyReminder(endpoint, params)).then(() => {
      setLoadingStatus({ status: false, message: 'Sending Survey Reminder...' })
      setAlertModal(false)
    })
  }
  const handleResponseCloseModal = () => {
    setResponseModal(false)
  }

  const handleExportResponse = () => {
    setLoadingStatus({ status: true, message: 'Exporting Survey Response..' })
    const params = {
      survey_id: editRows[0]?.id,
    }
    const survey_name = editRows[0]?.form_name
    dispatch(exportSurveyResponses(endpoint, params, survey_name)).then(() => {
      setLoadingStatus({ status: false, message: 'Exported Successfully' })
    })
  }

  const handleResponse = () => {
    setResponseModal(true)
  }
  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="radio" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  // Deafult Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()

      let timeoutId = setTimeout(() => {
        let filter_payload =
          checkBox === false
            ? []
            : [{ op: 'eq', field: 'is_active', value: checkBox.toString() }]
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        //disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }
  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(() => tableData, [hiddenColumns, tableData])
  const columns = useMemo(() => tableColumns, [hiddenColumns, tableColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { selectedRowIds, pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: hiddenColumns[table_name],
        pageSize: 50,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: <div></div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
            const currentState = row.getToggleRowSelectedProps()
            return (
              <IndeterminateCheckbox
                {...currentState}
                onClick={() => {
                  toggleAllRowsSelected(false)
                  toggleRowSelected(row.id, !currentState.checked)
                }}
              />
            )
          },
        },
        ...columns,
      ])
    }
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }
  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }
  const handleClickCheckbox = (e) => {
    if (e.target.checked) {
      setCheckBox(true)
    } else {
      setCheckBox(false)
    }
  }
  // updates editRows state on select checkbox event
  useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])

  useEffect(() => {
    //   setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (checkBox === false) {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify(columnFilters),
        JSON.stringify(columnSort)
      )
    } else if (checkBox === true) {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([{ op: 'eq', field: 'is_active', value: 'true' }]),
        JSON.stringify(columnSort)
      )
    } else {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify(columnFilters),
        JSON.stringify(columnSort)
      )
    }
  }, [pageSize, pageIndex, columnSort, checkBox])
  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem('hidden_columns', JSON.stringify(hiddenColumns))
  }, [hiddenColumns])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <div className="main-section-nps">
            <NavbarRow title="NPS" HelpContent={HelpContent} />
            <NpsMenu />
            <div className="table-container" style={{ textAlign: 'center' }}>
              <Table {...getTableProps()}>
                <thead className="sticky-table-header">
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, i) =>
                        column?.id === 'selection' ? (
                          <th key={column.id}>
                            <div
                              {...column.getHeaderProps(
                                column.getSortByToggleProps(),
                                { style: { width: column.size } }
                              )}
                              className="table-header-badge"
                            >
                              {column.render('Header')}

                              {columnSort[0]?.field === column['Header'] ? (
                                <span>
                                  {columnSort[0]?.direction === 'asc'
                                    ? ' 🔽'
                                    : '' || columnSort[0]?.direction === 'desc'
                                    ? ' 🔼'
                                    : ''}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div>
                              {column.canFilter
                                ? column.render('Filter')
                                : null}
                            </div>
                          </th>
                        ) : column.id == 'is_active' ? (
                          <th key={i}>
                            <div className="table-header-badge">
                              {column.render('Header')}
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                onClick={handleClickCheckbox}
                                checked={checkBox}
                              />
                            </div>
                          </th>
                        ) : (
                          <th key={i}>
                            <div
                              onClick={() => handleSorting(column.id)}
                              className="table-header-badge"
                            >
                              {column.render('Header')}

                              {columnSort[0]?.field === column['id'] ? (
                                <span>
                                  {columnSort[0]?.direction === 'asc'
                                    ? ' 🔽'
                                    : '' || columnSort[0]?.direction === 'desc'
                                    ? ' 🔼'
                                    : ''}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div>
                              {column.canFilter
                                ? column.render('Filter')
                                : null}
                            </div>
                          </th>
                        )
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody className="tbody npsAdminBody" {...getTableBodyProps()}>
                  {page.map((row, index) => {
                    prepareRow(row)
                    return (
                      <tr key={index}>
                        {row.cells.map((cell) => {
                          return cell.column.id === 'is_active' ? (
                            cell.value == true ? (
                              <td
                                key={cell}
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.size + '%',
                                  },
                                })}
                              >
                                <MdOutlineDone
                                  style={{
                                    fontSize: '25px',
                                    color: 'green',
                                  }}
                                />
                              </td>
                            ) : (
                              <td
                                key={cell}
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.size + '%',
                                  },
                                })}
                              >
                                <MdHighlightOff
                                  style={{
                                    fontSize: '25px',
                                    color: 'red',
                                  }}
                                />
                              </td>
                            )
                          ) : cell.column.id == 'start_date' ? (
                            <td
                              key={cell}
                              {...cell.getCellProps({
                                style: {
                                  width: cell.column.size + '%',
                                },
                              })}
                            >
                              {DateHandler.dateTimetoDateConverter(cell.value)}
                            </td>
                          ) : cell.column.id == 'end_date' ? (
                            <td
                              key={cell}
                              {...cell.getCellProps({
                                style: {
                                  width: cell.column.size + '%',
                                },
                              })}
                            >
                              {DateHandler.dateTimetoDateConverter(cell.value)}
                            </td>
                          ) : cell.column.id == 'nps_score_perc' ? (
                            <td
                              key={cell}
                              {...cell.getCellProps({
                                style: {
                                  width: cell.column.size + '%',
                                },
                              })}
                            >
                              {cell.value}
                            </td>
                          ) : cell.column.id == 'survey_comp_perc' ? (
                            <>
                              {' '}
                              {cell.value == 100 ? (
                                <td
                                  key={cell}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                      backgroundColor: '#39ed39',
                                    },
                                  })}
                                >
                                  {Number(cell.value).toFixed(2) + ' %'}
                                </td>
                              ) : cell.value == 50 ||
                                (cell.value > 50 && cell.value < 100) ? (
                                <td
                                  key={cell}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                      backgroundColor: '#ffff78',
                                    },
                                  })}
                                >
                                  {Number(cell.value).toFixed(2) + ' %'}
                                </td>
                              ) : (
                                <td
                                  key={cell}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                      backgroundColor: '#f54545',
                                    },
                                  })}
                                >
                                  {Number(cell.value).toFixed(2) + ' %'}
                                </td>
                              )}
                            </>
                          ) 
                          : (
                            <td
                              key={cell}
                              {...cell.getCellProps({
                                style: {
                                  width: cell.column.size + '%',
                                },
                              })}
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
            {/* Bottom ToolBar of table */}
            <Row>
              <Col sm={6}>
                <div className="page-control">
                  <div className="page-of">
                    Page{' '}
                    <em>
                      {pageIndex + 1} of {totalPages}
                    </em>
                  </div>
                  <div className="prev-next-btn">
                    <button
                      className="badge-btn"
                      onClick={() => onPrevious()}
                      disabled={!canPreviousPage}
                    >
                      {' '}
                      Prev{' '}
                    </button>
                    <button
                      onClick={() => onNext()}
                      className="badge-btn"
                      disabled={!canNextPage}
                    >
                      {' '}
                      Next{' '}
                    </button>
                  </div>
                  <div className="second-control">
                    <span>Go to page:</span>
                    <input
                      className="page-number-input"
                      type="number"
                      defaultValue={pageIndex || 1}
                      onBlur={(e) => {
                        const page = e.target.value ? Number(e.target.value) : 0
                        // handling zero indexing
                        gotoPage(page - 1)
                      }}
                    />
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value))
                      }}
                    >
                      {available_page_size.map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{
                      display: 'inline-block',
                      marginLeft: '24px',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    Total results:{' '}
                    {getAdminSurveyData?.getAdminSurveyData?.total_results}
                  </div>
                </div>
              </Col>
              {/* Bottom-right Buttons */}
              <Col sm={6}>
                {(editRows.length > 0 && editRows[0].is_owner) ||
                (editRows.length > 0 && isAdmin) ? (
                  <>
                    {editRows[0]?.survey_comp_perc == '100' ? (
                      <>
                        <Button
                          className="m_r-5  main-button btn-sm"
                          onClick={handleEdit}
                          style={{ marginTop: '10px' }}
                          disabled
                        >
                          Edit Survey
                        </Button>
                      </>
                    ) : (
                      <Button
                        className="m_r-5  main-button btn-sm"
                        onClick={handleEdit}
                        style={{ marginTop: '10px' }}
                      >
                        Edit Survey
                      </Button>
                    )}
                    {editRows[0]?.survey_comp_perc == '100' ||
                    editRows[0].start_date > DateHandler.getCurrentDate() ? (
                      <>
                        <Button
                          className="m_r-5  main-button btn-sm"
                          onClick={handleSendAlertInitial}
                          style={{ marginTop: '10px' }}
                        >
                          Send Initial Email
                        </Button>
                        <Button
                          className="m_r-5  main-button btn-sm"
                          onClick={handleSendAlertReminder}
                          style={{ marginTop: '10px' }}
                          disabled
                        >
                          Send Survey or Reminder
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          className="m_r-5  main-button btn-sm"
                          onClick={handleSendAlertInitial}
                          style={{ marginTop: '10px' }}
                        >
                          Send Initial Email
                        </Button>
                        <Button
                          className="m_r-5  main-button btn-sm"
                          onClick={handleSendAlertReminder}
                          style={{ marginTop: '10px' }}
                        >
                          Send Survey or Reminder
                        </Button>
                      </>
                    )}
                    {editRows[0]?.survey_comp_perc == '0' ? (
                      <></>
                    ) : (
                      <Button
                        className="m_r-5  main-button btn-sm"
                        onClick={handleResponse}
                        style={{ marginTop: '10px' }}
                      >
                        View Survey Response
                      </Button>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {/* <Button
                  className="m_r-5 main-button btn-sm"
                  style={{ marginTop: '10px' }}
                  onClick={handleShowUserModal}
                >
                  Add User
                </Button>
                <Button
                  className="m_r-5 main-button btn-sm"
                  style={{ marginTop: '10px' }}
                  onClick={handleShowCreateSurveyGroup}
                >
                  Create Survey Group
                </Button> */}
              </Col>
            </Row>

            <Modal
              className="admin-nps-modal"
              show={showModal}
              onHide={handleCloseModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Survey</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ overflowY: 'scroll' }}>
                <AdminViewForm
                  // surveyViewId={surveyViewId}
                  surveyId={surveyId}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
                {/* <Button variant="primary">Confirm</Button> */}
              </Modal.Footer>
            </Modal>
            {!isInitial && (
              <Modal show={showAlertModal} onHide={handleAlertCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Survey Reminder</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: 'hidden' }}>
                  Reminder will be sending to{' '}
                  {groupChoiceList.map((group, index) => (
                    <span key={index}>
                      <b>[{group}]</b>{' '}
                    </span>
                  ))}{' '}
                  group members to fill out the survey form before{' '}
                  <b>
                    {DateHandler.dateTimetoDateConverter(editRows[0]?.end_date)}
                    .
                  </b>
                  <br />
                  <br />
                  <b> Are you sure you want to send the reminder?</b>
                  <br /> <br />
                  <b>
                    {' '}
                    <i>
                      Note: Reminder will be sent only to the group members who
                      have not filled out the survey. You can not send reminder
                      more than once in 24 hours.{' '}
                    </i>
                  </b>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleAlertCloseModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={SendReminder}>
                    Confirm
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            {isInitial && (
              <Modal show={showAlertModal} onHide={handleAlertCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Survey Activation Reminder</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: 'hidden' }}>
                  The survey is now active, and an initial reminder email will
                  be sent to{' '}
                  {groupChoiceList.map((group, index) => (
                    <span key={index}>
                      <b>[{group}]</b>{' '}
                    </span>
                  ))}{' '}
                  group members, prompting them to fill out the survey. Please
                  ensure the survey is filled out before the deadline on{' '}
                  <b>
                    {DateHandler.dateTimetoDateConverter(editRows[0]?.end_date)}
                    .
                  </b>
                  <br />
                  <br />
                  <b>
                    Are you sure you want to send the initial reminder email?
                  </b>
                  <br /> <br />
                  <b>
                    {' '}
                    <i>
                      Note: This is the first reminder sent upon survey
                      activation. Future reminders will be sent only to group
                      members who have not completed the survey, and reminders
                      cannot be sent more than once every 24 hours.
                    </i>
                  </b>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleAlertCloseModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={SendReminder}>
                    Send Initial Reminder
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            <Modal
              show={showResponseModal}
              onHide={handleResponseCloseModal}
              className="group-nps-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>View Responses</Modal.Title>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip">Export Survey Responses</Tooltip>
                  }
                >
                  <div style={{ position: 'absolute', right: '20px' }}>
                    <Button
                      variant="outline-primary"
                      className="export-btn m_r-5 m_t-10 "
                      onClick={handleExportResponse}
                    >
                      <span className="toolbar-icon">
                        <BiExport />
                      </span>{' '}
                      Export
                    </Button>
                  </div>
                </OverlayTrigger>
              </Modal.Header>
              <Modal.Body style={{ overflowY: 'scroll' }}>
                <Form>
                  <Row className="rowFlex" style={{ marginBottom: '20px' }}>
                    <Form.Group
                      className=""
                      as={Col}
                      controlId="validationFormik01"
                    >
                      <ShowResponse surveyId={editRows[0]?.id} />
                    </Form.Group>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleResponseCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      )}
    </>
  )
}
