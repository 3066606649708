import { useState, useRef, useContext, useEffect } from 'react'
import Input from './input'
import './index.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Navbar from '../../Navbar'
import { toast } from 'react-toastify'
import NpsMenu from './npsMenu'
import { Form } from 'react-bootstrap'
import {
  format,
  subDays,
  isBefore,
  isAfter,
  parseISO,
  isEqual,
  differenceInDays,
} from 'date-fns'

import { useDispatch } from 'react-redux'
import LoadingStatus from '../../Helper/LoadingStatus'
import { AuthContext } from '../../context/authProvider'
import { createSurvey } from '../../../redux/actions/npsActions/npsSurveyAction'
import SelectAsyncPaginate from '../Utilities/selectPaginate'
import HelpContent from './helpContent'
import ReactQuill from 'react-quill'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import ConfigureEmailModal from '../Utilities/ConfigureEmailModal'
import EditFormChapterInput from '../ChapterNPS/editFormChapterInput'
function CreateForm() {
  const [authCreds] = useContext(AuthContext)
  const [formValues, setFormValues] = useState([])
  const [toggle, setToggle] = useState(false)
  const [groupChoiceList, setGroupChoiceList] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [fieldValue, setFieldValue] = useState('text')
  const [fieldValueRating, setFieldValueRating] = useState('star')

  const [counter, setCounter] = useState(1)
  const [isPrivate, setIsprivate] = useState(null)
  const [npsValue, setNpsValue] = useState(false)
  const [description, setDescription] = useState('')
  const [npsCounter, setNpsCounter] = useState(0)
  // const [active, setActive] = useState(false)
  const [activeCheck, setActiveCheck] = useState(false)
  const [title, setTitle] = useState('')
  const [editMode, setEditMode] = useState(false)
  const [indexValue, setIndex] = useState(0)
  const [placeholderValue, setPlaceHolder] = useState('Enter your answer')
  const [labelValue, setFormLabel] = useState('')

  const [remCount, setRemCount] = useState(1)
  const [initialCheckbox, setInitialCheckbox] = useState(true)
  const [reminderCheckbox, setReminderCheckbox] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [emailSelect, setEmailSelect] = useState({
    initialEmail: {
      id: '',
      title: '',
    },
    reminderEmail: {
      id: '',
      title: '',
    },
  })
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching Metadata details...',
  })
  // get endpoint from authCreds
  const endpoint = authCreds.restEndpoint
  // useRef for input and placeholder
  const inputRef = useRef()
  const placeholderRef = useRef()
  const inputRefs = useRef([])
  const pushRef = (el) => {
    inputRefs.current.push(el)
  }
  const selectRef = useRef()
  const selectRefRating = useRef()
  // const handleRichTextChange = (def) => {
  //   setFormData({
  //     ...formData,
  //     definition: def,
  //   })
  // }
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
      ['code'],
    ],
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'code',
  ]
  // useDispatch
  const dispatch = useDispatch()
  // functions for handling form fields
  const handleChange = (e, index) => {
    const values = [...formValues]
    values[index].value = e.target.value
    setFormValues(values)
  }
  const handleDeleteField = (e, index) => {
    const values = [...formValues]
    values.splice(index, 1)
    setFormValues(values)
  }

  const clearArray = () => {
    inputRefs.current.length = 0
  }
  const handleAddField = (e) => {
    if (
      inputRef.current.value === '' ||
      inputRef.current.value === null ||
      placeholderRef?.current?.value === '' ||
      placeholderRef?.current?.value === null
    ) {
      toast.error('Please enter values')
    } else {
      e.preventDefault()
      const values = [...formValues]
      if (npsValue == true) {
        setNpsCounter(1)
      }
      let options = inputRefs?.current?.map((n) => {
        return n?.value
      })
      let newOption = options.filter(function (element) {
        return element !== undefined
      })
      let newOption1 = [...new Set(newOption)]
      if (newOption1[0] == '') {
        toast.error('Please enter options')
      } else {
        values.push({
          label: inputRef.current.value || 'label',
          placeholder: placeholderRef?.current?.value || '',
          type: selectRef.current.value || 'text',
          value: '',
          required: activeCheck,
          ratingType: fieldValueRating,
          options: newOption1 || [],
        })
        setFormValues(values)
        setToggle(false)
        setFieldValue(selectRef.current.value)
        setFieldValueRating('star')
        clearArray()
      }
    }
  }

  const addBtnClick = (e) => {
    e.preventDefault()
    setToggle(true)
    clearArray()
    setFieldValue('text')
    setCounter(1)
  }
  function optionFunc(c) {
    var MultiGold = []
    Array.from(Array(c), (e, i) => {
      MultiGold.push(
        <input
          className="options"
          type="text"
          placeholder="Add option"
          ref={pushRef}
          key={i}
          name={'option' + i}
        />
      )
    })
    return <div>{MultiGold}</div>
  }
  const handleDropdown = (selectRef) => {
    if (selectRef.current.value === 'Nps') {
      setNpsValue(true)
    }
    setFieldValue(selectRef.current.value)
  }
  const handleDropdownRating = (selectRefRating) => {
    setFieldValueRating(selectRefRating.current.value)
  }

  function toggleSwitch() {
    setActiveCheck(!activeCheck)
  }
  useEffect(() => {
    setFormValues(formValues)
  }, [labelValue, editMode, placeholderValue, indexValue, fieldValue])

  const handleEditField = (e, index) => {
    formValues.map((obj, i) => {
      if (i === index) {
        e.preventDefault()
        clearArray()
        setFieldValue(obj.type)
        setFieldValueRating(obj.ratingType)
        setFormLabel(obj.label)
        setPlaceHolder(obj.placeholder)
        setEditMode(!editMode)
        setActiveCheck(obj.required)
        setIndex(index)
      }
    })
  }
  const handleEditSave = (e) => {
    e.preventDefault()
    const values = [...formValues]

    let options = inputRefs?.current?.map((n) => {
      return n?.value
    })
    let newOption = options.filter(function (element) {
      return element !== undefined
    })
    let newOption1 = [...new Set(newOption)]
    if (newOption1[0] == '') {
      toast.error('Please enter options')
    } else {
      (values[indexValue].label = inputRef.current.value || 'label'),
        (values[indexValue].placeholder = placeholderRef?.current?.value || ''),
        (values[indexValue].type = selectRef.current.value || 'text'),
        (values[indexValue].value = ''),
        (values[indexValue].required = activeCheck),
        (values[indexValue].ratingType = fieldValueRating),
        (values[indexValue].options = newOption1 || [])

      setFormValues(values)
      // setFormValuesPreview(values)
      // setToggle(false)
      setFieldValue(selectRef.current.value)
      setFieldValueRating('star')
      clearArray()
      setEditMode(false)
      // setToggle(false)
    }
  }
  const isRemDateWithInRange = (startDate, endDate) => {
    if (isDifferenceGreaterThanOne(startDate, endDate) && reminderCheckbox) {
      const RemStartDate = format(
        subDays(parseISO(endDate), remCount),
        'yyyy-MM-dd'
      )
      return (
        isAfter(parseISO(RemStartDate), parseISO(startDate)) &&
        (isBefore(parseISO(RemStartDate), parseISO(endDate)) ||
          isEqual(parseISO(RemStartDate), parseISO(endDate)))
      )
    }
    return true
  }

  const isDifferenceGreaterThanOne = (startDate, endDate) => {
    if (!startDate || !endDate) return false
    const start = parseISO(startDate)
    const end = parseISO(endDate)
    return differenceInDays(end, start) > 1
  }

  const handleSubmit = () => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const dayDiff = (end.getTime() - start.getTime()) / (1000 * 3600 * 24)

    if ((dayDiff == 0 || dayDiff == 1) && !emailSelect.initialEmail.id) {
      toast.error('Please select an initial emails.')
    } else if (
      dayDiff > 1 &&
      (!emailSelect.initialEmail.id || !emailSelect.reminderEmail.id)
    ) {
      toast.error('Please select both initial and reminder emails.')
    } else if (startDate == undefined || endDate == undefined) {
      toast.error('Please add start and end date')
    } else if (!isRemDateWithInRange(startDate, endDate)) {
      toast.error('Reminder days should be between start date and end date')
    } else {
      const groupID = groupChoiceList.map((object) => object.id)

      setLoadingStatus({ status: true, message: 'Adding Details...' })

      const params = {
        start_date: startDate,
        end_date: endDate,
        form_json: formValues,
        form_name: title,
        description: description,
        is_private: isPrivate,
        survey_group_ids: groupID,
        initial_email_template_id: emailSelect.initialEmail.id,
        reminder_email_template_id: emailSelect.reminderEmail.id,
        scheduled_rem_days_count:
          isDifferenceGreaterThanOne(startDate, endDate) && reminderCheckbox
            ? remCount
            : -1,
        survey_tags: [
          {
            name: 'is_initial_email_checkbox',
            value: initialCheckbox,
          },
          {
            name: 'is_reminder_email_checkbox',
            value: isDifferenceGreaterThanOne(startDate, endDate)
              ? reminderCheckbox
              : false,
          },
        ],
      }
      dispatch(createSurvey(endpoint, params)).then(() => {
        setLoadingStatus({ status: false, message: 'Adding Details...' })
        setFormValues([])
        setNpsCounter(0)
        setNpsValue(false)
        setEmailSelect({
          initialEmail: {
            id: '',
            title: '',
          },
          reminderEmail: {
            id: '',
            title: '',
          },
        })
      })
    }
  }
  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  const handleInitialCheckbox = () => {
    setInitialCheckbox(!initialCheckbox)
  }
  const handleReminderCheckbox = () => {
    setReminderCheckbox(!reminderCheckbox)
  }
  // const handleChangeCheck = () => {
  //   setActive(!active)
  // }
  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="nps">
          <Navbar title="NPS" HelpContent={HelpContent} />
          <NpsMenu />
          <form className="nps-form-main">
            <h2 className="head-main-nps">Create Survey</h2>

            <input
              type="text"
              placeholder="Add Title"
              onChange={(e) => setTitle(e.target.value)}
              className="titleInput"
            />
            <textarea
              id="w3review"
              className="descInput"
              name="w3review"
              rows="4"
              cols="50"
              placeholder="Add Description"
              onChange={(e) => setDescription(e.target.value)}
            />

            {formValues.map((obj, index) => (
              <>
                <Input
                  key={index}
                  objValue={obj}
                  onChange={handleChange}
                  index={index}
                  // Add this
                  deleteField={handleDeleteField}
                  editField={handleEditField}
                  disabled={false}
                />
                {indexValue == index && editMode ? (
                  <>
                    <EditFormChapterInput
                      editMode={editMode}
                      fieldValue={fieldValue}
                      inputRef={inputRef}
                      selectRefRating={selectRefRating}
                      handleDropdownRating={handleDropdownRating}
                      selectRef={selectRef}
                      handleDropdown={handleDropdown}
                      labelValue={labelValue}
                      placeholderRef={placeholderRef}
                      placeholderValue={placeholderValue}
                      handleEditSave={handleEditSave}
                      toggleSwitch={toggleSwitch}
                      activeCheck={activeCheck}
                      counter={counter}
                      setCounter={setCounter}
                      optionFunc={optionFunc}
                      modules={modules}
                      formats={formats}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            ))}
            {!toggle ? (
              <>
                <div className="center-nps">
                  <button className="add-btn-nps-1" onClick={addBtnClick}>
                    Add new
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="dialog-box-nps">
                  <select
                    ref={selectRef}
                    onChange={() => handleDropdown(selectRef)}
                  >
                    <option value="text">Text</option>
                    <option value="textarea">Text Area</option>
                    <option value="text-read-only">Read Only Text</option>
                    <option value="radio">Radio</option>
                    <option value="checkbox">CheckBox</option>
                    <option value="rating">Rating</option>

                    {npsCounter > 0 ? (
                      <> </>
                    ) : (
                      <option value="Nps">Net Promoter Score</option>
                    )}
                  </select>
                  {fieldValue !== 'text-read-only' ? (
                    <input
                      type="text"
                      placeholder="Add label"
                      className="form-fields"
                      ref={inputRef}
                    />
                  ) : (
                    <></>
                  )}

                  {fieldValue === 'text' || fieldValue == 'textarea' ? (
                    <input
                      type="text"
                      placeholder="Add placeholder"
                      ref={placeholderRef}
                      className="form-fields"
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div style={{ marginTop: '11px', marginBottom: '11px' }}>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    onChange={toggleSwitch}
                    checked={activeCheck}
                  />
                  <label for="start" className="require">
                    {' '}
                    Required*
                  </label>
                </div>
                {fieldValue == 'radio' ? (
                  <>
                    <div className="option-block">
                      {optionFunc(counter)}
                      <button
                        type="button"
                        onClick={() => setCounter(counter + 1)}
                        className="option-btn"
                      >
                        +
                      </button>
                      {counter > 1 ? (
                        <button
                          type="button"
                          onClick={() => setCounter(counter - 1)}
                          className="option-btn"
                        >
                          -
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {fieldValue == 'checkbox' ? (
                  <>
                    <div className="option-block">
                      {optionFunc(counter)}
                      <button
                        type="button"
                        onClick={() => setCounter(counter + 1)}
                        className="option-btn"
                      >
                        +
                      </button>
                      {counter > 1 ? (
                        <button
                          type="button"
                          onClick={() => setCounter(counter - 1)}
                          className="option-btn"
                        >
                          -
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {fieldValue == 'text-read-only' ? (
                  <>
                    <ReactQuill
                      theme="snow"
                      style={{ background: '#fff' }}
                      // value={formData.definition}
                      // onChange={handleRichTextChange}
                      modules={modules}
                      formats={formats}
                      ref={inputRef}
                      className="rich-text-editor"
                      required
                    />

                    {/* <textarea
                      id="w3review"
                      className="descInput"
                      name="w3review"
                      rows="4"
                      cols="50"
                      placeholder="Add Read only text"
                      ref={inputRef}
                      // onChange={(e) => setDescription(e.target.value)}
                    /> */}
                  </>
                ) : (
                  <></>
                )}
                {fieldValue == 'rating' ? (
                  <>
                    <select
                      ref={selectRefRating}
                      onChange={() => handleDropdownRating(selectRefRating)}
                      style={{
                        width: '20%',
                        border: '1px solid #9d9eff',
                        padding: '5px',
                        outline: 'none',
                      }}
                    >
                      <option value="star">Star</option>
                      <option value="Heart">Heart</option>
                      <option value="faces">Faces</option>
                    </select>
                  </>
                ) : (
                  <></>
                )}
                <button
                  className="add-btn-nps"
                  type="button"
                  onClick={handleAddField}
                >
                  Add
                </button>
              </>
            )}
          </form>
          {formValues.length > 0 ? (
            <div className="nps-form-main">
              <h2 className="sub-head-nps">Recipients</h2>

              <div>
                <input
                  type="radio"
                  name="privateoption"
                  className="radio-btn-select"
                  onChange={() => setIsprivate(true)}
                />
                <p style={{ margin: '7px' }}>Private</p>
                <input
                  type="radio"
                  name="privateoption"
                  className="radio-btn-select"
                  style={{ marginLeft: '100px' }}
                  onChange={() => setIsprivate(false)}
                />{' '}
                <p style={{ margin: '7px' }}>Public </p>
              </div>
              {isPrivate || isPrivate == false ? (
                <>
                  <div style={{ width: '100%' }}>
                    <SelectAsyncPaginate
                      onChange={(choice) => setGroupChoiceList(choice)}
                      endpointUrl="api/survey/get_survey_group_list"
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          {formValues.length > 0 ? (
            <div className="nps-form-main">
              <h2 className="sub-head-nps">Survey Email Notification</h2>
              <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {emailSelect.initialEmail.title && (
                    <span>Initial Email: {emailSelect.initialEmail.title}</span>
                  )}
                  {emailSelect.reminderEmail.title && (
                    <span>
                      Reminder Email: {emailSelect.reminderEmail.title}
                    </span>
                  )}
                </div>
                <div className="center-nps">
                  <button className="add-btn-nps-1" onClick={handleShowModal}>
                    Configure Email
                  </button>
                </div>
              </>
              <>
                <ConfigureEmailModal
                  emailSelect={emailSelect}
                  setEmailSelect={setEmailSelect}
                  show={showModal}
                  handleClose={handleCloseModal}
                  isChapter={false}
                />
              </>
            </div>
          ) : (
            <></>
          )}
          {formValues.length > 0 ? (
            <div className="nps-form-main">
              <h2 className="sub-head-nps">Scheduling</h2>
              <>
                <div style={{ marginTop: '20px' }}>
                  <label for="start" className="date-label">
                    Start date:
                  </label>
                  <DatePicker
                    selected={startDate}
                    placeholderText="YYYY-MM-DD"
                    minDate={new Date().setDate(new Date().getDate())}
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) =>
                      setStartDate(format(date, 'yyyy-MM-dd'))
                    }
                    className="form-control date-picker-control"
                  />
                  {/* <input
                    type="date"
                    className="date-field"
                    value={startDate}
                    min={new Date().toISOString().split('T')[0]}
                    onChange={(event) => setStartDate(event.target.value)}
                  />{' '} */}
                  <label for="start" className="date-label">
                    End date:
                  </label>
                  <DatePicker
                    selected={endDate}
                    placeholderText="YYYY-MM-DD"
                    minDate={new Date().setDate(new Date().getDate())}
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) => setEndDate(format(date, 'yyyy-MM-dd'))}
                    className="form-control date-picker-control"
                  />
                  {/* <input
                    className="date-field"
                    type="date"
                    value={endDate}
                    min={new Date().toISOString().split('T')[0]}
                    onChange={(event) => setEndDate(event.target.value)}
                  />{' '} */}
                  {/* <input
                      type="checkbox"
                      className="check-field"
                      defaultChecked={active}
                      onChange={handleChangeCheck}
                    />
                    <label for="start" className="date-label">
                      Is active
                    </label> */}
                </div>
                <div style={{ marginTop: '20px' }}>
                  <input
                    type="checkbox"
                    className="check-field"
                    defaultChecked={initialCheckbox}
                    onChange={handleInitialCheckbox}
                  />
                  <label for="start" className="date-label">
                    Send Initial Reminder
                  </label>
                  <input
                    type="checkbox"
                    className="check-field"
                    defaultChecked={reminderCheckbox}
                    onChange={handleReminderCheckbox}
                    disabled={!isDifferenceGreaterThanOne(startDate, endDate)}
                  />
                  <label for="start" className="date-label">
                    Automate Scheduled Reminder
                  </label>
                </div>
                <br />
                {isDifferenceGreaterThanOne(startDate, endDate) &&
                  reminderCheckbox && (
                    <div>
                      <label for="start" className="date-label">
                        Send daily reminder
                      </label>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">
                            Enter a number, Default value: 1
                          </Tooltip>
                        }
                      >
                        <input
                          type="number"
                          value={remCount}
                          placeholder="enter a number"
                          style={{ width: '120px' }}
                          onChange={(e) => setRemCount(e.target.value)}
                        />
                      </OverlayTrigger>
                      <label for="start" className="date-label">
                        days before end date
                      </label>
                    </div>
                  )}

                <button
                  type="button"
                  className="submit-btn-nps"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  )
}
export default CreateForm
