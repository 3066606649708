import React from 'react'

import 'react-toastify/dist/ReactToastify.css'
import NavbarRow from '../../Navbar'
import SnfClusterHelp from '../Snf_cluster/SnfClusterHelp'

import SnfTable from '../Snf_cluster/snfDataTable'
const SnfClusterDataTable = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'CLUSTER',
        accessor: 'CLUSTER',
      },
      {
        Header: 'TABLE CATALOG',
        accessor: 'TABLE_CATALOG',
      },
      {
        Header: 'TABLE SCHEMA',
        accessor: 'TABLE_SCHEMA',
        Cell: ({ value }) => (
          <div
            title={value}
            style={{
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value}
          </div>
        ),
      },
      {
        Header: 'TABLE NAME',
        accessor: 'TABLE_NAME',
        is_editable: false,
        is_filterable: true,
        size: 10,
      },
      {
        Header: 'DEVELOPER EMAILS',
        accessor: 'DEVELOPER_EMAILS',
        Cell: ({ value }) => (
          <div
            title={value?.split('; ').join('\n')}
            style={{
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value?.split(';').join('\n')}
          </div>
        ),
      },
      {
        Header: 'COLUMN COUNT',
        accessor: 'COLUMN_COUNT',
      },
      {
        Header: 'RECORD COUNT',
        accessor: 'RECORD_COUNT',
      },
      {
        Header: 'REASON FOR SIMILARITY',
        accessor: 'REASON_FOR_SIMILARITY',
        Cell: ({ value }) => (
          <div
            title={value?.split(';').join('\n')}
            style={{
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value?.split(';').join('\n')}
          </div>
        ),
      },
      {
        Header: 'STATUS',
        accessor: 'STATUS',
      },
      {
        Header: 'IS MERGEABLE',
        accessor: 'IS_MERGEABLE',
        Cell: ({ value }) => (
          <div>{String(value) === 'null' ? '' : String(value)}</div>
        ),
      },
      {
        Header: 'REMARKS',
        accessor: 'REMARKS',
        Cell: ({ value }) => (
          <div
            title={value?.split(';').join('\n')}
            style={{
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value?.split(';').join('\n')}
          </div>
        ),
      },
      {
        Header: 'KH CREATED BY',
        accessor: 'KH_CREATED_BY',
        Cell: ({ value }) => (
          <div
            title={value?.split(';').join('\n')}
            style={{
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value?.split(';').join('\n')}
          </div>
        ),
      },
      {
        Header: 'KH MODIFIED ON',
        accessor: 'KH_MODIFIED_ON',
        Cell: ({ value }) => (
          <div
            title={value?.split(';').join('\n')}
            style={{
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value?.split(';').join('\n')}
          </div>
        ),
      },
      {
        Header: 'KH MODIFIED BY',
        accessor: 'KH_MODIFIED_BY',
        Cell: ({ value }) => (
          <div
            title={value?.split(';').join('\n')}
            style={{
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value?.split(';').join('\n')}
          </div>
        ),
      },
      // add more columns here
    ],
    []
  )

  return (
    <>
      <React.Fragment>
        <div className="nps">
          <NavbarRow
            title={'Snowflake Clustering'}
            HelpContent={SnfClusterHelp}
          />
          <div
            class="container-fluid bg-white text-dark"
            style={{ height: 'fit-content', paddingTop: '20px', width: '100%' }}
          >
            <SnfTable columns={columns} />
          </div>
        </div>
      </React.Fragment>
    </>
  )
}

export default SnfClusterDataTable
