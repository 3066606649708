import React, { useEffect, useMemo, useContext } from 'react'
import '../../RefData/RefTable.css'
import ReactQuill from 'react-quill'
import '../index.css'
import '../../../../App.css'
import LoadingStatus from '../../../Helper/LoadingStatus'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import { Table, Button, Col, Row } from 'react-bootstrap'
import { AuthContext } from '../../../context/authProvider'
import { TableHandler } from '../../../Utils'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import { ForumHandler } from '../../../Utils'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import { MdHighlightOff, MdOutlineDone, MdFileDownload } from 'react-icons/md'
import {
  addUserstoSurveyGroup,
  enableDisableUserInGroup,
  getSurveyGroupMembers,
  getValidUsers,
} from '../../../../redux/actions/npsActions/npsSurveyAction'
import { toast } from 'react-toastify'
import MentionHandler from '../../Utilities/quillSearch'
import * as XLSX from 'xlsx';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { handleDownload } from '../../Utilities/exportTemplate'

export default function ManageSurveyGroup({ groupNameUser, groupId }) {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  const [editRows, setEditRows] = React.useState([])
  const [tableData, setTableData] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = React.useState(0)
  const [tableColumns, setTableColumns] = React.useState([])
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [columnFilters, setColumnFilters] = React.useState([
    { op: 'eq', field: 'group_name', value: `${groupNameUser}` },
  ])
  const [columnSort, setColumnSortValue] = React.useState([])
  const [isSort, setSort] = React.useState(false)
  const [showModal, setshowModal] = React.useState(false)
  const [addusermodal, setshowUserModal] = React.useState(false)
  const [editorContent, setEditorContent] = React.useState('')
  const [checkBox, setCheckBox] = React.useState(false)
  const [validEmails, setValidEmails] = React.useState([]);
  const [invalidEmails, setInvalidEmails] = React.useState([]);
  const [showResults, setShowResults] = React.useState(false);
  const [authCreds] = useContext(AuthContext)

  const dispatch = useDispatch()

  const endpoint = authCreds.restEndpoint

  const getSurveyGroupMemberData = useSelector(
    (state) => state.getSurveyGroupMemberData
  )

  const getValidUserEmailData = useSelector(
    (state) => state.getValidUserEmailData
  )
  let userFetchTimeOut

  const table_name = 'USER'
  const columnMetadata = {
    USER: {
      fields: [
        {
          name: 'user_email',
          headerTitle: 'USER EMAIL',
          is_editable: true,
          is_filterable: true,
          size: 20,
        },
        {
          name: 'user_name',
          headerTitle: 'USER NAME',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'role_type',
          headerTitle: 'ROLE TYPE',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'title',
          headerTitle: 'TITLE',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'khc_group',
          headerTitle: 'KHC GROUP',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'is_active',
          headerTitle: 'ACTIVE USER',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
      ],
      tag: 'user',
    },
  }
  const hiddenColumns = {
    USER: [
      'KH_UID',
      'KH_ETL_IS_DELETED',
      'KH_MODIFIED_ON',
      'KH_MODIFIED_BY',
      'KH_CREATED_ON',
      'KH_CREATED_BY',
    ],
  }

  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = [{ op: 'eq', field: 'group_name', value: `${groupNameUser}` }],
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    setLocalPageIndex(pageIndex)

    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value,
    }
    dispatch(getSurveyGroupMembers(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  
  const handleExcelDownload = (e) => {
    e.preventDefault();

    const cols = [
      'user_email', 
    ]

    handleDownload(cols)
  } 

  useEffect(() => {
    setValidEmails(getValidUserEmailData?.getValidUserEmailData?.valid_emails)
    setInvalidEmails(getValidUserEmailData?.getValidUserEmailData?.invalid_emails)
    setShowResults(true)

  }, [getValidUserEmailData])

  useEffect(() => {
    let table_data = getSurveyGroupMemberData?.getSurveyGroupMemberData?.data
    if (table_data?.length > 0) {
      setTableColumns(
        TableHandler.createColumnMappingforKholab(
          [
            'user_email',
            'user_name',
            'title',
            'role_type',
            'company',
            'khc_group',
            'city',
            'country',
            'is_active',
          ],
          columnMetadata,
          table_name
        )
      )
      setTotalPages()
      setTotalRows()
      setTableData(table_data)
    } else {
      setTotalPages(0)
      setTotalRows(0)
      setTableData([])
    }
  }, [setTableData, getSurveyGroupMemberData])

  const handleShowUserModal = (value) => {
    setshowUserModal(true)
    setValidEmails([])
    setInvalidEmails([])
    setShowResults(false)
  }

  const EnableDisableUser = (value) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    const params = {
      survey_group_id: groupId,
      user_emails: [editRows[0].user_email],
    }
    dispatch(enableDisableUserInGroup(endpoint, value, params)).then(() => {
      if (value === 'True') {
        toast.success('User Enabled')
      } else {
        toast.success('User Disabled')
      }
      fetchData({ pageSize, pageIndex }, JSON.stringify(columnFilters))
    })
  }
  const handleCloseModal = () => {
    setshowModal(false)
  }

  const handleCloseUserModal = () => {
    setshowUserModal(false)
  }

  const handleQuill = (value) => {
    setEditorContent(value)
  }
  const quillSearch = (searchTerm, renderList, mentionChar) => {
    clearTimeout(userFetchTimeOut)
    if (searchTerm.length >= 3) {
      userFetchTimeOut = setTimeout(() => {
        ForumHandler.fetchUserData(
          endpoint,
          searchTerm,
          renderList,
          mentionChar
        )
      }, 1000)
    }
  }


  const handleFileUpload = (e) => {
    var file = e.target.files[0];

    if (!file) {
      alert("Please select a file.");
      return;
    } else if (!file.name.endsWith(".xlsx") && !file.name.endsWith(".csv")) {
      alert("Invalid file type. Please upload an .xlsx or .csv file.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(sheet);

      if (!json.length || !Object.prototype.hasOwnProperty.call(json[0], "user_email")) {
        alert("File must have atleast 1 column containing emails with header 'user_email'.");
        return;
      }

      const emailList = json.map((row) => row.user_email).filter((email) => email);

      const payload = {
        user_emails: emailList,
      }
      dispatch(getValidUsers(endpoint, payload))
    };

    reader.readAsArrayBuffer(file);
  };

  const module = MentionHandler.mentionconfig(quillSearch)

  const handleSubmit = async () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi

    const matches = editorContent.match(regex)
    let mentionVal = matches ? matches.map((mention) => mention.slice(0)) : []

    const payload = {
      survey_group_id: groupId,
      user_emails: [...mentionVal, ...validEmails]
    }

    dispatch(addUserstoSurveyGroup(endpoint, payload))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })

        setshowUserModal(false)

        fetchData({ pageSize, pageIndex }, JSON.stringify(columnFilters))
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    setSort(true)

    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="radio" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  // Deafult Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()

      let timeoutId = setTimeout(() => {
        let filter_payload = [
          { op: 'eq', field: 'group_name', value: `${groupNameUser}` },
        ]
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }
  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(() => tableData, [hiddenColumns, tableData])
  const columns = useMemo(() => tableColumns, [hiddenColumns, tableColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { selectedRowIds, pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: hiddenColumns[table_name],
        pageSize: 50,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: <div></div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
            const currentState = row.getToggleRowSelectedProps()
            return (
              <IndeterminateCheckbox
                {...currentState}
                onClick={() => {
                  toggleAllRowsSelected(false)
                  toggleRowSelected(row.id, !currentState.checked)
                }}
              />
            )
          },
        },
        ...columns,
      ])
    }
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }
  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }
  const handleClickCheckbox = (e) => {
    if (e.target.checked) {
      setCheckBox(true)
    } else {
      setCheckBox(false)
    }
  }
  // updates editRows state on select checkbox event
  useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])

  useEffect(() => {
    //   setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (checkBox === false) {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify(columnFilters),
        JSON.stringify(columnSort)
      )
    } else if (checkBox === true) {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([
          { op: 'eq', field: 'is_active', value: 'true' },
          { op: 'eq', field: 'group_name', value: `${groupNameUser}` },
        ]),
        JSON.stringify(columnSort)
      )
    } else {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify(columnFilters),
        JSON.stringify(columnSort)
      )
    }
  }, [pageSize, pageIndex, columnSort, checkBox])
  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem('hidden_columns', JSON.stringify(hiddenColumns))
  }, [hiddenColumns])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <div style={{ background: '#fff' }}>
            <div className="table-container" style={{ textAlign: 'center' }}>
              <Table {...getTableProps()}>
                <thead className="sticky-table-header">
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, i) =>
                        column?.id === 'selection' ? (
                          <th key={column.id}>
                            <div
                              {...column.getHeaderProps(
                                column.getSortByToggleProps(),
                                { style: { width: column.size } }
                              )}
                              className="table-header-badge"
                            >
                              {column.render('Header')}

                              {columnSort[0]?.field === column['Header'] ? (
                                <span>
                                  {columnSort[0]?.direction === 'asc'
                                    ? ' 🔽'
                                    : '' || columnSort[0]?.direction === 'desc'
                                      ? ' 🔼'
                                      : ''}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div>
                              {column.canFilter
                                ? column.render('Filter')
                                : null}
                            </div>
                          </th>
                        ) : column.id == 'is_active' ? (
                          <th key={i}>
                            <div className="table-header-badge">
                              {column.render('Header')}
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                onClick={handleClickCheckbox}
                                checked={checkBox}
                              />
                            </div>
                          </th>
                        ) : (
                          <th key={i}>
                            <div
                              onClick={() => handleSorting(column.id)}
                              className="table-header-badge"
                            >
                              {column.render('Header')}

                              {columnSort[0]?.field === column['id'] ? (
                                <span>
                                  {columnSort[0]?.direction === 'asc'
                                    ? ' 🔽'
                                    : '' || columnSort[0]?.direction === 'desc'
                                      ? ' 🔼'
                                      : ''}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div>
                              {column.canFilter
                                ? column.render('Filter')
                                : null}
                            </div>
                          </th>
                        )
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="tbody npsgroupmembers"
                  {...getTableBodyProps()}
                >
                  {page.map((row, index) => {
                    prepareRow(row)
                    return (
                      <tr key={index}>
                        {row.cells.map((cell) => {
                          return cell.column.id === 'is_active' ? (
                            cell.value == true ? (
                              <td
                                key={cell}
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.size + '%',
                                  },
                                })}
                              >
                                <MdOutlineDone
                                  style={{
                                    fontSize: '25px',
                                    color: 'green',
                                  }}
                                />
                              </td>
                            ) : (
                              <td
                                key={cell}
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.size + '%',
                                  },
                                })}
                              >
                                <MdHighlightOff
                                  style={{
                                    fontSize: '25px',
                                    color: 'red',
                                  }}
                                />
                              </td>
                            )
                          ) : (
                            <td
                              key={cell}
                              {...cell.getCellProps({
                                style: {
                                  width: cell.column.size + '%',
                                },
                              })}
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
            {/* Bottom ToolBar of table */}
            <Row>
              <Col sm={6}>
                <div className="page-control">
                  <div className="page-of">
                    Page{' '}
                    <em>
                      {pageIndex + 1} of {totalPages}
                    </em>
                  </div>
                  <div className="prev-next-btn">
                    <button
                      className="badge-btn"
                      onClick={() => onPrevious()}
                      disabled={!canPreviousPage}
                    >
                      {' '}
                      Prev{' '}
                    </button>
                    <button
                      onClick={() => onNext()}
                      className="badge-btn"
                      disabled={!canNextPage}
                    >
                      {' '}
                      Next{' '}
                    </button>
                  </div>
                  <div className="second-control">
                    <span>Go to page:</span>
                    <input
                      className="page-number-input"
                      type="number"
                      defaultValue={pageIndex || 1}
                      onBlur={(e) => {
                        const page = e.target.value ? Number(e.target.value) : 0
                        // handling zero indexing
                        gotoPage(page - 1)
                      }}
                    />
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value))
                      }}
                    >
                      {available_page_size.map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{
                      display: 'inline-block',
                      marginLeft: '24px',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    Total results:{' '}
                    {
                      getSurveyGroupMemberData?.getSurveyGroupMemberData
                        ?.total_results
                    }
                  </div>
                </div>
              </Col>
              {/* Bottom-right Buttons */}
              <Col sm={6}>
                {editRows.length > 0 && editRows[0].is_active == true ? (
                  <>
                    <Button
                      className="m_r-5 = main-button btn-sm"
                      onClick={() => EnableDisableUser('False')}
                      style={{ marginTop: '10px' }}
                    >
                      Disable
                    </Button>
                  </>
                ) : editRows[0]?.is_active == false ? (
                  <Button
                    className="m_r-5 = main-button btn-sm"
                    onClick={() => EnableDisableUser('True')}
                    style={{ marginTop: '10px' }}
                  >
                    Enable
                  </Button>
                ) : (
                  <></>
                )}

                <Button
                  className="m_r-5 main-button btn-sm"
                  style={{ marginTop: '10px' }}
                  onClick={handleShowUserModal}
                >
                  Add User
                </Button>
              </Col>
            </Row>

            <Modal size="xl" show={addusermodal} onHide={handleCloseUserModal}>
              <Modal.Header closeButton>
                <Modal.Title>Add users</Modal.Title>
              </Modal.Header>
              <Modal.Body className="modalBody" >
                <>
                  <Form>
                    <Container className="m-0">
                      <Row
                        className="mr-3 rowFlex"
                        style={{
                          marginBottom: '4rem',
                          justifyContent: 'center',
                        }}
                      >
                        <Form.Group
                          className=""
                          as={Col}
                          md="4"
                          style={{ width: '70%' }}
                          controlId="validationFormik01"
                        >
                          <div className='addUser'>
                            <div style={{ width: '40%', paddingRight: '15px' }}>
                              <label style={{ marginBottom: '0.5rem' }}>Add Users</label>
                              <ReactQuill
                                style={{ background: '#fff' }}
                                theme="snow"
                                className="react-quill-badge group-quill"
                                defaultValue={editorContent}
                                onChange={handleQuill}
                                modules={module}
                                placeholder="add users using @ operator"
                              // formats={allowedFormats}
                              />
                            </div>
                            <div
                              style={{ width: '20%', textAlign: 'center', }}
                              as={Col}
                              md="4"
                            >
                              {'(OR)'}
                            </div>
                            <div
                              style={{ width: '40%', paddingLeft: '15px' }}
                              as={Col}
                              md="4"
                            >
                              <div>
                                <Form>
                                  <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label className="frm-label">
                                      <p>Select users from file (.xlsx or .csv)</p>
                                    </Form.Label>
                                    <div style={{display: 'flex', gap: '1rem' }}>
                                      <OverlayTrigger
                                        placement="top-end"
                                        overlay={<Tooltip id="tooltip">User emails must be in column with header: user_email </Tooltip>}
                                      >
                                        <Form.Control
                                          type="file"
                                          size="md"
                                          onChange={handleFileUpload}
                                        />
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="right-start"
                                        overlay={
                                          <Tooltip id="tooltip">Export Sample file to add users</Tooltip>
                                        }
                                      >
                                        <button
                                          className="btn btn-outline-primary"
                                          onClick={handleExcelDownload}
                                          style={{ padding: '0.1rem 0.5rem' }}
                                        >
                                          <span className="toolbar-icon">
                                            <MdFileDownload />
                                          </span>
                                        </button>
                                      </OverlayTrigger>
                                    </div>
                                  </Form.Group>
                                </Form>
                              </div>
                            </div>
                          </div>
                        </Form.Group>
                      </Row>
                    </Container>
                  </Form>
                  <div className='resVal' >
                    {showResults && (validEmails || invalidEmails) && (<div style={{ width: '50%' }}>
                      <hr />
                      <h4>Validation Results:</h4>
                      <p style={{ color: 'gray' }}>(Note: Only valid user emails will be added to the group.)</p>
                      <p style={{ color: 'green' }}>Valid Emails Count: {validEmails.length}</p>
                      <p style={{ color: 'red' }}>Invalid Emails Count: {invalidEmails.length}</p>
                      <hr />
                      {invalidEmails.length > 0 && (<div>
                        <h5>Invalid Emails:</h5>
                        <ul>
                          {invalidEmails.map((email, index) => (
                            <li style={{ color: 'red' }} key={index}>{email}</li>
                          ))}
                        </ul>
                      </div>)}
                    </div>)}
                  </div>

                  <Modal.Footer>
                    <Button
                      variant="primary"
                      type="submit"
                      className="addModalBtn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="danger"
                      type="submit"
                      className="addModalBtn"
                      onClick={handleCloseUserModal}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </>
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
    </>
  )
}
