export const GET_TABLES = 'GET_TABLES'
export const GET_REF_TABLES_DATA = 'GET_REF_TABLES_DATA'
export const GET_REF_TABLES_DATA_LOADING = 'GET_REF_TABLES_DATA_LOADING'
export const GET_TABLES_DATA_LOADING = 'GET_TABLES_DATA_LOADING'
export const GET_TABLES_DATA_ERROR = 'GET_TABLES_DATA_ERROR'
export const GET_META_DATA_LOADING = 'GET_META_DATA_LOADING'
export const GET_META_DATA = 'GET_META_DATA'
export const GET_META_DATA_ERROR = 'GET_META_DATA_ERROR'
export const GET_META_DATA_BY_TABLE = 'GET_META_DATA_BY_TABLE'
export const DELETE_REF_TABLES_DATA = 'DELETE_REF_TABLES_DATA'
export const GET_ADD_ROW_ERROR = 'GET_ADD_ROW_ERROR'
export const TABLE_ERROR = 'TABLE_ERROR'
export const GET_REF_TABLES_DATA_ERROR = 'GET_REF_TABLES_DATA_ERROR'
export const CLEAR_STATE = 'CLEAR_STATE'
export const FILE_LOADING = 'FILE_LOADING'
export const FILE_IMPORT_SUCCESS = 'FILE_IMPORT_SUCCESS'
export const FILE_IMPORT_ERROR = 'FILE_IMPORT_ERROR'
export const ADD_REF_TABLE_DATA = 'ADD_REF_TABLE_DATA'
export const EDIT_REF_TABLE_DATA = 'EDIT_REF_TABLE_DATA'
export const EDIT_REF_TABLE_DATA_LOADING = 'EDIT_REF_TABLE_DATA_LOADING'
export const EDIT_REF_TABLE_DATA_ERROR = 'EDIT_REF_TABLE_DATA_ERROR'
export const FILE_EXPORT_SUCCESS = 'FILE_EXPORT_SUCCESS'
export const FILE_EXPORT_ERROR = 'FILE_EXPORT_ERROR'
export const GET_META_DATA_BY_PICKLIST = 'GET_META_DATA_BY_PICKLIST'
export const CLEAR_STATE_META = 'CLEAR_STATE_META'
export const ADD_REF_TABLE_DATA_LOADING = 'ADD_REF_TABLE_DATA_LOADING'
export const GET_JIRA_TRACKER_ENTRIES = 'GET_JIRA_TRACKER_ENTRIES'
export const GET_JIRA_TRACKER_ENTRIES_LOADING =
  'GET_JIRA_TRACKER_ENTRIES_LOADING'
export const GET_JIRA_TRACKER_ENTRIES_ERROR = 'GET_JIRA_TRACKER_ENTRIES_ERROR'
export const GET_SPRINT_CALENDAR = 'GET_SPRINT_CALENDAR'
export const GET_MODAL_ERROR = 'GET_IT_PARTNER_ERROR'
export const GET_MODAL_LOADING = 'GET_IT_PARTNER_LOADING'
export const CREATE_JIRA_TRACKER_ENTRY = 'CREATE_JIRA_TRACKER_ENTRY'
export const CREATE_JIRA_TRACKER_ENTRY_LOADING =
  'CREATE_JIRA_TRACKER_ENTRY_LOADING'
export const CREATE_JIRA_TRACKER_ENTRY_ERROR = 'CREATE_JIRA_TRACKER_ENTRY_ERROR'
export const EDIT_JIRA_TRACKER_ENTRY = 'EDIT_JIRA_TRACKER_ENTRY'
export const EDIT_JIRA_TRACKER_ENTRY_LOADING = 'EDIT_JIRA_TRACKER_ENTRY_LOADING'
export const EDIT_JIRA_TRACKER_ENTRY_ERROR = 'EDIT_JIRA_TRACKER_ENTRY_ERROR'
export const GET_JIRA_MODAL_METADATA = 'GET_JIRA_MODAL_METADATA'
export const GET_JIRA_MODAL_METADATA_LOADING = 'GET_JIRA_MODAL_METADATA_LOADING'
export const GET_JIRA_MODAL_METADATA_ERROR = 'GET_JIRA_MODAL_METADATA_ERROR'
export const GET_CURRENT_SPRINT = 'GET_CURRENT_SPRINT'
export const GET_CURRENT_SPRINT_LOADING = 'GET_CURRENT_SPRINT_LOADING'
export const GET_CURRENT_SPRINT_ERROR = 'GET_CURRENT_SPRINT_ERROR'
export const ADD_RESET_STATE = 'ADD_RESET_STATE'
export const EDIT_RESET_STATE = 'EDIT_RESET_STATE'
export const ADD_COLLAB_POST = 'ADD_COLLAB_POST'
export const ADD_COLLAB_POST_LOADING = 'ADD_COLLAB_POST_LOADING'
export const ADD_COLLAB_ERROR = 'ADD_COLLAB_ERROR'
export const ADD_KUDOS_ENTRY_LOADING = 'ADD_KUDOS_ENTRY_LOADING'
export const ADD_KUDOS_ENTRY = 'ADD_KUDOS_ENTRY'
export const ADD_KUDOS_ERROR = 'ADD_KUDOS_ERROR'
export const GET_USER_KUDOS_LOADING = 'GET_USER_KUDOS_LOADING'
export const GET_USER_KUDOS = 'GET_USER_KUDOS'
export const GET_USER_KUDOS_ERROR = 'GET_USER_KUDOS_ERROR'
export const GET_COLLAB_LOADING = 'GET_COLLAB_LOADING'
export const GET_COLLAB_POST = 'GET_COLLAB_POST'
export const GET_COLLAB_ERROR = 'GET_COLLAB_ERROR'
export const GET_TAGS_LOADING = 'GET_TAGS_LOADING'
export const GET_TAG_ERROR = 'GET_TAG_ERROR'
export const GET_POPULAR_TAGS = 'GET_POPULAR_TAGS'
export const ADD_ANSWER_LOAD = 'ADD_ANSWER_LOAD'
export const ADD_ANSWER_POST = 'ADD_ANSWER_POST'
export const ADD_ANSWER_ERROR = 'ADD_ANSWER_ERROR'
export const ADD_COMMENT_ERROR = 'ADD_COMMENT_ERROR'
export const ADD_COMMENT_LOAD = 'ADD_COMMENT_LOAD'
export const ADD_COMMENT_POST = 'ADD_COMMENT_POST'
export const ADD_VOTE_POST = 'ADD_VOTE_POST'
export const ADD_VOTE_LOAD = 'ADD_VOTE_LOAD'
export const ADD_VOTE_ERROR = 'ADD_VOTE_ERROR'
export const GET_POPULAR_ANSWER = 'GET_POPULAR_ANSWER'
export const GET_ANSWER_ERROR = 'GET_ANSWER_ERROR'
export const GET_ANSWER_LOADING = 'GET_ANSWER_LOADING'
export const EDIT_COLLAB_POST = 'EDIT_COLLAB_POST'
export const EDIT_COLLAB_POST_LOADING = 'EDIT_COLLAB_POST_LOADING'
export const EDIT_COLLAB_ERROR = 'EDIT_COLLAB_ERROR'
export const GET_POST_BY_ID_LOAD = 'GET_POST_BY_ID_LOAD'
export const GET_POST_BY_ID_ERROR = 'GET_POST_BY_ID_ERROR'
export const GET_POST_BY_ID_DATA = 'GET_POST_BY_ID_DATA'
export const GET_TABLES_PROFILE_ERROR = 'GET_TABLES_PROFILE_ERROR'
export const GET_TABLES_PROFILE = 'GET_TABLES_PROFILE'
export const GET_TABLES_PROFILE_LOADING = 'GET_TABLES_PROFILE_LOADING'
export const ADD_SUBSCRIPTION_LOADING = 'ADD_SUBSCRIPTION_LOADING'
export const ADD_SUBSCRIPTION_ERROR = 'ADD_SUBSCRIPTION_ERROR'
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION'
export const GET_AZURE_OCR_PDF_LOADING = 'GET_AZURE_OCR_PDF_LOADING'
export const GET_AZURE_OCR_PDF_DATA = 'GET_AZURE_OCR_PDF_DATA'
export const GET_AZURE_OCR_PDF_ERROR = 'GET_AZURE_OCR_PDF_ERROR'
export const GET_COLLAB_USER = 'GET_COLLAB_USER'
export const GET_COLLAB_USER_ERROR = 'GET_COLLAB_USER_ERROR'
export const GET_COLLAB_USER_LOAD = 'GET_COLLAB_USER_LOAD'
export const GET_AZURE_OCR_MODELS = 'GET_AZURE_OCR_MODELS'
export const GET_AZURE_OCR_MODELS_LOADING = 'GET_AZURE_OCR_MODELS_LOADING'
export const GET_AZURE_OCR_MODELS_ERROR = 'GET_AZURE_OCR_MODELS_ERROR'
export const COLLAB_RESET = 'COLLAB_RESET'
export const GET_BADGES_USER = 'GET_BADGES_USER'
export const GET_BADGES_USER_LOAD = 'GET_BADGES_USER_LOAD'
export const GET_BADGES_USER_ERROR = 'GET_BADGES_USER_ERROR'
export const GET_BADGES_ADMIN = 'GET_BADGES_ADMIN'
export const GET_BADGES_ADMIN_ERROR = 'GET_BADGES_ADMIN_ERROR'
export const GET_ADMIN_BADGES_LOAD = 'GET_ADMIN_BADGES_LOAD'
export const GET_MY_BADGES_LOAD = 'GET_MY_BADGES_LOAD'
export const GET_MY_BADGES = 'GET_MY_BADGES'
export const GET_MY_BADGES_ERROR = 'GET_MY_BADGES_ERROR'
export const GET_PENDING_BADGES_LOAD = 'GET_PENDING_BADGES_LOAD'
export const GET_PENDING_BADGES = 'GET_PENDING_BADGES'
export const GET_PENDING_BADGE_ERROR = 'GET_PENDING_BADGE_ERROR'
export const START_FNF_LOADING = 'START_FNF_LOADING'
export const STOP_FNF_LOADING = 'STOP_FNF_LOADING'
export const GET_FNF_ERROR = 'GET_FNF_STEP0_SUMMARY_ERROR'
export const GET_FNF_STEP0_SUMMARY_DATA = 'GET_FNF_STEP0_SUMMARY_DATA'
export const GET_FNF_STEP05_SUMMARY_DATA = 'GET_FNF_STEP05_SUMMARY_DATA'
export const GET_FNF_STEP1_SUMMARY_DATA = 'GET_FNF_STEP1_SUMMARY_DATA'
export const GET_FNF_STEP0_LIKELIHOOD_DATA = 'GET_FNF_STEP0_LIKELIHOOD_DATA'
export const GET_FNF_STEP05_LIKELIHOOD_DATA = 'GET_FNF_STEP05_LIKELIHOOD_DATA'
export const GET_FNF_STEP1_LIKELIHOOD_DATA = 'GET_FNF_STEP1_LIKELIHOOD_DATA'
export const GET_FNF_STEP0_UNIQUEVALUES_DATA = 'GET_FNF_STEP0_UNIQUEVALUES_DATA'
export const GET_FNF_STEP05_UNIQUEVALUES_DATA =
  'GET_FNF_STEP05_UNIQUEVALUES_DATA'
export const GET_FNF_STEP1_UNIQUEVALUES_DATA = 'GET_FNF_STEP1_UNIQUEVALUES_DATA'
export const GET_FNF_ORDER_ITEMS_DATA = 'GET_FNF_ORDER_ITEMS_DATA'
export const CLEAR_FNF_ITEMS_DATA = 'CLEAR_FNF_ITEMS_DATA'
export const GET_FNF_GRID_RULES = 'GET_FNF_GRID_RULES'
export const GET_FNF_GRID_UNIQUEVALUES_DATA = 'GET_FNF_GRID_UNIQUEVALUES_DATA'
export const ADD_BADGE_LOAD = 'ADD_BADGE_LOAD'
export const ADD_BADGE_POST = 'ADD_BADGE_POST'
export const ADD_BADGE_ERROR = 'ADD_BADGE_ERROR'
export const EDIT_BADGE_LOAD = 'EDIT_BADGE_LOAD'
export const EDIT_BADGE_ERROR = 'EDIT_BADGE_ERROR'
export const EDIT_BADGE_POST = 'EDIT_BADGE_POST'
export const GET_SUMMARY_BADGES_LOAD = 'GET_SUMMARY_BADGES_LOAD'
export const GET_BADGE_TOP_EARNERS = 'GET_BADGE_TOP_EARNERS'
export const GET_BADGE_TOP_EARNERS_LOAD = 'GET_BADGE_TOP_EARNERS_LOAD'
export const GET_BADGE_TOP_EARNERS_ERROR = 'GET_BADGE_TOP_EARNERS_ERROR'
export const GET_SUMMARY_BADGES = 'GET_SUMMARY_BADGES'
export const GET_SUMMARY_BADGE_ERROR = 'GET_SUMMARY_BADGE_ERROR'
export const FILE_EXPORT_BADGE_ERROR = 'FILE_EXPORT_BADGE_ERROR'
export const FILE_EXPORT_BADGE_SUCCESS = 'FILE_EXPORT_BADGE_SUCCESS'
export const FILE_LOADING_BADGE = 'FILE_LOADING_BADGE'
export const EDIT_MY_BADGE_ERROR = 'EDIT_MY_BADGE_ERROR'
export const EDIT_MY_BADGE_LOAD = 'EDIT_MY_BADGE_LOAD'
export const EDIT_MY_BADGE_POST = 'EDIT_MY_BADGE_POST'
export const BADGE_REQUEST_POST = 'BADGE_REQUEST_POST'
export const BADGE_REQUEST_ERROR = 'BADGE_REQUEST_ERROR'
export const BADGE_REQUEST_LOAD = 'BADGE_REQUEST_LOAD'
export const EDIT_BADGE_APPROVAL_LOAD = 'EDIT_BADGE_APPROVAL_LOAD'
export const EDIT_BADGE_APPROVAL_ERROR = 'EDIT_BADGE_APPROVAL_ERROR'
export const EDIT_BADGE_APPROVAL_POST = 'EDIT_BADGE_APPROVAL_POST'
export const IS_APPROVER_ERROR = 'IS_APPROVER_ERROR'
export const IS_APPROVER_LOAD = 'IS_APPROVER_LOAD'
export const IS_APPROVER_POST = 'IS_APPROVER_POST'
export const GET_MOST_UPVOTED_POSTS = 'GET_MOST_UPVOTED_POSTS'
export const GET_MOST_UPVOTED_POSTS_LOADING = 'GET_MOST_UPVOTED_POSTS_LOADING'
export const GET_MOST_UPVOTED_POSTS_ERROR = 'GET_MOST_UPVOTED_POSTS_ERROR'
export const GET_RECENT_POSTS = 'GET_RECENT_POSTS'
export const GET_RECENT_POSTS_LOADING = 'GET_RECENT_POSTS_LOADING'
export const GET_RECENT_POSTS_ERROR = 'GET_RECENT_POSTS_ERROR'
export const BADGE_RESET = 'BADGE_RESET'
export const DELETE_BADGE_ERROR = 'DELETE_BADGE_ERROR'
export const DELETE_BADGE = 'DELETE_BADGE'
export const DELETE_BADGE_LOAD = 'DELETE_BADGE_LOAD'
export const GET_DBT_REPORT_ERROR = 'GET_DBT_REPORT_ERROR'
export const GET_DBT_REPORT = 'GET_DBT_REPORT'
export const GET_DBT_REPORT_LOADING = 'GET_DBT_REPORT_LOADING'
export const GET_ALL_USER_ERROR = 'GET_ALL_USER_ERROR'
export const GET_ALL_USER = 'GET_ALL_USER'
export const GET_ALL_USER_LOAD = 'GET_ALL_USER_LOAD'
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR'
export const EDIT_USER = 'EDIT_USER'
export const EDIT_USER_LOAD = 'EDIT_USER_LOAD'
export const GET_FORUM_METADATA = 'GET_FORUM_METADATA'
export const GET_FORUM_METADATA_LOADING = 'GET_FORUM_METADATA_LOADING'
export const GET_FORUM_METADATA_ERROR = 'GET_FORUM_METADATA_ERROR'
export const GET_ALL_COUNTRY_ERROR = 'GET_ALL_COUNTRY_ERROR'
export const GET_ALL_COUNTRY = 'GET_ALL_COUNTRY'
export const GET_ALL_COUNTRY_LOAD = 'GET_ALL_COUNTRY_LOAD'
export const APPROVED_STATUS = 'APPROVED'
export const PENDING_STATUS = 'PENDING'
export const EDIT_ADMIN_BADGE_ERROR = 'EDIT_ADMIN_BADGE_ERROR'
export const EDIT_ADMIN_BADGE_POST = 'EDIT_ADMIN_BADGE_POST'
export const EDIT_ADMIN_BADGE_LOAD = 'EDIT_ADMIN_BADGE_LOAD'
export const GET_GLOSSARY_TERMS = 'GET_GLOSSARY_TERMS'
export const GET_GLOSSARY_TERMS_LOAD = 'GET_GLOSSARY_TERMS_LOAD'
export const GET_GLOSSARY_TERMS_ERROR = 'GET_GLOSSARY_TERMS_ERROR'
export const CREATE_GLOSSARY_TERM_POST = 'CREATE_GLOSSARY_TERM_POST'
export const CREATE_GLOSSARY_TERM_ERROR = 'CREATE_GLOSSARY_TERM_ERROR'
export const CREATE_GLOSSARY_TERM_LOAD = 'CREATE_GLOSSARY_TERM_LOAD'
export const GET_GLOSSARY_TERM_ID = 'GET_GLOSSARY_TERM_ID'
export const GET_GLOSSARY_TERM_ID_LOAD = 'GET_GLOSSARY_TERM_ID_LOAD'
export const GET_GLOSSARY_TERM_ID_ERROR = 'GET_GLOSSARY_TERMS_ERROR'
export const APPROVE_GLOSSARY_TERM_POST = 'APPROVE_GLOSSARY_TERM_POST'
export const APPROVE_GLOSSARY_TERM_ERROR = 'APPROVE_GLOSSARY_TERM_ERROR'
export const APPROVE_GLOSSARY_TERM_LOAD = 'APPROVE_GLOSSARY_TERM_LOAD'
export const REJECT_GLOSSARY_TERM_POST = 'REJECT_GLOSSARY_TERM_POST'
export const REJECT_GLOSSARY_TERM_ERROR = 'REJECT_GLOSSARY_TERM_ERROR'
export const REJECT_GLOSSARY_TERM_LOAD = 'REJECT_GLOSSARY_TERM_LOAD'
export const GET_PENDING_GLOSSARY_TERMS = 'GET_PENDING_GLOSSARY_TERMS'
export const GET_PENDING_GLOSSARY_TERMS_LOAD = 'GET_PENDING_GLOSSARY_TERMS_LOAD'
export const GET_PENDING_GLOSSARY_TERMS_ERROR =
  'GET_PENDING_GLOSSARY_TERMS_ERROR'
export const GET_COLLAB_SEARCH_RESULTS = 'GET_COLLAB_SEARCH_RESULTS'
export const GET_COLLAB_SEARCH_RESULTS_ERROR = 'GET_COLLAB_SEARCH_RESULTS_ERROR'
export const GET_COLLAB_SEARCH_RESULTS_LOADING =
  'GET_COLLAB_SEARCH_RESULTS_LOADING'
export const GET_SEARCH_GLOSSARY_TERMS = 'GET_SEARCH_GLOSSARY_TERMS'
export const GET_SEARCH_GLOSSARY_TERMS_LOAD = 'GET_SEARCH_GLOSSARY_TERMS_LOAD'
export const GET_SEARCH_GLOSSARY_TERMS_ERROR = 'GET_SEARCH_GLOSSARY_TERMS_ERROR'
export const EDIT_GLOSSARY_TERM_POST = 'EDIT_GLOSSARY_TERM_POST'
export const EDIT_GLOSSARY_TERM_ERROR = 'EDIT_GLOSSARY_TERM_ERROR'
export const EDIT_GLOSSARY_TERM_LOAD = 'EDIT_GLOSSARY_TERM_LOAD'
export const GET_DE_FILE_UPLOAD_PATH = 'GET_DE_FILE_UPLOAD_PATH'
export const GET_DE_FILE_UPLOAD_PATH_LOADING = 'GET_DE_FILE_UPLOAD_PATH_LOADING'
export const GET_DE_FILE_UPLOAD_PATH_ERROR = 'GET_DE_FILE_UPLOAD_PATH_ERROR'
export const GET_DE_FILE_UPLOAD_REGEX = 'GET_DE_FILE_UPLOAD_REGEX'
export const GET_DE_FILE_UPLOAD_REGEX_LOADING =
  'GET_DE_FILE_UPLOAD_REGEX_LOADING'
export const GET_DE_FILE_UPLOAD_REGEX_ERROR = 'GET_DE_FILE_UPLOAD_REGEX_ERROR'
export const GET_DE_FILE_UPLOAD_PRESENT_FILE_NAMES =
  'GET_DE_FILE_UPLOAD_PRESENT_FILE_NAMES'
export const GET_DE_FILE_UPLOAD_PRESENT_FILE_NAMES_LOADING =
  'GET_DE_FILE_UPLOAD_PRESENT_FILE_NAMES_LOADING'
export const GET_DE_FILE_UPLOAD_PRESENT_FILE_NAMES_ERROR =
  'GET_DE_FILE_UPLOAD_PRESENT_FILE_NAMES_ERROR'
export const ADD_SURVEY_LOAD = 'ADD_SURVEY_LOAD'
export const ADD_SURVEY_POST = 'ADD_SURVEY_POST'
export const ADD_SURVEY_ERROR = 'ADD_SURVEY_ERROR'
export const ADD_SURVEY_GROUP_ERROR = 'ADD_SURVEY_GROUP_ERROR'
export const ADD_SURVEY_GROUP_POST = 'ADD_SURVEY_GROUP_POST'
export const ADD_SURVEY_GROUP_LOAD = 'ADD_SURVEY_GROUP_LOAD'
export const GET_ADMIN_SURVEY_POST = 'GET_ADMIN_SURVEY_POST'
export const GET_ADMIN_SURVEY_LOAD = 'GET_ADMIN_SURVEY_LOAD'
export const GET_ADMIN_SURVEY_ERROR = 'GET_ADMIN_SURVEY_ERROR'
export const ADD_USER_SURVEY_GROUP_POST = 'ADD_USER_SURVEY_GROUP_POST'
export const ADD_USER_SURVEY_GROUP_LOAD = 'ADD_USER_SURVEY_GROUP_LOAD'
export const ADD_USER_SURVEY_GROUP_ERROR = 'ADD_USER_SURVEY_GROUP_ERROR'
export const GET_SURVEY_GROUP_LOAD = 'GET_SURVEY_GROUP_LOAD'
export const GET_SURVEY_GROUP_POST = 'GET_SURVEY_GROUP_POST'
export const GET_SURVEY_GROUP_ERROR = 'GET_SURVEY_GROUP_ERROR'
export const GET_SURVEY_GROUP_USERS_POST = 'GET_SURVEY_GROUP_USERS_POST'
export const GET_SURVEY_GROUP_USERS_ERROR = 'GET_SURVEY_GROUP_USERS_ERROR'
export const GET_SURVEY_GROUP_USERS_LOAD = 'GET_SURVEY_GROUP_USERS_LOAD'
export const GET_SURVEY_ERROR = 'GET_SURVEY_ERROR'
export const GET_SURVEY_LOAD = 'GET_SURVEY_LOAD'
export const GET_SURVEY_POST = 'GET_SURVEY_POST'
export const ACTIVATE_USER_SURVEY_GROUP_ERROR =
  'ACTIVATE_USER_SURVEY_GROUP_ERROR'
export const ACTIVATE_USER_SURVEY_GROUP_POST = 'ACTIVATE_USER_SURVEY_GROUP_POST'
export const ACTIVATE_USER_SURVEY_GROUP_LOAD = 'ACTIVATE_USER_SURVEY_GROUP_LOAD'
export const GET_USER_SURVEY_LOAD = 'GET_USER_SURVEY_LOAD'
export const GET_USER_SURVEY_ERROR = 'GET_USER_SURVEY_ERROR'
export const GET_USER_SURVEY_POST = 'GET_USER_SURVEY_POST'
export const SUBMIT_SURVEY_ERROR = 'SUBMIT_SURVEY_ERROR'
export const SUBMIT_SURVEY_LOAD = 'SUBMIT_SURVEY_LOAD'
export const SUBMIT_SURVEY_POST = 'SUBMIT_SURVEY_POST'
export const GET_TERM_STATUS = 'GET_TERM_STATUS'
export const GET_TERM_STATUS_ERROR = 'GET_TERM_STATUS_ERROR'
export const GET_TERM_STATUS_LOAD = 'GET_TERM_STATUS_LOAD'
export const GET_SURVEY_GROUP_LIST_LOAD = 'GET_SURVEY_GROUP_LIST_LOAD'
export const GET_SURVEY_GROUP_LIST_POST = 'GET_SURVEY_GROUP_LIST_POST'
export const GET_SURVEY_GROUP_LIST_ERROR = 'GET_SURVEY_GROUP_LIST_ERROR'
export const EDIT_SURVEY_ERROR = 'EDIT_SURVEY_ERROR'
export const EDIT_SURVEY_LOAD = 'EDIT_SURVEY_LOAD'
export const EDIT_SURVEY_POST = 'EDIT_SURVEY_POST'
export const NEW_INGESTION_INTAKE_REQUEST = 'NEW_INGESTION_INTAKE_REQUEST'
export const NEW_INGESTION_INTAKE_LOAD = 'NEW_INGESTION_INTAKE_LOAD'
export const NEW_INGESTION_INTAKE_ERROR = 'NEW_INGESTION_INTAKE_ERROR'
export const GET_INGESTION_INTAKE_DATA_SOURCE_TYPES_LOAD =
  'GET_INGESTION_INTAKE_DATA_SOURCE_TYPES_LOAD'
export const GET_INGESTION_INTAKE_DATA_SOURCE_TYPES =
  'GET_INGESTION_INTAKE_DATA_SOURCE_TYPES'
export const GET_INGESTION_INTAKE_DATA_SOURCE_TYPES_ERROR =
  'GET_INGESTION_INTAKE_DATA_SOURCE_TYPES_ERROR'
export const GET_INGESTION_INTAKE_REQUESTS = 'GET_INGESTION_INTAKE_REQUESTS'
export const GET_INGESTION_INTAKE_REQUESTS_LOAD =
  'GET_INGESTION_INTAKE_REQUESTS_LOAD'
export const GET_INGESTION_INTAKE_REQUESTS_ERROR =
  'GET_INGESTION_INTAKE_REQUESTS_ERROR'
export const GET_INGESTION_INTAKE_DATA_CLASSIFICATION =
  'GET_INGESTION_INTAKE_DATA_CLASSIFICATION'
export const GET_INGESTION_INTAKE_DATA_CLASSIFICATION_LOAD =
  'GET_INGESTION_INTAKE_DATA_CLASSIFICATION_LOAD'
export const GET_INGESTION_INTAKE_DATA_CLASSIFICATION_ERROR =
  'GET_INGESTION_INTAKE_DATA_CLASSIFICATION_ERROR'
export const GET_SURVEY_RESPONSE_ERROR = 'GET_SURVEY_RESPONSE_ERROR'
export const GET_SURVEY_RESPONSE_LOAD = 'GET_SURVEY_RESPONSE_LOAD'
export const GET_SURVEY_RESPONSE_POST = 'GET_SURVEY_RESPONSE_POST'
export const GET_SURVEY_RESPONSE_JSON_ERROR = 'GET_SURVEY_RESPONSE_JSON_ERROR'
export const GET_SURVEY_RESPONSE_JSON_POST = 'GET_SURVEY_RESPONSE_JSON_POST'
export const GET_SURVEY_RESPONSE_JSON_LOAD = 'GET_SURVEY_RESPONSE_JSON_LOAD'
export const SURVEY_REMINDER_LOAD = 'SURVEY_REMINDER_LOAD'
export const EXPORT_SURVEY_RESPONSE_LOAD = 'EXPORT_SURVEY_RESPONSE_LOAD'
export const SURVEY_REMINDER_POST = 'SURVEY_REMINDER_POST'
export const SURVEY_REMINDER_ERROR = 'SURVEY_REMINDER_ERROR'
export const GET_DBT_GRADING_PROJECTS = 'GET_DBT_GRADING_PROJECTS'
export const GET_DBT_GRADING_PROJECTS_LOAD = 'GET_DBT_GRADING_PROJECTS_LOAD'
export const GET_DBT_GRADING_PROJECTS_ERROR = 'GET_DBT_GRADING_PROJECTS_ERROR'
export const GET_DBT_GRADING_MODELS_ERROR = 'GET_DBT_GRADING_MODELS_ERROR'
export const GET_DBT_GRADING_MODELS = 'GET_DBT_GRADING_MODELS'
export const GET_DBT_GRADING_MODELS_LOAD = 'GET_DBT_GRADING_MODELS_LOAD'
export const GET_DBT_GRADING_SCORES_ERROR = 'GET_DBT_GRADING_SCORES_ERROR'
export const GET_DBT_GRADING_SCORES = 'GET_DBT_GRADING_SCORES'
export const GET_DBT_GRADING_SCORES_LOAD = 'GET_DBT_GRADING_SCORES_LOAD'
export const GET_PODS = 'GET_PODS'
export const GET_PODS_LOADING = 'GET_PODS_LOADING'
export const GET_PODS_ERROR = 'GET_PODS_ERROR'
export const GET_PODS_TABLE = 'GET_PODS_TABLE'
export const GET_PODS_TABLE_LOADING = 'GET_PODS_TABLE_LOADING'
export const GET_PODS_TABLE_ERROR = 'GET_PODS_TABLE_ERROR'
export const ADD_POD_ENTRY = 'ADD_POD_ENTRY'
export const ADD_POD_ENTRY_LOADING = 'ADD_POD_ENTRY_LOADING'
export const ADD_POD_ENTRY_ERROR = 'ADD_POD_ENTRY_ERROR'
export const EDIT_POD_ENTRY = 'EDIT_POD_ENTRY'
export const EDIT_POD_ENTRY_LOADING = 'EDIT_POD_ENTRY_LOADING'
export const EDIT_POD_ENTRY_ERROR = 'EDIT_POD_ENTRY_ERROR'
export const MY_PODS = 'MY_PODS'
export const MY_PODS_LOADING = 'MY_PODS_LOADING'
export const MY_PODS_ERROR = 'MY_PODS_ERROR'
export const EDIT_POD_APPROVAL = 'EDIT_POD_APPROVAL'
export const EDIT_POD_APPROVAL_LOADING = 'EDIT_POD_APPROVAL_LOADING'
export const EDIT_POD_APPROVAL_ERROR = 'EDIT_POD_APPROVAL_ERROR'
export const POD_FUNCTIONAL_AREA = 'POD_FUNCTIONAL_AREA'
export const POD_FUNCTIONAL_AREA_LOADING = 'POD_FUNCTIONAL_AREA_LOADING'
export const POD_FUNCTIONAL_AREA_ERROR = 'POD_FUNCTIONAL_AREA_ERROR'
export const GET_POD_MEMBERS = 'GET_POD_MEMBERS'
export const GET_POD_MEMBERS_LOADING = 'GET_POD_MEMBERS_LOADING'
export const GET_POD_MEMBERS_ERROR = 'GET_POD_MEMBERS_ERROR'
export const GET_POD_APPROVERS = 'GET_POD_APPROVERS'
export const GET_POD_APPROVERS_LOADING = 'GET_POD_APPROVERS_LOADING'
export const GET_POD_APPROVERS_ERROR = 'GET_POD_APPROVERS_ERROR'
export const GET_ALL_IDEAS_ERROR = 'GET_ALL_IDEAS_ERROR'
export const GET_ALL_IDEAS = 'GET_ALL_IDEAS'
export const GET_ALL_IDEAS_LOAD = 'GET_ALL_IDEAS_LOAD'
export const ADD_IDEAS_VOTE_LOAD = 'ADD_IDEAS_VOTE_LOAD'
export const ADD_IDEAS_VOTE_ERROR = 'ADD_IDEAS_VOTE_ERROR'
export const ADD_IDEAS_VOTE = 'ADD_IDEAS_VOTE'
export const ADD_IDEA_LOAD = 'ADD_IDEA_LOAD'
export const ADD_IDEA = 'ADD_IDEA'
export const ADD_IDEA_ERROR = 'ADD_IDEA_ERROR'
export const EDIT_IDEA_LOAD = 'EDIT_IDEA_LOAD'
export const EDIT_IDEA = 'EDIT_IDEA'
export const EDIT_IDEA_ERROR = 'EDIT_IDEA_ERROR'
export const ADD_COMMENT_IDEA_ERROR = 'ADD_COMMENT_IDEA_ERROR'
export const ADD_COMMENT_IDEA = 'ADD_COMMENT_IDEA'
export const ADD_COMMENT_IDEA_LOAD = 'ADD_COMMENT_IDEA_LOAD'
export const GET_IDEA_COMMENTS_BY_ID_ERROR = 'GET_IDEA_COMMENTS_BY_ID_ERROR'
export const GET_IDEA_COMMENTS_BY_ID = 'GET_IDEA_COMMENTS_BY_ID'
export const GET_IDEA_COMMENTS_BY_ID_LOAD = 'GET_IDEA_COMMENTS_BY_ID_LOAD'
export const GET_POD_BY_ID = 'GET_POD_BY_ID'
export const GET_POD_BY_ID_LOADING = 'GET_POD_BY_ID_LOADING'
export const GET_POD_BY_ID_ERROR = 'GET_POD_BY_ID_ERROR'
export const DELETE_POD = 'DELETE_POD'
export const DELETE_POD_LOADING = 'DELETE_POD_LOADING'
export const DELETE_POD_ERROR = 'DELETE_POD_ERROR'
export const POD_DETAILS = 'POD_DETAILS'
export const POD_DETAILS_LOADING = 'POD_DETAILS_LOADING'
export const POD_DETAILS_ERROR = 'POD_DETAILS_ERROR'
export const POD_CONSUMED_CREDITS_LOADING = 'POD_CONSUMED_CREDITS_LOADING'
export const POD_CONSUMED_CREDITS = 'POD_CONSUMED_CREDITS'
export const POD_CONSUMED_CREDITS_ERROR = 'POD_CONSUMED_CREDITS_ERROR'
export const POD_ASSETS_LOAD = 'POD_ASSETS_LOAD'
export const POD_ASSETS = 'POD_ASSETS'
export const POD_ASSETS_ERROR = 'POD_ASSETS_ERROR'
export const ADD_POD_ASSET_LOAD = 'ADD_POD_ASSET_LOAD'
export const ADD_POD_ASSET = 'ADD_POD_ASSET'
export const ADD_POD_ASSET_ERROR = 'ADD_POD_ASSET_ERROR'
export const DELETE_POD_ASSET_LOAD = 'DELETE_POD_ASSET_LOAD'
export const DELETE_POD_ASSET = 'DELETE_POD_ASSET'
export const DELETE_POD_ASSET_ERROR = 'DELETE_POD_ASSET_ERROR'
export const GET_IDEA_DETAIL_ERROR = 'GET_IDEA_DETAIL_ERROR'
export const GET_IDEA_DETAIL = 'GET_IDEA_DETAIL'
export const GET_IDEA_DETAIL_LOAD = 'GET_IDEA_DETAIL_LOAD'
export const APPROVE_REJECT_IDEA_LOAD = 'APPROVE_REJECT_IDEA_LOAD'
export const APPROVE_REJECT_IDEA = 'APPROVE_REJECT_IDEA'
export const APPROVE_REJECT_IDEA_ERROR = 'APPROVE_REJECT_IDEA_ERROR'
export const ASSIGN_IDEA_LOAD = 'ASSIGN_IDEA_LOAD'
export const ASSIGN_IDEA = 'ASSIGN_IDEA'
export const ASSIGN_IDEA_ERROR = 'ASSIGN_IDEA_ERROR'
export const MARK_IDEA_COMPLETION_LOAD = 'MARK_IDEA_COMPLETION_LOAD'
export const MARK_IDEA_COMPLETION = 'MARK_IDEA_COMPLETION'
export const MARK_IDEA_COMPLETION_ERROR = 'MARK_IDEA_COMPLETION_ERROR'
export const GET_AVG_ALGORITHMS_SCORE_ERROR = 'GET_AVG_ALGORITHMS_SCORE_ERROR'
export const GET_AVG_ALGORITHMS_SCORE = 'GET_AVG_ALGORITHMS_SCORE'
export const GET_AVG_ALGORITHMS_SCORE_LOAD = 'GET_AVG_ALGORITHMS_SCORE_LOAD'
export const ADD_TEMPLATE_ERROR = 'ADD_TEMPLATE_ERROR'
export const ADD_TEMPLATE_LOAD = 'ADD_TEMPLATE_LOAD'
export const ADD_TEMPLATE_POST = 'ADD_TEMPLATE_POST'
export const GET_TEMPLATE_LIST_ERROR = 'GET_TEMPLATE_LIST_ERROR'
export const GET_TEMPLATE_LIST_POST = 'GET_TEMPLATE_LIST_POST'
export const GET_TEMPLATE_LIST_LOAD = 'GET_TEMPLATE_LIST_LOAD'
export const GET_TEMPLATE_LOAD = 'GET_TEMPLATE_LOAD'
export const GET_TEMPLATE_POST = 'GET_TEMPLATE_POST'
export const GET_TEMPLATE_ERROR = 'GET_TEMPLATE_ERROR'
export const GET_JOB_POSTINGS_ERROR = 'GET_ALL_JOB_POSTINGS_ERROR'
export const GET_JOB_POSTINGS = 'GET_ALL_JOB_POSTINGS'
export const GET_JOB_POSTINGS_LOAD = 'GET_ALL_JOB_POSTINGS_LOAD'
export const GET_RELATED_GLOSSARY_TERMS = 'GET_RELATED_GLOSSARY_TERMS'
export const GET_RELATED_GLOSSARY_TERMS_LOAD = 'GET_RELATED_GLOSSARY_TERMS_LOAD'
export const GET_RELATED_GLOSSARY_TERMS_ERROR =
  'GET_RELATED_GLOSSARY_TERMS_ERROR'
export const DELETE_TEMPLATE_LOAD = 'DELETE_TEMPLATE_LOAD'
export const DELETE_TEMPLATE_POST = 'DELETE_TEMPLATE_POST'
export const DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR'
export const RECOVER_POD = 'RECOVER_POD'
export const RECOVER_POD_LOADING = 'RECOVER_POD_LOADING'
export const RECOVER_POD_ERROR = 'RECOVER_POD_ERROR'
export const EDIT_SURVEY_TEMPLATE_LOAD = 'EDIT_SURVEY_TEMPLATE_LOAD'
export const EDIT_SURVEY_TEMPLATE_POST = 'EDIT_SURVEY_TEMPLATE_POST'
export const EDIT_SURVEY_TEMPLATE_ERROR = 'EDIT_SURVEY_TEMPLATE_ERROR'
export const GET_FILTER_IDEAS_ERROR = 'GET_FILTER_IDEAS_ERROR'
export const GET_FILTER_IDEAS = 'GET_FILTER_IDEAS'
export const GET_FILTER_IDEAS_LOAD = 'GET_FILTER_IDEAS_LOAD'
export const GET_SNF_CLUSTER_DATA = 'GET_SNF_CLUSTER_DATA'
export const LOAD_SNF_CLUSTER_DATA = 'LOAD_SNF_CLUSTER_DATA'
export const ERROR_SNF_CLUSTER_DATA = 'ERROR_SNF_CLUSTER_DATA'
export const GET_ALL_ANNOUNCEMENT_ERROR = 'GET_ALL_ANNOUNCEMENT_ERROR'
export const GET_ALL_ANNOUNCEMENT = 'GET_ALL_ANNOUNCEMENT'
export const GET_ALL_ANNOUNCEMENT_LOAD = 'GET_ALL_ANNOUNCEMENT_LOAD'
export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT'
export const GET_CHAPTER_ANNOUNCEMENT = 'GET_CHAPTER_ANNOUNCEMENT'
export const GET_ANNOUNCEMENT_LOAD = 'GET_ANNOUNCEMENT_LOAD'
export const ADD_ANNOUNCEMENT_LOAD = 'ADD_ANNOUNCEMENT_LOAD'
export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT'
export const ADD_ANNOUNCEMENT_ERROR = 'ADD_ANNOUNCEMENT_ERROR'
export const UPDATE_ANNOUNCEMENT_LOAD = 'UPDATE_ANNOUNCEMENT_LOAD'
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT'
export const UPDATE_ANNOUNCEMENT_ERROR = 'UPDATE_ANNOUNCEMENT_ERROR'
export const DELETE_ANNOUNCEMENT_LOAD = 'DELETE_ANNOUNCEMENT_LOAD'
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT'
export const DELETE_ANNOUNCEMENT_ERROR = 'DELETE_ANNOUNCEMENT_ERROR'
export const RESTORE_ANNOUNCEMENT_LOAD = 'RESTORE_ANNOUNCEMENT_LOAD'
export const RESTORE_ANNOUNCEMENT = 'RESTORE_ANNOUNCEMENT'
export const RESTORE_ANNOUNCEMENT_ERROR = 'RESTORE_ANNOUNCEMENT_ERROR'
export const GET_RECENT_BADGES = 'GET_RECENT_BADGES'
export const GET_RECENT_BADGES_LOAD = 'GET_RECENT_BADGES_LOAD'
export const GET_RECENT_BADGES_ERROR = 'GET_RECENT_BADGES_ERROR'
export const GET_RECENT_KUDOS = 'GET_RECENT_KUDOS'
export const GET_RECENT_KUDOS_LOAD = 'GET_RECENT_KUDOS_LOAD'
export const GET_RECENT_KUDOS_ERROR = 'GET_RECENT_KUDOS_ERROR'
export const GET_TEMPLATE_PLACEHOLDER_LOAD = 'GET_TEMPLATE_PLACEHOLDER_LOAD'
export const GET_TEMPLATE_PLACEHOLDER_POST = 'GET_TEMPLATE_PLACEHOLDER_POST'
export const GET_TEMPLATE_PLACEHOLDER_ERROR = 'GET_TEMPLATE_PLACEHOLDER_ERROR'
export const ADD_VALIDATE_USER_EMAILS_LOAD = 'ADD_VALIDATE_USER_EMAILS_LOAD'
export const ADD_VALIDATE_USER_EMAILS_POST = 'ADD_VALIDATE_USER_EMAILS_POST'
export const ADD_VALIDATE_USER_EMAILS_ERROR = 'ADD_VALIDATE_USER_EMAILS_ERROR'
export const GET_NPS_METRICS = 'GET_NPS_TEMPLATES_METRICS'
export const GET_NPS_METRICS_LOAD = 'GET_NPS_TEMPLATES_METRICS_LOAD'
export const GET_NPS_METRICS_ERROR = 'GET_NPS_TEMPLATES_METRICS_ERROR'
export const LOAD_SNF_CLUSTER_DATA_BY_CLUSTER_ID =
  'LOAD_SNF_CLUSTER_DATA_BY_CLUSTER_ID'
export const GET_SNF_CLUSTER_DATA_BY_CLUSTER_ID =
  'GET_SNF_CLUSTER_DATA_BY_CLUSTER_ID'
export const ERROR_SNF_CLUSTER_DATA_BY_CLUSTER_ID =
  'ERROR_SNF_CLUSTER_DATA_BY_CLUSTER_ID'
export const GET_HOME_FILTERS_LOAD = 'GET_HOME_FILTERS_LOAD'
export const GET_HOME_FILTERS = 'GET_HOME_FILTERS'
export const GET_HOME_FILTERS_ERROR = 'GET_HOME_FILTERS_ERROR'
export const PREVIEW_TEMPLATE_LOAD = 'PREVIEW_TEMPLATE_LOAD'
export const PREVIEW_TEMPLATE_POST = 'PREVIEW_TEMPLATE_POST'
export const PREVIEW_TEMPLATE_ERROR = 'PREVIEW_TEMPLATE_ERROR'
export const GET_EMAIL_TEMPLATE_ERROR = 'GET_EMAIL_TEMPLATE_ERROR'
export const GET_EMAIL_TEMPLATE_POST = 'GET_EMAIL_TEMPLATE_POST'
export const GET_EMAIL_TEMPLATE_LOAD = 'GET_EMAIL_TEMPLATE_LOAD'
export const ADD_EMAIL_TEMPLATE_ERROR = 'ADD_EMAIL_TEMPLATE_ERROR'
export const ADD_EMAIL_TEMPLATE_POST = 'ADD_EMAIL_TEMPLATE_POST'
export const ADD_EMAIL_TEMPLATE_LOAD = 'ADD_EMAIL_TEMPLATE_LOAD'
export const DELETE_EMAIL_TEMPLATE_LOAD = 'DELETE_EMAIL_TEMPLATE_LOAD'
export const DELETE_EMAIL_TEMPLATE_POST = 'DELETE_EMAIL_TEMPLATE_POST'
export const DELETE_EMAIL_TEMPLATE_ERROR = 'DELETE_EMAIL_TEMPLATE_ERROR'
export const UPDATE_EMAIL_TEMPLATE_ERROR = 'ADD_EMAIL_TEMPLATE_ERROR'
export const UPDATE_EMAIL_TEMPLATE_POST = 'ADD_EMAIL_TEMPLATE_POST'
export const UPDATE_EMAIL_TEMPLATE_LOAD = 'ADD_EMAIL_TEMPLATE_LOAD'
export const GET_KHOLAB_CHAPTER_ADMIN = 'GET_KHOLAB_CHAPTER_ADMIN'
export const GET_KHOLAB_CHAPTER_ADMIN_LOADING = 'GET_KHOLAB_CHAPTER_ADMIN_LOADING'
export const GET_KHOLAB_CHAPTER_ADMIN_ERROR = 'GET_KHOLAB_CHAPTER_ADMIN_ERROR'
