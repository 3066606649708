import {
  GET_TEMPLATE_PLACEHOLDER_LOAD,
  GET_TEMPLATE_PLACEHOLDER_POST,
  GET_TEMPLATE_PLACEHOLDER_ERROR,
  } from '../../../constants/types'
  
  const initialState = {
    getTemplatePlaceholderData: [],
    loading: true,
    error: '',
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_TEMPLATE_PLACEHOLDER_LOAD:
        return {
          ...state,
          loading: true,
        }
      case GET_TEMPLATE_PLACEHOLDER_POST:
        return {
          ...state,
          loading: false,
          getTemplatePlaceholderData: action.payload,
        }
      case GET_TEMPLATE_PLACEHOLDER_ERROR:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }
      default:
        return state
    }
  }
  
