import chapter_overview from '../../../media/chapter_overview.png'
import c4e from '../../../media/c4e.png';
import fusionPOD from '../../../media/fusionPOD.png'

export const chapterHeads = [
        {
            "user_email": "maria.vasu@kraftheinz.com",
            "user_name": "maria.vasu",
            "role_type": "Agile",
            "link": "",
            "link_desc": "",
            "distribution_list": "dr-globalagilecoe@kraftheinz.com",
            "description": ""
        },
        {
            "user_email": "joseph.mcnamara@kraftheinz.com",
            "user_name": "joseph.mcnamara",
            "role_type": "Architecture",
            "link": "https://heinz365.sharepoint.com/sites/GlobalIT_EA",
            "link_desc": "",
            "distribution_list": "",
            "description": "Responsible for aligning business strategy to technology strategy via capability analysis and roadmaps. Drives strategic IT transformation programs (e.g. Capable, Lean & Health), guides system architecture designs, and supports handover to engineering teams for delivery.  Leads architecture governance which sets architecture standards/guideline, provides project & product guidance, and oversees execution of enterprise IT strategy."
        },
        {
            "user_email": "patrick.nestor@kraftheinz.com",
            "user_name": "patrick.nestor",
            "role_type": "Advanced Analytics",
            "link": "https://heinz365.sharepoint.com/:fl:/g/contentstorage/CSP_cd64517f-e2c8-4ce7-94b9-49d945f472bb/EaJEG9tCpatEjGScmgzS-jIBMcWB7I9UN12hsy1OeMOHSA?e=8N1Z5s&nav=cz0lMkZjb250ZW50c3RvcmFnZSUyRkNTUF9jZDY0NTE3Zi1lMmM4LTRjZTctOTRiOS00OWQ5NDVmNDcyYmImZD1iJTIxZjFGa3pjamk1MHlVdVVuWlJmUnl1OHRuOEtGaVE0VkVvaERlRGowRXhrTmJXRko1dllTU1Jhc1NuaWJ5WWIwWCZmPTAxTFpPSkRUNUNJUU41V1FWRlZOQ0lZWkU0VElHTkY2UlMmYz0lMkYmYT1Mb29wQXBwJnA9JTQwZmx1aWR4JTJGbG9vcC1wYWdlLWNvbnRhaW5lciZ4PSU3QiUyMnclMjIlM0ElMjJUMFJUVUh4b1pXbHVlak0yTlM1emFHRnlaWEJ2YVc1MExtTnZiWHhpSVdZeFJtdDZZMnBwTlRCNVZYVlZibHBTWmxKNWRUaDBiamhMUm1sUk5GWkZiMmhFWlVScU1FVjRhMDVpVjBaS05YWlpVMU5TWVhOVGJtbGllVmxpTUZoOE1ERk1XazlLUkZRelJrcEhXbGxJU0U1TlQwWkVXVU5HV1VoWlZ6SkJUakpJVXclM0QlM0QlMjIlMkMlMjJpJTIyJTNBJTIyYmJmYTBkYjQtMDk0Ny00NTA2LWI4OGMtY2ZlNWY3NmU5MTlkJTIyJTdE",
            "link_desc": "Loop Page",
            "distribution_list": "dl_khc_global_adv_analytics@kraftheinz.com",
            "description": "Advanced Analytics chapter is comprised of group of employees who specializes in designing & building analytics, machine learning and AI solutions that support business partners around KHC in driving business value through data-driven decisions."
        },
        {
            "user_email": "jaques.castello@kraftheinz.com",
            "user_name": "jaques.castello",
            "role_type": "BI Engineering",
            "link": "https://heinz365.sharepoint.com/:u:/r/sites/GlobalBusinessIntelligence/SitePages/ProjectHome.aspx?csf=1&web=1&e=gB1FUc",
            "link_desc": "Need to request access",
            "distribution_list": "BI.CHAPTER@kraftheinz.com",
            "description": "Responsible for delivering critical business data in accessible formats such as reports, dashboards, and visualizations. BI teams are responsible for the ongoing analysis of business data to support decision-making across the organization."
        },
        {
            "user_email": "ashish.agrawal@kraftheinz.com",
            "user_name": "ashish.agrawal",
            "role_type": "Cloud Engineering",
            "link": "https://kraftheinz.atlassian.net/wiki/spaces/CCOE/pages/2201255941",
            "link_desc": "Jira Access Required",
            "distribution_list": "CloudCOE@kraftheinz.com",
            "description": "Responsible for providing landing zones, advanced ecosystem of tools, platforms, knowledge resources, and Infrastructure as Code framework, to empower Digital and IT teams (across horizons) to create cost-efficient and sustainable cloud solutions."
        },
        {
            "user_email": "gyansagar.sikharam@kraftheinz.com",
            "user_name": "gyansagar.sikharam",
            "role_type": "Data Architecture",
            "link": "",
            "link_desc": "",
            "distribution_list": "",
            "description": ""
        },
        {
            "user_email": "bryan.chavez@kraftheinz.com",
            "user_name": "bryan.chavez",
            "role_type": "Data Engineering",
            "link": "https://deweb.apps.kraftheinz.com/home",
            "link_desc": "",
            "distribution_list": "sgkhc_data_engineering@kraftfoods.com",
            "description": "Responsible for designing, building, and maintaining systems and integrations for collecting, storing, and analyzing data at scale. It's the vital infrastructure that allows businesses to make data-driven decisions, develop new products and services"
        },
        {
            "user_email": "diogo.cassimiro@kraftheinz.com",
            "user_name": "diogo.cassimiro",
            "role_type": "Data Stewardship",
            "link": "",
            "link_desc": "",
            "distribution_list": "",
            "description": ""
        },
        {
            "user_email": "andrew.lindsay@kraftheinz.com",
            "user_name": "andrew.lindsay",
            "role_type": "Enterprise Design",
            "link": "https://heinz365.sharepoint.com/sites/KHEnterpriseDesign",
            "link_desc": "",
            "distribution_list": "ent.design@kraftheinz.com",
            "description": "Responsible for design thinking, user experience (UX) design, and engineering principles to create meaningful and holistic experiences for users. They go beyond the traditional UX designer by focusing on the entire journey of the user, including physical and digital touchpoints, emotions, and social interactions."
        },
        {
            "user_email": "mir.ali11@kraftheinz.com",
            "user_name": "mir.ali11",
            "role_type": "Full-Stack Software Engineering",
            "link": "https://heinz365.sharepoint.com/sites/KHDigitalEngineering/SitePages/Team%20Page%202.aspx",
            "link_desc": "",
            "distribution_list": "DL_KHC_FullStack_ENGINEERING@kraftheinz.com",
            "description": "Responsible for the back end and front-end design, documentation, development, modification, testing, installation, implementation, maintenance of new or existing applications."
        },
        {
            "user_email": "jorge.balestra@kraftheinz.com",
            "user_name": "jorge.balestra",
            "role_type": "ML Engineering",
            "link": "https://heinz365.sharepoint.com/sites/KHCMLOps258",
            "link_desc": "",
            "distribution_list": "DL_KHC_MLOPS_ENGINEERING@kraftheinz.com",
            "description": "Responsible for building and deploying machine learning (ML) models into production. ML engineers are responsible for the entire ML pipeline, from data collection and preparation to model training, deployment, and monitoring. They are also responsible for maintaining the successful execution of ML pipelines, scaling and optimizing them when required."
        },
        {
            "user_email": "joy.soda@kraftheinz.com",
            "user_name": "joy.soda",
            "role_type": "Service Operations",
            "link": "https://teams.microsoft.com/l/channel/19%3AyCWAQLM9FbJxMWWicAsiLeJXSfG2Yavnc7Dc-TH3ILI1%40thread.tacv2/General?groupId=4471aa4d-048e-4887-8ea5-389eb45e610b&tenantId=",
            "link_desc": "Need to Request Access",
            "distribution_list": "dlkhcoperationschapterleads@kraftheinz.com",
            "description": ""
        }
    ];

export const kholabSections = [
    {
        title: 'PODs',
        content: 'A central repository of POD (product-oriented delivery) teams to identify different Chapter members working together to deliver products.  This will give full visibility (all PODs, not just a specific POD) on team member composition and maturity (badges/kudos), quality of the products being delivered (PENDING), as well as product resource consumption (PENDING).  The individual POD view also provides an easy way to contact a specific POD for product-related questions or issues.  This information will be kept up-to-date through a recurring certification process (PENDING).  The product owners and stakeholders will also be used to drive external Chapter NPS surveys within Kholab (PENDING).'
    },
    {
        title: 'Q&A Forum',
        content: 'A quick way to search answers and ask questions with respect to a specific area (some Chapter / some non-Chapter).  This allows the knowledge to be long-lived and visible as opposed to getting lost or archived via email or Microsoft Teams.  This will help prevent the same questions being asked especially for new hires and junior resources.  As the amount and quality of knowledge content increases, a future integration will include automated recommended answers to questions via an LLM (PENDING).'
    },
    {
        title: 'Badge',
        content: 'A lense on Chapter members and their competencies identified as critical by the respective Chapter Head and C4E (badges can only be created by Chapter leadership). This view will help Chapter members to identify potential mentors or experts for a given area or help project teams identify individuals suited for specific project deliverables and requirements.  Unlike other certifications tools, a Chapter member may request a badge by submitting evidence that shows practical application of the knowledge as opposed to only academic understanding.  A general guideline around the types of badges are: BRONZE = developing skills, SILVER = individual expert, GOLD = demonstrated sharing/teaching of skills.  The summary view provides leadership a holistic view on chapter member maturity (internal group and external vendor alignment to Chapter competencies).  In addition, clicking on a Chapter member&lsquo;s row, shows their profile which includes specific badge details (as well as kudos).'
    },
    {
        title: 'Ideas',
        content: 'A centralized queue of Chapter ideas that are visible for discourse and voting for the Chapter and the organization. This will help leadership get a pulse on how to improve capabilities, address pain points, and bring new innovations to the Chapter - prioritized on need or interest (upvoting).  Submitted ideas should be more focused on broad changes that multiple members or PODs can benefit from and less focused on POD/Product specific requirements (maintained separately in JIRA/ADO backlogs).'
    },
    {
        title: 'Glossary',
        content: 'A community-enriched glossary where anyone can submit and search terms that are relevant to a specific area (some Chapter / some non-Chapter).  This will help new hires, junior resources, and others to get acclimated to jargon, acronyms, and terms important to a Chapter or to the business.  Kraft Heinz specific terms will be highlighted and be filterable.'
    },
    {
        title: 'Send Kudos',
        content: 'An easy mechanism to send an appreciation to any colleague (not just full-time employees but also contractors and consultants).  This information will be long-lived and centrally available in the user profile as well as summarized in the Badges/PODs views.  A future integration will incorporate Celebrations (limited to only FTEs) data (PENDING).'
    },
    {
        title: 'Job Postings',
        content: 'A listing of internal job openings filtered for each Chapter where Kraft Heinz employees can be redirected to apply in Workday or to contact a hiring manager directly.  This will help promote internal hiring and provide visibility to new opportunities within each Chapter.'
    },
    {
        title: 'NPS',
        content: 'A mechanism for Chapter Heads to run an internal NPS (for Chapter members) as well as an external NPS (for Stakeholders / Product Owners - defined from PODs with participating Chapter members). This capability will focus on transparency while also providing automations for more efficient execution and analysis of the surveys.'
    }
]

export const OverViewTabContent = () => {
    return (
        <>
            <div className="dashboard-flex">
                <div className="first-part">
                    <h2>What is a Chapter?</h2>
                </div>
                <div className="second-part">
                    <p>A Chapter is a cross-cutting global network of specialist employees, sharing a common job function. Chapters ensure that all members have access to frameworks / tools / platforms, professional development, & guidance on how to perform at their peak. Chapters enable the high-quality deliverables and outcomes of Chapter members. Membership of Chapters is mandatory where that specialism is the core of an employee’s role.</p>
                    <p>Below is a sample of KHC Chapters in practice:</p>
                    <img data-testid="media-image" draggable="false" alt="Chapter Overview Image" src={chapter_overview} className="dashboard-modal-image"></img>
                </div>
            </div>
            <div className="dashboard-flex">
                <div className="first-part">
                    <h2>Why Chapters ?</h2>
                </div>
                <div className="second-part">
                    <ul>
                        <li>
                            Siloed teams developing siloed products w/ less alignment to enterprise / platform strategy
                        </li>
                        <li>
                            Macro technical debt - inconsistencies, inefficiencies, duplications across solutions
                        </li>
                        <li>
                            Imbalance between delivery and continuous improvement/operations for a function
                        </li>
                        <li>
                            Lack of strategy (tool/people) for how a specific type of work/function will be done across the enterprise
                        </li>
                        <li>
                            Lack of established best-practices, guard-rails, and standards
                        </li>
                        <li>
                            Disconnect between platform capabilities and what the users need or want
                        </li>
                        <li>
                            Lack of governance of the quality of external / vendor resources
                        </li>
                        <li>
                            Lack of development path to achieve mastery of given skill / domain
                        </li>
                        <li>
                            Lack of progression, collaboration, and community
                            <br></br><br></br>
                            <strong>&ldquo;Scale up with Agile but do it efficiently and responsibly with Chapters&rdquo;</strong>
                            <br></br><br></br>
                            <strong>&ldquo;PODs brings focus to the WHAT/WHY, Chapters bring focus on the HOW&rdquo;</strong>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="dashboard-flex">
                <div className="first-part">
                    <h2>KHC Chapter Model Definitions</h2>
                </div>
                <div className="second-part">
                    <h3>Chapter </h3>
                    <p>Chapter is a cross-cutting global network of specialist employees, sharing a common job function. Chapters ensure that all members have access to frameworks / tools / platforms, professional development, &amp; guidance on how to perform at their peak. Chapter’s enable the high-quality deliverables and outcomes of Chapter members. Membership of Chapters is mandatory where that specialism is the core of an employee’s role.</p>
                    <p><em>Chapter members may or may not report to the Head of the Chapter.</em></p>
                    <h3 >Center for Enablement (C4E)</h3>
                    <p>Center for Enablement (C4E) (optional depending on total number of Chapter members) is focused on enablement and tasked with building frameworks; productizing, publishing &amp; harvesting reusable assets / tools; creating guard rails; creating training material and knowledge repositories; and establishing best practices / standards for the Chapter. C4E Members are highly-experienced senior professionals that support multiple services, projects, platforms, &amp; federated products in an execution and/or advisory capacity to the Fusion PODs / Organization Units / and Communities of Interest.</p>
                    <p><em>The C4E members report directly to the Head of the Chapter.</em></p>
                    <h3>POD</h3>
                    <p>POD (product-oriented delivery) is a multi-disciplinary team combining technical resources from multiple Chapters to build and operate digital products. Membership of PODs is not static and adjusts to the needs of the Product throughout its life. Product Owners must work together with technical POD members to assign work and provide day-to-day management of the project/program.</p>
                    <p><em>Pods can be made up of members from multiple Chapters and can be part of an Organization Unit or directly aligned to a specific engagement.</em></p>
                    <h3>Organization Unit (OU)</h3>
                    <p>Organization Unit (OU) is a group containing multiple PODs who coordinate between each other under the same administration. The organization unit leadership will be responsible for setting the vision and direction for that organization and encouraging collaboration across PODs / Chapter members within those PODs.</p>
                    <p><em>The organization unit is made up of PODs with members from different chapters.</em></p>
                    <h3>Community of Practice / Interest (CoP / CoI)</h3>
                    <p>Communities of Practice (a.k.a. Communities of Interest) is a group of people with a special interest in an area or topic. They may be linked to Chapters but is not essential or required. Membership of communities of interest is optional and open to all - anyone can join regardless if their role is related to the area or topic.</p>
                    <p><em>Communities of Practice will typically be led by a volunteer coordinator from a relevant Chapter (not a formal role).</em></p>
                </div>
            </div>
            <div className="dashboard-flex">
                <div className="first-part">
                    <h2>When does a Chapter make sense?</h2>
                </div>
                <div className="second-part">
                    <h3>Job Function Employee Density</h3>
                    <ul>
                        <li>Employees less than 5 - No</li>
                        <li>Employees 5 to 10 - Maybe</li>
                        <li>Employees greater than 10 - Yes</li>
                    </ul>
                    <h3>Geographical Coverage</h3>
                    <ul>
                        <li>Employees limited to a single zone - Maybe</li>
                        <li>Employees global presence - Yes</li>
                    </ul>
                    <h3>Job Complexity</h3>
                    <ul>
                        <li>Highly-specialized job function - Yes</li>
                        <li>Complex role requiring continuous development across multiple areas (depth or breadth) - Yes</li>
                        <li>Would benefit from centralized governance and standards - Yes</li>
                        <li>Would benefit from centralized enablement of capabilities, process, and platforms - Yes</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export const FusionPODContent = () => {
    return (
        <>          
            <h2><strong>POD</strong> = <em>“Product Oriented Delivery”</em></h2>
            <p>A Fusion / collaboration between multi-disciplinary Chapter members and silo team members to execute the delivery of digital products. Fusion PODs work closely with stakeholders to ensure that products meet their needs by delivering high-quality software products quickly and efficiently. To achieve this, Fusion PODs use an agile (iterative and incremental) approach to development and operations, breaking down product development into smaller, more manageable tasks. These teams work independently with the required self-sufficiency to ensure timely delivery and progress. It is the responsibility of Fusion PODs to plan for development as well as operational responsibilities (80% development, 20% operations: quality/tech-debt/bug fixes/testing).</p>
            <ul>
                <li>New initiatives should leverage existing PODs if the scope and timelines can be managed.</li>
                <li>Chapter members must bring visibility and transparency to Chapter initiatives into the POD backlog</li>
                <li>The product owner will manage product development but must also collaborate with the different C4Es to deliver centrally-driven initiatives and practices.</li>
            </ul>
            <h2>Benefits:</h2>
            <ul>
                <li>Increased agility: Agile pods can adapt quickly to changes in requirements or market conditions.</li>
                <li>Improved collaboration: Pods bring together people with different skills and perspectives, which can lead to better problem-solving and decision-making.</li>
                <li>Faster time to market: Agile pods can deliver products more quickly than traditional teams.</li>
                <li>Reduced costs: Agile pods can help to reduce costs by eliminating the need for handoffs between teams and by minimizing rework.</li>
            </ul>
            <img data-testid="media-image" draggable="false" alt="Fusion POD Image" src={fusionPOD} className="dashboard-modal-image" style={{width: 'fit-content'}}></img>
</>
    )
}

export const ChapterDeployFramework = () => {
    return (
        <>
        <h3>Phase 1</h3>
        <ul>
            <li>Select Chapter Head</li>
            <li>Establish agreement on global alignment and scope</li>
            <li>Design C4E Structure and start hiring</li>
            <li>Identify mechanism to identify all existing Chapter members</li>
            <li>Cleanup Job Family in Workday</li>
        </ul>
        <h3>Phase 2</h3>
        <ul>
            <li>Create Email Distribution List for all Chapter members</li>
            <li>Establish access governance to internal systems and services (Create single AD Group owned by Head of Chapter)</li>
            <li>This will help mitigate resources being brought in w/o Chapter consent</li>
            <li>Establish hiring governance process / interview panel</li>
            <li>C4E should be embedded into hiring of both contractors and FTEs</li>
            <li>Chapter Head meet with vendors to discuss new governance process, standards, & ways of working</li>
            <li>Develop formal onboarding template</li>
        </ul>
        <h3>Phase 3</h3>
        <ul>
            <li>Establish monthly Chapter meetings</li>
            <li>Publish calendar for learning opportunities</li>
            <li>Introduce collaboration tools</li>
            <li>Create single repository for Chapter knowledge and documentation</li>
            <li>Engage OCM to create formal communications around Chapter deployment</li>
        </ul>
        <h3>Phase 4</h3>
        <ul>
            <li>Establish operational health KPIs / metrics</li>
            <li>Create control tower dashboard</li>
            <li>Setup quarterly cadence for NPS Survey for Chapter members</li>
            <li>Build system guardrails and automated standards enforcement processes</li>
            <li>If applicable build platform / tools to facilitate Chapter maturity</li>
            <li>Create knowledge / document repository</li>
        </ul>
        <h3>Future</h3>
        <ul>
            <li>Develop formal training program</li>
            <li>Establish talent pipeline via internship opportunities</li>
            <li>Mentorship and shadowing opportunities</li>
            <li>Develop competency framework</li>
            <li>Work with federated team member organizations to be part of performance-related discussions</li>
        </ul>
        </>
    )
}

export const RolesResponsibilitiesContent = () => {
    return (
        <>
            <div className="dashboard-flex">
                <div className="first-part">
                    <h2>Head of Chapter</h2>
                </div>
                <div className="second-part">
                    <p>The Head of Chapter is the most senior member of the professional network. They must evaluate, promote & coach their people, but without traditional direct oversight outside of the C4E. They are not involved in managing the day-to-day work of chapter members. They are hands-on servant leaders who prioritize serving the greater good, empowering chapter members to deliver amazing outcomes, while being fully capable of performing as a senior technical expert in their field.</p>
                    <p>They are accountable to:</p>
                    <ul>
                        <li>Lead the Chapter globally - not limited or dedicated to a specific region.</li>
                        <li>Servant Leadership - inspire vision, encourage diversity of thought, create culture of trust, empathize, foster leadership/growth in others.</li>
                        <li>Directly manage Center for Enablement (C4E) team members (if a C4E exists for a chapter).</li>
                        <li>Govern a pool of 3rd party vendors, ensuring specialist resources are available for short-to-medium term engagements.</li>
                        <li>Define strategy, standards / best practices, and tooling.</li>
                        <li>Build community, ensuring members meet regularly to discuss their discipline, and to support each other.</li>
                        <li>Drive excellence, ensuring professional development & growth opportunities for all Chapter members.</li>
                        <li>Unleash talent, overseeing recruitment, performance management & assignment planning processes.</li>
                    </ul>
                </div>
            </div>
            <div className="dashboard-flex">
                <div className="first-part">
                    <h2>Center for Enablement (C4E)</h2>
                </div>
                <div className="second-part">
                    <p>C4E team consists of 4-8 members team who directly report into Chapter Head and help with below activities.</p>
                    <img data-testid="media-image" draggable="false" alt="Center for Enablement Image" src={c4e} className="dashboard-modal-image" style={{width: 'fit-content', display: 'block'}}></img>
                    <strong>Staffing</strong>  
                    <ul>
                        <li>Main point of contact for staffing. Provide staffing solutions with the flexibility to reallocate resources to support changing business needs, while ensuring resources are used efficiently across product / platform teams</li>
                        <li>Establish interview process / governance for both FTEs and contractors. Negotiate / manage process for developing vendor bench pipeline (investment vendor resources (no-cost))</li>
                        <li>Talent / Internship pipeline</li>
                    </ul>
                    <strong>Resource Development</strong>
                    <ul>
                        <li>Onboarding / tooling / platform capabilities</li>
                        <li>Establish Career Framework / Competency Model for FTEs</li>
                        <li>Learning Opportunities: brown-bag / knowledge-sharing sessions; coaching dojos; experiment / incubator boards</li>
                        <li>Provide development pathways for resource groups (all chapter members) aligned to specific IT disciplines and competencies.
                            <ul>
                                <li>Establish technical competency requirements for vendor resources; external vendor training</li>
                                <li>Professional / internal certifications</li>
                            </ul>
                        </li>
                        <li>House / incubate specialty skill sets required for product / platform delivery. As chapters mature, resources can shift to functional IT teams</li>
                        <li>Performance / OPR discussions for FTEs</li>
                    </ul>
                    <strong>Operational</strong>
                    <ul>
                        <li>Management of centralized knowledge repository / documentation / chapter-branding</li>
                        <li>Establish technical guardrails (automated) and process / routines (design feedback / reviews / approvals / deployments)</li>
                        <li>Functional / domain expertise and solution / architecture guidance</li>
                        <li>Develop analytics / reporting on metrics and KPIs for measuring Chapter health and impact + NPS surveys</li>
                        <li>Facilitate monthly chapter meetings (celebrate victories, communicate strategy, highlight new platform capabilities, retrospectives)</li>
                    </ul>
                </div>
            </div>
            <div className="dashboard-flex">
                <div className="first-part">
                    <h2>Chapter Members</h2>
                </div>
                <div className="second-part">
                    <p>Chapter members are employees from different areas of the organization who perform similar job functions. They are responsible for:</p>
                    <ul>
                        <li>Using standard Chapter framework, tools & techniques.</li>
                        <li>Responsible for contributing to chapter learning and best practices.</li>
                        <li>Follow similar career path defined by their job functions.</li>
                    </ul>
                </div>
            </div>
        </>
    )
}


export const chapterContent = {
    defaultTab: 'Overview',
    dataSet: [
        {
            key: 'Overview',
            title: 'Overview',
            content: <OverViewTabContent />
        },
        {
            key: 'fusionPOD',
            title: 'Fusion POD',
            content: <FusionPODContent /> 
        },
        {
            key: 'Roles&Responsibilities',
            title: 'Roles & Responsibilities',
            content: <RolesResponsibilitiesContent />
        },
        {
            key: 'chapterDeployFramework',
            title: 'Chapter Deploy Framework',
            content: <ChapterDeployFramework />
        },
    ]
}
