import React from 'react'
import { Box, Container, Divider, Typography, Grid } from '@mui/material'
import { DynamicComposedChart } from '../Utilities/DynamicComposedChart'
import {CustomTooltipContainer,useStyles,MatrixBox} from  './npsStyling'

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { promoters, passives, detractors, recipients, responses } =
      payload[0].payload

    return (
      <CustomTooltipContainer>
        <p style={{ margin: 0, fontWeight: 'bold' }}>{`${label}`}</p>
        <p
          style={{ color: 'blue', margin: 0 }}
        >{`NPS: ${payload[3]?.payload?.nps}%`}</p>
        <Divider style={{ margin: '5px 0' }} />
        <p style={{ color: '#70B77E', margin: 0 }}>
          {`Promoters: ${promoters}%`}
        </p>
        <p style={{ color: '#DAA520', margin: 0 }}>
          {`Passives: ${passives}%`}
        </p>
        <p style={{ color: '#E57373', margin: 0 }}>
          {`Detractors: ${detractors}%`}
        </p>
        <Divider style={{ margin: '5px 0' }} />
        <p style={{ margin: 0 }}>{`Recipients: ${recipients}`}</p>
        <p style={{ margin: 0 }}>{`Responses: ${responses}`}</p>
      </CustomTooltipContainer>
    )
  }

  return null
}

export default function Kpi({ surveys }) {
  const barDataKeys = ['promoters', 'passives', 'detractors']
  const barColors = {
    promoters: '#70B77E',
    passives: '#FFDD57',
    detractors: '#E57373',
  }
  const lineDataKey = 'nps'
  const lineColor = 'blue'
  const classes = useStyles()

  const hasSurveysWithValidData = surveys.some(
    (survey) => survey.data.length > 0
  )

  return (
    <MatrixBox>
      <Grid container spacing={4} justifyContent="center">
        {hasSurveysWithValidData ? (
          surveys.map((survey, index) => (
            <Grid item xs={12} sm={6} key={index}>
              {survey?.data?.length > 0 && (
                <Box
                  sx={{
                    backgroundColor: 'transparent',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    padding: '16px',
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "'Helvetica Neue', Arial, sans-serif",
                      marginBottom: 1,
                      fontStyle: 'italic',
                    }}
                  >
                    {survey.title}
                  </Typography>
                  <DynamicComposedChart
                    data={survey.data}
                    intervals={1}
                    xAxisKey="created_on"
                    yAxisLabel="NPS SCORE PERCENTAGE"
                    yAxisDomain={[-100, 100]}
                    yAxisTicks={Array.from(
                      { length: 21 },
                      (_, i) => i * 10 - 100
                    )}
                    barDataKeys={barDataKeys}
                    barColors={barColors}
                    lineDataKey={lineDataKey}
                    lineColor={lineColor}
                    tooltipContent={CustomTooltip}
                  />
                  <Container>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box className={classes.boxContainer}>
                        <Box className={classes.nps} />
                        <Typography
                          variant="body2"
                          sx={classes.annotation}
                        >
                          NPS
                        </Typography>
                      </Box>
                      <Box className={classes.boxContainer}>
                        <Box className={classes.promoters} />
                        <Typography
                          variant="body2"
                          sx={classes.annotation}
                        >
                          Promoters
                        </Typography>
                      </Box>
                      <Box className={classes.boxContainer}>
                        <Box className={classes.passives} />
                        <Typography
                          variant="body2"
                          sx={classes.annotation}
                        >
                          Passives
                        </Typography>
                      </Box>
                      <Box className={classes.boxContainer}>
                        <Box className={classes.detractors} />
                        <Typography
                          variant="body2"
                          sx={classes.annotation}
                        >
                          Detractors
                        </Typography>
                      </Box>
                    </Box>
                    <Divider style={{ margin: '5px 0' }} />
                  </Container>
                </Box>
              )}
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', padding: '20px' }}
            >
              More KPIs to come...
            </Typography>
          </Grid>
        )}
      </Grid>
    </MatrixBox>
  )
}
